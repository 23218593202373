import { UTMParameters } from "@app/shared/types";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";

const initialState: {
    menuOpen: boolean;
    referralCode: string | null;
    utmParameters: UTMParameters | null;
} = {
    menuOpen: false,
    referralCode: null,
    utmParameters: null,
};

const navigationSlice = createSlice({
    name: "navigation",
    initialState: initialState,
    reducers: {
        openMenu: (state) => Object.assign(state, { menuOpen: true }),
        closeMenu: (state) => Object.assign(state, { menuOpen: false }),
        toggleMenu: (state) => Object.assign(state, { menuOpen: !state.menuOpen }),
        setReferralCode: (state, action: PayloadAction<string>) => ({
            ...state,
            referralCode: action.payload,
        }),
        setUtmParameters: (state, action: PayloadAction<UTMParameters>) => ({
            ...state,
            utmParameters: action.payload,
        }),
    },
});

export const selectIsMenuOpen = (state: RootState) => state.navigation.menuOpen;

export const selectReferralCode = (state: RootState) => state.navigation.referralCode;

export const selectUtmParameters = (state: RootState) => state.navigation.utmParameters;

export const { openMenu, closeMenu, toggleMenu, setReferralCode, setUtmParameters } =
    navigationSlice.actions;
export default navigationSlice.reducer;
