import { useQuery } from "@apollo/client";
import { GRAPHQL_QUERY_IS_USER_ENROLLED_IN_COURSES } from "app/queries";
import { selectIsEnrolledInCourses } from "features/auth/auth";
import { useSelector } from "react-redux";

export const useIsUserEnrolledInCourses = () => {
    const hasUserFeatureEnrolledInCourses = useSelector(selectIsEnrolledInCourses);

    const { data } = useQuery(GRAPHQL_QUERY_IS_USER_ENROLLED_IN_COURSES, {
        fetchPolicy: "network-only",
        skip: hasUserFeatureEnrolledInCourses,
    });

    return hasUserFeatureEnrolledInCourses || data?.isUserEnrolledInCourses || false;
};
