import { Sangha, UserProfile } from "@app/shared/types";

export const getSanghaMemberProfiles = (sangha: Sangha): UserProfile[] => {
    if (sangha && sangha.memberships) {
        return sangha.memberships
            .map((membership) => membership.user?.profile)
            .filter((p) => p !== undefined);
    }
    return [];
};
