import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import { connectRouter, routerMiddleware } from "connected-react-router";
import "firebase/analytics";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/functions";
import "firebase/storage";
import { actionTypes, firebaseReducer } from "react-redux-firebase";
import { combineReducers } from "redux";
import authReducer from "../features/auth/auth";
import communityReducer from "../features/community/communitySlice";
import memberReducer from "../features/member/memberReducer";
import navigationReducer from "../features/navigation/navigationSlice";
import history from "./history";
import { trackingMiddleware } from "./tracking";

export const runningOnLocalhost = window.location.hostname === "localhost";

/* FIREBASE */

const firebaseConfig = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_DATABASE_URL,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGIN_GSENDERID,
    appId: process.env.REACT_APP_APP_ID,
    measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};

firebase.initializeApp(firebaseConfig);
firebase.setLogLevel("info");
firebase.analytics.isSupported().then((isSupported) => {
    if (isSupported) {
        firebase.analytics();
    }
});
firebase.functions();

if (runningOnLocalhost) {
    firebase.functions().useFunctionsEmulator("http://localhost:5001");
    //firebase.firestore().settings({host: "localhost:8080",ssl: false});
}

/* STORE */

const createRootReducer = (history: any) => {
    return combineReducers({
        // Core app
        router: connectRouter(history),
        navigation: navigationReducer,
        firebase: firebaseReducer,
        // firestore: firestoreReducer,

        // auth
        auth: authReducer,

        // public pages
        community: communityReducer,

        // member zone
        member: memberReducer,
    });
};

export const rootReducer = createRootReducer(history);

export const middlewares = [
    routerMiddleware(history),
    trackingMiddleware,
    ...getDefaultMiddleware({
        serializableCheck: {
            ignoredActions: [actionTypes.LOGIN],
        },
        immutableCheck: false,
    }),
];

export const createStoreWithMiddleware = (reducer: any, preloadedState?: any) => {
    return configureStore({
        reducer: reducer,
        middleware: middlewares,
        preloadedState: preloadedState,
    });
};
export const store = createStoreWithMiddleware(rootReducer);

export const firebaseProviderProps = {
    firebase,
    config: {
        userProfile: "users",
        useFirestoreForProfile: true,
    },
    dispatch: store.dispatch,
};

export type RootState = ReturnType<typeof rootReducer>;

export default store;
