import * as React from "react";
import { useHistory } from "react-router";

export const useModalNavigation = () => {
    const history = useHistory();
    const goBack = (event?: {} | React.MouseEvent<any, MouseEvent>) => {
        // This allows a saner approach via _target calls by adding the base
        // path as the logical forward path, upon clicking browser back the
        // history will never be more than 2, thus ensuring the close button on
        // a modal always returns a user to the base path
        // OR new window(some browsers consider blank new tabs to be first
        // location this allows us to drop user on the underlying path, then if
        // they go back in browser they can go back to modal then new tab)
        const canGoBack = history.length > 2;
        if (!canGoBack) {
            // https://github.com/remix-run/history/blob/main/docs/api-reference.md#history.push
            return history.push(history.location.pathname);
        }
        return history.goBack();
    };

    return { goBack };
};
