import { Sangha } from "@app/shared/types";
import { Button } from "@mui/material";
import { analyticsTrack } from "app/analytics/track";
import { DateTime } from "luxon";
import * as React from "react";

export const SanghaStartButton = (props: {
    sangha: Sangha;
    disabled?: boolean;
    className?: string;
}) => {
    const { sangha, className, disabled } = props;
    const startLink = sangha.nextSession?.zoomInfo?.startLink;

    const sanghaSessionId = sangha.nextSession?.id;

    const onStartSession = (ev: React.MouseEvent<any>) => {
        ev.preventDefault();

        analyticsTrack("teacher.sangha.session.start", {
            session: sanghaSessionId,
            sanghaId: sangha.id,
            sanghaType: sangha.type,
            officialStartTime: sangha.nextSession?.time,
            delay:
                sangha.nextSession && DateTime.now() > DateTime.fromISO(sangha.nextSession.time)
                    ? DateTime.now().diff(DateTime.fromISO(sangha.nextSession.time), "seconds")
                          .seconds
                    : 0,
        });

        window.open(startLink, "_blank");
    };

    return (
        <Button
            variant="primary"
            size="small"
            fullWidth
            className={className}
            onClick={onStartSession}
            href={startLink}
            disabled={disabled || !startLink}
            aria-disabled={disabled || !startLink}
            data-testid="startButton"
        >
            Start Session
        </Button>
    );
};
