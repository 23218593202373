import { IconButton } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { routes } from "app/routes";
import { push } from "connected-react-router";
import { useDispatch } from "react-redux";
import { LandingTwoColumnLayout } from "features/landingPage/FunnelMVP/LandingTwoColumnLayout";
import { localImageSrc } from "app/images";
import { useLogEventOnLoad } from "hooks/useLogEventOnLoad";
import { analyticsTrack } from "app/analytics/track";
import classNames from "classnames";
import PageWrapper from "components/PageWrapper";
import { FloatingButton } from "components/FloatingButton";

const useStyles = makeStyles((theme) => ({
    container: {
        background: theme.palette.neutralWarm,
        minHeight: "100vh",
    },

    closeButton: {
        position: "absolute",
        right: 20,
        top: 130,
        zIndex: theme.zIndex.appBar,
        [theme.breakpoints.down("md")]: {
            right: 0,
            top: 110,
        },
    },
    closeIcon: {
        fontSize: 60,

        [theme.breakpoints.down("md")]: {
            fontSize: 40,
        },
    },
}));

export const CancellationFlow = (props: {
    content: React.ReactNode;
    onNext?: () => void;
    continueButtonText?: React.ReactNode;
    pageId: string;
    continueButtonDisabled?: boolean;
}) => {
    const dispatch = useDispatch();

    const classes = useStyles();
    useLogEventOnLoad(`cancellationFlow.${props.pageId}.viewed`);

    return (
        <PageWrapper>
            <IconButton
                data-testid="closeButton"
                className={classes.closeButton}
                onClick={() => {
                    dispatch(push(routes.memberMembership()));
                    analyticsTrack(`cancellationFlow.${props.pageId}.closeModal`);
                }}
            >
                <span className={classNames("material-symbols-rounded", classes.closeIcon)}>
                    close
                </span>
            </IconButton>
            {props.content}

            <FloatingButton
                disabled={props.continueButtonDisabled}
                variant="primary"
                onClick={() => {
                    if (props.onNext) {
                        props.onNext();
                    }
                    analyticsTrack(`cancellationFlow.${props.pageId}.nextButton.click`);
                }}
                data-testid="continueButton"
            >
                {props.continueButtonText}
            </FloatingButton>
        </PageWrapper>
    );
};
