import { BRAND_NAME } from "@app/shared/constants";
import { Container, Divider, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { routes } from "app/routes";
import { InfoPopup } from "components/InfoPopup";
import PageWrapper from "components/PageWrapper";
import { DonationSection } from "features/donation/DonatePage";
import MembershipChargeExplanationPopup from "features/landingPage/MembershipChargeExplanationPopup";
import LoadingPage from "features/pages/LoadingPage";
import { useLogEventOnLoad } from "hooks/useLogEventOnLoad";
import { useState } from "react";

const useStyles = makeStyles((theme) => ({
    container: {
        marginTop: theme.spacing(5),
        marginBottom: theme.spacing(5),
    },
}));

export const VisitorDonate = () => {
    const classes = useStyles();

    useLogEventOnLoad("visitor.donation.viewed");

    const [loading, setLoading] = useState(false);

    if (loading) {
        return <LoadingPage />;
    }

    return (
        <PageWrapper>
            <Container maxWidth="lg" className={classes.container}>
                <Typography variant="h2">
                    Help us create a revolution of <strong>Awareness, Peace, and Love</strong>
                </Typography>
            </Container>

            <Container maxWidth="lg" className={classes.container}>
                <Typography variant="body1">
                    {BRAND_NAME} is a for-purpose company and a Public Benefit Corporation. Our core
                    purpose is to cultivate awareness, peace, and love.
                </Typography>
                <Typography variant="body1">
                    As we look out at the world we see a great opportunity for mindfulness teachings
                    to reduce suffering. Rates of anxiety, depression, and mental illness are
                    skyrocketing. We know from great ancient teachings as well as modern science
                    that mindfulness and meditation can help people overcome these challenges and
                    find greater clarity, ease, wellness, and health.
                </Typography>
                <Typography variant="body1">
                    If you’d like to support our movement, regardless of whether you want to join
                    the community, your generosity would go a long way.
                </Typography>

                <InfoPopup
                    linkText="Read more about how your contribution is used"
                    amplitudeEventId="visitorDonationExplanation"
                    hideInfoIcon={true}
                >
                    <MembershipChargeExplanationPopup />
                </InfoPopup>

                <Divider />

                <DonationSection
                    setLoading={setLoading}
                    redirectUrl={routes.visitorDonationSuccess()}
                    analyticsPrefix="visitor"
                />
            </Container>
        </PageWrapper>
    );
};
