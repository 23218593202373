import { Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import DocumentContext from "components/DocumentContext";

const useStyles = makeStyles((theme) => ({
    contentContainer: {
        paddingBottom: theme.spacing(14),
        [theme.breakpoints.down("sm")]: {
            paddingBottom: theme.spacing(8),
        },
    },
    videoPlayerContainer: {
        position: "relative",
        paddingBottom: "62.5%",
        height: 0,
        borderRadius: theme.borderRadius.default,
    },
    videoPlayer: {
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        borderRadius: theme.borderRadius.default,
    },
}));

export const SetupMindfulCommunicationVideoPage = () => {
    const classes = useStyles();

    return (
        <>
            <DocumentContext title="The Dos & Don'ts of Mindful Communication" addBrandNameSuffix />

            <div className={classes.contentContainer}>
                <Typography variant="h1">The Dos & Don'ts of Mindful Communication</Typography>
                <Typography variant="body1">
                    Please watch this short (and hopefully funny!) exploration of how NOT to
                    communicate mindfully.
                </Typography>
                <div className={classes.videoPlayerContainer}>
                    <iframe
                        src="https://www.youtube.com/embed/qIGC9C3u9q0?si=ECbMQjSBvPnERMmO"
                        title="YouTube video player"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowFullScreen
                        className={classes.videoPlayer}
                    ></iframe>
                </div>
            </div>
        </>
    );
};
