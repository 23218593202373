import { Redirect, Route, Switch } from "react-router";
import { routes } from "../../../app/routes";
import DocumentContext from "components/DocumentContext";
import { CancellationFlowReason } from "../membership/CancellationFlowReason";
import { CancellationFlowPage5 } from "../membership/CancellationFlowPage5";
import { CancellationFlowPage6 } from "../membership/CancellationFlowPage6";
import { CancellationFlowPage7, CancellationDateType } from "../membership/CancellationFlowPage7";
import { useEffect, useState } from "react";
import { useMutation } from "@apollo/client";
import { GRAPHQL_MUTATION_SET_CANCELLATION_RESPONSE } from "app/queries";

export const CancellationModal = () => {
    const [cancellationResponse, setCancellationResponse] = useState(null);
    const [cancellationDateType, setCancellationDateType] = useState<CancellationDateType>(
        CancellationDateType.END_OF_BILLING_CYCLE,
    );

    const [postCancellationResponse] = useMutation(GRAPHQL_MUTATION_SET_CANCELLATION_RESPONSE, {
        variables: {
            cancellationResponse: cancellationResponse,
        },
    });

    useEffect(() => {
        if (cancellationResponse) {
            postCancellationResponse();
        }
    }, [cancellationResponse]);

    const saveData = (cancellationResponse: any) => {
        setCancellationResponse(cancellationResponse);
    };

    const setCancellationDate = (dateType: CancellationDateType) => {
        setCancellationDateType(dateType);
    };
    return (
        <>
            <DocumentContext title="Cancel Membership" addBrandNameSuffix />
            <Switch>
                <Route
                    path={routes.cancelMembershipReason()}
                    render={(props) => <CancellationFlowReason saveData={saveData} />}
                />
                <Route
                    path={routes.cancelMembershipPage5()}
                    render={(props) => <CancellationFlowPage5 saveData={saveData} />}
                />
                <Route
                    path={routes.cancelMembershipPage6()}
                    render={(props) => (
                        <CancellationFlowPage6
                            saveData={saveData}
                            cancellationDateType={cancellationDateType}
                        />
                    )}
                />
                <Route
                    path={routes.cancelMembershipPage7()}
                    render={(props) => (
                        <CancellationFlowPage7
                            setCancellationDate={setCancellationDate}
                            cancellationDateType={cancellationDateType}
                        />
                    )}
                />

                <Redirect to={routes.cancelMembershipReason()} />
            </Switch>
        </>
    );
};
