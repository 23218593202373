import { Button, Typography } from "@mui/material";
import { analyticsTrack } from "app/analytics/track";
import Guidelines from "components/Guidelines";
import PageWrapper from "components/PageWrapper";
import { selectUserProfile } from "features/auth/auth";
import { acceptCommunityCommitments } from "features/member/profile/memberProfileSlice";
import LoadingPage from "features/pages/LoadingPage";
import { useLogEventOnLoad } from "hooks/useLogEventOnLoad";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FixedCTAContainer } from "features/signup/FixedCTAContainer";
import { SetupProgressBar } from "features/setup/SetupProgressBar";
import { BRAND_NAME } from "@app/shared/constants";
import { SetupMindfulCommunicationVideoPage } from "features/setup/SetupMindfulCommunicationVideoPage";
import { CSDialog } from "components/CSDialog";
import { CSCheckboxField } from "components/CSCheckboxField";
import GuidelinesPage from "features/pages/GuidelinesPage";

export const SignupCommitmentsStep = (props: { analyticsPrefix: string }) => {
    const [guideslinesAccepted, setGuidelinesAccepted] = useState(false);
    const userProfile = useSelector(selectUserProfile);
    const dispatch = useDispatch();

    const [page, setPage] = useState(1);

    const [videoConfirmationDialogOpen, setVideoConfirmationDialogOpen] = useState(false);
    const [videoWatchedConfirmation, setVideoWatchedConfirmation] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [page]);

    useLogEventOnLoad(`${props.analyticsPrefix}.commitments.load`);

    if (!userProfile) {
        return <LoadingPage />;
    }

    const onAccept = async () => {
        analyticsTrack(`${props.analyticsPrefix}.commitments.submit`);
        setGuidelinesAccepted(true);
        dispatch(
            acceptCommunityCommitments({
                id: userProfile.id,
            }),
        );
    };

    const renderAgreementButton = () => (
        <FixedCTAContainer>
            {page === 1 ? (
                <Button data-testid="continueButton" variant="primary" onClick={() => setPage(2)}>
                    I agree
                </Button>
            ) : (
                <Button
                    data-testid="continueButton"
                    variant="primary"
                    disabled={guideslinesAccepted}
                    onClick={() => setVideoConfirmationDialogOpen(true)}
                >
                    Continue
                </Button>
            )}
        </FixedCTAContainer>
    );

    const renderConfirmationDialog = () => (
        <CSDialog
            open={videoConfirmationDialogOpen}
            onClose={() => {
                setVideoConfirmationDialogOpen(false);
                setVideoWatchedConfirmation(false);
            }}
        >
            <Typography variant="h2">Confirmation</Typography>
            <CSCheckboxField
                value={videoWatchedConfirmation}
                onChange={setVideoWatchedConfirmation}
                label="I confirm that I watched the entire video."
            />
            <Button
                variant="primary"
                disabled={!videoWatchedConfirmation}
                onClick={() => onAccept()}
            >
                Continue
            </Button>
            <Button
                variant="primary"
                sx={{ ml: 2 }}
                disabled={videoWatchedConfirmation}
                onClick={() => setVideoConfirmationDialogOpen(false)}
            >
                Close and watch the video
            </Button>
        </CSDialog>
    );

    return (
        <>
            <SetupProgressBar currentStep={0} />
            <PageWrapper>
                {page === 1 ? <Guidelines /> : <SetupMindfulCommunicationVideoPage />}
                {renderAgreementButton()}
                {renderConfirmationDialog()}
            </PageWrapper>
        </>
    );
};
