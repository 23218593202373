import { useLazyQuery, useMutation } from "@apollo/client";
import {
    MEN_ONLY_SESSION_NOTE,
    POC_ONLY_SESSION_NOTE,
    STARTING_SOON_MINUTES,
} from "@app/shared/constants";
import {
    Sangha,
    SanghaSessionCycle,
    Session,
    SessionStarted,
    SessionStatus,
    SessionType,
    UserProfile,
} from "@app/shared/types";
import {
    dateTimeFromString,
    getFormattedDateFromString,
    isSessionACommunityEvent,
} from "@app/shared/utils";
import ManageAccountsOutlinedIcon from "@mui/icons-material/ManageAccountsOutlined";
import {
    alpha,
    Avatar,
    Button,
    CircularProgress,
    IconButton,
    Menu,
    MenuItem,
    Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { analyticsTrack } from "app/analytics/track";
import { externalImageSrc, localImageSrc } from "app/images";
import { GRAPHQL_MUTATION_SUB_SESSION, GRAPHQL_QUERY_EVENT_RSVP_USERS } from "app/queries";
import { routes } from "app/routes";
import classNames from "classnames";
import clsx from "clsx";
import { showSnackbarAlertOnRedirect } from "components/AlertSnackBar";
import EventCard from "components/EventCard";
import { FadeCarousel } from "components/FadeCarousel";
import { push } from "connected-react-router";
import { selectIsAdmin, selectIsTeacher, selectLoggedInTeacherId } from "features/auth/auth";
import { LinkButton } from "features/navigation/LinkButton";
import { SanghaDayAndTimeDescription } from "features/sangha/SanghaDayAndTimeDescription";
import { RequestSubstituteMenuItem } from "features/teacherSubstitutes/RequestSubstituteMenuItem";
import { useUpdateCurrentTime } from "hooks/useUpdateCurrentTime";
import _, { truncate } from "lodash";
import { DateTime, DateTimeFormatOptions, Duration } from "luxon";
import { Fragment, useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { JoinInterestGroupButton } from "../interestGroups/JoinInterestGroupButton";
import { getInterestGroupConfirmationDialogs } from "../sangha/getInterestGroupConfirmationDialogs";
import { isSessionStarted } from "../sangha/isSessionStarted";
import { AddToCalendarMenuItem } from "./AddToCalendarMenuItem";
import { ConfirmationDialogProps } from "./ButtonWithConfirmationDialogs";
import { LeaveGroupMenuItem } from "./LeaveGroupMenuItem";
import { RsvpButton } from "./RsvpButton";
import { RsvpMenuItem } from "./RsvpMenuItem";
import {
    getTitle,
    renderNextSessionDate,
    renderSessionDate,
    renderSessionTime,
    renderUserTimezone,
} from "./shared";
import { WhosAttendingButton } from "./WhosAttendingButton";
import { WhosAttendingMenuItem } from "./WhosAttendingMenuItem";
import { ZoomButton } from "./ZoomButton";
import { SessionTypeChip } from "./sessionTypeChip";
import { theme } from "app/theme";
import { ParticipationBadge } from "./ParticipationBadge";
import { NumberOfMembersBadge } from "./NumberOfMembersBadge";
import { isUserInMMTSignupFlow, isUserInSetupFlow } from "features/signup/signupHelpers";

const useStyles = makeStyles((theme) => ({
    eventTime: {
        marginBottom: 0,
    },
    cancelledCard: {
        color: theme.palette.primaryLeaves,
        "& $eventDateTimeContainer": {
            textDecoration: "line-through",
        },
    },
    contentWrapper: {
        display: "flex",
        height: "100%",
    },
    cancelledSessionMessage: {
        textAlign: "left",
    },
    name: {
        display: "inline-block",
        margin: 0,
        marginRight: theme.spacing(1),
    },
    teacherName: {
        margin: 0,
        display: "inline-block",
        whiteSpace: "nowrap",
    },
    teacherLink: {
        verticalAlign: "baseline",
        color: "inherit",
        textTransform: "inherit",
        textDecoration: "inherit",
        fontWeight: "inherit",
        fontSize: "inherit",
        lineHeight: "unset",
    },
    with: {
        display: "inline-block",
        marginRight: theme.spacing(0.5),
        marginBottom: 0,
    },
    and: {
        display: "inline-block",
        marginLeft: theme.spacing(0.5),
        marginRight: theme.spacing(0.5),
    },
    icon: {
        maxWidth: theme.spacing(5),
    },
    col1: {
        display: "inline-block",
        marginRight: theme.spacing(2),
        marginTop: 0,
        marginBottom: 0,
        [theme.breakpoints.down("md")]: {
            display: "block",
            marginRight: theme.spacing(0),
        },
    },
    col2: {
        display: "inline-block",
        [theme.breakpoints.down("md")]: {
            display: "block",
        },
    },
    description: {
        display: "inline-block",
        marginRight: theme.spacing(2),
    },
    buttonWrapper: {
        display: "flex",
        flexDirection: "column",
        flexWrap: "wrap",
        gap: theme.spacing(1),

        "& *": {
            flex: "0 0 auto",
        },

        [theme.breakpoints.up("lg")]: {
            flexWrap: "nowrap",
        },

        [theme.breakpoints.down("md")]: {
            justifyContent: "left",
        },
    },
    rsvpButton: {
        marginTop: theme.spacing(2),
    },
    chatButton: {},
    chatMenuItem: {
        marginBottom: 0,
    },
    whosAttendingButton: {},
    confirmationDialogTitle: {
        marginBottom: theme.spacing(5),
    },
    additionalInfo: {},
    dedicatedColors: {
        backgroundColor: alpha(theme.palette.primaryLeaves, 0.2),
        color: theme.palette.primaryLeaves,
    },
    dropInColors: {
        backgroundColor: alpha(theme.palette.accentPeach, 0.2),
        color: theme.palette.accentPeach,
    },
    menu: {
        borderRadius: theme.borderRadius.default,
        backgroundColor: theme.palette.background.default,
        overflow: "visible",
    },
    menuItem: {
        marginBottom: 0,
    },
    cardActionButtons: {
        display: "flex",
        position: "absolute",
        // TODO hacky way to position the menu button - should be set up to align to the parent element of the whole card instead of only the content area.
        top: theme.spacing(-3.5),
        right: theme.spacing(-2),
    },
    closeButton: {
        padding: 0,
        color: theme.palette.grey800,
        "&:hover": {
            color: theme.palette.accentGold,
        },
    },
    closeIcon: {
        fontSize: theme.typography.pxToRem(25),
    },
    menuButton: {
        padding: 0,
        color: theme.palette.grey800,
        "&:hover": {
            color: theme.palette.accentGold,
        },
    },
    menuIcon: {
        fontSize: theme.typography.pxToRem(25),
    },
    menuItemIcon: {
        marginRight: theme.spacing(2),
    },
    cardContentContainer: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        gap: theme.spacing(1),
        minHeight: 100,
        height: "100%",
    },
    cardContentMain: {
        display: "flex",
        gap: theme.spacing(1),
        flexDirection: "column",
    },
    cardContentBottom: {
        display: "flex",
        flexDirection: "column",
        marginBottom: "auto",
        gap: theme.spacing(1),
        [theme.breakpoints.up("md")]: {
            marginTop: "auto",
            marginBottom: 0,
        },
    },
    eventName: {
        margin: 0,
    },
    eventTitle: {
        color: theme.palette.grey800,
        lineHeight: "1rem",
    },
    cancelledEventTextColor: {
        color: `${theme.palette.primaryLeaves} !important`,
    },
    eventDateTimeContainer: {
        color: theme.palette.grey800,
        display: "flex",
        flexDirection: "column",
    },
    eventDescription: {
        marginBottom: 0,
        color: theme.palette.grey800,
        fontSize: theme.typography.pxToRem(15),
        lineHeight: theme.typography.pxToRem(19),
    },
    eventCancelled: {
        textDecoration: "line-through",
    },
    cardShadow: {
        boxShadow: theme.shadow.default,
    },
    teachersAndMembersCountContainer: {
        color: theme.palette.grey800,
        display: "flex",
        alignItems: "center",
        gap: theme.spacing(0.5),
        flexWrap: "wrap",
    },
    teachersContainer: {
        display: "flex",
        alignItems: "center",
        flexWrap: "wrap",
    },
    teacherImage: {
        width: 20,
        height: 20,
        borderRadius: theme.borderRadius.circular,
        marginRight: theme.spacing(0.5),
        flexShrink: 0,
    },
}));

export const CommunityEventCard = (props: {
    event: Session;
    timezone: string;
    isPublic?: boolean;
    currentServerTime?: DateTime;
    customButtons?: React.ReactNode;
    additionalInfo?: string;
    showLeaveGroupOptions?: boolean;
    showSpecificDate?: boolean;
    hasCloseButton?: boolean;
    onCardClose?: () => void;
    addShadow?: boolean;
    onAttend?: () => void;
    serverTimeDelta?: number;
    onConfirmSubstitute?: () => void;
    userRsvpedToSession: boolean;
    sangha?: Sangha;
}): JSX.Element => {
    const classes = useStyles();
    const {
        event,
        timezone,
        currentServerTime,
        customButtons,
        showSpecificDate,
        hasCloseButton,
        onCardClose,
        addShadow,
        onAttend,
        serverTimeDelta,
        onConfirmSubstitute,
        userRsvpedToSession,
        sangha,
    } = props;

    const isTeacher = useSelector(selectIsTeacher);
    const loggedInTeacherId = useSelector(selectLoggedInTeacherId);
    const isAdmin = useSelector(selectIsAdmin);
    const location = useLocation();

    const history = useHistory();
    const shouldDisableQueryingServerTime = !!currentServerTime && !serverTimeDelta;
    const updatedCurrentServerTime = useUpdateCurrentTime(
        serverTimeDelta,
        30000,
        shouldDisableQueryingServerTime,
    );

    const isSubstituteRequestsPage = history.location.pathname.includes(
        "/teachers/substitute-requests",
    );

    const dispatch = useDispatch();

    const [loadRsvpUsers, rsvpUsersQueryStatus] = useLazyQuery(GRAPHQL_QUERY_EVENT_RSVP_USERS, {
        variables: { id: event.id },
    });

    const [subThisSessionMutation] = useMutation(GRAPHQL_MUTATION_SUB_SESSION);

    const rsvpUsers = (rsvpUsersQueryStatus.data?.event?.rsvpUsers || []) as UserProfile[];

    const userIsPartOfGroup = !!event.sanghaMembership;
    const userIsPartOfGroupOrRsvpedToSession = userRsvpedToSession || userIsPartOfGroup;

    const userIsTeacherOfEvent =
        isTeacher &&
        _.includes(
            event.teachers.map((x) => x.id),
            loggedInTeacherId,
        );

    const { ref, inView } = useInView({
        threshold: 0,
        triggerOnce: true,
    });
    useEffect(() => {
        if (inView && !customButtons) {
            loadRsvpUsers();
        }
    }, [inView, customButtons]);

    const sessionStartedStatus = isSessionStarted({
        sessionStart: dateTimeFromString(event.time, timezone),
        sessionEnd: dateTimeFromString(event.endTime, timezone),
        currentServerTime: currentServerTime || updatedCurrentServerTime,
    });

    const eventStartedOrStartingSoon =
        sessionStartedStatus === SessionStarted.IN_PROGRESS ||
        sessionStartedStatus === SessionStarted.ABOUT_TO_START;

    const minutesUntilSessionStarts = Math.floor(
        dateTimeFromString(event.time, timezone).diff(
            currentServerTime || updatedCurrentServerTime,
            "minutes",
        ).minutes,
    );

    const durationUntilSession = Duration.fromObject({
        minutes: minutesUntilSessionStarts,
    }).shiftTo("hours", "minutes");

    const formattedTimeDifference =
        minutesUntilSessionStarts >= 60
            ? durationUntilSession.toFormat("h 'h' m 'min'")
            : durationUntilSession.toFormat("m 'min'");

    const teacherPictures = event.teachers.map((teacher) =>
        externalImageSrc(teacher.picture ?? "", 25),
    );

    const isEventOngoing = !props.isPublic && !event.cancelled && eventStartedOrStartingSoon;

    const isGroupSession = [SessionType.SANGHA_SESSION, SessionType.INTEREST_GROUP].includes(
        event.type,
    );
    const isInterestGroup = event.type === SessionType.INTEREST_GROUP;
    const isCommunityEvent = isSessionACommunityEvent(event);

    useEffect(() => {
        // when the card reloads, make sure to reinitialize the AddEvent library
        (window as any).addeventatc?.refresh();
    }, []);

    const getConfirmationDialogs = (): ConfirmationDialogProps[] => {
        if (
            event.type === SessionType.SILENT_MEDITATION_HALL ||
            event.type === SessionType.SILENT_SIT
        ) {
            return [
                {
                    enableConfirmButtonDelay: 0,
                    button: "Enter",
                    content: (
                        <>
                            <Typography variant="h3" className={classes.confirmationDialogTitle}>
                                A space for noble silence
                            </Typography>
                            <Typography variant="body1">
                                You are entering the silent meditation hall. Please leave your
                                microphone muted and maintain noble silence during your time in this
                                sacred space.
                            </Typography>
                        </>
                    ),
                },
            ];
        }
        if (event.type === SessionType.INTEREST_GROUP && event.sangha) {
            return getInterestGroupConfirmationDialogs(event.sangha.id);
        }
        if (event.notes === POC_ONLY_SESSION_NOTE) {
            return [
                {
                    localStorageKey: "pocReminderSeenV2",
                    button: "I identify as a person of color",
                    content: (
                        <>
                            <Typography variant="h3" className={classes.confirmationDialogTitle}>
                                A space for people of color
                            </Typography>
                            <Typography variant="body1">
                                This is a space specifically for people of color. For those of us
                                with marginalized identities, it can be important to have spaces in
                                which we can relate to one another through the lens of our unique
                                cultural and societal experiences, to help cultivate safety and
                                healing.
                            </Typography>

                            <Typography variant="body1">
                                As a reminder, people of all backgrounds who do not identify as
                                white are welcome to participate, and ethnicity is not always
                                visually evident.
                            </Typography>
                        </>
                    ),
                },
            ];
        }
        if (event.notes === MEN_ONLY_SESSION_NOTE) {
            return [
                {
                    localStorageKey: "menOnlyReminderSeenV2",
                    button: "Continue",
                    content: (
                        <>
                            <Typography variant="h3" className={classes.confirmationDialogTitle}>
                                A space for men to explore, share and connect
                            </Typography>
                            <Typography variant="body1">
                                These sessions offer a safe space for breaking cultural notions
                                around masculinity, creating open spaces for all voices to be heard,
                                and tearing down the walls of separation (self vs other).
                            </Typography>
                        </>
                    ),
                },
            ];
        }

        return [];
    };

    const getEventDescription = (): string => {
        const descriptions: Partial<Record<SessionType, string>> = {
            SANGHA_SESSION:
                event?.sessionDescription ||
                "Meet the same teacher and small group for tailored guidance and deeper learning.",
            COMMUNITY_SIT:
                event?.sessionDescription ||
                "Meditate in community with the guidance of an expert teacher.",
            SILENT_SIT:
                event?.sessionDescription ||
                "Meditate in silence in community, with the presence of an expert teacher.",
            INTEREST_GROUP:
                event?.sessionDescription ||
                event.sangha?.presentationData?.subtitle ||
                event.sangha?.presentationData?.description ||
                "Meditate and engage in meaningful conversations with like-minded individuals.",
            COMMUNITY_TALK:
                event?.sessionDescription ||
                "Join a community talk with world-renowned meditation teachers.",
            WELCOME_SESSION:
                event?.sessionDescription ||
                "Connect with members and teachers to learn about our guidelines and offerings.",
            QA_SESSION:
                event?.sessionDescription ||
                "Ask, learn, grow. Live Q+A with teachers to deepen your practice.",
        };

        const description = descriptions[event.type] || "";

        return cropDescription(description);
    };
    const eventDescriptionText = getEventDescription();

    const renderZoomButton = ({
        isStartButton = false,
        isCardDropInSession = false,
    }: {
        isStartButton?: boolean;
        isCardDropInSession?: boolean;
    }) => {
        return (
            <ZoomButton
                fullWidth={true}
                isStartButton={isStartButton}
                isCardDropInSession={isCardDropInSession}
                isEventOngoing={isEventOngoing}
                event={event}
                sessionStartedStatus={sessionStartedStatus}
                confirmationDialogs={isStartButton ? undefined : getConfirmationDialogs()}
                iconHeight={20}
            />
        );
    };
    const renderJoinButton = () =>
        renderZoomButton({ isStartButton: false, isCardDropInSession: isCommunityEvent });
    const renderStartButton = () =>
        renderZoomButton({ isStartButton: true, isCardDropInSession: isCommunityEvent });

    const renderTime = (event: Session) => {
        return renderSessionTime(event, timezone);
    };
    const renderRecurrentWeekdayAndTime = (event: Session) => {
        const recurrentWeekday =
            dateTimeFromString(event.time, timezone).toLocaleString({ weekday: "long" }) + "s";

        const timeFormat: DateTimeFormatOptions = {
            hour: "2-digit",
            minute: "2-digit",
        };
        const sessionTime = `${getFormattedDateFromString(event.time, timezone, timeFormat)} - ${getFormattedDateFromString(
            event.endTime,
            timezone,
            timeFormat,
        )}`;
        return `${recurrentWeekday}, ${sessionTime}  ${renderUserTimezone(timezone)}`;
    };
    const renderFormattedSessionTime = (event: Session) => {
        const timeFormat: DateTimeFormatOptions = {
            hour: "2-digit",
            minute: "2-digit",
        };
        const sessionTime = `${getFormattedDateFromString(event.time, timezone, timeFormat)} - ${getFormattedDateFromString(
            event.endTime,
            timezone,
            timeFormat,
        )}`;
        return ` ${sessionTime}  ${renderUserTimezone(timezone)}`;
    };

    const renderWhosAttending = (isButton: boolean) => {
        if (!event.cancelled) {
            if (rsvpUsersQueryStatus.loading) {
                return <CircularProgress />;
            }

            return isButton ? (
                <WhosAttendingButton
                    rsvpUsers={rsvpUsers}
                    event={event}
                    renderEventTime={renderTime}
                    classes={{ button: classes.whosAttendingButton }}
                />
            ) : (
                <WhosAttendingMenuItem
                    rsvpUsers={rsvpUsers}
                    event={event}
                    renderEventTime={renderTime}
                    classes={{ button: classes.whosAttendingButton }}
                    onDialogClose={handleMenuClose}
                    sangha={sangha}
                />
            );
        }
    };

    const renderChatMenuItem = () => {
        return (
            <MenuItem
                data-testid="chatWithGroupMenuItem"
                onClick={() => dispatch(push(routes.memberSanghaDetails(event.sangha?.id || "")))}
                className={classes.chatMenuItem}
            >
                <span className={classNames("material-symbols-rounded", classes.menuItemIcon)}>
                    chat
                </span>{" "}
                Group Details
            </MenuItem>
        );
    };

    const renderRsvpComponent = (isButton: boolean) => {
        if (event.type === SessionType.SILENT_MEDITATION_HALL) {
            return null;
        }

        if (isButton) {
            return (
                <RsvpButton
                    userRsvpedToSession={userRsvpedToSession}
                    event={event}
                    onAttend={onAttend}
                />
            );
        } else {
            return <RsvpMenuItem rsvpUsers={rsvpUsers} event={event} />;
        }
    };

    const renderAddToCalendarMenuItem = () => {
        if (!userIsPartOfGroupOrRsvpedToSession && !userIsTeacherOfEvent) {
            // Only show the add to calendar item for group members and the group teacher
            return null;
        }

        const isWeeklyEvent = event.sangha?.cycle === SanghaSessionCycle.weekly;
        // We know the teacher is subbing if they are different from the groups regular teacher
        const isSubstituteTeacher =
            event.sangha && userIsTeacherOfEvent && event.sangha.teacher.id !== loggedInTeacherId;

        return (
            <AddToCalendarMenuItem
                event={event}
                // Only support recurrance for weekly groups, this is more complicated for monthly and twice weekly groups.
                // For now these will only add this one session to the users calendar
                // For substitute teachers we want to add only a single session, and not make it recurring
                isRecurringEvent={isWeeklyEvent && !isSubstituteTeacher}
            />
        );
    };

    const renderLeaveGroupMenuItem = () => {
        if (userIsPartOfGroup && event.sangha && event.sanghaMembership) {
            return (
                <LeaveGroupMenuItem
                    sangha={event.sangha}
                    sanghaMembershipId={event.sanghaMembership.id}
                    eventId={event.id}
                />
            );
        }
    };

    const renderButtons = () => {
        if (props.customButtons) {
            return <>{props.customButtons}</>;
        }

        if (isInterestGroup && !(isTeacher || isAdmin) && !userIsPartOfGroup) {
            return event.sangha ? (
                <JoinInterestGroupButton
                    fullWidth
                    interestGroup={event.sangha}
                    variant="tertiary"
                    style={{ marginRight: "auto" }}
                />
            ) : null;
        }

        if (
            event.type === SessionType.SANGHA_SESSION &&
            !userIsPartOfGroup &&
            !userIsTeacherOfEvent
        ) {
            const isInMMTSignupFlow = isUserInMMTSignupFlow() && isUserInSetupFlow(location);

            return (
                <Button
                    variant="secondary"
                    size="small"
                    fullWidth
                    onClick={() => {
                        analyticsTrack("mentorshipGroupList.selectGroupToAdd", {
                            groupId: event.sangha?.id,
                            teacherName: event.sangha?.teacher.name,
                            openSpots: event.sangha?.openSpots,
                        });

                        history.push(
                            isInMMTSignupFlow
                                ? routes.setupAddMentorshipGroup(
                                      event.sangha?.id || sangha?.id || "",
                                  )
                                : routes.addMentorshipGroup(event.sangha?.id || sangha?.id || ""),
                        );
                    }}
                >
                    Learn more
                </Button>
            );
        }

        return (
            <div className={classes.buttonWrapper}>
                {isGroupSession &&
                    !(isTeacher || isAdmin) &&
                    !isEventOngoing &&
                    renderGroupPageButton()}
                {(isTeacher || isAdmin) && renderStartButton()}
                {eventStartedOrStartingSoon && renderJoinButton()}
                {!eventStartedOrStartingSoon && !isGroupSession && renderRsvpComponent(true)}
            </div>
        );
    };

    const renderGroupPageButton = () => {
        return (
            <LinkButton
                fullWidth
                to={event.sangha ? routes.memberSanghaDetails(event.sangha?.id) : "#"}
                variant="secondary"
                size="small"
                classes={{ root: classes.chatButton }}
            >
                Group details
            </LinkButton>
        );
    };

    const renderTeachers = () =>
        event.teachers &&
        event.teachers[0] && (
            <>
                <div className={classNames(classes.teachersContainer)}>
                    {event.teachers.length > 1 ? (
                        <FadeCarousel
                            images={teacherPictures}
                            interval={5000}
                            classes={{
                                container: classes.teacherImage,
                            }}
                        />
                    ) : (
                        <Avatar
                            src={
                                event.teachers[0].picture ?? localImageSrc(`community/bowl.png`, 25)
                            }
                            variant="rounded"
                            classes={{
                                root: classes.teacherImage,
                            }}
                            sx={{ width: 25, height: 25 }}
                        />
                    )}
                    <Typography variant="caption" className={classes.with}>
                        With
                    </Typography>

                    {event.teachers.map((teacher, index) => (
                        <Fragment key={teacher.id}>
                            {index > 0 && (
                                <Typography variant="caption" className={classes.and}>
                                    and
                                </Typography>
                            )}

                            <Typography
                                key={teacher.id}
                                variant="caption"
                                className={classes.teacherName}
                            >
                                <Button
                                    variant="tertiary"
                                    onClick={() => dispatch(push(routes.teacherPage(teacher.id)))}
                                    className={classes.teacherLink}
                                >
                                    {teacher.name}
                                </Button>
                            </Typography>
                        </Fragment>
                    ))}
                    {event.hasSubstituteTeacher && (
                        <Typography variant="caption" className={classes.teacherName}>
                            (Substitute)
                        </Typography>
                    )}
                </div>
            </>
        );

    function cropDescription(description: string, maxLength: number = 80): string {
        return truncate(description, {
            length: maxLength,
            omission: "...",
        });
    }

    const renderEventDateTime = () => {
        if (event.type === SessionType.SILENT_MEDITATION_HALL) {
            return (
                <Typography
                    variant="caption"
                    className={classNames({
                        [classes.eventCancelled]: event.cancelled,
                    })}
                >
                    Open 24/7
                </Typography>
            );
        }
        if (event.type === SessionType.INTEREST_GROUP && event.sangha && !showSpecificDate) {
            return (
                <>
                    <SanghaDayAndTimeDescription
                        sangha={event.sangha}
                        userTimeZone={timezone}
                        overrideStartTime={DateTime.fromISO(event.time)}
                        overrideEndTime={DateTime.fromISO(event.endTime)}
                        renderTimeZone={true}
                    />
                </>
            );
        }
        if (
            (event.type === SessionType.INTEREST_GROUP ||
                event.type === SessionType.SANGHA_SESSION) &&
            userIsPartOfGroup &&
            minutesUntilSessionStarts > STARTING_SOON_MINUTES
        ) {
            return (
                <>
                    <Typography
                        variant="caption"
                        className={classNames({
                            [classes.eventCancelled]: event.cancelled,
                        })}
                    >
                        {renderNextSessionDate(event, timezone)}
                    </Typography>
                    <Typography
                        variant="caption"
                        className={classNames({
                            [classes.eventCancelled]: event.cancelled,
                        })}
                    >
                        {renderRecurrentWeekdayAndTime(event)}
                    </Typography>
                </>
            );
        }
        return (
            <>
                <Typography
                    variant="caption"
                    className={classNames({
                        [classes.eventCancelled]: event.cancelled,
                    })}
                >
                    {renderSessionDate(event, timezone)}
                    {renderFormattedSessionTime(event)}
                </Typography>
            </>
        );
    };

    const handleChangeGroupParticipation = ({ sangha }: Session) => {
        if (!sangha) {
            throw new Error("No sangha found");
        }

        const isSwitchingGroupParticipation = true;
        dispatch(
            push({
                pathname: routes.memberConfirmInterestGroup(sangha.id),
                state: { isSwitchingGroupParticipation },
            }),
        );
    };

    const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null);

    const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) =>
        setMenuAnchor(event.currentTarget);
    const handleMenuClose = () => setMenuAnchor(null);
    const handleSubThisSession = async () => {
        analyticsTrack("subThisSession.button.clicked", {
            sessionId: event.id,
            substituteTeacherId: loggedInTeacherId,
            session: event,
        });
        const substitutionResult = await subThisSessionMutation({
            variables: {
                sessionId: event.id,
                substituteTeacherId: loggedInTeacherId,
            },
        });
        if (substitutionResult.data.confirmSessionSubstitution === false) {
            analyticsTrack("substitutionDenied", {
                sessionId: event.id,
                substituteTeacherId: loggedInTeacherId,
                session: event,
            });
            showSnackbarAlertOnRedirect("This session is no longer available for substitution.");
            window.location.reload();
        } else if (onConfirmSubstitute) {
            onConfirmSubstitute();
        }
    };

    const renderMenuButton = () => {
        const isUserSignedUp = event.sanghaMembership;

        return (
            <>
                <div className={classes.cardActionButtons}>
                    <IconButton
                        size="small"
                        aria-label="menu"
                        aria-controls="card-menu"
                        aria-haspopup="true"
                        onClick={handleMenuOpen}
                        className={classes.menuButton}
                        data-testid="cardMenuButton"
                    >
                        <span className={classNames("material-symbols-rounded", classes.menuIcon)}>
                            more_horiz
                        </span>
                    </IconButton>
                    {!!hasCloseButton && (
                        <IconButton
                            size="small"
                            aria-label="close"
                            aria-controls="card-close"
                            aria-haspopup="true"
                            onClick={onCardClose}
                            className={classes.closeButton}
                        >
                            <span
                                className={classNames(
                                    "material-symbols-rounded",
                                    classes.closeIcon,
                                )}
                            >
                                close
                            </span>
                        </IconButton>
                    )}
                </div>
                <Menu
                    id="card-menu"
                    anchorEl={menuAnchor}
                    keepMounted={false}
                    open={!!menuAnchor}
                    onClose={handleMenuClose}
                    classes={{ paper: classes.menu }}
                    style={{ zIndex: 1102 }}
                >
                    {/* Chat with group menu item for teachers */}
                    {/* We will show this menu item for teachers&admins to have more space in the card for the "Start session" button */}
                    {isGroupSession &&
                        (isTeacher || isAdmin || (userIsPartOfGroup && isEventOngoing)) &&
                        renderChatMenuItem()}
                    {/* See participants menu item */}
                    {renderWhosAttending(false)}
                    {/* Add to calendar menu item */}
                    {renderAddToCalendarMenuItem()}
                    {/* Change group participation menu item */}
                    {isInterestGroup && isUserSignedUp && (
                        <MenuItem
                            onClick={() => {
                                handleChangeGroupParticipation(event);
                                handleMenuClose();
                            }}
                            className={classes.menuItem}
                            data-testid="changeGroupParticipationLink"
                        >
                            <ManageAccountsOutlinedIcon className={classes.menuItemIcon} /> Change
                            group participation
                        </MenuItem>
                    )}
                    {/* Leave group menu item */}
                    {isInterestGroup && renderLeaveGroupMenuItem()}
                    {(userIsTeacherOfEvent || isAdmin) && !isSubstituteRequestsPage && (
                        <RequestSubstituteMenuItem
                            session={event}
                            onClose={() => handleMenuClose()}
                            classes={{ menuItem: classes.menuItem }}
                        />
                    )}
                    {isCommunityEvent && userRsvpedToSession && renderRsvpComponent(false)}
                </Menu>
            </>
        );
    };

    return (
        <>
            <EventCard
                eventType={event.type}
                containerAttributes={{
                    ref: ref,
                    "data-testid": "communityEventCard",
                    "data-session-status": event.cancelled
                        ? SessionStatus.CANCELED
                        : SessionStatus.PUBLISHED,
                    "data-session-id": event.id,
                    "data-group-id": event.sangha?.id,
                    id: event.id, // this is for the scroll functionality - please do not remove
                }}
                cancelled={event.cancelled}
                pictures={
                    event.type === SessionType.INTEREST_GROUP && event.sangha?.topicData?.imageId
                        ? [event.sangha.topicData.imageId]
                        : event.type === SessionType.SILENT_MEDITATION_HALL ||
                            event.type === SessionType.SILENT_SIT
                          ? [localImageSrc(`community/bowl.png`, 90)]
                          : event.teachers.map((teacher) =>
                                externalImageSrc(teacher.picture ?? "", 90),
                            )
                }
                information={
                    <>
                        {!event.cancelled && renderMenuButton()}
                        <div className={classes.cardContentContainer}>
                            <div className={classes.cardContentMain}>
                                <Typography
                                    variant="h6"
                                    className={classNames(classes.eventTitle, {
                                        [classes.cancelledEventTextColor]: event.cancelled,
                                    })}
                                >
                                    {getTitle(event, sangha)}
                                </Typography>
                                <SessionTypeChip sessionType={event.type} />
                                {!(
                                    (
                                        (event.type === SessionType.INTEREST_GROUP ||
                                            event.type === SessionType.SANGHA_SESSION) &&
                                        userIsPartOfGroup
                                    ) /* All cards exept MFGs & mentorship groups which the user is enrolled should show a short description */
                                ) &&
                                    eventDescriptionText && (
                                        <Typography
                                            variant="caption"
                                            className={classNames(classes.eventDescription, {
                                                [classes.cancelledEventTextColor]: event.cancelled,
                                            })}
                                        >
                                            {eventDescriptionText}
                                        </Typography>
                                    )}
                                {minutesUntilSessionStarts < STARTING_SOON_MINUTES &&
                                    (userIsPartOfGroup || userIsTeacherOfEvent) &&
                                    minutesUntilSessionStarts > 0 && (
                                        <Typography
                                            variant="caption"
                                            sx={{ mb: 0, color: theme.palette.accentEarthy }}
                                        >
                                            <strong>Starting in {formattedTimeDifference}</strong>
                                        </Typography>
                                    )}
                                <div
                                    className={classNames(classes.eventDateTimeContainer, {
                                        [classes.cancelledEventTextColor]: event.cancelled,
                                    })}
                                >
                                    {renderEventDateTime()}
                                </div>
                            </div>

                            <div className={classes.cardContentBottom}>
                                <div
                                    className={classNames(
                                        classes.teachersAndMembersCountContainer,
                                        {
                                            [classes.cancelledEventTextColor]: event.cancelled,
                                        },
                                    )}
                                >
                                    {renderTeachers()}
                                    <NumberOfMembersBadge
                                        event={event}
                                        rsvpUsers={rsvpUsers}
                                        userRsvpedToSession={userRsvpedToSession}
                                        userIsTeacherOfEvent={userIsTeacherOfEvent}
                                        sangha={sangha}
                                    />
                                </div>

                                <ParticipationBadge
                                    userIsPartOfGroup={userIsPartOfGroup}
                                    event={event}
                                />

                                {(isTeacher || isAdmin) &&
                                    event.substituteRequested &&
                                    !isSubstituteRequestsPage && (
                                        <Typography
                                            variant="caption"
                                            className={classes.additionalInfo}
                                        >
                                            Substitute requested
                                        </Typography>
                                    )}

                                {props.additionalInfo && (
                                    <Typography
                                        variant="caption"
                                        className={classes.additionalInfo}
                                    >
                                        {props.additionalInfo}{" "}
                                    </Typography>
                                )}
                                <div>
                                    {event.cancelled ? (
                                        <div className={classes.cancelledSessionMessage}>
                                            <Typography variant="h6">Session cancelled</Typography>
                                        </div>
                                    ) : (
                                        <div className={classes.buttonWrapper}>
                                            {!props.isPublic && !isSubstituteRequestsPage
                                                ? renderButtons()
                                                : isTeacher && (
                                                      <Button
                                                          variant="primary"
                                                          onClick={handleSubThisSession}
                                                          data-testid="confirmButton"
                                                      >
                                                          Sub this session
                                                      </Button>
                                                  )}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </>
                }
                // actionBar={}
                classes={{
                    card: clsx({
                        [classes.cancelledCard]: event.cancelled,
                        [classes.cardShadow]: addShadow,
                    }),
                    contentWrapper: classes.contentWrapper,
                }}
            />
        </>
    );
};

export default CommunityEventCard;
