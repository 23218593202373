/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export const routes = {
    // public pages

    community: () => "/community",
    communityGuidelines: () => "/community-guidelines",
    contactUs: () => "/contact",
    faq: () => "/faq",
    home: () => "/",
    privacyPolicy: () => "/privacy-policy",
    cookiePolicy: () => "/cookies",
    termsOfService: () => "/terms-of-service",
    retreats: () => "/retreats/october-2024",

    // webflow pages
    testimonials: () => "/testimonials",
    teacherSection: () => "/#teachers-section",
    about: () => "/about",
    careers: () => "/join-our-team",
    publicTeachersLandingPage: () => "/about/teachers",

    // redesign temp url
    diversity: () => "/diversity",

    // login & profile
    login: () => "/login",
    oauthAuthorize: () => "/oauth/authorize",

    join: () => routes.home(),

    // member zone
    memberZone: () => "/member",
    memberHomePage: () => "/member/home",
    memberSchedulePage: () => "/member/my-schedule",
    memberMembership: (stripeResult?: string) =>
        `/member/membership${stripeResult ? `?stripeResult=${stripeResult}` : ""}`,
    memberSanghas: () => "/member/sanghas",
    memberSanghasDeprecated: () => "/member_dashboard_group",
    memberSanghaDetails: (sanghaId: string) => `/member/sanghas/${sanghaId}`,
    memberSanghaDetailsLegacy: (sanghaId: string) => `/member/sanghas/${sanghaId}/legacy`,

    memberUpdatePaymentMethod: () => "/member/update-payment-method",

    memberSessionsRightNow: () => "/member/sessions-right-now",
    memberDropInSessions: () => "/member/drop-in",
    memberDropInSessionsTalks: () => "/member/drop-in/talks",
    memberDropInSessionsMeditations: () => "/member/drop-in/meditations",
    memberDropInSessionsWelcomeSessions: () => "/member/drop-in/welcome-sessions",
    memberDropInSessionsAllSessions: () => "/member/drop-in/all-sessions",
    memberDropInSessionsSilentMeditations: () => "/member/drop-in/silent-meditations",
    memberDropInSessionsConflictResolutionPractices: () =>
        "/member/drop-in/conflict-resolution-practices",

    editProfilePage: () => "/member/edit-profile",

    setupEditProfilePage: () => "/member/setup/edit-profile",
    setupWelcomeSession: () => "/member/setup/welcome-session",
    setupShowYouAround: () => "/member/setup/show-you-around",

    conflictResolution: () => "/member/conflict-resolution",

    testInterestGroupGuidelines: () => "/member/test-mfg-guidelines",

    memberTeachersPage: () => "/member/teachers",
    publicTeachersPage: () => "/teachers",

    // donation flow
    memberContribute: () => "/member/contribute",
    memberDonate: () => "/member/donate",
    memberDonationSuccess: () => "/member/donate/success",

    memberOnboarding: () => "/member/onboarding",

    // donation flow for visitors
    visitorDonate: () => "/donate",
    visitorDonationSuccess: () => "/donate-success",

    // immersion cancellation flow
    cancelMembership: () => "/member/cancel",
    cancelMembershipPage1: () => "/member/cancel/page1",
    cancelMembershipReason: () => "/member/cancel/reason",
    cancelMembershipPage3: () => "/member/cancel/page3",
    cancelMembershipPage5: () => "/member/cancel/page5",
    cancelMembershipPage6: () => "/member/cancel/page6",
    cancelMembershipPage7: () => "/member/cancel/page7",

    // add mentorship group
    addMentorshipGroup: (sanghaId: string) => `/member/add-mentorship-group/${sanghaId}`,

    // add interest group
    allInterestGroups: () => "/mindful-friend-groups",
    addInterestGroup: (groupId: string) => `/mindful-friend-groups/group/${groupId}`,

    setupAllInterestGroups: () => `/member/setup/mindful-friend-groups`,
    setupAddInterestGroup: (groupId: string) =>
        `/member/setup/mindful-friend-groups/group/${groupId}`,
    setupConfirmInterestGroup: (groupId: string) =>
        `/member/setup/mindful-friend-groups/group/${groupId}/confirm`,

    setupAllMentorshipGroups: () => `/member/setup/mentorship-groups`,
    setupAddMentorshipGroup: (sanghaId: string) => `/member/setup/add-mentorship-group/${sanghaId}`,

    memberAddInterestGroup: (groupId: string) => `/member/mindful-friend-groups/group/${groupId}`,
    memberConfirmInterestGroup: (groupId: string) =>
        `/member/mindful-friend-groups/group/${groupId}/confirm`,

    // signup flow
    signup: (preselectedBillingPlan?: string) =>
        `/signup${preselectedBillingPlan ? `?plan=${preselectedBillingPlan}` : ""}`,
    signupAccount: () => "/signup/account",
    signupCommitments: () => "/signup/commitments",
    signupBilling: () => "/signup/billing",
    signupSuccess: () => "/signup/success",

    // teacher zone
    teacherPostSession: (sanghaId: string) => `/teachers/post-session/${sanghaId}`,
    teacherPostSessionRecap: (sanghaId: string) => `/teachers/post-session/${sanghaId}/recap`,
    teacherPostSessionReflection: (sanghaId: string) =>
        `/teachers/post-session/${sanghaId}/reflection`,
    teacherPostSessionSuccess: (sanghaId: string) => `/teachers/post-session/${sanghaId}/success`,
    teacherSubstituteRequests: () => `/teachers/substitute-requests`,

    // Page used for testing the zoom app
    teacherZoomAppTestPage: () => "/teachers/zoom-app-test",

    // Courses
    courseSignup: (courseId: string) => `/app/courses/${courseId}/enroll`,
    coursePaymentSuccess: (courseId: string) => `/app/courses/${courseId}/signup/success`,
    coursesDashboard: () => "/app/courses",
    coursesPublicDashboard: () => "/public/courses",
    courseEmbeddedLandingPage: (courseId: string) => `/app/courses/${courseId}`,

    // Sessions
    memberSessions: () => "/member/sessions",

    // Custom Path
    intentionDetailPage: () => "/member/intentions",
    intentionsSubmittedPage: () => "/intentions-submitted",
    recommendationsListPage: () => "/member/recommendations", // Temporary page created to visualize all recommendation cards and improve design

    // Teacher page
    teacherPage: (teacherId: string) => `/teachers/${teacherId}`,

    lounge: () => "/member/lounge",
};

export const getFullUrl = (path: string) => `${window.location.origin}${path}`;
