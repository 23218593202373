import { Alert, Button, Snackbar } from "@mui/material";
import { useForm, SubmitHandler } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import * as z from "zod";
import React, { useEffect } from "react";
import { useLocation } from "react-router";
import makeStyles from "@mui/styles/makeStyles";
import { FormInputText } from "./FormInputText";
import { analyticsTrack } from "app/analytics/track";

const useStyles = makeStyles((theme) => ({
    newsletterTextField: {
        marginBottom: 0,
        [theme.breakpoints.down("md")]: {
            margin: "0 auto",
        },
    },
    button: {
        alignSelf: "flex-start",
        [theme.breakpoints.down("md")]: {
            margin: "0 auto",
        },
    },
    emailSubscribeForm: {
        display: "flex",
        alignItems: "flex-start",
        gap: theme.spacing(2),
        [theme.breakpoints.down("md")]: {
            flexDirection: "column",
        },
    },
}));

type EmailSubscribeProps = {
    placement: string;
    classes?: Partial<ReturnType<typeof useStyles>>;
};

export const EmailSubscribe = (props: EmailSubscribeProps) => {
    const { placement } = props;
    const [showSuccess, setShowSuccess] = React.useState(false);
    const classes = useStyles(props);
    const pageLocation = useLocation();

    const emailSubscribeSchema = z.object({
        NewsletterSubscribeEmail: z
            .string()
            .min(1, "Please complete this field")
            .email("Please enter a valid email address"),
    });
    type EmailSubscribeInput = z.TypeOf<typeof emailSubscribeSchema>;

    const {
        control,
        formState: { /* errors, */ isSubmitSuccessful },
        reset,
        handleSubmit,
    } = useForm<EmailSubscribeInput>({
        resolver: zodResolver(emailSubscribeSchema),
        defaultValues: {
            NewsletterSubscribeEmail: "",
        },
    });

    const onEmailSubscribe: SubmitHandler<EmailSubscribeInput> = (values) => {
        const email = values.NewsletterSubscribeEmail;
        const subscribeSource = placement + ":" + pageLocation.pathname;

        analyticsTrack("Email Submitted", {
            email,
            subscribeSource,
        });

        if (isSubmitSuccessful) {
            setShowSuccess(true);
            reset();
        }
    };

    const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === "clickaway") {
            return;
        }

        setShowSuccess(false);
    };

    return (
        <>
            <form className={classes.emailSubscribeForm} onSubmit={handleSubmit(onEmailSubscribe)}>
                <FormInputText
                    name="NewsletterSubscribeEmail"
                    label="Email"
                    data-testid="emailField"
                    control={control}
                    placeholder="Your email address"
                    autoComplete="email"
                    className={classes.newsletterTextField}
                />

                <Button
                    variant="primary"
                    type="submit"
                    data-testid="emailSubscribeButton"
                    className={classes.button}
                >
                    Subscribe
                </Button>
            </form>

            <Snackbar
                open={showSuccess}
                autoHideDuration={6000}
                onClose={handleClose}
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            >
                <Alert onClose={handleClose} severity="success">
                    You have successfully subscribed!
                </Alert>
            </Snackbar>
        </>
    );
};
