export interface AffiliatePartnerPromotion {
    partnerId: string;
    partnerType: string;
    promotionCode?: string; // Promotion code on Stripe (eg: HisHolinessTheDalaiLama)

    // Other fields as required by the landing page design
    name: string;
    discount: number;
    customGreeting?: string; // a way to override the default "Hello friend from ______" greeting
}

const teamMembers = [
    "Winter",
    "Rahul",
    "Farrin",
    "Willem",
    "Preet",
    "Lisa",
    "Ash",
    "Ying",
    "Criss",
    "Zeynep",
];

// Teacher names should be normalized (to remove accents), and spaces and dashes removed to form the code
const teachers = [
    { name: "Alexa Redner", code: "Alexa" },
    { name: "Anne Cushman", code: "Anne" },
    { name: "Augusta Hopkins", code: "Augusta" },
    { name: "Barbara Newell", code: "Barbara" },
    { name: "Beth Smith", code: "Beth" },
    { name: "Carla Brennan", code: "Carla" },
    { name: "Cynthia Wilcox", code: "Cynthia" },
    { name: "David Cabrera", code: "David" },
    { name: "Eileen Spillane", code: "Eileen" },
    { name: "Emily Horn", code: "Emily" },
    { name: "Genevieve Tregor", code: "Genevieve" },
    { name: "Janice Gates", code: "Janice" },
    { name: "La Sarmiento", code: "La" },
    { name: "Luis Gil", code: "Luis" },
    { name: "Michael Cohen", code: "Michael" },
    { name: "Michelle Libi", code: "MichelleL" },
    { name: "Mick Neustadt", code: "Mick" },
    { name: "Nicole Jarosinski", code: "Nicole" },
    { name: "Nolitha Tsengiwe", code: "Nolitha" },
    { name: "Rae Houseman", code: "Rae" },
    { name: "Ramona Lisa Ortiz-Smith", code: "Ramona" },
    { name: "Ray Manyoky", code: "Ray" },
    { name: "Rebecca Hines", code: "Rebecca" },
    { name: "Scot Walker", code: "Scot" },
    { name: "Sharon Shelton", code: "Sharon" },
    { name: "Stan Eisenstein", code: "Stan" },
    { name: "Suzie Brown", code: "Suzie" },
    { name: "Maria Tere Abdala-Romano", code: "Maria" },
    { name: "Yong Oh", code: "Yong" },
    { name: "Ramón Honea", code: "Ramon" },
    { name: "Chad Skeers", code: "Chad" },
    { name: "Rob Creekmore", code: "Rob" },
    { name: "Patrick Brown", code: "Patrick" },
    { name: "Mary Davis", code: "Mary" },
    { name: "James Lowe", code: "James" },
    { name: "Kristen May", code: "Kristen" },
    { name: "Michele Ku", code: "MicheleK" },
    { name: "Constance Casey", code: "Constance" },
    { name: "Kay Buckby", code: "Kay" },
    { name: "Brisa Deneumostier", code: "Brisa" },
    { name: "Magloire Aguirre", code: "Magloire" },
    { name: "Rita Young", code: "Rita" },
    { name: "Eileen Tupaz", code: "EileenTupaz" },
    { name: "Daria Steketee", code: "Daria" },
    { name: "Deborah Berryman", code: "Deborah" },
    { name: "Zoe Zohs", code: "Zoe" },
    { name: "Crystal Goh", code: "Crystal" },
];

// uniqueReferrals is in Sync with:
// https://docs.google.com/spreadsheets/d/1-wYoubd25X8qDzhdAdA3SEEWvkw6WVqjLX3Nzpbq78g/
const uniqueReferrals = [
    { name: "Amy McDonald", code: "amymcdonald" },
    { name: "Lynn Roulo", code: "lynnroulo" },
];

export const affiliatePartners: AffiliatePartnerPromotion[] = [
    {
        partnerId: "MockPartnerCode",
        partnerType: "testPartner",
        promotionCode: "MockPartnerCode",
        name: "Mount Sineru",
        discount: 33,
    },
    {
        partnerId: "SpiritRock",
        partnerType: "testPartner",
        promotionCode: "SpiritRock",
        name: "Spirit Rock",
        discount: 20,
    },
    {
        partnerId: "LaFamiliaAtHandshake",
        partnerType: "organization",
        promotionCode: "LaFamiliaAtHandshake",
        name: "La Familia at Handshake",
        discount: 20,
    },
    {
        partnerId: "YungPueblo",
        partnerType: "uniqueReferral",
        promotionCode: "YungPueblo",
        name: "Yung Pueblo",
        discount: 20,
    },
    {
        partnerId: "MMTCP22",
        partnerType: "uniqueReferral",
        promotionCode: "MMTCP22",
        name: "MMTCP Graduates",
        customGreeting: `Hello, MMTCP Graduates`,
        discount: 30,
    },
    {
        partnerId: "afriend",
        partnerType: "uniqueReferral",
        promotionCode: "afriend",
        name: "Friend",
        customGreeting: `Hello, friend of a friend`,
        discount: 20,
    },
    ...teamMembers.map((teamMember) => ({
        partnerId: teamMember,
        promotionCode: teamMember,
        name: `Friend of ${teamMember}`,
        partnerType: "teamReferral",
        customGreeting: `Hello, friend of ${teamMember}`,
        discount: 20,
    })),

    ...teachers.map((teacher) => ({
        // Remove accents, spaces, dashes, etc from teacher name to create code
        partnerId: teacher.code,
        promotionCode: teacher.code,
        name: `Friend of ${teacher.name}`,
        partnerType: "teacherReferral",
        customGreeting: `Hello friend of ${teacher.name}`,
        discount: 30,
    })),
    ...uniqueReferrals.map((referral) => ({
        // Remove accents, spaces, dashes, etc from referral name to create code
        partnerId: referral.code,
        //promotionCode: referral.code,
        name: `Friend of ${referral.name}`,
        partnerType: "uniqueReferral",
        customGreeting: `Hello friend of ${referral.name}`,
        discount: 0,
    })),
];

export const getAffiliatePartner = (partnerId?: string | null) =>
    partnerId ? affiliatePartners.find((x) => x.partnerId === partnerId) : undefined;

export const getValidAffiliateReferralCodes = () =>
    affiliatePartners.filter((p) => p.promotionCode).map((p) => p.promotionCode);
