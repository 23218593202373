import makeStyles from "@mui/styles/makeStyles";

export type CSPanelProps = {
    children: React.ReactNode;
    classes?: Partial<ReturnType<typeof useStyles>>;
};

const useStyles = makeStyles((theme) => ({
    panel: {
        position: "sticky",
        bottom: 0,
        left: 0,
        zIndex: "1",
        width: "100%",
        alignSelf: "flex-start",
        alignItems: "center",
        backgroundColor: theme.palette.background.default,
        boxShadow: theme.shadow.default,
        padding: theme.spacing(3),
        textAlign: "center",
    },
}));

export const CSPanel = (props: CSPanelProps) => {
    const classes = useStyles(props);

    return <div className={classes.panel}>{props.children}</div>;
};
