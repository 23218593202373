import { Session, SessionType } from "@app/shared/types";
import { Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import classNames from "classnames";

interface ParticipationBadgeProps {
    userIsPartOfGroup: boolean;
    event: Session;
}

const useStyles = makeStyles((theme) => ({
    commitmentBadge: {
        color: theme.palette.primaryLeaves,
        lineHeight: "1rem",
    },
    commitmentIcon: {
        fontSize: theme.typography.pxToRem(18),
        marginRight: theme.spacing(0.5),
        verticalAlign: "text-bottom",
    },
}));

export const ParticipationBadge = (props: ParticipationBadgeProps) => {
    const { userIsPartOfGroup, event } = props;
    const classes = useStyles();

    if (userIsPartOfGroup && event.type === SessionType.INTEREST_GROUP) {
        if (event.sanghaMembership?.isDropIn === true) {
            return (
                <Typography
                    data-testid="commitmentBadge"
                    variant="caption"
                    className={classNames(classes.commitmentBadge)}
                >
                    <span
                        className={classNames("material-symbols-rounded", classes.commitmentIcon)}
                    >
                        check_circle
                    </span>
                    <strong>Drop-in</strong> participant
                </Typography>
            );
        } else {
            return (
                <Typography
                    data-testid="commitmentBadge"
                    variant="caption"
                    className={classNames(classes.commitmentBadge)}
                >
                    <span
                        className={classNames("material-symbols-rounded", classes.commitmentIcon)}
                    >
                        check_circle
                    </span>
                    <strong>Dedicated</strong> participant
                </Typography>
            );
        }
    } else if (userIsPartOfGroup && event.type === SessionType.SANGHA_SESSION) {
        return (
            <Typography
                data-testid="commitmentBadge"
                variant="caption"
                className={classNames(classes.commitmentBadge)}
            >
                <span className={classNames("material-symbols-rounded", classes.commitmentIcon)}>
                    check_circle
                </span>
                You are a <strong>member</strong>
            </Typography>
        );
    }

    return null;
};
