import {
    CUSTOM_PATH_TYPEFORM_URL_DEV,
    CUSTOM_PATH_TYPEFORM_URL_PRODUCTION,
} from "@app/shared/constants";
import makeStyles from "@mui/styles/makeStyles";
import { analyticsTrack } from "app/analytics/track";
import { isProduction } from "app/config";
import classNames from "classnames";
import { LinkButton, LinkButtonProps } from "features/navigation/LinkButton";

const CUSTOM_PATH_TYPEFORM_BASE_URL = isProduction()
    ? CUSTOM_PATH_TYPEFORM_URL_PRODUCTION
    : CUSTOM_PATH_TYPEFORM_URL_DEV;

const useStyles = makeStyles((theme) => ({
    mountainFlagIcon: {
        marginRight: theme.spacing(1),
    },
}));

type SetIntentionButtonProps = Omit<LinkButtonProps, "to" | "onClick" | "variant" | "fullWidth"> & {
    userId: string;
    userEmail: string;
    showIcon?: boolean;
};

export const SetIntentionButton = ({
    userId,
    userEmail,
    size = "medium",
    showIcon = true,
    ...linkButtonProps
}: SetIntentionButtonProps): JSX.Element => {
    const classes = useStyles();
    const encodedUserId = encodeURIComponent(userId);
    const encodedUserEmail = encodeURIComponent(userEmail);

    const intentionTypeformLink = `${CUSTOM_PATH_TYPEFORM_BASE_URL}#user_id=${encodedUserId}&email=${encodedUserEmail}&is_reflection=false`;
    return (
        <LinkButton
            to={intentionTypeformLink}
            variant="primary"
            fullWidth={false}
            onClick={() => {
                analyticsTrack("Set Intention Button Clicked", {
                    userId,
                    typeformLink: intentionTypeformLink,
                });
            }}
            size={size}
            {...linkButtonProps}
        >
            {showIcon && (
                <span className={classNames("material-symbols-rounded", classes.mountainFlagIcon)}>
                    mountain_flag
                </span>
            )}
            Set intentions
        </LinkButton>
    );
};
