import { Typography } from "@mui/material";
import { routes } from "../../../app/routes";
import PageWrapper from "components/PageWrapper";
import { NavLink } from "../../navigation/NavLink";

export const MembershipCanceled = () => {
    return (
        <PageWrapper>
            <Typography variant="h2">We're sorry to see you go!</Typography>
            <Typography variant="body1">
                Your membership is currently inactive. Please{" "}
                <NavLink to={routes.contactUs()}>contact us</NavLink> if you think this is a
                mistake. You can re-join from our <NavLink to={routes.home()}>homepage</NavLink> by
                clicking on "Join Us".
            </Typography>
        </PageWrapper>
    );
};
