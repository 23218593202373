import { analyticsTrack } from "app/analytics/track";
import { scrollToElement } from "./scrollToElement";

const ERROR_ELEMENT_CLASSES = ["validatedFormFieldError", "Mui-error"];

export const scrollToFirstError = () => {
    setTimeout(() => {
        const errorElement = document.querySelectorAll<HTMLElement>(
            ERROR_ELEMENT_CLASSES.map((x) => `.${x}`).join(","),
        )[0];

        if (errorElement) {
            scrollToElement(errorElement);
        } else {
            // TODO: Alert on this log
            analyticsTrack("validation.missing-error-element");
        }
    }, 0);
};
