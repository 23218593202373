import makeStyles from "@mui/styles/makeStyles";
import * as React from "react";
import { TwoColumnLayout } from "./TwoColumnLayout";

// Note: MessagePostPanel styles are closely tied to the spacing here

export const PRIMARY_COLUMN_WIDTH_PERCENT = 60;
export const SECONDARY_COLUMN_WIDTH_PERCENT = 100 - PRIMARY_COLUMN_WIDTH_PERCENT;

const useStyles = makeStyles((theme) => ({
    wrapper: {
        marginTop: 0,
        padding: 0,
    },
    container: {
        display: "block",
    },
    primaryColumn: {
        [theme.breakpoints.up("md")]: {
            width: `${PRIMARY_COLUMN_WIDTH_PERCENT}%`,
            marginLeft: `${SECONDARY_COLUMN_WIDTH_PERCENT}%`,
        },
    },
    secondaryColumn: {
        [theme.breakpoints.up("md")]: {
            position: "fixed",
            height: "100%",
            width: `${SECONDARY_COLUMN_WIDTH_PERCENT}%`,
        },

        [theme.breakpoints.up("lg")]: {
            width: (SECONDARY_COLUMN_WIDTH_PERCENT / 100) * theme.breakpoints.values["lg"],
        },
    },
    primaryInnerColumn: {},
    secondaryInnerColumn: {},
    single: {
        "& $primaryColumn": {
            marginLeft: "auto",
            marginRight: "auto",
        },
    },
}));

export interface TwoColumnStickyLayoutProps {
    children: React.ReactNode;
    secondaryColumn?: React.ReactNode;
    secondaryColumnFirst?: boolean;
    classes?: any;
}

export const TwoColumnStickyLayout = (props: TwoColumnStickyLayoutProps) => {
    const classes = useStyles(props);

    props = {
        ...props,
        classes,
    };
    return <TwoColumnLayout {...props} />;
};
