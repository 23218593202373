// analytics.js
import Analytics from "analytics";
import segmentPlugin from "@analytics/segment";
import perfumePlugin from "@analytics/perfumejs";
import googleTagManager from "@analytics/google-tag-manager";
// import perfumePlugin from "./plugins/perfumePlugin";
import Perfume from "perfume.js";

// Usually we don't want to send data into segment when testing locally
// Update the following line to enable this
const ENABLE_SEGMENT_LOCALLY = false;
const ENABLE_GTM_LOCALLY = false;

// Prevent bot traffic from sending analytics data
const isBotTraffic = navigator.userAgent.includes("Pingdom");

const isEnabledInEnvironment = (enableLocally: boolean) =>
    (enableLocally || process.env.NODE_ENV !== "development") &&
    !process.env.IS_CYPRESS &&
    !isBotTraffic;

const SEGMENT_ENABLED = isEnabledInEnvironment(ENABLE_SEGMENT_LOCALLY);
const GTM_ENABLED = isEnabledInEnvironment(ENABLE_GTM_LOCALLY);

const SEGMENT_KEY = SEGMENT_ENABLED ? process.env.REACT_APP_SEGMENT : "TEST";
const GTM_KEY = GTM_ENABLED ? process.env.REACT_APP_GTM : "TEST";

// For usage see: https://getanalytics.io/tutorials/using-with-react-router/

const analyticsInstance = Analytics({
    app: "cloud-sangha",
    plugins: [
        segmentPlugin({
            writeKey: SEGMENT_KEY,
        }),
        googleTagManager({
            containerId: GTM_KEY,
        }),
        perfumePlugin({
            perfume: Perfume,
            metricNames: [
                "fp", // firstPaint
                "fcp", // firstContentfulPaint
                "lcp", // largestContentfulPaint
                "lcpFinal", // largestContentfulPaintFinal
                "fid", // firstInputDelay
                "cls", // cumulativeLayoutShift
                "clsFinal", // cumulativeLayoutShiftFinal
                "tbt", // totalBlockingTime
                "tbtFinal", // totalBlockingTimeFinal
                "rt",
                "ttfb",
                "navigationTiming",
                "networkInformation",
                "storageEstimate",
                "ntbt",
                //"resourceTiming",
                "dataConsumption",
            ],
            perfumeOptions: {
                //resourceTiming: true,
                //elementTiming: true,
                //maxMeasureTime: 30000,
            },
        }),
    ],
});

export default analyticsInstance;
