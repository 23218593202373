import { ProductFeature } from "@app/shared/types";
import { selectIsTeacherOrAdmin } from "features/auth/auth";
import { LoggedInUserProfileContext } from "features/auth/RequireAuthentication";
import { useContext } from "react";
import { useSelector } from "react-redux";
import { MemberContext } from "../MemberContext";

export const useHasMentorshipGroup = () => {
    const user = useContext(LoggedInUserProfileContext);
    const { memberships } = useContext(MemberContext);
    const isTeacherOrAdmin = useSelector(selectIsTeacherOrAdmin);

    const hasAccessToMentorshipGroup =
        isTeacherOrAdmin || user.features.includes(ProductFeature.DEDICATED_SANGHA);

    const willUpgradeToMentorshipGroup =
        memberships[0]?.scheduledChange?.billingChoice?.plan.features.includes(
            ProductFeature.DEDICATED_SANGHA,
        );

    return hasAccessToMentorshipGroup || willUpgradeToMentorshipGroup;
};
