import { analyticsTrack } from "app/analytics/track";

export const videoAnalyticsEvents = (videoId: string) => ({
    onPlay: () => {
        analyticsTrack(`video.${videoId}.play`);
    },
    onPause: () => {
        analyticsTrack(`video.${videoId}.pause`);
    },
    onEnded: () => {
        analyticsTrack(`video.${videoId}.ended`);
    },
});
