import { Container } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import classNames from "classnames";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
    root: {
        //textAlign: "center",
        padding: theme.spacing(4),
        marginTop: theme.spacing(4),

        [theme.breakpoints.down("md")]: {
            padding: theme.spacing(2),
            border: "none",
            boxShadow: "none",
            background: "inherit",
        },
    },
    removeHeader: {
        marginTop: "0px !important",
        paddingTop: "8px !important",
    },
}));

export const PageWrapper = (props: {
    children: any;
    className?: any;
    maxWidth?: false | "xs" | "sm" | "md" | "lg" | "xl";
    removeHeader?: boolean;
}) => {
    const classes = useStyles();
    const { children, className, removeHeader, ...others } = props;
    const rootClass = className ? clsx(classes.root, className) : classes.root;

    return (
        <Container
            className={classNames(rootClass, { [classes.removeHeader]: removeHeader })}
            maxWidth={props.maxWidth || "lg"}
            {...others}
        >
            {children}
        </Container>
    );
};

export default PageWrapper;
