import { ProductFeature, Sangha, SanghaType } from "@app/shared/types";
import {
    Avatar,
    Card,
    CardActionArea,
    CardActions,
    CardContent,
    CardMedia,
    Chip,
    Typography,
    alpha,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { SanghaDayAndTimeDescription } from "features/sangha/SanghaDayAndTimeDescription";
import { useUserTimezone } from "hooks/useUserTimezone";
import { analyticsTrack } from "app/analytics/track";
import { routes } from "app/routes";
import { useHistory, useLocation } from "react-router";
import { cloudinary } from "app/cloudinary";
import { AdvancedImage, responsive } from "@cloudinary/react";
import { externalImageSrc } from "app/images";
import GroupIcon from "@mui/icons-material/Group";
import {
    getCurrentCohort,
    isUserInFreeTrialSession,
    isUserInSetupFlow,
    isUserOutsideOfMemberZone,
} from "features/signup/signupHelpers";
import { isSet } from "lodash";
import { useSelector } from "react-redux";
import { selectUser } from "features/auth/auth";
import classNames from "classnames";

const useStyles = makeStyles((theme) => ({
    card: {
        backgroundColor: theme.palette.neutralCool,
        color: theme.palette.grey900,
        borderRadius: theme.borderRadius.default,
        boxShadow: "none",

        "&:hover": {
            transform: "scale(1.025)",
            transition: "transform .3s",
            boxShadow: theme.shadow.spread,
        },
    },
    actionArea: {
        display: "flex",
        flexDirection: "column",
        height: "100%",
    },
    cardContent: {
        paddingTop: theme.spacing(1),
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        display: "flex",
        flexDirection: "column",
        flex: "1 0 auto",
        boxSizing: "border-box",
        width: "100%",
        zIndex: 2,
        color: theme.palette.neutralWhite,
    },
    cardImage: {
        position: "absolute",
        height: "100%",
        width: "100%",
        objectFit: "cover",
        zIndex: 1,
    },
    freeTrialLabel: {
        position: "absolute",
        top: 15,
        right: 15,
        zIndex: 3,
    },
    eventTime: {
        marginBottom: 0,
        marginTop: theme.spacing(10),
    },
    eventName: {
        marginBottom: theme.spacing(0),
    },
    subtitle: {
        marginBottom: 0,
    },
    cardActions: {
        padding: 0,
        marginTop: theme.spacing(2),
        maxWidth: "100%",
        display: "flex",
        justifyContent: "space-between",
    },
    avatar: {
        width: theme.spacing(4),
        height: theme.spacing(4),
        marginRight: theme.spacing(1),
        borderRadius: theme.borderRadius.circular,
        border: `2px solid ${theme.palette.neutralWhite}`,
    },
    teacherName: {
        overflow: "hidden",
        textOverflow: "ellipsis",
        marginBottom: 0,
    },
    groupIcon: {
        color: theme.palette.neutralWhite,
        borderRadius: theme.borderRadius.circular,
        width: theme.spacing(4),
        height: theme.spacing(4),
        marginLeft: theme.spacing(1),
        padding: theme.spacing(0.5),
        marginRight: theme.spacing(1),
    },
    memberCountContainer: {
        display: "flex",
        alignItems: "center",
        whiteSpace: "nowrap",
    },
    memberCount: {
        marginBottom: 0,
    },
    bottomContainer: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        flex: "1 0 auto",
        boxSizing: "border-box",
        width: "100%",
    },
    teacherContainer: {
        display: "flex",
        alignItems: "center",
        flex: "1 0 auto",
        maxWidth: "55%",
        [theme.breakpoints.down("md")]: {
            maxWidth: "50%",
        },
    },
    cardOverlay: {
        position: "absolute",
        height: "100%",
        width: "100%",
        backgroundColor: alpha(theme.palette.grey900, 0.7),
        zIndex: 2,
    },
}));

export const InterestGroupCard = (props: {
    interestGroup: Sangha;
    filterQuery?: string;
}): JSX.Element => {
    const { interestGroup, filterQuery } = props;
    const classes = useStyles();
    const timezone = useUserTimezone();
    const history = useHistory();
    const groupIsFull = !interestGroup.availableSpots || interestGroup.availableSpots <= 0;

    const location = useLocation();
    const isSetupFlow = isUserInSetupFlow(location);
    const isOutsideOfMemberZone = isUserOutsideOfMemberZone(location);

    const user = useSelector(selectUser);
    const isActiveMember = user?.features.includes(ProductFeature.CORE_MEMBERSHIP);

    const isFreeTrialSignupFlow = isUserInFreeTrialSession() && (!isActiveMember || isSetupFlow);

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const handleClick = (event?: any) => {
        if (!groupIsFull) {
            analyticsTrack("Button Clicked", {
                label: "Join",
                id: interestGroup.id,
                type: SanghaType.InterestGroup,
                location: "InterestGroupCard",
                isFreeTrial: isFreeTrialSignupFlow,
                cohort: getCurrentCohort(),
            });
            // hacks to prevent navigation on windows/macOS if ctrl or meta(macOS)
            // keys are pressed in combination with a click and allows browser to
            // fulfill its default function
            if (event.metaKey || event.ctrlKey) {
                return;
            }

            if (isSetupFlow) {
                history.push(`${routes.setupAddInterestGroup(interestGroup.id)}?${filterQuery}`);
            } else if (isOutsideOfMemberZone && !isActiveMember) {
                history.push(`${routes.addInterestGroup(interestGroup.id)}?${filterQuery}`);
            } else {
                history.push(`${routes.memberAddInterestGroup(interestGroup.id)}?${filterQuery}`);
            }
        }
    };

    const cldImage = cloudinary.image(interestGroup.topicData?.imageId).format("auto");

    const numberOfMembers = interestGroup.maxSpots
        ? interestGroup.maxSpots - interestGroup.availableSpots
        : undefined;

    return (
        <Card className={classes.card} data-testid="interestGroupCard" raised={true}>
            <CardActionArea
                onClick={handleClick}
                disabled={groupIsFull}
                className={classes.actionArea}
            >
                <div className={classes.cardOverlay} />
                <AdvancedImage
                    className={classes.cardImage}
                    cldImg={cldImage}
                    plugins={[responsive({ steps: 200 })]}
                />
                {isFreeTrialSignupFlow && (
                    <Chip
                        variant="green"
                        className={classes.freeTrialLabel}
                        label="Try it for free"
                    />
                )}
                <CardContent className={classes.cardContent}>
                    <Typography variant="caption" className={classes.eventTime}>
                        <SanghaDayAndTimeDescription
                            sangha={interestGroup}
                            userTimeZone={timezone}
                            renderTimeZone={true}
                        />
                    </Typography>
                    <Typography variant="h3" className={classes.eventName}>
                        {interestGroup.name}
                    </Typography>
                    <div className={classes.bottomContainer}>
                        <Typography variant="body1" className={classes.subtitle}>
                            {interestGroup.presentationData?.subtitle}
                        </Typography>
                        <CardActions className={classes.cardActions}>
                            <div className={classes.teacherContainer}>
                                <Avatar
                                    data-testid="teacherAvatar"
                                    className={classes.avatar}
                                    alt={interestGroup.teacher.name}
                                    src={
                                        interestGroup.teacher.picture
                                            ? externalImageSrc(interestGroup.teacher.picture, 32)
                                            : "EMPTY"
                                    }
                                    variant="circular"
                                />
                                <Typography variant="caption" className={classes.teacherName}>
                                    <strong>With {interestGroup.teacher.name}</strong>
                                </Typography>
                            </div>
                            {numberOfMembers ? (
                                <div className={classes.memberCountContainer}>
                                    <span
                                        className={classNames(
                                            "material-symbols-rounded",
                                            classes.groupIcon,
                                        )}
                                    >
                                        group
                                    </span>{" "}
                                    <Typography variant="caption" className={classes.memberCount}>
                                        {`${numberOfMembers} ${
                                            numberOfMembers > 1 ? "Members" : "Member"
                                        }`}
                                    </Typography>
                                </div>
                            ) : null}
                        </CardActions>
                    </div>
                </CardContent>
            </CardActionArea>
        </Card>
    );
};

export default InterestGroupCard;
