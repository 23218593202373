import {
    DocumentNode,
    OperationVariables,
    QueryHookOptions,
    TypedDocumentNode,
    useQuery,
} from "@apollo/client";
import { Session } from "@app/shared/types";
import { Box, BoxProps } from "@mui/system";
import { GenericErrorPage } from "components/GenericErrorPage";
import LoadingPage from "features/pages/LoadingPage";
import { useFetchDataAndKeepUpdated } from "hooks/useFetchDataAndKeepUpdated";
import { DateTime } from "luxon";
import smoothscroll from "smoothscroll-polyfill";
import { EventList } from "./EventList";
import { EventListByDay } from "./EventListByDay";
import { useScrollToEvent } from "./useScrollToEvent";
import { GRAPHQL_QUERY_UPCOMING_RSVPED_SESSIONS_FOR_USER } from "app/queries";

smoothscroll.polyfill();

export interface EventListLoaderProps<
    TData = any,
    TVariables extends OperationVariables = OperationVariables,
> extends BoxProps {
    eventsQuery: DocumentNode | TypedDocumentNode<TData, TVariables>;
    queryOptions?: QueryHookOptions<TData, TVariables>;
    elementBefore?: React.ReactNode;
    elementAfter?: React.ReactElement<{ onClick?: () => void }>;
    emptyStateElement?: React.ReactElement;
    currentServerTime: DateTime;
    splitEventsByDay?: boolean;
    limitDisplayedEvents?: number;
    showAllEvents?: boolean;
    showSpecificDate?: boolean;
    filterEvents?: (events: Session) => boolean;
    enableScrollFromQueryParams?: boolean;
}

export const EventListLoader = (props: EventListLoaderProps) => {
    const {
        eventsQuery,
        queryOptions,
        elementBefore,
        currentServerTime,
        splitEventsByDay,
        elementAfter,
        emptyStateElement,
        limitDisplayedEvents,
        showAllEvents,
        showSpecificDate,
        filterEvents,
        enableScrollFromQueryParams,
        ...others
    } = props;

    const { data, loading, error } = useFetchDataAndKeepUpdated({
        query: eventsQuery,
        options: queryOptions,
        pollIntervalInMinutes: 30,
    });

    let events = data ? (Object.values(data)[0] as Session[]) : undefined;

    const { data: rsvpedSessionData, refetch: refetchRsvpedSessions } = useQuery(
        GRAPHQL_QUERY_UPCOMING_RSVPED_SESSIONS_FOR_USER,
        {
            fetchPolicy: "network-only",
        },
    );

    const upcomingRsvpedSessionsForUser = rsvpedSessionData?.upcomingRsvpedSessionsForUser as {
        id: string;
    }[];

    useScrollToEvent(events, enableScrollFromQueryParams || false);

    if (error) {
        return <GenericErrorPage />;
    }

    if (loading) {
        return <LoadingPage />;
    }

    if (events && filterEvents) {
        events = events.filter(filterEvents);
    }

    if (!events || events.length < 1) {
        if (emptyStateElement) {
            return (
                <Box {...others}>
                    {elementBefore}
                    {emptyStateElement}
                </Box>
            );
        } else {
            return null;
        }
    }

    const displaySeeMoreButton = events.length > (limitDisplayedEvents || 0) && !showAllEvents;

    if (limitDisplayedEvents && !showAllEvents) {
        events = events.slice(0, limitDisplayedEvents);
    }

    return (
        <Box {...others}>
            <>
                {elementBefore}
                {splitEventsByDay ? (
                    <EventListByDay
                        events={events}
                        currentServerTime={currentServerTime}
                        showSpecificDate={showSpecificDate}
                        rsvpedSessions={upcomingRsvpedSessionsForUser}
                        onSessionAttend={refetchRsvpedSessions}
                    />
                ) : (
                    <EventList
                        events={events}
                        currentServerTime={currentServerTime}
                        showSpecificDate={showSpecificDate}
                        rsvpedSessions={upcomingRsvpedSessionsForUser}
                        onSessionAttend={refetchRsvpedSessions}
                    />
                )}
                {displaySeeMoreButton && elementAfter}
            </>
        </Box>
    );
};
