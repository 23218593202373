import makeStyles from "@mui/styles/makeStyles";
import { PageSection } from "../home/PageSection";
import LoadingBar from "components/LoadingBar";
import PageWrapper from "components/PageWrapper";

const useStyles = makeStyles((theme) => ({
    root: {
        minHeight: 500,
        //paddingTop: theme.spacing(10),
    },
}));

export const LoadingPage = (props: { caption?: string }) => {
    const classes = useStyles();

    return (
        <PageSection opaque>
            <PageWrapper className={classes.root} maxWidth="sm">
                <LoadingBar caption={props.caption} />
            </PageWrapper>
        </PageSection>
    );
};

export default LoadingPage;
