import { useMutation } from "@apollo/client";
import { Sangha, SanghaType } from "@app/shared/types";
import { analyticsTrack } from "app/analytics/track";
import { GRAPHQL_MUTATION_POST_SANGHA_SESSION_INDIVIDUAL_ATTENDANCE } from "app/queries";
import * as React from "react";
import { ButtonWithConfirmationDialogs } from "../community/ButtonWithConfirmationDialogs";
import { getInterestGroupConfirmationDialogs } from "./getInterestGroupConfirmationDialogs";

export const SanghaJoinButton = (props: {
    sangha: Sangha;
    disabled?: boolean;
    className?: string;
}) => {
    const { sangha, className, disabled } = props;
    const joinLink = sangha.nextSession?.zoomInfo?.joinLink;

    const sanghaSessionId = sangha.nextSession?.id;

    const [postSanghaSessionIndividualAttendance] = useMutation(
        GRAPHQL_MUTATION_POST_SANGHA_SESSION_INDIVIDUAL_ATTENDANCE,
        {
            variables: {
                sanghaId: sangha.id,
                sanghaSessionId: sanghaSessionId as string,
            },
        },
    );

    const onJoinSession = () => {
        // Log attendance
        postSanghaSessionIndividualAttendance().catch(() => {
            analyticsTrack("member.sangha.session.attendance.retry");
            postSanghaSessionIndividualAttendance();
        });

        const isRegistrantLink = joinLink?.includes("tk=");

        analyticsTrack("member.sangha.session.join", {
            session: sanghaSessionId,
            sanghaId: sangha.id,
            isRegistrantLink,
        });

        window.open(joinLink, "_blank");
    };

    return (
        <ButtonWithConfirmationDialogs
            variant="primary"
            className={className}
            size="small"
            fullWidth
            onConfirm={() => onJoinSession()}
            href={joinLink}
            disabled={disabled || !joinLink}
            aria-disabled={disabled || !joinLink}
            data-testid="joinButton"
            onOpenDialog={(dialogIndex) => {
                analyticsTrack("member.sangha.session.confirmationDialog.open", {
                    session: sanghaSessionId,
                    sanghaId: sangha.id,
                    dialogIndex,
                });
            }}
            confirmationDialogs={
                sangha.type === SanghaType.InterestGroup
                    ? getInterestGroupConfirmationDialogs(sangha.id)
                    : []
            }
        >
            Join Now
        </ButtonWithConfirmationDialogs>
    );
};
