import { DateTime, DurationObject } from "luxon";
import {
    MembershipStatus as MembershipStatusConstants,
    SanghaMembershipStatus,
    WebsiteNotificationType,
} from "./constants";

type ElementType<T extends ReadonlyArray<unknown>> =
    T extends ReadonlyArray<infer ElementTypeInferred> ? ElementTypeInferred : never;

export enum MembershipType {
    explorer = "explorer",
    immersion = "immersion",
    immersion_twice_monthly = "immersion_twice_monthly",

    general = "general",
    mmtcp = "mmtcp",
}

export type MembershipStatus = ElementType<typeof MembershipStatusConstants.All>;

export type MembershipChange = {
    billingChoice: MembershipBillingChoice;
    changeDate: string;
};

export type Discount = {
    amountOff?: number | null;
    percentOff?: number | null;
    duration?: string;
    endDate?: string | null;
};

export type FailedPaymentInvoice = {
    id: string;
    date: string;
};

export type Membership = {
    active: boolean;
    activeSanghaMembership: SanghaMembership;
    billingChoice: MembershipBillingChoice;
    scheduledChange?: MembershipChange;
    billingPlanOptions: MembershipBillingPlan[];
    slidingScaleBillingPlanOptions: MembershipBillingPlan[];
    email: string;
    id: string;
    inviteExpirationDate: string;
    lastRenewalReminderSentDate: string;
    membershipType: MembershipType;
    renewalDate: string;
    sanghaMemberships: SanghaMembership[];
    startDate: string;
    status: MembershipStatus;
    stripeCustomerId: string;
    willRenew: boolean;
    freeTrialEndDate: string;
    cohort: string | null;
    stripeSubscriptionId: String;
    discount: Discount;
    failedPaymentInvoice?: FailedPaymentInvoice;
};

export enum MembershipBillingCycle {
    month = "month",
    quarter = "quarter",
    month_in_weeks = "month_in_weeks",
    quarter_in_weeks = "quarter_in_weeks",
    semiannual = "semiannual",
    year = "year",
    year_in_weeks = "year_in_weeks",
}

export type MembershipBillingChoiceInput = {
    planName: string;
    donationAmount: number;
};

export type MembershipBillingChoice = {
    plan: MembershipBillingPlan;
    donationAmount: number;
};

export enum ProductFeature {
    CORE_MEMBERSHIP = "CORE_MEMBERSHIP",
    ELIGIBLE_FOR_ADD_ONS = "ELIGIBLE_FOR_ADD_ONS",
    DEDICATED_SANGHA = "DEDICATED_SANGHA",
    ENROLLED_IN_COURSES = "ENROLLED_IN_COURSES",
}

export enum OnboardingItemType {
    FIRST_WELCOME_SESSION = "FIRST_WELCOME_SESSION",
    FIRST_MFG = "FIRST_MFG",
    FIRST_TALK = "FIRST_TALK",
    FIRST_MEDITATION = "FIRST_MEDITATION",
    MINDFUL_RELATING_COURSE = "MINDFUL_RELATING_COURSE",
}

export interface MembershipBillingPlan {
    available: boolean;
    baseAmount: number;
    displayMonthlyAmount?: number;
    billedEvery: string;
    cycle: MembershipBillingCycle;
    membershipType: MembershipType;
    discounted: boolean;
    displayName: string;
    extendedDisplayName?: string;
    sessionsPerCycle?: number;
    duration: DurationObject;
    displaySavings?: string;
    planName: string;
    productCode: string;
    features: ProductFeature[];
    slidingScale?: boolean;
    addon?: boolean;

    // This field is only exposed on the front-end, through a graphQL resolver
    durationInSeconds?: number;
}

export type Message = {
    id: string;
    author: UserProfile;
    content: string;
    date: string;
    messageThread?: MessageThread;
    type: MessageType | null;
};

export type MessageThread = {
    id: string;
    sanghaSession: SanghaSession;
    messages: Message[];
    sangha: Sangha;
};

export type Notification = {
    id: string;
    content: string;
    membership?: Membership;
    message?: Message;
    type: WebsiteNotificationType;
    user: UserProfile;
};

export type Sangha = {
    id: string;
    type: SanghaType;
    audience: string;
    audienceDisplay: string;
    hasAvailability: boolean;
    name: string;
    memberships?: SanghaMembership[];
    eligibleMembershipTypes: MembershipType[];
    messageThread: MessageThread;
    sharedSitsEnabled?: boolean;
    teacher: Teacher;
    additionalTeachers: Teacher[];
    zoomId: string;
    highlightMemberDisplay?: boolean;
    openSpots: number;
    availableSpots: number;
    upcomingSessionForInvitation?: string;
    maxSpots?: number;
    presentationData?: PresentationData;
    lastPodAssigmentSentDate?: string;
    duration?: number;
    topicData?: InterestGroupTopic;
    cycle: SanghaSessionCycle;
    timeZone: string;
    firstSessionDate: string;
    nextSession?: SanghaSession;
    circleSpaceId?: string;
};

export type SanghaMembership = {
    id: string;
    active: boolean;
    user: User;
    lastSessionReminderSentDate: string;
    lastCheckInSurveySentDate?: string;
    sangha: Sangha;
    startDate: string;
    endDate: string;
    inviteExpirationDate: string;
    status: SanghaMembershipStatus;
    isDropIn: boolean;
};

export enum SessionType {
    SANGHA_SESSION = "SANGHA_SESSION",
    INTEREST_GROUP = "INTEREST_GROUP",

    COMMUNITY_SIT = "COMMUNITY_SIT",
    COMMUNITY_TALK = "COMMUNITY_TALK",
    WELCOME_SESSION = "WELCOME_SESSION",
    SILENT_SIT = "SILENT_SIT",

    // These are not shown on our site - they are purely for billing on Airtable
    EXTERNAL_EVENT = "EXTERNAL_EVENT",

    // These sessions are only shown to teachers (so that they can be started from our site). They are not shown to members
    // The event title can be put into the "notes" field
    TEACHER_ONLY_SESSION = "TEACHER_ONLY_SESSION",

    CONFLICT_RESOLUTION_PRACTICE = "CONFLICT_RESOLUTION_PRACTICE",
    QA_SESSION = "QA_SESSION",

    // No longer used - TODO: remove
    SILENT_MEDITATION_HALL = "SILENT_MEDITATION_HALL",
}

export enum SessionStatus {
    DRAFT = "DRAFT",
    PUBLISHED = "PUBLISHED",
    CANCELED = "CANCELED",
}

export type Session = {
    id: string;
    time: string;
    zoomInfo: SessionZoomInfo;
    cancelled: boolean;
    endTime: string;
    teachers: Teacher[];
    type: SessionType;
    notes?: string;
    sessionDescription?: string;
    sangha?: Sangha;
    rsvpUsers?: UserProfile[];
    isPromo?: boolean;
    title?: string;
    sanghaMembership?: SanghaMembership;
    substituteRequested?: boolean;
    hasSubstituteTeacher?: boolean;
    joinLink?: string;
};

export type SanghaSession = Session;

export enum SanghaSessionCycle {
    weekly = "weekly",
    monthly = "monthly",
    twiceMonthly = "twiceMonthly",
}

export enum SanghaType {
    MentorshipGroup = "MentorshipGroup",
    InterestGroup = "InterestGroup",
}

export type InterestGroupTopic = {
    id: string;
    name: string;

    // This is a cloudinary image ID
    imageId: string;
};

export type PresentationData = {
    subtitle: string;
    video: string;
    description: string;
    quotes: string[];
    whoIsThisFor: string[];
};

export type SessionZoomInfo = {
    joinLink: string;
    startLink: string;
};

export type Link = {
    name: string;
    url: string;
};

export type Certification = {
    name: string;
    institution: string;
    yearOfCompletion: string;
};

export type Teacher = {
    id: string;
    background: string | null;
    headline: string | null;
    location: string | null;
    loveWorkingWith: string | null;
    name: string;
    picture: string | null;
    pronouns: string | null;
    published: boolean;
    quote: string | null;
    specialties: string[] | null;
    training: string[] | null;
    userProfile: UserProfile;
    website: string | null;
    yearsMeditating: string | null;
    yearsTeaching: string | null;
    shortBio: string | null;
    previewVideoLink: Link | null;
    links: Link[] | null;
    certifications: Certification[] | null;
};

export type TeamMember = {
    name: string;
    link?: string;
    role: string;
    picture: string;
    className?: string;
};

export type User = {
    id: string;
    email: string;
    memberships: Membership[];
    profile: UserProfile;
    roles: string[];
    sanghaMemberships: SanghaMembership[];
    timeZone: string;
    features: ProductFeature[];
    teacher: Teacher | null;
};

export type UserProfile = {
    id: string;
    name: string;
    fullName: string;
    nickName: string;
    location: string | null;
    bio: string | null;
    isComplete: boolean;
    hasSeenCalendarPage: boolean;
    acceptedCommitments: boolean;
    completedOnboardingItems?: OnboardingItemType[];
    acceptedLoungeGuidelines?: boolean;
    picture: string | null;
    pronouns: string | null;
    numPositiveSurveyInteractions?: number;
    lastDateOfDonation?: string;

    ageRange?: string;
    ethnicity?: string;
    sexualOrientation?: string;
    gender?: string;
};

export type SanghaStartTime = {
    weekdayPST: number;
    startHourPST: number;
};

export enum MessageType {
    RECAP = "RECAP",
    REFLECTION_QUESTION = "REFLECTION_QUESTION",
}

export type TeacherPostSessionInput = {
    recapMessage: string;
    recapSignature: string;
    reflectionQuestion: string;
};

export type CancellationResponseInput = {
    reasonsForLeaving?: string[];
    elaborateOnReasons?: string;
    doneDifferently?: string;
    anythingElse?: string;
};

export enum NotificationType {
    // Common notifications
    SANGHA_MESSAGE = "SANGHA_MESSAGE",
    REFLECTION_QUESTION = "REFLECTION_QUESTION",

    // Member notifications
    SESSION_REMINDER = "SESSION_REMINDER",
    FEEDBACK = "FEEDBACK",

    // Teacher notifications
    ABSENCE_REVIEW = "ABSENCE_REVIEW",
    TEACHER_BILLING_UPDATE = "TEACHER_BILLING_UPDATE",
    GROUP_CHANGE_AND_FEEDBACK_UPDATE = "GROUP_CHANGE_AND_FEEDBACK_UPDATE",
}

export enum NotificationMode {
    EMAIL = "EMAIL",
    WEBSITE = "WEBSITE",
}

export enum NotificationFrequency {
    DAILY = "DAILY",
    MULTIPLE_TIMES_PER_DAY = "MULTIPLE_TIMES_PER_DAY",
    IMMEDIATE = "IMMEDIATE",
}

export interface NotificationPreference {
    id: string;
    type: NotificationType;
    mode: NotificationMode;
    enabled: boolean;
    frequency?: NotificationFrequency;
}

export enum SessionStarted {
    NOT_YET_STARTED = "NOT_YET_STARTED",
    ABOUT_TO_START = "ABOUT_TO_START",
    IN_PROGRESS = "IN_PROGRESS",
    FINISHED = "FINISHED",
}

export interface UTMParameters {
    utm_campaign?: string;
    utm_content?: string;
    utm_id?: string;
    utm_medium?: string;
    utm_source?: string;
    utm_term?: string;
    gclid?: string; // Tracked for google ads
}

export type Course = {
    id: string;
    name: string;
    description: string;
    availability: CourseAvailability;
    url: string;
    landingPageUrl: string;
    landingPageUrlForMembers?: string;
    circleSpaceId: string;
    stripePriceId: string;
    stripePriceIdForMembers?: string;
    stripePriceIdForInstallments?: string;
    myCourseMembership?: CourseMembership;
    price: number;
    priceForMembers?: number;
    imageUrl: string;
    teachers: Teacher[];
    createdDate?: DateTime;
};

export type CourseMembership = {
    id: string;
    courseId: string;
    userId: string;
    status: UserCourseStatus;
    enrollmentDate?: DateTime;
    completionDate?: DateTime;
    stripeCheckoutSessionId?: string;
    pricePaid?: number;
};

export enum UserCourseStatus {
    IN_PROGRESS = "IN_PROGRESS",
    COMPLETED = "COMPLETED",
    OPTED_OUT = "OPTED_OUT",
}

export enum CourseAvailability {
    PUBLIC = "PUBLIC",
    PUBLIC_NEEDS_APPROVAL = "PUBLIC_NEEDS_APPROVAL",
    PRIVATE = "PRIVATE",
    CLOSED = "CLOSED",
}

export enum OnboardingCourses {
    MINDFUL_RELATING = "Mindful Relating",
}

export type IntentionInput = {
    customPathGoals: string[];
    recommendations: string[];
    fullIntention: string;
    userId: string;
    createdDate?: DateTime;
    intentionSummary?: string;
    fullReflection?: string;
};

export type Recommendation = {
    imageId: string;
    title: string;
    description: string;
    buttonText: string;
    buttonLink: string;
    customPathGoal: string;
};
