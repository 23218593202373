import { Box, Typography } from "@mui/material";
import { GRAPHQL_QUERY_MY_SCHEDULE } from "app/queries";
import DocumentContext from "components/DocumentContext";
import PageWrapper from "components/PageWrapper";
import { useCurrentServerTime } from "hooks/useCurrentServerTime";
import { EventListLoader } from "../eventDisplay/EventListLoader";

export const MemberSchedulePage = () => {
    const currentServerTime = useCurrentServerTime();

    return (
        <div>
            <DocumentContext addBrandNameSuffix title="My Schedule" />

            <PageWrapper data-testid="mySchedulePage">
                <Box sx={{ mb: 10 }}>
                    <Typography variant="h2">My Schedule</Typography>

                    <EventListLoader
                        eventsQuery={GRAPHQL_QUERY_MY_SCHEDULE}
                        currentServerTime={currentServerTime}
                        showSpecificDate={true}
                    />
                </Box>
            </PageWrapper>
        </div>
    );
};
