interface UserProfileNameFields {
    nickName: string;
    fullName: string;
}

// Within the context of a sangha, we usually want to show users nicknames
// If there are duplicate nicknames, though, we'd display their full name instead
export const userDisplayNameWithinSangha = (
    userToDisplay: UserProfileNameFields,
    usersInSangha: UserProfileNameFields[],
) => {
    const nickNameMatches = usersInSangha.filter((x) => x?.nickName === userToDisplay.nickName);

    // If more than one member has the same nickname, return the full name
    const displayName =
        nickNameMatches.length > 1 ? userToDisplay.fullName : userToDisplay.nickName;

    return displayName;
};
