import { trackEvent, trackPageView } from "@redux-beacon/google-analytics-gtag";
import { LOCATION_CHANGE } from "connected-react-router";
import { createMiddleware, Target } from "redux-beacon";
//import { requestLogin } from "../features/auth/auth";
//import { applyForEvent } from "../features/events/eventSlice";
import { actionTypes as firebaseActionTypes } from "react-redux-firebase";

import "firebase/analytics";
import firebase from "firebase/app";

function FirebaseAnalytics(): Target {
    return function target(events) {
        firebase.analytics.isSupported().then((isSupported) => {
            if (!isSupported) {
                return;
            }

            // const pageTracking = events.filter((event) => event.type === "page");
            const eventTracking = events.filter((event) => event.type === "event");

            // pageTracking.forEach((event) => {
            //     const { type, trackingId, ...params } = event;
            //     firebase.analytics().logEvent("page_view", params);
            // });

            eventTracking.forEach((event) => {
                const { type, action, ...params } = event;
                firebase.analytics().logEvent(params.action, params);
            });
        });
    };
}

const eventsMap = {
    // Pages
    [LOCATION_CHANGE]: trackPageView((action) => ({
        path: action.payload.location.pathname,
        location: action.payload.location.pathname,
        title: action.payload.location.pathname,
    })),

    // Auth
    // [requestLogin.toString()]: trackEvent(action => ({
    //     category: "auth",
    //     action: "requestLogin",
    //     label: "Login Dialog Presented",
    // })),
    [firebaseActionTypes.LOGIN]: trackEvent((action) => ({
        category: "auth",
        action: "loginSucceeded",
        label: "Login Success",
    })),
    [firebaseActionTypes.LOGIN_ERROR]: trackEvent((action) => ({
        category: "auth",
        action: "loginFailed",
        label: "Login Fail",
    })),
    [firebaseActionTypes.LOGOUT]: trackEvent((action) => ({
        category: "auth",
        action: "logout",
        label: "Logout",
    })),
};

const ga = FirebaseAnalytics();
export const trackingMiddleware = createMiddleware(eventsMap, ga);
