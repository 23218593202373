import { useContext } from "react";
import { Route } from "react-router";
import { LoggedInUserProfileContext, RequireAuthentication } from "./RequireAuthentication";

export { LoggedInUserProfileContext };

export const AuthRoute = function ({ path, children, ...rest }: any): any {
    const user = useContext(LoggedInUserProfileContext);

    return (
        <RequireAuthentication>
            <Route path={path} {...rest}>
                {children}
            </Route>
        </RequireAuthentication>
    );
};
