import { Sangha } from "@app/shared/types";
import { Button, Link, Paper, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { LIMITED_WIDTH } from "app/styles";
import _ from "lodash";
import { useState } from "react";
import * as React from "react";
import { PRIMARY_ADMIN_ACCOUNT_NAME } from "@app/shared/constants";
import { useDispatch } from "react-redux";
import { push } from "connected-react-router";
import { routes } from "app/routes";

const useStyles = makeStyles((theme) => ({
    root: {
        marginBottom: theme.spacing(2),
        padding: theme.spacing(3, 5),
        borderRadius: theme.borderRadius.default,
        transition: "background-color 300ms ease",
        backgroundColor: theme.palette.background.contrast,
        maxWidth: LIMITED_WIDTH,
        display: "flex",
        justifyContent: "space-between",
    },

    name: {
        marginTop: 0,
        marginBottom: ".3em",
        lineHeight: "1.4em",
    },

    topLine: {
        margin: theme.spacing(0),
    },

    teacherName: {
        margin: theme.spacing(0),
    },

    image: {
        justifySelf: "right",
        backgroundSize: "cover",
        backgroundPosition: "50% 50%",
        borderRadius: theme.borderRadius.default,
        marginLeft: theme.spacing(2),

        width: 140,
        height: 90,
    },
}));

export const SanghaGroupInfo = (props: { sangha: Sangha }) => {
    const { sangha } = props;
    const classes = useStyles();

    const dispatch = useDispatch();

    const handleTeacherClick = (ev: React.MouseEvent<unknown>) => {
        ev.preventDefault();
        dispatch(push(routes.teacherPage(sangha.teacher.id)));
    };

    return (
        <Paper elevation={0} className={classes.root} data-testid="sanghaGroupInfo">
            <div>
                {sangha.audienceDisplay && sangha.audienceDisplay !== "all meditators" && (
                    <Typography variant="h4" className={classes.name}>
                        {_.upperFirst(sangha.audienceDisplay)} group
                    </Typography>
                )}
                <Typography variant="body1" className={classes.topLine}>
                    Led by the wonderful
                </Typography>
                <Typography variant="body1" className={classes.teacherName}>
                    {sangha.teacher.name === PRIMARY_ADMIN_ACCOUNT_NAME ? (
                        "Mystery Teacher"
                    ) : (
                        <Button variant="tertiary" onClick={handleTeacherClick}>
                            {sangha.teacher.name}
                        </Button>
                    )}
                </Typography>
            </div>
            <div
                className={classes.image}
                style={{
                    backgroundImage: `url("${sangha.teacher.picture}")`,
                }}
            />
        </Paper>
    );
};

export default SanghaGroupInfo;
