import { REFLECTION_QUESTION_MAX_LENGTH } from "@app/shared/constants";
import { Button, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { routes } from "app/routes";
import { BackLinkButton } from "components/BackButton";
import { FormFieldTextCounter } from "components/FormFieldTextCounter";
import { TwoColumnLayout } from "layouts/TwoColumnLayout";
import { StepProps, TeacherPostSessionFlowStep } from "./postSessionFlowTypes";
import { CSTextField } from "components/CSTextField";

const useStyles = makeStyles((theme) => ({
    warning: {
        marginBottom: theme.spacing(2),
    },
}));

export const ReflectionQuestionSection = (props: StepProps) => {
    const classes = useStyles();

    const onContinue = () => {
        props.onSubmitStep(TeacherPostSessionFlowStep.SUBMIT);
    };

    const onChangeReflectionQuestion = (reflectionQuestion: string) => {
        props.onChange({
            ...props.data,
            reflectionQuestion,
        });
    };

    const renderRightColumn = () => (
        <>
            <Typography variant="body2" className={classes.warning}>
                You can't undo this
            </Typography>
            <Button
                data-testid="submitButton"
                disabled={!props.data.reflectionQuestion}
                onClick={onContinue}
            >
                Send to {props.sangha.name}
            </Button>
        </>
    );

    return (
        <TwoColumnLayout secondaryColumn={renderRightColumn()}>
            <BackLinkButton to={routes.teacherPostSessionRecap(props.sangha.id)} />

            <Typography variant="h4">
                What <strong>question</strong> should your group reflect on?
            </Typography>

            <CSTextField
                data-testid="reflectionQuestionInput"
                value={props.data.reflectionQuestion}
                onChange={(value) => onChangeReflectionQuestion(value)}
                placeholder="Your question"
                label="Your question"
                multiline
                fullWidth
                inputProps={{
                    maxLength: REFLECTION_QUESTION_MAX_LENGTH,
                }}
            />
            <FormFieldTextCounter
                value={props.data.reflectionQuestion}
                maxLength={REFLECTION_QUESTION_MAX_LENGTH}
            />
        </TwoColumnLayout>
    );
};
