import { Typography } from "@mui/material";
import EditProfileForm from "./EditProfileForm";
import { useDispatch } from "react-redux";
import { push } from "connected-react-router";
import { routes } from "app/routes";

export const MemberZoneEditProfilePage = () => {
    const dispatch = useDispatch();
    const header = <Typography variant="h1">Your Profile</Typography>;
    const onSaveComplete = () => {
        dispatch(push(routes.memberMembership()));
    };

    return <EditProfileForm header={header} onSaveComplete={onSaveComplete} />;
};
