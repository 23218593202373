import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
    Button,
    Checkbox,
    FormControlLabel,
    IconButton,
    Link,
    TextField,
    Typography,
    useTheme,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { analyticsTrack } from "app/analytics/track";
import _ from "lodash";
import { MouseEvent, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { routes } from "../../app/routes";
import { NavLink } from "../navigation/NavLink";
import {
    loginWithEmail,
    LoginWithEmailParams,
    requestResetPasswordLink,
    selectLoginError,
    selectRequestResetPasswordLinkComplete,
    selectResetPasswordError,
} from "./auth";
import { CSTextField } from "components/CSTextField";

const useStyles = makeStyles((theme) => ({
    form: {
        width: "100%", // Fix IE 11 issue.
        margin: theme.spacing(2, 0, 2, 0),
        textAlign: "center",
    },

    formField: {
        //marginBottom: theme.spacing(2),
    },

    submit: {
        margin: theme.spacing(0, 0, 2),
    },

    terms: {
        margin: theme.spacing(2, 0),
    },
    link: {
        ...theme.typography.caption,
    },
}));

export const LoginForm = (props: {}) => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const theme = useTheme();

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [forgotPassword, setForgotPassword] = useState(false);
    const [rememberMe, setRememberMe] = useState(false);

    const loginError = useSelector(selectLoginError);
    const resetPasswordError = useSelector(selectResetPasswordError);

    const isEmailError = loginError?.includes("email");
    const isPasswordError = loginError?.includes("password");

    const onLoginWithEmail = async (ev: MouseEvent) => {
        const loginParams: LoginWithEmailParams = {
            email,
            password,
            rememberMe,
        };

        // This special parameter allows admins to login as another member
        if (loginParams.email.indexOf("@@@") > -1) {
            const splitEmail = loginParams.email.split("@@@");
            loginParams.email = splitEmail[0];
            loginParams.loginAs = splitEmail[1];
        }

        analyticsTrack("login.triggered", _.pick(loginParams, "email", "loginAs", "rememberMe"));

        dispatch(loginWithEmail(loginParams));

        ev.preventDefault();
    };
    const onResetPassword = (ev: MouseEvent) => {
        analyticsTrack("resetPassword.triggered", { email });
        dispatch(requestResetPasswordLink({ email: email }));
        ev.preventDefault();
    };

    const passwordResetRequestComplete = useSelector(selectRequestResetPasswordLinkComplete);

    const emailField = (
        <CSTextField
            autoFocus
            className={classes.formField}
            inputProps={{
                autoComplete: "username",
            }}
            autoComplete="username"
            fullWidth
            type="email"
            value={email}
            placeholder="Your email"
            label="Your email"
            onChange={(value) => setEmail(value)}
            showError={isEmailError}
            error={loginError}
            hasNoMaxWidth={true}
            data-testid="loginEmailField"
        />
    );

    if (forgotPassword) {
        if (resetPasswordError) {
            analyticsTrack("resetPassword.error", { email, resetPasswordError });
        }
        if (passwordResetRequestComplete) {
            analyticsTrack("resetPassword.success", { email });
        }
        return (
            <form className={classes.form} noValidate>
                {resetPasswordError && (
                    <Typography
                        variant="body2"
                        color="error"
                        style={{ marginBottom: theme.spacing(2) }}
                    >
                        {resetPasswordError}
                    </Typography>
                )}

                {emailField}

                {passwordResetRequestComplete ? (
                    <Typography>
                        We have sent you an email with a link to reset your password
                    </Typography>
                ) : (
                    <Button
                        fullWidth
                        variant="primary"
                        className={classes.submit}
                        onClick={onResetPassword}
                        type="submit"
                    >
                        Send reset link
                    </Button>
                )}

                <IconButton
                    data-testid="forgot-password-back-button"
                    onClick={() => setForgotPassword(false)}
                >
                    <ArrowBackIcon />
                </IconButton>
            </form>
        );
    }

    return (
        <form className={classes.form} noValidate>
            {emailField}

            <CSTextField
                inputProps={{
                    autoComplete: "current-password",
                }}
                autoComplete="current-password"
                className={classes.formField}
                fullWidth
                value={password}
                type="password"
                placeholder="Your password"
                label="Your password"
                onChange={(value) => setPassword(value)}
                showError={isPasswordError}
                error={loginError}
                hasNoMaxWidth={true}
                data-testid="loginPasswordField"
            />
            <FormControlLabel
                control={
                    <Checkbox checked={rememberMe} onChange={() => setRememberMe(!rememberMe)} />
                }
                label="Remember me"
                style={{ marginBottom: 16 }}
            />
            <Button
                fullWidth
                variant="primary"
                className={classes.submit}
                onClick={onLoginWithEmail}
                type="submit"
                data-testid="loginButton"
            >
                Log In
            </Button>

            <Button variant="tertiary" onClick={() => setForgotPassword(true)}>
                Forgot password?
            </Button>

            <Typography className={classes.terms} variant="caption" component="div">
                <span>By signing in you agree to our </span>
                <NavLink to={routes.privacyPolicy()} className={classes.link}>
                    Privacy Policy
                </NavLink>
                <span> and </span>
                <NavLink to={routes.termsOfService()} className={classes.link}>
                    Terms of Service
                </NavLink>
                .
            </Typography>
        </form>
    );
};

export default LoginForm;
