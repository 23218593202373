import { alpha, Checkbox, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { analyticsTrack } from "app/analytics/track";
import { OptionBox } from "components/OptionBox";
import { useState } from "react";

export interface LandingFlowOptionListProps {
    items: string[];
    analyticsId: string;
    isMultiSelect: boolean;
    onSelect?: (selected: string[]) => void;
    classes?: Partial<ReturnType<typeof useStyles>>;
}

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: theme.spacing(8),
        paddingBottom: theme.spacing(5),
    },
    option: {
        marginBottom: theme.spacing(5),
        backgroundColor: alpha(theme.palette.grey700, 0.08),
        display: "flex",

        "&:hover": {
            backgroundColor: alpha(theme.palette.grey700, 0.15),
        },
    },
    selected: {
        backgroundColor: theme.palette.primaryLeaves,
        color: theme.palette.neutralWarm,

        "&:hover": {
            backgroundColor: theme.palette.primaryLeaves,
        },
    },
    optionText: {
        marginBottom: 0,
        flexGrow: 1,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
}));

export const LandingFlowOptionList = (props: LandingFlowOptionListProps) => {
    const classes = useStyles(props);

    const [selectedItems, setSelectedItems] = useState<string[]>([]);

    const isSelected = (item: string) => selectedItems.includes(item);

    const updateSelectedItems = (items: string[]) => {
        setSelectedItems(items);
        if (props.onSelect) {
            props.onSelect(items);
        }
    };

    const onClickItem = (item: string) => {
        if (props.isMultiSelect) {
            if (isSelected(item)) {
                analyticsTrack(`${props.analyticsId}.selectAnswer`, {
                    option: item,
                });
                updateSelectedItems(selectedItems.filter((x) => x !== item));
            } else {
                analyticsTrack(`${props.analyticsId}.removeAnswer`, {
                    option: item,
                });
                updateSelectedItems([...selectedItems, item]);
            }
        } else {
            analyticsTrack(`${props.analyticsId}.selectAnswer`, {
                option: item,
            });
            updateSelectedItems([item]);
        }
    };

    const renderItems = () =>
        props.items.map((item, index) => (
            <OptionBox
                key={item}
                selected={isSelected(item)}
                onClick={() => onClickItem(item)}
                data-testid={`option${index}`}
                classes={{
                    root: classes.option,
                    selected: classes.selected,
                }}
            >
                <Checkbox checked={isSelected(item)} />
                <Typography variant="body1" className={classes.optionText}>
                    {item}
                </Typography>
            </OptionBox>
        ));

    return <div className={classes.root}>{renderItems()}</div>;
};
