import {
    Checkbox,
    CheckboxProps,
    FormControl,
    FormControlLabel,
    FormHelperText,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import _ from "lodash";
import React from "react";
import { Controller } from "react-hook-form";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "block",
        marginBottom: theme.spacing(3),
    },
}));

export interface FormInputCheckboxProps
    extends Omit<CheckboxProps, "variant" | "onChange" | "error" | "helperText"> {
    name: string;
    control: any;
    label: React.ReactNode;
    type?: any;
    classes?: Partial<ReturnType<typeof useStyles>>;
    error?: any;
}

export const FormInputCheckbox: React.FC<FormInputCheckboxProps> = (props) => {
    const classes = useStyles();
    const { error, name, control, label } = props;

    return (
        <FormControl className={classes.root} size={"small"} variant={"outlined"} error={!!error}>
            <FormControlLabel
                control={
                    <Controller
                        name={name}
                        control={control}
                        defaultValue="false"
                        render={({ field }) => (
                            <Checkbox {..._.omit(props, "control", "label")} {...field} />
                        )}
                    />
                }
                label={label}
            />
            {error && <FormHelperText>{error.message}</FormHelperText>}
        </FormControl>
    );
};
