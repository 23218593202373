import { Avatar, Button, Menu, MenuItem } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import { externalImageSrc } from "app/images";
import classNames from "classnames";
import { selectUserProfile } from "features/auth/auth";
import { useRef, useState } from "react";
import { useSelector } from "react-redux";
import { MenuLink } from "./MenuLink";
import { MenuLinkInfo } from "./useNavigationLinks";

const useStyles = makeStyles((theme) => ({
    root: {},
    avatar: {
        borderRadius: theme.borderRadius.circular,
        width: 32,
        height: 32,
        border: `2px solid ${theme.palette.accentGold}`,
    },
    rotate: {
        transform: "rotate(180deg)",
        transition: "transform 0.3s ease",
    },
    menuItem: {
        "& .MuiLink-root": {
            color: theme.palette.grey900,
            fontWeight: 400,
            "&:hover": {
                color: theme.palette.accentGold,
            },
        },
    },
    menuOpenIcon: {
        color: theme.palette.neutralWhite,
        marginLeft: theme.spacing(0.5),
    },
    accountMenuButton: {
        padding: 0,
        minWidth: "auto",
    },
}));

export const AccountMenu = (props: { links: MenuLinkInfo[] }) => {
    const [open, setOpen] = useState(false);

    const menuButtonRef = useRef(null);

    const classes = useStyles();
    const userProfile = useSelector(selectUserProfile);

    const renderMenuButton = (useRef: boolean) => (
        <Button
            color="secondary"
            onClick={() => setOpen((prev) => !prev)}
            ref={useRef ? menuButtonRef : undefined}
            data-testid="accountMenu"
            className={classes.accountMenuButton}
        >
            <Avatar
                className={classes.avatar}
                alt={userProfile?.fullName}
                src={userProfile?.picture ? externalImageSrc(userProfile?.picture, 55) : undefined}
            >
                {userProfile?.fullName[0]}
            </Avatar>
            <ExpandMoreRoundedIcon
                className={classNames(classes.menuOpenIcon, { [classes.rotate]: open })}
            />
        </Button>
    );

    const renderMenu = () => {
        return (
            <Menu open={open} onClose={() => setOpen(false)} anchorEl={menuButtonRef.current}>
                {props.links.map((link) => (
                    <MenuItem
                        key={link.name}
                        onClick={() => setOpen(false)}
                        className={classes.menuItem}
                    >
                        <MenuLink link={link} />
                    </MenuItem>
                ))}
            </Menu>
        );
    };

    return (
        <div className={classes.root}>
            {renderMenuButton(true)}
            {renderMenu()}
        </div>
    );
};
