import { BRAND_NAME, CONFLICT_RESOLUTION_EMAIL, CONTACT_EMAIL } from "@app/shared/constants";
import { Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { NavLink } from "features/navigation/NavLink";

const useStyles = makeStyles((theme) => ({
    contentContainer: {
        paddingBottom: theme.spacing(2),
        [theme.breakpoints.down("sm")]: {
            paddingBottom: theme.spacing(8),
        },
    },
    subList: {
        listStyleType: "lower-alpha",
    },
}));

export const Guidelines = ({ mainHeadingVariant = "h2" }: { mainHeadingVariant?: "h1" | "h2" }) => {
    const classes = useStyles();

    return (
        <div className={classes.contentContainer}>
            <Typography variant="h1">Community Guidelines</Typography>

            <Typography variant="h3">{BRAND_NAME} is a safe space</Typography>
            <Typography variant="body1">
                At {BRAND_NAME}, we are committed to creating a safe, neutral space where everyone
                feels welcome and all emotions are honored. We strive to foster mindfulness,
                understanding, and compassion for all personal perspectives and firsthand
                experiences while maintaining an environment free from divisive discussions or
                debates around sensitive topics.
            </Typography>
            <Typography variant="body1">
                These guidelines are not just rules — they are the foundation of the respectful,
                supportive, and inclusive community we are building together.{" "}
                <b>Please read through them with care and attention</b>, as they represent our
                collective commitment to ensuring that {BRAND_NAME} remains a space where everyone
                can feel safe, be heard, and grow. Your participation in upholding these guidelines
                helps create the supportive environment we all need to thrive.
            </Typography>

            <Typography variant="h3">I agree to&hellip;</Typography>
            <Typography variant="body1">
                As a member of {BRAND_NAME}, I commit to upholding these guidelines in their
                entirety. These principles apply to every aspect of the {BRAND_NAME} community,
                including Mindful Friend Groups (MFGs), Mentorship Groups, retreats, courses,
                sessions, breakout rooms, community talks, Q+As, the Lounge, and any personal
                interactions. By agreeing to the following, I contribute to creating a safe,
                inclusive, and supportive space for all members:
            </Typography>
            <Typography variant="body1" component="div">
                <ol>
                    <li>
                        <b>Honor everyone's unique journey</b> by embracing diversity in race,
                        color, nationality, sex, gender, age, religion, body size, ability, medical
                        conditions, military status, or marital status. Be mindful that each
                        person's experience is their own, and we're here to support one another with
                        openness and respect.
                    </li>
                    <li>
                        <b>Support others with kindness and respect.</b> Approach every interaction
                        with a spirit of compassion and understanding, creating a space where
                        everyone feels safe to share.
                    </li>
                    <li>
                        <b>Share from my own experience</b>, using “I” statements. Speak honestly
                        and vulnerably from the heart, without assuming or projecting onto others'
                        experiences.
                    </li>
                    <li>
                        <b>Take responsibility</b> for my own process when I share. Allow others to
                        be a loving witness, but don't expect them to hold my emotional weight. If I
                        need to pause, take a breath, or step away, I can do so without
                        judgment—trusting the space will still be there when I return.
                    </li>
                    <li>
                        <b>Avoid giving unsolicited advice</b> or trying to 'fix' others. Offer
                        support by simply listening and being present, allowing people to find their
                        own path.
                    </li>
                    <li>
                        <b>Hold what others share in strict confidence.</b> Stories shared in this
                        space are personal and should be honored with care and privacy.
                    </li>
                    <li>
                        <b>Avoid using AI transcription tools</b> such as read.ai, otter.ai, and
                        Zoom IQ. Ensure that all voices are heard in their true, authentic form
                        within this space.
                    </li>
                    <li>
                        <b>Encourage full participation</b> by all present. Be mindful of how often
                        you speak—sit back to create space for others if you tend to contribute
                        frequently, and speak up if you usually stay quiet. The goal is to create a
                        balanced, inclusive environment where everyone feels they can share.
                    </li>
                    <li>
                        <b>Set aside roles</b> like coach, teacher, or therapist during community
                        conversations. Show up as a fellow participant, allowing everyone the space
                        to express without authority or judgment.
                    </li>
                    <li>
                        <b>Avoid promoting products or services</b> or asking for personal favors
                        outside the group context. Focus on genuine connection and shared
                        experiences, free from external agendas.
                    </li>
                </ol>
            </Typography>

            <Typography variant="h3">To be fully present & support others:</Typography>
            <Typography variant="body1">
                <ul>
                    <li>
                        <b>Arrive early</b> to settle in for an on-time start.
                    </li>
                    <li>
                        <b>Mute my microphone</b> when not speaking.
                    </li>
                    <li>
                        <b>Use a device with a big screen</b> that allows me to see everyone.
                    </li>
                    <li>
                        <b>Turn off all other devices</b> (cellphones, tablets, etc.) to give my
                        full presence to the session and refrain from multitasking.
                    </li>
                    <li>
                        <b>Keep my video on</b> to enhance connection, and briefly switch it off if
                        I need to move.
                    </li>
                    <li>
                        <b>"Hide Self View" in Zoom</b> for more natural interaction.
                    </li>
                </ul>
            </Typography>

            <Typography variant="h3">In breakout rooms:</Typography>
            <Typography variant="body1">
                <ul>
                    <li>
                        <b>Nominate a timekeeper.</b>
                    </li>
                    <li>
                        <b>Know that it's okay not to share.</b>
                    </li>
                    <li>
                        <b>Chat casually</b> once everyone has had a chance to respond to the
                        prompt.
                    </li>
                    <li>
                        <b>Leave the breakout room</b> or session anytime I feel uncomfortable.
                    </li>
                </ul>
            </Typography>

            <Typography variant="h3">Emotional expression & sensitive topics:</Typography>
            <Typography variant="body1">
                We understand that members may be affected by global events, identity issues, or
                other deeply personal experiences. While we want to provide a space where everyone
                can express their emotions and find support, we ask that discussions focus on
                <b> inner experiences</b> rather than debates about external issues. To maintain
                inclusivity and avoid alienating others, we request that members:
            </Typography>
            <Typography variant="body1" component="div">
                <ul>
                    <li>
                        <b>Avoid sharing political opinions or engaging in debates</b> on topics
                        such as:
                        <ul>
                            <li>Global events, wars, and conflicts</li>
                            <li>Social justice movements and activism</li>
                            <li>Public health issues (e.g., COVID-19, vaccinations)</li>
                            <li>Economic inequality or class struggles</li>
                            <li>Environmental issues (e.g., climate change)</li>
                        </ul>
                    </li>
                    <li>
                        <b>Focus on how mindfulness can help you hold difficulties</b> that arise
                        from these tensions rather than exploring the political or advocacy aspects
                        of the issue.
                    </li>
                    <li>
                        <b>Sharing personal experiences</b>: We welcome personal stories and
                        emotional expression related to your identity (e.g., race, gender, or
                        cultural background), spirituality, lived experience, or any of the topics
                        noted above. However, discussions should focus on personal growth and
                        mindfulness and avoid political advocacy, religious proselytizing, or
                        attempts to influence others.
                    </li>
                </ul>
            </Typography>

            <Typography variant="h3">Dealing with trauma & emotional triggers:</Typography>
            <Typography variant="body1" component="div">
                <ul>
                    <li>
                        We recognize that some members may be processing trauma or experiencing
                        heightened emotional responses. While we offer a supportive space, please
                        note that {BRAND_NAME} is not the appropriate setting for
                        <b> active trauma processing.</b> If you feel that sharing your trauma
                        exceeds what can be supported here, we encourage you to seek additional
                        support from a therapist or professional.
                    </li>
                </ul>
            </Typography>

            <Typography variant="h3">
                What to do when impacted by another member's share:
            </Typography>
            <Typography variant="body1" component="div">
                <ul>
                    <li>
                        <b>Pause. Take a moment to breathe and care for yourself.</b>
                    </li>
                    <li>
                        Consider that there may have been no intent to cause harm or awareness that
                        harm was caused.
                    </li>
                    <li>
                        <b>Without judgment or blame</b>, let your group know that something shared
                        impacted you, using “I” statements. This can lead to deeper understanding
                        and healing for all.
                    </li>
                    <li>
                        If the current session feels too charged,{" "}
                        <b>wait until the next session </b>
                        to share.
                    </li>
                    <li>
                        If you are outside your window of tolerance for what feels safe, please take
                        steps to gently care for yourself.
                    </li>
                </ul>
            </Typography>

            <Typography variant="h3">
                What to do when concerned you have impacted another member:
            </Typography>
            <Typography variant="body1" component="div">
                <ul>
                    <li>
                        <b>Pause, relax, and open</b>; take a moment to breathe and extend
                        self-compassion.
                    </li>
                    <li>
                        Acknowledge that you may have caused unintentional harm. Example: “If what I
                        shared caused harm, please forgive me. This was not my intention, and I
                        understand there may still have been impact.”
                    </li>
                </ul>
            </Typography>

            <Typography variant="h3">Report concerns or conflicts:</Typography>
            <Typography variant="body1" component="div">
                <ul>
                    <li>
                        If at any time you feel uncomfortable or concerned about another member's
                        share, please report it to {BRAND_NAME}{" "}
                        <NavLink href={`mailto:${CONFLICT_RESOLUTION_EMAIL}`}>
                            ({CONFLICT_RESOLUTION_EMAIL})
                        </NavLink>
                        .
                    </li>
                </ul>
            </Typography>

            <Typography variant="h3">
                I understand that {BRAND_NAME} reserves the right to&hellip;
            </Typography>
            <Typography variant="body1" component="div">
                <ul>
                    <li>
                        <b>Redirect members to seek professional support</b> from a therapist or
                        other expert if participation here could be detrimental.
                    </li>
                    <li>
                        <b>Remove members who repeatedly breach these guidelines.</b>
                    </li>
                    <li>
                        <b>Remove posts that are divisive or exclusionary.</b>
                    </li>
                </ul>
            </Typography>

            <Typography variant="h3">The Dos & Don'ts of mindful communication:</Typography>
            <Typography variant="body1" component="div">
                <ul>
                    <li>
                        <b>Do</b> express your emotions in a way that fosters understanding and
                        connection.
                    </li>
                    <li>
                        <b>Do</b> reflect on how your words may impact others before sharing.
                    </li>
                    <li>
                        <b>Don't</b> engage in political debates or advocacy. Instead, focus on how
                        mindfulness can help you navigate difficult emotions.
                    </li>
                    <li>
                        <b>Do</b> practice compassion and respect, even when dealing with
                        challenging topics.
                    </li>
                    <li>
                        <b>Don't</b> expect others to hold your emotions—everyone is here to witness
                        and support, but your process is your responsibility.
                    </li>
                </ul>
            </Typography>

            <Typography variant="h3">
                Thank You for Being Part of the {BRAND_NAME} Community
            </Typography>
            <Typography variant="body1">
                We deeply appreciate your commitment to fostering a mindful, compassionate, and
                inclusive space at {BRAND_NAME}. By following these guidelines, you help create a
                safe environment where everyone can feel heard, supported, and respected. Together,
                we are building a community rooted in mindfulness, personal growth, and shared
                connection.
            </Typography>
            <Typography variant="body1">
                Thank you for your presence, participation, and care. We are here when you need us.
                Email us at <NavLink href={`mailto:${CONTACT_EMAIL}`}>{CONTACT_EMAIL}</NavLink> if
                you have any questions or need clarification on any of our Community Guidelines.
            </Typography>
            <Typography variant="body1">
                In Service,
                <br />
                The {BRAND_NAME} Team
            </Typography>
        </div>
    );
};

export default Guidelines;
