import { Sangha } from "@app/shared/types";
import { useUserTimezone } from "hooks/useUserTimezone";
import { GRAPHQL_QUERY_SESSION_BY_ID } from "app/queries";
import { useQuery } from "@apollo/client";
import CommunityEventCard from "./CommunityEventCard";
import { CircularProgressContainer } from "components/CircularProgressContainer";
import { GenericErrorMessage } from "components/GenericErrorMessage";

export const ImmersionGroupCard = (props: { sangha: Sangha }) => {
    const { sangha } = props;

    const timezone = useUserTimezone();

    const { data, loading, error } = useQuery(GRAPHQL_QUERY_SESSION_BY_ID, {
        variables: { sessionId: sangha.nextSession?.id },
        skip: !sangha.nextSession,
    });

    const session = data?.sessionById;

    if (loading) {
        return <CircularProgressContainer />;
    }

    if (error) {
        return <GenericErrorMessage />;
    }

    return (
        <CommunityEventCard
            event={session}
            timezone={timezone}
            userRsvpedToSession={false}
            sangha={sangha}
        />
    );
};

export default ImmersionGroupCard;
