import { AllEthnicityOptions } from "@app/shared/constants";

export const GenderOptions = ["Female", "Male", "Non-binary", "Prefer not to say"] as const;

export const EthnicityOptions = AllEthnicityOptions;

export const AgeRangeOptions = [
    "Under 25 years old",
    "25-39 years old",
    "40-59 years old",
    "60+ years old",
] as const;

export const SexualOrientationOptions = [
    "Bisexual",
    "Asexual",
    "Gay",
    "Straight",
    "Lesbian",
    "Pansexual",
    "Queer",
    "Questioning or unsure",
    "Other",
    "Prefer not to say",
] as const;
