import makeStyles from "@mui/styles/makeStyles";
import classNames from "classnames";
import { ReactComponent as LogoSvg } from "images/logos/logo.svg";
import { ReactComponent as WordmarkSvg } from "images/logos/wordmark.svg";
import { ReactComponent as BrandmarkSvg } from "images/logos/brandmark.svg";
import { capitalize } from "lodash";
import { theme } from "app/theme";

interface LogoProps {
    variant: "logo" | "brandmark" | "wordmark";
    color: "black" | "green" | "neutralWarm" | "white";
    margin: "default" | "spaced" | "none";
    height?: number;
    width?: number;
    className?: string;
}

// Values still need to be added/replaced
const useStyles = makeStyles(() => ({
    logo: { display: "flex", alignItems: "center", justifyContent: "center" },
    black: { "& path": { fill: theme.palette.grey900 } },
    green: { "& path": { fill: theme.palette.primaryBanyan } },
    neutralWarm: { "& path": { fill: theme.palette.neutralWarm } },
    white: { "& path": { fill: theme.palette.neutralWhite } },
    default: { margin: 10 },
    spaced: { margin: 20 },
    brandmarkDefault: { margin: 50 },
    brandmarkSpaced: { margin: 100 },
}));

const logoComponents = {
    logo: LogoSvg,
    brandmark: BrandmarkSvg,
    wordmark: WordmarkSvg,
};

export const Logo = (props: LogoProps) => {
    const { variant, color, margin, height, width, className } = props;
    const classes = useStyles();

    const variantMargin = (
        variant === "brandmark" ? `brandmark${capitalize(margin)}` : margin
    ) as keyof typeof classes;

    const logoStylingClasses = classNames(classes[color], classes[variantMargin]);

    const LogoComponent = logoComponents[variant];
    return (
        <LogoComponent
            className={classNames(classes.logo, logoStylingClasses, className)}
            style={{ height: height || "auto", width: width || "auto" }}
        />
    );
};
