import { Paper, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { LIMITED_WIDTH } from "app/styles";
import { DateTime } from "luxon";

const useStyles = makeStyles((theme) => ({
    root: {
        marginBottom: theme.spacing(2),
        padding: theme.spacing(6),
        borderRadius: theme.borderRadius.default,
        transition: "background-color 300ms ease",
        backgroundColor: theme.palette.background.contrast,
        maxWidth: LIMITED_WIDTH,
    },

    title: {
        display: "inline-block",
        marginRight: theme.spacing(3),
    },
}));

export const CancelledMembershipExplanation = (props: {
    cancelDate: DateTime;
    isImmersion: boolean;
    classes?: unknown;
}): JSX.Element => {
    const classes = useStyles(props);
    const { cancelDate, isImmersion } = props;
    const numDaysRemaining = Math.ceil(
        cancelDate.diff(DateTime.now(), "days").toObject().days ?? 1,
    ); //=> { days: 1 }

    return (
        <Paper elevation={0} className={classes.root} data-testid="freeTrialMembershipExplanation">
            <div>
                <Typography variant="h4" className={classes.title}>
                    {isImmersion ? "Immersion" : "Explorer"}
                </Typography>
                <Typography variant="body1" className={classes.title}>
                    {numDaysRemaining} {numDaysRemaining > 1 ? "days" : "day"} remaining
                </Typography>
            </div>

            <Typography variant="body1">
                You chose to cancel your membership. You'll still have full access until the end of
                your current billing cycle on {props.cancelDate.toLocaleString(DateTime.DATE_MED)}.
            </Typography>
        </Paper>
    );
};

CancelledMembershipExplanation.defaultProps = {
    cancelDate: DateTime.now(),
    isImmersion: false,
    classes: undefined,
};

export default CancelledMembershipExplanation;
