import { Box, Button, Typography } from "@mui/material";
import { CSCheckboxField } from "components/CSCheckboxField";
import PageWrapper from "components/PageWrapper";
import { NavLink } from "features/navigation/NavLink";
import { LoungeOpenedSuccessMessage } from "./LoungeOpenedSuccessMessage";
import { routes } from "app/routes";
import classNames from "classnames";
import { useState } from "react";
import { useMutation } from "@apollo/client";
import {
    GRAPHQL_MUTATION_INVITE_MEMBER_TO_CIRCLE,
    GRAPHQL_MUTATION_SET_LOUNGE_GUIDELINES_ACCEPTANCE,
} from "app/queries";
import { theme } from "app/theme";
import makeStyles from "@mui/styles/makeStyles";
import { trackOpeningLounge } from "./loungeHelpers";

const useStyles = makeStyles((theme) => ({
    buttonContainer: {
        textAlign: "right",
        marginTop: theme.spacing(2),
    },
}));

interface LoungeWelcomeMessageProps {
    loungeUrl: string;
}

export const LoungeWelcomeMessage = (props: LoungeWelcomeMessageProps) => {
    const { loungeUrl } = props;

    const classes = useStyles();

    const [showCommunityGuidelinesInfo, setShowCommunityGuidelinesInfo] = useState(false);
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);
    const [guidelinesAccepted, setGuidelinesAccepted] = useState(false);

    const [inviteMemberToCircleMutation] = useMutation(GRAPHQL_MUTATION_INVITE_MEMBER_TO_CIRCLE, {
        variables: {
            spaceIds: ["1249150", "1355187"], // "Community Posts" & "How to use the Lounge"
        },
    });
    const [setLoungeGuidelinesAcceptance] = useMutation(
        GRAPHQL_MUTATION_SET_LOUNGE_GUIDELINES_ACCEPTANCE,
    );
    const handleWelcomeBannerClick = () => setShowCommunityGuidelinesInfo(true);
    const handleOpeningCircleClick = async () => {
        await inviteMemberToCircleMutation();
        await setLoungeGuidelinesAcceptance({ variables: { accepted: true } });
        window.open(loungeUrl, "_blank");
        trackOpeningLounge(loungeUrl);
        setShowSuccessMessage(true);
    };
    return showSuccessMessage ? (
        <LoungeOpenedSuccessMessage loungeUrl={loungeUrl} />
    ) : (
        <PageWrapper>
            {showCommunityGuidelinesInfo ? (
                <Box>
                    <Typography variant="h2">Continue following our guidelines</Typography>
                    <Typography variant="body1">
                        Our Banyan guidelines continue to apply in the Banyan Lounge. Please check
                        the full{" "}
                        <NavLink to={routes.communityGuidelines()}>guidelines here</NavLink>.
                    </Typography>
                    <CSCheckboxField
                        value={guidelinesAccepted}
                        onChange={setGuidelinesAccepted}
                        label="I accept to continue following the guidelines."
                    />
                    {showSuccessMessage ? (
                        <LoungeOpenedSuccessMessage loungeUrl={loungeUrl} />
                    ) : (
                        <div className={classes.buttonContainer}>
                            <Button
                                variant="primary"
                                onClick={() => handleOpeningCircleClick()}
                                disabled={!guidelinesAccepted}
                            >
                                Explore the lounge
                                <span
                                    className={classNames("material-symbols-rounded")}
                                    style={{ marginLeft: theme.spacing(1) }}
                                >
                                    open_in_new
                                </span>
                            </Button>
                        </div>
                    )}
                </Box>
            ) : (
                <Box>
                    <Typography variant="h2">WELCOME TO THE BANYAN LOUNGE</Typography>
                    <Typography variant="body1">
                        This is where Banyan members hang out when we're not in our weekly groups.
                        Come chat, learn, make friends, and practice more often.
                    </Typography>
                    <Typography variant="h4">What To Expect</Typography>
                    <Typography variant="body1">
                        <ul>
                            <li>Community posts to share insights and practice together</li>
                            <li>
                                Optional member directory and private messaging (one on one, and in
                                group)
                            </li>
                            <li>Courses to further your practice</li>
                            <li>
                                Private spaces for each mentorship and mindful friends group you
                                belong to
                            </li>
                        </ul>
                    </Typography>
                    <div className={classes.buttonContainer}>
                        <Button variant="primary" onClick={() => handleWelcomeBannerClick()}>
                            Continue
                        </Button>
                    </div>
                </Box>
            )}
        </PageWrapper>
    );
};
