import { analyticsTrack } from "app/analytics/track";
import { useEffect } from "react";

export const useLogEventOnLoad = (eventType: string, eventProperties?: Object) => {
    useEffect(() => {
        analyticsTrack(eventType, eventProperties);

        // Disable dependency warning as we only want this to fire when the component is loaded
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
};
