import { Paper, PaperProps } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { LIMITED_WIDTH } from "app/styles";
import classNames from "classnames";
import _ from "lodash";

export interface OptionBoxProps extends PaperProps {
    readOnly?: Boolean;
    selected?: Boolean;
    classes?: any;
}

const useStyles = makeStyles((theme) => ({
    root: {
        marginBottom: theme.spacing(2),
        padding: theme.spacing(3, 5),
        borderRadius: theme.borderRadius.default,
        transition: "background-color 300ms ease",
        backgroundColor: theme.palette.background.contrast,
        textAlign: "center",

        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(4),
        },
    },

    selectable: {
        cursor: "pointer",
        color: theme.palette.grey700,

        "&:hover": {
            backgroundColor: theme.palette.grey200,
        },
    },

    selected: {
        backgroundColor: theme.palette.grey700,
        color: theme.palette.background.default,

        "&:hover": {
            backgroundColor: theme.palette.grey700,
        },
    },
}));

export const OptionBox = (props: OptionBoxProps) => {
    const classes = useStyles(props);

    const rootClasses = classNames(classes.root, {
        [classes.selectable]: !props.readOnly,
        [classes.selected]: props.selected,
    });

    return (
        <Paper
            elevation={0}
            data-selected={props.selected}
            className={rootClasses}
            {..._.omit(props, "readOnly", "selected", "classes")}
        />
    );
};
