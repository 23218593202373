import { Grid } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { NAVBAR_HEIGHT } from "app/styles";
import classNames from "classnames";
import PageWrapper from "components/PageWrapper";
import * as React from "react";

const useStyles = makeStyles((theme) => ({
    wrapper: {
        marginTop: 0,
        padding: 0,
    },

    single: {},

    container: {},
    primaryColumn: {
        padding: theme.spacing(4),
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
    },
    secondaryColumn: {
        padding: theme.spacing(4),
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        textAlign: "center",
        maxHeight: `calc(100vh - ${NAVBAR_HEIGHT}px)`,
        overflowY: "auto",

        [theme.breakpoints.down("md")]: {
            alignItems: "flex-start",
            maxHeight: "none",
            paddingTop: 0,
        },
    },
    secondaryFirst: {
        "& $secondaryColumn": {
            [theme.breakpoints.down("md")]: {
                paddingTop: theme.spacing(2),
                paddingBottom: theme.spacing(2),
            },
        },
    },
    primaryInnerColumn: {},
    secondaryInnerColumn: {
        margin: "auto 0",
    },
}));

export interface TwoColumnLayoutProps {
    children: React.ReactNode;
    secondaryColumn?: React.ReactNode;
    secondaryColumnFirst?: boolean;
    classes?: Partial<ReturnType<typeof useStyles>>;
}

export const TwoColumnLayout = (props: TwoColumnLayoutProps) => {
    const classes = useStyles(props);

    const renderPrimaryColumn = () => (
        <Grid item xs className={classes.primaryColumn}>
            <div className={classes.primaryInnerColumn}>{props.children}</div>
        </Grid>
    );

    const renderSecondaryColumn = () =>
        props.secondaryColumn ? (
            <Grid item xs={12} md={5} className={classes.secondaryColumn} data-scrollable="true">
                <div className={classes.secondaryInnerColumn}>{props.secondaryColumn}</div>
            </Grid>
        ) : null;

    const renderColumns = () => {
        if (props.secondaryColumnFirst) {
            return (
                <>
                    {renderSecondaryColumn()}
                    {renderPrimaryColumn()}
                </>
            );
        } else {
            return (
                <>
                    {renderPrimaryColumn()}
                    {renderSecondaryColumn()}
                </>
            );
        }
    };

    return (
        <PageWrapper
            className={classNames(classes.wrapper, {
                [classes.single]: !props.secondaryColumn,
            })}
        >
            <Grid
                className={classNames(classes.container, {
                    [classes.secondaryFirst]: props.secondaryColumnFirst,
                })}
                container
                alignItems="stretch"
            >
                {renderColumns()}
            </Grid>
        </PageWrapper>
    );
};
