import { Button, ButtonProps } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { push, replace } from "connected-react-router";
import { MouseEvent } from "react";
import * as React from "react";
import { useDispatch } from "react-redux";

const useStyles = makeStyles((theme) => ({
    button: {},
}));

export interface LinkButtonProps extends ButtonProps {
    to: string;
    additionalOnClick?: (ev: React.MouseEvent<any>) => void;
    useReplace?: boolean;
    openInNewTab?: boolean;
}

export const LinkButton = (props: LinkButtonProps) => {
    const dispatch = useDispatch();
    const classes = useStyles();

    const { to, additionalOnClick, useReplace, openInNewTab, ...others } = props;

    const onClick = (ev: MouseEvent<HTMLButtonElement>) => {
        if (to.startsWith("http")) {
            if (openInNewTab) {
                window.open(to, "_blank", "noopener,noreferrer");
            } else {
                window.location.href = to;
            }
        } else {
            if (useReplace) {
                dispatch(replace(to));
            } else {
                dispatch(push(to));
            }
        }

        ev.preventDefault();
        if (props.onClick) {
            props.onClick(ev);
        }
        if (additionalOnClick) {
            additionalOnClick(ev);
        }
    };

    return <Button className={classes.button} href={props.to} {...others} onClick={onClick} />;
};
