import { LinearProgress, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
    root: {
        textAlign: "center",
        paddingTop: theme.spacing(10),
    },

    progressBar: {
        margin: theme.spacing(2),
    },
}));

export const LoadingBar = (props: { caption?: string; className?: any }) => {
    const classes = useStyles();
    const { caption, className } = props;

    return (
        <div className={clsx(classes.root, className)}>
            <Typography variant="h4">{caption || "Loading"}...</Typography>
            <LinearProgress className={classes.progressBar} />
        </div>
    );
};

export default LoadingBar;
