import { useMutation } from "@apollo/client";
import { Course } from "@app/shared/types";
import { Button, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { CSDialog } from "components/CSDialog";
import { CSTextField } from "components/CSTextField";
import { GRAPHQL_MUTATION_OPT_OUT_OF_COURSE } from "app/queries";

interface CourseOptOutDialogProps {
    dialogOpen: boolean;
    onClose: () => void;
    course: Course;
    onOptOut: () => void;
}

const useStyles = makeStyles((theme) => ({
    buttonContainer: {
        display: "flex",
        justifyContent: "flex-end",
        gap: theme.spacing(2),
    },
}));

export const CourseOptOutDialog = (props: CourseOptOutDialogProps) => {
    const { dialogOpen, onClose, course, onOptOut } = props;
    const classes = useStyles();

    const [courseOptOutMutation] = useMutation(GRAPHQL_MUTATION_OPT_OUT_OF_COURSE);

    const onSkipCourse = async () => {
        await courseOptOutMutation({
            variables: {
                courseId: course.id,
            },
        });
        onOptOut();
        onClose();
    };

    return (
        <CSDialog
            open={dialogOpen}
            closeButtonSize="small"
            onClose={onClose}
            data-testid="optOutDialog"
        >
            <Typography variant="h2">ARE YOU SURE YOU WON'T LEARN ANYTHING NEW?</Typography>
            <Typography variant="body1">
                We know you may already be well-trained in mindful communication. Our course is an
                opportunity to learn new things from different teachers. Think about it...
            </Typography>
            <Typography variant="body1">
                <strong>
                    Please share below what training / experience you have, such as a mindfulness
                    teacher training, non-violent communication training, or mindful communication
                    training.
                </strong>
            </Typography>
            <CSTextField label="Share here" multiline={true} />
            <div className={classes.buttonContainer}>
                <Button
                    variant="destructive"
                    onClick={() => onSkipCourse()}
                    data-testid="optOutButton"
                >
                    Skip course
                </Button>
                <Button variant="secondary" onClick={onClose}>
                    Cancel
                </Button>
            </div>
        </CSDialog>
    );
};
