import { Box, Typography } from "@mui/material";
import { routes } from "app/routes";
import { LIMITED_WIDTH } from "app/styles";
import { OptionBox } from "components/OptionBox";
import PageWrapper from "components/PageWrapper";
import { push } from "connected-react-router";
import giveIcon from "images/icons/give2.svg";
import { Image } from "mui-image";
import { useDispatch } from "react-redux";

export const ContributePage = () => {
    const dispatch = useDispatch();

    return (
        <PageWrapper>
            <Box sx={{ width: "50%", maxWidth: 300 }}>
                <Image src={giveIcon} />
            </Box>
            <Typography variant="h4">Pay it forward</Typography>
            <Typography variant="body1" sx={{ maxWidth: LIMITED_WIDTH }}>
                This community works because we all contribute as generously as we can. If you can
                afford to contribute more, choose to pay it forward today.
            </Typography>

            <OptionBox onClick={() => dispatch(push(routes.memberDonate()))} data-testid="donate">
                <strong>Give a one-time donation</strong>
            </OptionBox>
        </PageWrapper>
    );
};
