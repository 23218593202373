import { useQuery } from "@apollo/client";
import { Sangha } from "@app/shared/types";
import { analyticsTrack } from "app/analytics/track";
import {
    GRAPHQL_QUERY_CURRENT_DEFAULT_REFLECTION_QUESTION,
    GRAPHQL_QUERY_SANGHA,
} from "app/queries";
import { routes } from "app/routes";
import { push } from "connected-react-router";
import { selectUserProfile } from "features/auth/auth";
import { GenericErrorPage } from "components/GenericErrorPage";
import LoadingBar from "components/LoadingBar";
import { PageWrapper } from "components/PageWrapper";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, Route, Switch, useParams } from "react-router";
import {
    OnChangeFunction,
    OnSubmitStepFunction,
    StepProps,
    TeacherPostSessionFlowData,
    TeacherPostSessionFlowStep,
} from "./postSessionFlowTypes";
import { RecapSection } from "./RecapSection";
import { ReflectionQuestionSection } from "./ReflectionQuestionSection";
import { SubmitTeacherPostSessionFlow } from "./SubmitTeacherPostSessionFlow";

const initialData: TeacherPostSessionFlowData = {
    recap: {
        message: "",
        signature: "With love,",
    },
    reflectionQuestion: "",
};

const stepMap = {
    [TeacherPostSessionFlowStep.RECAP]: {
        route: routes.teacherPostSessionRecap,
        component: RecapSection,
    },
    [TeacherPostSessionFlowStep.REFLECTION_QUESTION]: {
        route: routes.teacherPostSessionReflection,
        component: ReflectionQuestionSection,
    },
    [TeacherPostSessionFlowStep.SUBMIT]: {
        route: routes.teacherPostSessionSuccess,
        component: SubmitTeacherPostSessionFlow,
    },
};

export const TeacherPostSessionFlow = () => {
    const [data, setData] = useState(initialData);
    const [step, setStep] = useState(TeacherPostSessionFlowStep.RECAP);

    const dispatch = useDispatch();
    const profile = useSelector(selectUserProfile);
    const teacherName = profile?.nickName;

    const { sanghaId } = useParams<{ sanghaId: string }>();

    const { data: currentDefaultReflectionQuestionData } = useQuery(
        GRAPHQL_QUERY_CURRENT_DEFAULT_REFLECTION_QUESTION,
        {
            fetchPolicy: "network-only",
        },
    );
    const defaultReflectionQuestion: string | null =
        currentDefaultReflectionQuestionData?.currentDefaultReflectionQuestion;
    useEffect(() => {
        if (defaultReflectionQuestion) {
            setData((prev) => ({
                ...prev,
                reflectionQuestion: defaultReflectionQuestion,
            }));
        }
    }, [defaultReflectionQuestion]);

    const sanghaResult = useQuery(GRAPHQL_QUERY_SANGHA, {
        variables: { sanghaId },
    });

    const sangha = sanghaResult.data?.sangha as Sangha;

    const [loaded, setLoaded] = useState(false);
    useEffect(() => {
        if (!loaded && sangha && teacherName) {
            analyticsTrack(`teacher.postSession.load`, {
                sanghaName: sangha.name,
                sanghaId,
                teacherName,
                step,
                sanghaCycle: sangha.cycle,
            });
            setLoaded(true);
        }
    }, [sangha, teacherName, step, sanghaId, loaded]);

    if (sanghaResult.error) {
        return <GenericErrorPage />;
    }

    if (sanghaResult.loading || !teacherName) {
        return (
            <PageWrapper>
                <LoadingBar />
            </PageWrapper>
        );
    }

    const onSubmitStep: OnSubmitStepFunction = (nextStep) => {
        analyticsTrack(`teacher.postSession.stepSubmitted`, {
            sanghaName: sangha.name,
            sanghaId,
            teacherName,
            step,
            nextStep,
            sanghaCycle: sangha.cycle,
            defaultReflection: defaultReflectionQuestion,
            usedDefaultReflection: data.reflectionQuestion === defaultReflectionQuestion,
        });
        setStep(nextStep);
        dispatch(push(stepMap[nextStep].route(sanghaId)));
    };

    const onChange: OnChangeFunction = (newData) => {
        setData((prev) => ({
            ...prev,
            ...newData,
        }));
    };

    const stepProps: StepProps = {
        sangha,
        data,
        teacherName,
        onSubmitStep,
        onChange,
    };

    return (
        <Route>
            <Switch>
                {[
                    TeacherPostSessionFlowStep.RECAP,
                    TeacherPostSessionFlowStep.REFLECTION_QUESTION,
                    TeacherPostSessionFlowStep.SUBMIT,
                ].map((stepForRoute) => {
                    if (step < stepForRoute) {
                        return null;
                    }

                    const Component = stepMap[stepForRoute].component;

                    return (
                        <Route
                            key={stepForRoute}
                            path={stepMap[stepForRoute].route(sanghaId)}
                            render={() => <Component {...stepProps} />}
                        />
                    );
                })}

                <Redirect to={routes.teacherPostSessionRecap(sanghaId)} />
            </Switch>
        </Route>
    );
};
