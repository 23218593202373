import { analyticsTrack } from "app/analytics/track";
import { routes } from "app/routes";
import { selectIsLoggedIn, selectCanAccessMemberZone } from "features/auth/auth";
import { LoadingPage } from "features/pages/LoadingPage";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Redirect } from "react-router";

export interface PageRedirectProps {
    pageName: string;
}

export const PageRedirect = (props: PageRedirectProps) => {
    const isLoggedIn = useSelector(selectIsLoggedIn);
    const canAccessMemberZone = useSelector(selectCanAccessMemberZone);
    const { pageName } = props;

    useEffect(() => {
        const lastRefreshedPageKey = `refreshedPage_${pageName}`;
        const lastRefreshedPage = sessionStorage.getItem(lastRefreshedPageKey);

        if (!lastRefreshedPage || Date.now() - parseInt(lastRefreshedPage) > 5 * 1000) {
            sessionStorage.setItem(lastRefreshedPageKey, Date.now().toString());
            analyticsTrack(`${pageName}.refresh`);
            window.location.reload();
        } else {
            analyticsTrack(`${pageName}.refresh.fail`, {
                lastRefreshedPage,
                currentTime: Date.now(),
            });
        }
    }, [pageName]);

    if (isLoggedIn && canAccessMemberZone) {
        return <Redirect to={routes.memberZone()} />;
    }

    return <LoadingPage />;
};
