export enum ModalID {
    membership_charge_explanation = "membership_charge_explanation",
    intro_video = "intro_video",
    immersion_video = "immersion_video",
    testimonial_danya = "testimonial_danya",
    testimonial_silvia = "testimonial_silvia",
    testimonial_karen = "testimonial_karen",
    community_sessions_sample = "community_sessions_sample",
}

export const getModalHash = (id: ModalID) => `modal_${id}`;
