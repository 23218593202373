import { Message, Sangha } from "@app/shared/types";
import { Typography } from "@mui/material";
import { analyticsTrack } from "app/analytics/track";
import { useBreakpointQuery } from "hooks/useBreakPointQuery";
import { useRef, useState } from "react";
import { ReflectionQuestionSection } from "../messaging/ReflectionQuestionSection";
import { SanghaSessionPanel } from "./SanghaSessionPanel";

export const MemberSanghaDetails = (props: {
    sangha: Sangha;
    reflectionQuestionMessage: Message | undefined;
}) => {
    const { sangha, reflectionQuestionMessage } = props;

    const sanghaDetailsEl = useRef<HTMLDivElement>(null);

    // Detect if the sangha details section overflows and log a metric if so
    const [overflowLogged, setOverflowLogged] = useState(false);
    setTimeout(() => {
        if (!overflowLogged && sanghaDetailsEl.current) {
            const scrollParent = sanghaDetailsEl.current.closest("[data-scrollable]");
            if (scrollParent) {
                // If the scroll height and client height are different, that means the content is overflowing
                if (scrollParent.scrollHeight !== scrollParent.clientHeight) {
                    analyticsTrack("member.sangha.details.overflow", {
                        overflowAmount: scrollParent.scrollHeight - scrollParent.clientHeight,
                        scrollHeight: scrollParent.scrollHeight,
                        clientHeight: scrollParent.clientHeight,
                        sanghaName: sangha.name,
                    });
                    // Scroll to bottom so buttons are visible
                    scrollParent.scrollTo({
                        top: scrollParent.scrollHeight,
                        behavior: "smooth",
                    });
                    setOverflowLogged(true);
                }
            } else {
                analyticsTrack("member.sangha.details.scrollParentMissing");
            }
        }
    }, 0);

    const isMobile = useBreakpointQuery("sm");

    return (
        <div data-testid="sanghaDetailsSection" ref={sanghaDetailsEl}>
            <Typography variant="h2">{sangha.name}</Typography>
            {reflectionQuestionMessage && (
                <ReflectionQuestionSection reflectionQuestionMessage={reflectionQuestionMessage} />
            )}
            {!isMobile && (
                <SanghaSessionPanel
                    sangha={sangha}
                    hasReflectionQuestion={Boolean(reflectionQuestionMessage)}
                />
            )}
        </div>
    );
};
