const formatPriceAmount = (price: number) =>
    Intl.NumberFormat("en-US", {
        minimumFractionDigits: 0,
        // The following should be enabled when/if trailingZeroDisplay is widely
        // available
        // (https://caniuse.com/mdn-javascript_builtins_intl_numberformat_numberformat_options_trailingzerodisplay_parameter)--
        // This will fix the issue of say 20% discounts looking like "$31.2"
        // minimumFractionDigits: 2,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        //trailingZeroDisplay: "stripIfInteger",
    }).format(price);

export const displayPrice = (price: number) => `${formatPriceAmount(price)} USD`;
