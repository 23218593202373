import { Typography } from "@mui/material";
import { CSDialog } from "components/CSDialog";
import { useSelector } from "react-redux";
import { selectIsLoggedIn } from "./auth";
import LoginForm from "./LoginForm";

export const LoginDialog = () => {
    const loggedIn = useSelector(selectIsLoggedIn);
    const loginRequested = false; //useSelector(selectLoginRequested)

    const open = !loggedIn && loginRequested;

    const handleClose = () => {
        // dispatch(requestLogin({ request: false }))
    };

    return (
        <CSDialog onClose={handleClose} open={open}>
            <Typography variant="h3">Log in to continue</Typography>
            <LoginForm />
        </CSDialog>
    );
};
