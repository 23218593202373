import { MessageThread, MessageType, Sangha } from "@app/shared/types";
import { analyticsTrack } from "app/analytics/track";
import { useEffect, useMemo } from "react";
import MessagePostPanel from "./MessagePostPanel";
import { MessageThreadContainer } from "./MessageThreadContainer";

export const MessageSection = (props: { sangha: Sangha; activeThread: MessageThread }) => {
    const { sangha, activeThread } = props;

    const reflectionQuestionMessage = useMemo(
        () =>
            activeThread.messages
                ? activeThread.messages.find((x) => x.type === MessageType.REFLECTION_QUESTION)
                : undefined,
        [activeThread.messages],
    );

    const hasReflection = Boolean(reflectionQuestionMessage);
    useEffect(() => {
        if (activeThread.id) {
            analyticsTrack(`member.sangha.messages.viewed`, {
                sanghaId: sangha.id,
                thread: activeThread.id,
                hasReflection,
            });
        }
    }, [activeThread.id, sangha.id, hasReflection]);

    const logMessagePosted = () => {
        analyticsTrack("member.sangha.message.new", {
            thread: activeThread.id,
            sanghaId: sangha.id,
            hasReflection: Boolean(reflectionQuestionMessage),
        });
    };

    return (
        <>
            <MessageThreadContainer sangha={sangha} activeThread={activeThread} />
            <MessagePostPanel sanghaId={sangha.id} logMessagePosted={logMessagePosted} />
        </>
    );
};
