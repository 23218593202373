import { useMutation, useQuery } from "@apollo/client";
import { MembershipStatus } from "@app/shared/constants";
import { Membership } from "@app/shared/types";
import { loadStripe } from "@stripe/stripe-js";
import { GRAPHQL_MUTATION_MEMBERSHIP_PAYMENT_METHOD, GRAPHQL_QUERY_MEMBERSHIPS } from "app/queries";
import { getFullUrl, routes } from "app/routes";
import { StripePublicKey } from "app/stripe";
import LoadingPage from "features/pages/LoadingPage";
import { useEffect, useState } from "react";

const StripeDevelopmentMode = process.env.REACT_APP_STRIPE_DEVELOPMENT_MODE === "true";

const getStripeInstance = () => {
    return loadStripe(StripePublicKey);
};

const UpdatePaymentMethod = () => {
    const [updatePaymentMethodMutation] = useMutation(GRAPHQL_MUTATION_MEMBERSHIP_PAYMENT_METHOD);

    const { data: membershipData } = useQuery(GRAPHQL_QUERY_MEMBERSHIPS);

    const [isLoading, setIsLoading] = useState(true);

    const memberships = (membershipData?.myMemberships || []) as Membership[];
    const activeMemberships = memberships.filter((m) => MembershipStatus.isActive(m.status));
    const membership = activeMemberships[0];

    useEffect(() => {
        const updatePaymentMethod = async () => {
            if (!membership) {
                return;
            }
            try {
                const result = await updatePaymentMethodMutation({
                    variables: {
                        membershipId: membership?.id,
                        successUrl: getFullUrl(routes.memberMembership("success")),
                        failedUrl: getFullUrl(routes.memberMembership("failed")),
                        developmentMode: StripeDevelopmentMode,
                    },
                });
                const sessionId = result.data.updateMembershipBillingPaymentMethod;
                const stripe = await getStripeInstance();
                stripe?.redirectToCheckout({ sessionId: sessionId });
                setIsLoading(false);
            } catch (error) {
                throw new Error("An error occurred while updating your payment method.");
            }
        };

        void updatePaymentMethod();
    }, [membership]);

    if (isLoading) {
        return <LoadingPage />;
    }

    return null;
};

export default UpdatePaymentMethod;
