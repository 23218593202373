export interface ScrollToElementOptions {
    offset?: number;
    delay?: number;
}

export const scrollToElement = (element: HTMLElement, options: ScrollToElementOptions = {}) => {
    const { offset = 30, delay = 0 } = options;
    setTimeout(() => {
        const modalElement = element.closest(".MuiDialogContent-root");

        let targetScrollPos: number;

        if (modalElement) {
            element.scrollIntoView({ behavior: "smooth", block: "center" });
            return;
        } else {
            const elementScrollPos = element.getBoundingClientRect().top + window.pageYOffset;

            const navBarElement = document.querySelectorAll(".MuiAppBar-root");
            const navBarHeight = navBarElement[0]?.clientHeight || 0;

            targetScrollPos = elementScrollPos - navBarHeight - offset;
        }

        const elementToScroll = modalElement || window;

        elementToScroll.scrollTo({ top: targetScrollPos, behavior: "smooth" });
    }, delay);
};
