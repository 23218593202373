import { Box, CircularProgress, Divider, Typography } from "@mui/material";
import { CheckboxItem, CheckboxListItem } from "./CheckboxListItem";
import { theme } from "app/theme";

export type CheckboxListInnerProps = {
    items: CheckboxItem[];
    selectedValues: string[];
    onChange: (value: string[]) => void;
    disabled?: boolean;
};

export const CheckboxListInner = (props: CheckboxListInnerProps) => {
    const { items, selectedValues, onChange, disabled } = props;

    const onToggle = (item: CheckboxItem, value: boolean) => {
        const otherSelectedValues = selectedValues.filter((value) => value !== item.value);

        if (value) {
            onChange([...otherSelectedValues, item.value]);
        } else {
            onChange(otherSelectedValues);
        }
    };

    const isSelected = (item: CheckboxItem) => selectedValues.indexOf(item.value) > -1;

    if (items.length < 1) {
        return (
            <div style={{ display: "flex", justifyContent: "center", marginBottom: 8 }}>
                <CircularProgress size={30} />
            </div>
        );
    }

    const isTopicFilter = items.every((item) => !!item.audience);

    if (isTopicFilter) {
        const topicItems = items.filter(
            (item) => item.audience?.toLowerCase() === "all meditators",
        );
        const affinityItems = items.filter(
            (item) => item.audience && item.audience.toLowerCase() !== "all meditators",
        );
        return (
            <>
                {topicItems.map((item) => (
                    <CheckboxListItem
                        key={item.value}
                        item={item}
                        checked={isSelected(item)}
                        onToggle={(checked) => onToggle(item, checked)}
                        disabled={disabled}
                    />
                ))}
                {affinityItems.length > 0 && (
                    <>
                        <Box sx={{ pl: 2, pr: 2 }}>
                            <Divider sx={{ m: 0, mb: 1 }} />
                            <Typography variant="caption" sx={{ color: theme.palette.grey700 }}>
                                <strong>Affinity Groups</strong>
                            </Typography>
                        </Box>
                        {affinityItems.map((item) => (
                            <CheckboxListItem
                                key={item.value}
                                item={item}
                                checked={isSelected(item)}
                                onToggle={(checked) => onToggle(item, checked)}
                                disabled={disabled}
                            />
                        ))}
                    </>
                )}
            </>
        );
    }

    return (
        <>
            {items.map((item) => (
                <CheckboxListItem
                    key={item.value}
                    item={item}
                    checked={isSelected(item)}
                    onToggle={(checked) => onToggle(item, checked)}
                    disabled={disabled}
                />
            ))}
        </>
    );
};
