import { BRAND_NAME } from "@app/shared/constants";
import { Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { routes } from "app/routes";
import DocumentContext from "components/DocumentContext";
import PageWrapper from "components/PageWrapper";
import { useHistory } from "react-router";
import { NavLink } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    container: {
        height: "100%",
    },

    closeButton: {
        position: "absolute",
        right: 0,
        top: 0,
        color: theme.palette.neutralWarm,
        zIndex: theme.zIndex.appBar,
    },

    root: {
        padding: theme.spacing(4),
    },
}));

export const DiversityAtCloudSangha = () => {
    const classes = useStyles();

    const history = useHistory();

    return (
        <>
            <DocumentContext
                addBrandNameSuffix
                title="Diversity"
                description={`At ${BRAND_NAME}, we support meditators around the world. We embrace people of all backgrounds, racial
                        identities, gender identities, disabilities, and sexual orientations.`}
            />
            <div className={classes.container}>
                <PageWrapper className={classes.root}>
                    <Typography variant="h2">Diversity</Typography>
                    <Typography variant="body1">
                        At {BRAND_NAME}, we support meditators around the world. We envision an
                        awakened humanity. This has to include all individual expressions of our
                        shared humanity, and we embrace people of all backgrounds, racial
                        identities, gender identities, disabilities, and sexual orientations. These
                        are some of the ways in which our individual expressions may be different,
                        and they are also the ways in which we become separated and in some cases
                        are used as a basis for oppression.
                    </Typography>
                    <Typography variant="body1">
                        For all of us to rediscover our shared humanity, it’s crucial that we
                        recognize, explore, and celebrate our differences. And we believe we can
                        work together to heal the wounds that have divided us. When we acknowledge
                        and turn towards our pain, we can transform that pain into clarity,
                        compassion, and belonging. So diversity is not a checkbox for us. It is core
                        to our aspiration for collective awakening. If you have questions about
                        whether {BRAND_NAME} will be accessible to you,{" "}
                        <NavLink to={routes.contactUs()}>please reach out to us</NavLink>. We look
                        forward to connecting with you.
                    </Typography>
                    <Typography variant="h3">Teacher Community </Typography>
                    <Typography variant="body1">
                        Teachers in our community represent a wide range of identities and
                        backgrounds. Today, 38% of our teachers identify as other than white, 17%
                        identify as LGBTQIA+, 21% reside outside of North America, and 23% speak a
                        language other than English.
                    </Typography>
                    <Typography variant="body1">
                        As we grow and invite new teachers to join, we are mindful to ensure diverse
                        representation. In our teacher application, we ask about gender identity,
                        racial identity, sexual orientation, language(s), location. In our mentor
                        training program for new teachers, we also have modules that specifically
                        train mentors on diversity and how to work with different identities in
                        groups.
                    </Typography>
                    <Typography variant="h3">Safer Spaces and Affinity Groups </Typography>
                    <Typography variant="body1">
                        We also know and recognize that many people need dedicated spaces to
                        practice in the safety of others with similar life experiences. This safety
                        can be necessary to turn attention towards suffering — for example as a
                        result of oppression — so that healing may occur. That’s why we offer
                        affinity groups for Women, People of Color (an identity of non-white and can
                        include those with mixed racial heritage) and LGBTQIA+.
                    </Typography>

                    <Typography variant="h3">Team</Typography>
                    <Typography variant="body1">
                        Because our ability to create experiences that serve a diverse audience is a
                        direct result of our own awareness and understanding, we also promote
                        diversity within our team. We understand that having a wider range of
                        perspectives within our team will lead to better decisions, and allow us to
                        better serve our global community. Our team is global and represents many
                        different backgrounds, countries of birth and upbringing, gender identities,
                        and sexual orientations.
                    </Typography>
                </PageWrapper>
            </div>
        </>
    );
};
