import { Typography } from "@mui/material";
import { SetupProgressBar } from "features/setup/SetupProgressBar";
import EditProfileForm from "./EditProfileForm";
import { useDispatch } from "react-redux";
import { push } from "connected-react-router";
import { routes } from "app/routes";
import { BRAND_NAME } from "@app/shared/constants";

export const SetupEditProfilePage = () => {
    const dispatch = useDispatch();
    const header = (
        <Typography variant="h5">
            Welcome to {BRAND_NAME}. 🎉 <br />
            Let's get you set up.
        </Typography>
    );
    const progressBar = <SetupProgressBar currentStep={1} />;
    const onSaveComplete = () => {
        dispatch(push(routes.setupWelcomeSession()));
    };

    return (
        <EditProfileForm
            header={header}
            progressBar={progressBar}
            onSaveComplete={onSaveComplete}
        />
    );
};
