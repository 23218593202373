import { Button, ButtonProps } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import classNames from "classnames";
import { useBreakpointQuery } from "hooks/useBreakPointQuery";
import { LinkButton } from "features/navigation/LinkButton";
import * as React from "react";

const useStyles = makeStyles((theme) => ({
    root: {
        position: "fixed",
        bottom: theme.spacing(8),
        right: theme.spacing(4),
        boxShadow: theme.shadows[2],
        zIndex: theme.zIndex.speedDial,
        [theme.breakpoints.down("sm")]: {
            position: "fixed",
            margin: "auto",
            padding: theme.spacing(4),
            left: theme.spacing(4),
        },
    },
}));

/**
 * It's a floating button that can be used as a link or a button
 * @param props - {
 * @returns A React component that renders a  floating button.
 * Note: In some cases it's preferable that the button doesn't
 * float, esp mobile-->See "GetStartedSupportneeds.tsx" for an
 * example of how to do this.
 */
export const FloatingButton = (
    props: {
        to?: string;
        onClick?: VoidFunction;
        children: React.ReactNode;
    } & ButtonProps,
) => {
    const classes = useStyles();
    const isMobile = useBreakpointQuery("xs");

    const buttonProps: Partial<ButtonProps> = {
        variant: "secondary",
        size: isMobile ? "small" : "medium",
        ...props,
        className: classNames(classes.root, props.className),
    };

    if (props.to) {
        return <LinkButton {...buttonProps} to={props.to} />;
    } else {
        return <Button {...buttonProps} onClick={props.onClick} />;
    }
};
