import { routes } from "app/routes";
import { SELECTED_INTEREST_GROUP_FIELD } from "features/member/interestGroups/constants";
import { ProgressBar } from "features/signup/ProgressBar";
import { getCurrentCohort } from "./signupHelpers";

export enum SignupStep {
    ACCOUNT = "ACCOUNT",
    CHECKOUT = "CHECKOUT",
}

export const SignupProgressBar = (props: {
    currentStep: number;
    onStepClick?: (step: number) => void;
}) => {
    const signupStepData = {
        [SignupStep.ACCOUNT]: { label: "Create your account", route: routes.signupBilling() },
        [SignupStep.CHECKOUT]: { label: "Checkout", route: routes.signupBilling() },
    };

    const signupStepOrder = [SignupStep.ACCOUNT, SignupStep.CHECKOUT];

    return (
        <ProgressBar
            {...props}
            stepData={signupStepData}
            stepOrder={signupStepOrder}
            nonClickableSteps={[SignupStep.ACCOUNT]}
        />
    );
};
