import { Radio, alpha } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import classNames from "classnames";

const useStyles = makeStyles((theme) => ({
    cardToggle: {
        display: "flex",
        alignItems: "center",
        position: "relative",
        flexDirection: "row",
        gap: theme.spacing(2),
        padding: theme.spacing(2),
        border: theme.border.default,
        borderRadius: theme.borderRadius.default,
        borderColor: theme.palette.grey400,
        cursor: "pointer",
        backgroundColor: theme.palette.grey100,
        [theme.breakpoints.down("sm")]: {
            flexDirection: "column",
            alignItems: "flex-start",
            gap: theme.spacing(0),
        },
    },
    activeCard: {
        backgroundColor: theme.palette.neutralWarm,
        borderColor: theme.palette.accentGold,
    },
    contentContainer: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
    },
    disabled: {
        opacity: 0.5,
        userSelect: "none",
        cursor: "not-allowed",
    },
}));

export interface CardToggleProps {
    isSelected: boolean;
    onClick?: (value?: any) => void;
    children: React.ReactNode;
    radioValue?: any;
    disabled?: boolean;
    testId?: string;
}

export const CardToggle = (props: CardToggleProps) => {
    const classes = useStyles();
    const { isSelected, onClick, children, radioValue, disabled, testId } = props;

    return (
        <div
            className={classNames(classes.cardToggle, {
                [classes.activeCard]: isSelected,
                [classes.disabled]: disabled,
            })}
            onClick={onClick}
            data-testid={testId}
        >
            <div className={classes.contentContainer}>
                <Radio
                    color="primary"
                    checked={isSelected}
                    onChange={onClick}
                    value={radioValue}
                    disabled={disabled}
                />
                {children}
            </div>
        </div>
    );
};
