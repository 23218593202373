import { Recommendation } from "@app/shared/types";
import { AdvancedImage, responsive } from "@cloudinary/react";
import { Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { analyticsTrack } from "app/analytics/track";
import { cloudinary } from "app/cloudinary";
import { LinkButton } from "features/navigation/LinkButton";

const useStyles = makeStyles((theme) => ({
    card: {
        display: "flex",
        justifyContent: "flex-start",
        minWidth: 350,
        alignItems: "center",
        gap: theme.spacing(2),
        padding: theme.spacing(2),
        borderRadius: theme.borderRadius.default,
        border: `2px solid ${theme.palette.grey400}`,
    },
    cardImage: {
        alignSelf: "flex-start",
        width: 90,
        height: 90,
        minHeight: 90,
        minWidth: 90,
        objectFit: "cover",
        backgroundColor: theme.palette.grey400,
        borderTopLeftRadius: theme.borderRadius.small,
        borderBottomLeftRadius: theme.borderRadius.small,
        borderTopRightRadius: theme.borderRadius.small,
        borderBottomRightRadius: theme.borderRadius.small,
    },
    cardContent: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        height: "100%",
    },
    addonBadge: {
        color: theme.palette.primaryLeaves,
        marginBottom: theme.spacing(1),
    },
}));

export const RecommendationCard = (props: { recommendation: Recommendation }) => {
    const { recommendation } = props;
    const classes = useStyles(props);
    const isAddon = recommendation.customPathGoal?.toLowerCase().includes("mentorship");

    const cloudinaryImage = cloudinary
        .image(recommendation.imageId || "production/custom_path_recommendations/default_bowl")
        .format("auto");

    return (
        <div className={classes.card} key={recommendation.title}>
            <AdvancedImage
                cldImg={cloudinaryImage}
                className={classes.cardImage}
                plugins={[responsive({ steps: 200 })]}
                alt={recommendation.title}
            />
            <div className={classes.cardContent}>
                <div>
                    {isAddon && (
                        <Typography
                            variant="body3"
                            textTransform="uppercase"
                            className={classes.addonBadge}
                        >
                            <strong>Add-on</strong>
                        </Typography>
                    )}
                    <Typography variant="h6">{recommendation.title}</Typography>
                    <Typography variant="caption" paragraph sx={{ lineHeight: "normal" }}>
                        {recommendation.description}
                    </Typography>
                </div>

                <div>
                    <LinkButton
                        to={recommendation.buttonLink}
                        variant="secondary"
                        size="small"
                        fullWidth={false}
                        onClick={() => {
                            analyticsTrack("Recommendation Button Clicked", {
                                recommendationCustomPathGoal: recommendation.customPathGoal,
                                recommendationData: recommendation,
                            });
                        }}
                    >
                        {recommendation.buttonText}
                    </LinkButton>
                </div>
            </div>
        </div>
    );
};

export default RecommendationCard;
