import { Typography, alpha } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { AccentBar } from "components/AccentBar";
import PageWrapper from "components/PageWrapper";
import { LinkButton } from "features/navigation/LinkButton";
import groupSessionIcon from "../../images/icons/Group-Sessions-Graphic.svg";
import teacherIcon from "../../images/icons/Teacher-Graphic.svg";
import transformationIcon from "../../images/icons/Transformation-Graphic.svg";

const useStyles = makeStyles((theme) => ({
    heroSection: {
        padding: theme.spacing(8),
        color: theme.palette.neutralWhite,
        textAlign: "center",
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url("https://assets.cloudsangha.co/images/buddha_in_trees-min.jpeg")`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        [theme.breakpoints.down("lg")]: {
            padding: theme.spacing(4),
        },
    },
    benefitsSection: {
        padding: theme.spacing(8),
        textAlign: "center",
        [theme.breakpoints.down("lg")]: {
            padding: theme.spacing(4),
        },
    },
    benefitsImage: {
        width: 550,
        height: 550,
        objectFit: "cover",
        borderTopRightRadius: theme.borderRadius.extraLarge,
        borderBottomLeftRadius: theme.borderRadius.extraLarge,
        [theme.breakpoints.down("lg")]: {
            width: "80vw",
            height: "auto",
            maxWidth: 550,
            maxHeight: 550,
            borderTopRightRadius: theme.borderRadius.large,
            borderBottomLeftRadius: theme.borderRadius.large,
            marginBottom: theme.spacing(2),
        },
    },
    benefitsContent: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        gap: theme.spacing(8),
        [theme.breakpoints.down("lg")]: {
            marginTop: theme.spacing(4),
            flexDirection: "column",
            gap: theme.spacing(2),
            "& > :nth-child(1)": {
                order: 2,
            },
            "& > :nth-child(2)": {
                order: 1,
            },
            "& > :nth-child(3)": {
                order: 3,
            },
        },
    },
    benefitsColumn: {
        display: "flex",
        flexDirection: "column",
        gap: theme.spacing(4),
        [theme.breakpoints.down("lg")]: {
            gap: theme.spacing(2),
        },
    },
    benefit: {},
    convertSection: {
        padding: theme.spacing(8),
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url("https://assets.cloudsangha.co/images/hand_in_field-min.jpeg")`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        textAlign: "center",
        [theme.breakpoints.down("lg")]: {
            padding: theme.spacing(4),
        },
    },
    convertCard: {
        padding: theme.spacing(8),
        backgroundColor: alpha(theme.palette.primaryLeaves, 0.7),
        textAlign: "center",
        color: theme.palette.neutralWhite,
        [theme.breakpoints.down("lg")]: {
            padding: theme.spacing(4),
        },
    },
}));

export const MembershipUpsellSection = () => {
    const classes = useStyles();

    return (
        <>
            <AccentBar />
            <PageWrapper data-testid="membershipUpsell">
                <Typography variant="h2">Get to know us</Typography>
            </PageWrapper>
            <div className={classes.heroSection}>
                <Typography variant="h2" sx={{ mb: 0 }}>
                    Grow together, rooted in mindfulness
                </Typography>
                <Typography variant="body2" sx={{ mb: 4 }}>
                    Home to people who want to grow in well-being, alongside teachers and
                    like-minded others.
                </Typography>
                <LinkButton to="https://banyantogether.com" variant="primary" openInNewTab>
                    Know More
                </LinkButton>
            </div>
            <div className={classes.benefitsSection}>
                <Typography variant="h2" sx={{ mb: 4 }}>
                    Find inner freedom amidst life's challenges
                </Typography>
                <div className={classes.benefitsContent}>
                    <div className={classes.benefitsColumn}>
                        <div className={classes.benefit}>
                            <img src={groupSessionIcon} alt="Group sessions icon" />
                            <Typography variant="h4">
                                Group sessions with people who care
                            </Typography>
                            <Typography variant="body1">
                                Real and honest conversations help you see your gifts, blocks, and
                                blindspots.
                            </Typography>
                        </div>
                        <div className={classes.benefit}>
                            <img src={teacherIcon} alt="Teachers icon" />
                            <Typography variant="h4">
                                Facilitated by mindfulness teachers
                            </Typography>
                            <Typography variant="body1">
                                All sessions are live and facilitated by highly experienced
                                mindfulness meditation teachers.
                            </Typography>
                        </div>
                    </div>
                    <img
                        src={"https://assets.cloudsangha.co/images/yoga_in_nature-min.jpeg"}
                        alt="Yoga in nature"
                        className={classes.benefitsImage}
                    />
                    <div className={classes.benefit}>
                        <img src={transformationIcon} alt="Transformation icon" />
                        <Typography variant="h4">Proven Practices For Transformation</Typography>
                        <Typography variant="body1">
                            Find inner freedom by practicing healthier responses to old situations
                            and new opportunities.
                        </Typography>
                    </div>
                </div>
            </div>
            <div className={classes.convertSection}>
                <div className={classes.convertCard}>
                    <Typography variant="h2" sx={{ mb: 0 }}>
                        Become a member
                    </Typography>
                    <Typography variant="body2" sx={{ mb: 4 }}>
                        Home to people who want to grow in well-being, alongside teachers and
                        like-minded others.{" "}
                    </Typography>
                    <LinkButton to="https://banyantogether.com" variant="primary" openInNewTab>
                        Know More
                    </LinkButton>
                </div>
            </div>
        </>
    );
};
