import { useQuery } from "@apollo/client";
import { SessionZoomInfo } from "@app/shared/types";
import { Container, Link, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { GRAPHQL_QUERY_TEST_SESSION } from "app/queries";
import LoadingPage from "features/pages/LoadingPage";

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(4),
    },
}));

export const ZoomAppTestPage = () => {
    const classes = useStyles();

    const { data, loading } = useQuery(GRAPHQL_QUERY_TEST_SESSION);

    if (loading || !data?.testSession) {
        return <LoadingPage />;
    }

    const zoomInfo = data.testSession.zoomInfo as SessionZoomInfo;

    return (
        <Container className={classes.root} maxWidth="lg">
            <Typography variant="h2">Zoom App Test</Typography>
            <p>
                See{" "}
                <Link
                    href="https://www.notion.so/banyantogether/Zoom-App-Test-Environment-c59d4549f6f84f98bbb856063a5ccfa0?pvs=4"
                    target="_blank"
                >
                    this page for instructions
                </Link>{" "}
                on how to use this test page to try out the Zoom App.
            </p>

            <Typography variant="h4">Start Link</Typography>
            <Typography variant="body1">
                <Link target="_blank" href={zoomInfo.startLink}>
                    {zoomInfo.startLink}
                </Link>
            </Typography>

            <Typography variant="h4">Join Link</Typography>
            <Typography variant="body1">
                <Link target="_blank" href={zoomInfo.joinLink}>
                    {zoomInfo.joinLink}
                </Link>
            </Typography>
        </Container>
    );
};
