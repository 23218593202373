import { Container } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
    pageSection: {
        position: "relative",
        //borderTop: "solid 1px red",
    },

    pageSectionOverlay: {
        position: "absolute",
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        backgroundColor: theme.palette.background.default,
        opacity: 0,
    },

    pageSectionOverlayOpaque: {
        opacity: 1.0,
    },

    pageSectionContainer: {
        position: "relative",
        padding: theme.spacing(8, 2),
        zIndex: 1,
    },
}));

export const PageSection = (props: {
    opaque?: boolean;
    height?: number | string;
    children?: any;
    containerClasses?: any;
    containerStyle?: any;
}) => {
    const classes = useStyles();
    const sectionHeight = props.height;

    const overlayClasses = clsx(classes.pageSectionOverlay, {
        [classes.pageSectionOverlayOpaque]: props.opaque,
    });

    const containerClasses = clsx(classes.pageSectionContainer, props.containerClasses || {});

    return (
        <div className={classes.pageSection} style={{ minHeight: sectionHeight }}>
            <div className={overlayClasses} />
            <Container
                maxWidth="lg"
                component="main"
                className={containerClasses}
                style={props.containerStyle}
                children={props.children || []}
            />
        </div>
    );
};
