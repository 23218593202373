import { useQuery } from "@apollo/client";
import { ALL_ONBOARDING_ITEMS, STARTING_SOON_MINUTES } from "@app/shared/constants";
import { Box, Button, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { analyticsTrack } from "app/analytics/track";
import {
    GRAPHQL_QUERY_LATEST_INTENTION,
    GRAPHQL_QUERY_MY_RECOMMENDATIONS,
    GRAPHQL_QUERY_MY_SCHEDULE,
    GRAPHQL_QUERY_UPCOMING_EVENTS,
} from "app/queries";
import { routes } from "app/routes";
import { theme } from "app/theme";
import classNames from "classnames";
import { AnnouncementsCarousel } from "components/AnnouncementsCarousel";
import CustomPathForYou from "components/CustomPathForYou";
import DocumentContext from "components/DocumentContext";
import { GenericErrorPage } from "components/GenericErrorPage";
import PageWrapper from "components/PageWrapper";
import { LoggedInUserProfileContext } from "features/auth/RequireAuthentication";
import { LinkButton } from "features/navigation/LinkButton";
import LoadingPage from "features/pages/LoadingPage";
import { useCurrentServerTime } from "hooks/useCurrentServerTime";
import _ from "lodash";
import { useContext, useEffect, useState } from "react";
import { EventListLoader } from "../eventDisplay/EventListLoader";
import { OnboardingPopupButton } from "../onboarding/OnboardingPopupButton";
import {
    filterSessionsRightNow,
    sessionTypesForSessionsRightNow,
} from "./MemberSessionsRightNowPage";

const useStyles = makeStyles((theme) => ({
    pageWrapper: {
        marginTop: theme.spacing(2),
        paddingTop: 0,
        paddingBottom: 0,
    },
    colorGrey800: {
        color: theme.palette.grey800,
    },
    myScheduleHeader: {
        color: theme.palette.grey800,
        marginTop: theme.spacing(4),
    },
    headerWithIconContainer: {
        display: "flex",
        alignItems: "center",
        gap: theme.spacing(1),
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(1),
    },
    headerIcon: {
        color: theme.palette.warning500,
        fontSize: theme.typography.pxToRem(16),
    },
    emptyStateCard: {
        backgroundColor: theme.palette.grey100,
        borderRadius: theme.borderRadius.default,
        textAlign: "center",
        padding: theme.spacing(4, 7),
        [theme.breakpoints.up("sm")]: {
            width: "350px",
        },
        [theme.breakpoints.up("md")]: {
            width: "calc(100% / 2)",
        },
        [theme.breakpoints.up("lg")]: {
            width: "calc(100% / 3)",
        },
    },
    buttonContainer: {
        textAlign: "center",
    },
    noMargin: {
        margin: 0,
    },
    buttonIcon: {
        fontSize: theme.typography.pxToRem(26),
        marginRight: theme.spacing(1),
    },
    toggleButton: {
        [theme.breakpoints.down("sm")]: {
            width: "100%",
        },
    },
    hidden: {
        display: "none",
    },
    visible: {
        display: "block",
    },
}));

enum SessionSections {
    MY_SCHEDULE = "MY_SCHEDULE",
    SESSIONS_RIGHT_NOW = "SESSIONS_RIGHT_NOW",
}

export const MemberHomePage = () => {
    const classes = useStyles();
    const [sessionsSectionDisplayed, setSessionsSectionDisplayed] = useState<SessionSections>(
        SessionSections.MY_SCHEDULE,
    );

    const handleSectionSelection = (
        event: React.MouseEvent<HTMLElement>,
        newSection: SessionSections,
    ) => {
        if (newSection !== null) {
            setSessionsSectionDisplayed(newSection);
        }
    };

    const user = useContext(LoggedInUserProfileContext);

    const currentServerTime = useCurrentServerTime();

    const {
        data: intentionData,
        loading: intentionLoading,
        error: intentionError,
    } = useQuery(GRAPHQL_QUERY_LATEST_INTENTION, {
        fetchPolicy: "network-only",
    });

    const {
        data: recommendationsData,
        loading: recommendationsLoading,
        error: recommendationsError,
    } = useQuery(GRAPHQL_QUERY_MY_RECOMMENDATIONS, {
        fetchPolicy: "network-only",
        skip: !intentionData,
        variables: {
            customPathGoals: intentionData?.latestIntention?.recommendations,
        },
    });

    const [showAllEvents, setShowAllEvents] = useState(false);

    const handleSeeAllEventsClick = () => {
        setShowAllEvents(true);
    };

    const seeMoreButton = () => (
        <Box className={classes.buttonContainer}>
            <Button variant="secondary" onClick={handleSeeAllEventsClick}>
                See all
            </Button>
        </Box>
    );

    const [showOnboardingPopup, setShowOnboardingPopup] = useState(false);

    const completedItemsFromDatabase = [...(user.profile.completedOnboardingItems || [])];

    const hasCompletedOnboarding = _.isEqual(
        completedItemsFromDatabase.sort(),
        ALL_ONBOARDING_ITEMS.sort(),
    );

    useEffect(() => {
        if (!hasCompletedOnboarding) {
            setShowOnboardingPopup(true);
        } else {
            analyticsTrack("onboarding.completed", {
                userId: user.id,
                onboardingItems: user.profile.completedOnboardingItems,
            });
        }
    }, [hasCompletedOnboarding]);

    if (intentionLoading || recommendationsLoading) {
        return <LoadingPage />;
    }

    if (intentionError || recommendationsError) {
        return <GenericErrorPage />;
    }

    return (
        <>
            <CustomPathForYou
                intention={intentionData.latestIntention}
                recommendations={recommendationsData.myRecommendations}
            />
            <PageWrapper data-testid="memberHomePage" className={classes.pageWrapper}>
                <DocumentContext addBrandNameSuffix title="Home" />
                {showOnboardingPopup && <OnboardingPopupButton />}
                <ToggleButtonGroup
                    value={sessionsSectionDisplayed}
                    exclusive
                    onChange={handleSectionSelection}
                    color="primary"
                    sx={{
                        display: "flex",
                        flexWrap: "wrap",
                        gap: 1,
                        "& .MuiToggleButtonGroup-grouped": {
                            borderRadius: `${theme.borderRadius.default}px !important`,
                        },
                        mb: 2,
                    }}
                >
                    <ToggleButton
                        value={SessionSections.MY_SCHEDULE}
                        aria-label="my schedule"
                        className={classes.toggleButton}
                    >
                        <span
                            className={classNames("material-symbols-rounded", classes.buttonIcon)}
                        >
                            calendar_today
                        </span>
                        My Schedule
                    </ToggleButton>
                    <ToggleButton
                        value={SessionSections.SESSIONS_RIGHT_NOW}
                        aria-label="sessions right now"
                        className={classes.toggleButton}
                        data-testid="sessionsRightNowButton"
                    >
                        <span
                            className={classNames("material-symbols-rounded", classes.buttonIcon)}
                        >
                            radio_button_checked
                        </span>
                        Starting soon
                    </ToggleButton>
                </ToggleButtonGroup>
                <EventListLoader
                    className={classNames({
                        [classes.visible]: sessionsSectionDisplayed === SessionSections.MY_SCHEDULE,
                        [classes.hidden]: sessionsSectionDisplayed !== SessionSections.MY_SCHEDULE,
                    })}
                    elementAfter={seeMoreButton()}
                    eventsQuery={GRAPHQL_QUERY_MY_SCHEDULE}
                    data-testid="mySchedule"
                    currentServerTime={currentServerTime}
                    limitDisplayedEvents={3}
                    showAllEvents={showAllEvents}
                    showSpecificDate={true}
                    enableScrollFromQueryParams={true}
                    emptyStateElement={
                        <Box className={classes.emptyStateCard}>
                            <Typography variant="h6" className={classes.colorGrey800}>
                                Your schedule is empty
                            </Typography>
                            <LinkButton
                                to={routes.memberSessions()}
                                sx={{ marginTop: 1.5 }}
                                variant="primary"
                                size="small"
                                fullWidth={false}
                            >
                                Explore events
                            </LinkButton>
                        </Box>
                    }
                />
                <EventListLoader
                    className={classNames({
                        [classes.visible]:
                            sessionsSectionDisplayed === SessionSections.SESSIONS_RIGHT_NOW,
                        [classes.hidden]:
                            sessionsSectionDisplayed !== SessionSections.SESSIONS_RIGHT_NOW,
                    })}
                    sx={{ mb: 4 }}
                    data-testid="sessionsRightNow"
                    currentServerTime={currentServerTime}
                    eventsQuery={GRAPHQL_QUERY_UPCOMING_EVENTS}
                    queryOptions={{
                        variables: {
                            includeTypes: sessionTypesForSessionsRightNow,
                            startsWithinMinutes: STARTING_SOON_MINUTES,
                            limit: 6,
                        },
                    }}
                    filterEvents={filterSessionsRightNow}
                    emptyStateElement={
                        <Box className={classes.emptyStateCard}>
                            <Typography variant="h6" className={classes.colorGrey800}>
                                No events starting soon
                            </Typography>
                        </Box>
                    }
                    limitDisplayedEvents={3}
                />
            </PageWrapper>
            <AnnouncementsCarousel
                intention={intentionData?.latestIntention}
                data-testid="announcementsCarousel"
            />
        </>
    );
};
