import { Session } from "@app/shared/types";
import PageWrapper from "components/PageWrapper";
import { EventListByDay } from "../eventDisplay/EventListByDay";
import { SESSIONS_PAGE_HEIGHT, SESSIONS_PAGE_HEIGHT_MOBILE } from "./SessionsPage";
import { useUpdateCurrentTime } from "hooks/useUpdateCurrentTime";
import { Typography, useMediaQuery } from "@mui/material";
import { theme } from "app/theme";

export const SessionsList = ({
    sessions,
    serverTimeDelta,
    rsvpedSessions,
    onSessionAttend,
}: {
    sessions: Session[];
    serverTimeDelta: number;
    rsvpedSessions: { id: string }[];
    onSessionAttend: () => void;
}) => {
    const currentServerTime = useUpdateCurrentTime(serverTimeDelta);
    const isMobile = useMediaQuery(theme.breakpoints.down("md"), { noSsr: true });

    return (
        <div
            style={{
                overflow: "auto",
                height: isMobile ? SESSIONS_PAGE_HEIGHT_MOBILE : SESSIONS_PAGE_HEIGHT,
            }}
        >
            <PageWrapper removeHeader data-testId="sessionsListPage">
                {sessions.length === 0 ? (
                    <div style={{ textAlign: "center", marginTop: 20 }}>
                        <Typography variant="h4">
                            No sessions found. Please try adjusting your filters.
                        </Typography>
                    </div>
                ) : (
                    <EventListByDay
                        events={sessions}
                        currentServerTime={currentServerTime}
                        showSpecificDate={true}
                        rsvpedSessions={rsvpedSessions}
                        onSessionAttend={onSessionAttend}
                    />
                )}
            </PageWrapper>
        </div>
    );
};
