import { UserProfile } from "@app/shared/types";
import { Avatar, Button, CircularProgress, Link, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { useState } from "react";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectUserProfile } from "../../auth/auth";
import { saveProfilePicture } from "./memberProfileSlice";
import Webcam from "react-webcam";
import { NavLink } from "features/navigation/NavLink";

const useStyles = makeStyles((theme) => ({
    avatar: {
        width: 300,
        height: 200,
        borderRadius: theme.borderRadius.default,
        marginRight: theme.spacing(10),

        [theme.breakpoints.down("sm")]: {
            width: 150,
            height: 100,
        },
    },

    avatarLoading: {
        width: "100%",
        height: 120,
        paddingTop: theme.spacing(4),
        marginBottom: theme.spacing(4),
    },
    profilePicImage: {
        paddingRight: theme.spacing(4),
    },

    temporaryPicture: {
        backgroundColor: theme.palette.primaryLeaves,
        height: 200,
        width: 300,
        borderRadius: theme.borderRadius.default,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        color: "white",
        textAlign: "center",
        [theme.breakpoints.down("sm")]: {
            width: 150,
            height: 100,
        },
    },
    webCamButton: {
        marginTop: theme.spacing(4),
        marginRight: theme.spacing(2),
    },
}));

export const ProfilePicture = () => {
    const dispatch = useDispatch();
    const classes = useStyles();

    const userProfile = useSelector(selectUserProfile) as UserProfile;

    const [savingPicture, setSavingPicture] = useState(false);

    const [webCamEnabled, setWebCamEnabled] = useState(false);
    const [tookWebcamPhoto, setTookWebcamPhoto] = useState(false);

    const [webCamImage, setWebCamImage] = useState("");
    const [existingPicture, setExistingPicture] = useState(userProfile.picture);

    const videoConstraints = {
        width: 1920,
        height: 1080,
        facingMode: "user",
    };

    const WebcamCapture = () => (
        <Webcam
            audio={false}
            height={200}
            minScreenshotHeight={200}
            minScreenshotWidth={300}
            screenshotFormat="image/jpeg"
            width={300}
            videoConstraints={videoConstraints}
            mirrored={true}
            forceScreenshotSourceSize
        >
            {({ getScreenshot }) => (
                <Button
                    variant="secondary"
                    data-testid="startButton"
                    onClick={() => {
                        const imageSrc = getScreenshot();
                        if (imageSrc) {
                            setWebCamImage(imageSrc);
                            setWebCamEnabled(false);
                            setTookWebcamPhoto(true);
                            uploadPicture(undefined, imageSrc);
                        }
                    }}
                    style={{
                        display: "block",
                    }}
                    className={classes.webCamButton}
                >
                    Snap photo
                </Button>
            )}
        </Webcam>
    );

    const onPictureUpload = async (ev: React.ChangeEvent<any>) => {
        setWebCamEnabled(false);
        if (ev.target.validity.valid) {
            uploadPicture(ev.target.files[0]);
        }
        // if we just uploaded a photo, we want to keep track to know it's not a webcam photo
        setTookWebcamPhoto(false);
    };

    const uploadPicture = async (file?: any, data?: string) => {
        setSavingPicture(true);
        let result = await dispatch(
            saveProfilePicture({
                userProfile: userProfile,
                file: file,
                data: data,
            }),
        );
        const { payload } = result as any;
        setExistingPicture(payload);
        setSavingPicture(false);
    };

    const TemporaryPicture = () => (
        <div
            className={classes.temporaryPicture}
            onClick={() => {
                setWebCamEnabled(true);
            }}
        >
            <p style={{ margin: 10 }}>Click here to allow us temporary access to your camera</p>
        </div>
    );

    const renderWebcamButton = (title: string) => {
        return (
            <Button
                variant="secondary"
                onClick={() => {
                    setWebCamEnabled(true);
                }}
                className={classes.webCamButton}
            >
                {title}
            </Button>
        );
    };

    const renderUploadButton = () => {
        return (
            <>
                <input
                    type="file"
                    id="avatarUpload"
                    accept="image/png, image/jpeg, image/jpg"
                    data-testid="profileFileUpload"
                    hidden
                    onChange={onPictureUpload}
                />
                <label htmlFor="avatarUpload">
                    <Button variant="secondary" className={classes.webCamButton} component="span">
                        Upload photo
                    </Button>
                </label>
            </>
        );
    };

    const renderProfilePic = () => {
        if (!webCamEnabled) {
            if (tookWebcamPhoto) {
                return (
                    <>
                        <Avatar className={classes.avatar} src={webCamImage} />
                        {renderWebcamButton("Take another photo")}
                    </>
                );
            } else if (existingPicture) {
                return (
                    <>
                        <Avatar
                            className={classes.avatar}
                            src={existingPicture}
                            data-testid="uploadedPicture"
                        />
                        {renderWebcamButton("Snap photo")}
                        {renderUploadButton()}
                    </>
                );
            } else {
                return (
                    <>
                        <TemporaryPicture />
                        {renderWebcamButton("Snap photo")}
                        {renderUploadButton()}
                    </>
                );
            }
        } else {
            return <WebcamCapture />;
        }
    };

    return (
        <>
            <div>
                <Typography variant="body1">
                    Say cheese! Our community thrives when seeing each other's authentic selves.
                    Take a humble profile photo straight from your camera or{" "}
                    <label htmlFor="avatarUpload">
                        <input
                            type="file"
                            id="avatarUpload"
                            accept="image/png, image/jpeg, image/jpg"
                            data-testid="profileFileUpload"
                            hidden
                            onChange={onPictureUpload}
                        />
                        <NavLink>upload an existing photo.</NavLink>
                    </label>
                </Typography>
            </div>
            <div>
                {savingPicture ? (
                    <div className={classes.avatarLoading}>
                        <CircularProgress />
                    </div>
                ) : (
                    renderProfilePic()
                )}
            </div>
        </>
    );
};

export default ProfilePicture;
