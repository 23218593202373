import { useQuery } from "@apollo/client";
import { Course } from "@app/shared/types";
import makeStyles from "@mui/styles/makeStyles";
import { GRAPHQL_QUERY_COURSE } from "app/queries";
import { NAVBAR_HEIGHT } from "app/styles";
import { selectCanAccessMemberZone } from "features/auth/auth";
import { MENU_SHADOW_HEIGHT } from "features/navigation/TopBarMenu";
import LoadingPage from "features/pages/LoadingPage";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";

const useStyles = makeStyles(() => ({
    pageContainer: {
        position: "relative",
        height: `calc(100vh - ${NAVBAR_HEIGHT}px)`,
    },
    embeddedLandingPage: {
        border: "none",
        width: "100%",
        height: `calc(100% + ${MENU_SHADOW_HEIGHT}px)`,
        position: "absolute",
        top: -MENU_SHADOW_HEIGHT,
    },
}));

export const CourseEmbeddedLandingPage = () => {
    const classes = useStyles();

    const isBanyanMember = useSelector(selectCanAccessMemberZone);

    const { courseId } = useParams<{ courseId: string }>();
    const { data: courseData, loading: courseLoading } = useQuery(GRAPHQL_QUERY_COURSE, {
        variables: { id: courseId },
    });

    useEffect(() => {
        if (!courseLoading && courseData?.course?.myCourseMembership) {
            window.location.href = courseData.course.url;
        }
    }, [courseLoading, courseData]);


    if (courseLoading) {
        return <LoadingPage />;
    }
    const course = courseData?.course as Course;

    if (course?.myCourseMembership) {
        // Return the LoadingPage to avoid flashing the landing page while redirect happens
        return <LoadingPage />;
    }

    return (
        <div className={classes.pageContainer}>
            <iframe
                src={
                    isBanyanMember && !!course.landingPageUrlForMembers
                        ? course.landingPageUrlForMembers
                        : course.landingPageUrl
                }
                className={classes.embeddedLandingPage}
                title={course.name}
            />
        </div>
    );
};
