import { Button } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { useEffect, useState } from "react";
import { CSDialog, CSDialogProps } from "./CSDialog";

const useStyles = makeStyles((theme) => ({
    buttonContainer: {
        textAlign: "right",
    },
}));

interface ConfirmationDialogProps extends CSDialogProps {
    onConfirm: () => void;
    onClose: (ev: React.MouseEvent<any>) => void;
    confirmButtonText: string;
    enableConfirmButtonDelay?: number;
}

export const ConfirmationDialog = (props: ConfirmationDialogProps) => {
    const classes = useStyles(props);

    const { onConfirm, confirmButtonText, children, ...others } = props;

    const enableConfirmButtonDelay = props.enableConfirmButtonDelay ?? 3000; // 3 seconds

    const [enableConfirmButton, setEnableConfirmButton] = useState(false);
    useEffect(() => {
        if (props.open) {
            setTimeout(() => {
                setEnableConfirmButton(true);
            }, enableConfirmButtonDelay);
        } else {
            setEnableConfirmButton(false);
        }
    }, [props.open]);

    return (
        <CSDialog {...others}>
            {children}

            <div className={classes.buttonContainer}>
                <Button
                    variant="primary"
                    disabled={!enableConfirmButton}
                    onClick={onConfirm}
                    data-testid="confirmButton"
                >
                    {confirmButtonText}
                </Button>
            </div>
        </CSDialog>
    );
};
