import { useLocation } from "react-router";
import { createContext, useContext, useState, useEffect, ReactNode } from 'react';

interface GenericErrorMessageContextType {
    registerGenericErrorMessage: (id: string) => void;
    errorShouldBeVisible: (id: string) => boolean;
}

export const GenericErrorMessageVisibilityContext = createContext<GenericErrorMessageContextType>({
    registerGenericErrorMessage: () => { },
    errorShouldBeVisible: () => false,
});

export const GenericErrorMessageProvider =  ({ children }: { children: React.ReactNode }) => {
    const reactRouterLocation = useLocation();
    const [visibleErrorId, setVisibleErrorId] = useState<string | null>(null);

    useEffect(() => {
        setVisibleErrorId(null);
    }, [reactRouterLocation.pathname]);

    const registerGenericErrorMessage = (id: string) => {
        if (visibleErrorId === null) { // Only show the first GenericErrorMessage registered
            setVisibleErrorId(id);
        }
    };

    const errorShouldBeVisible = (id: string) => visibleErrorId === id;

    return (
        <GenericErrorMessageVisibilityContext.Provider value={{ registerGenericErrorMessage, errorShouldBeVisible }}>
            {children}
        </GenericErrorMessageVisibilityContext.Provider>
    );
};

export const useGenericErrorMessageVisibility = () => useContext(GenericErrorMessageVisibilityContext);