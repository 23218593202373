import { DocumentNode, MutationFunctionOptions, useMutation } from "@apollo/client";
import { loadStripe } from "@stripe/stripe-js";
import { isRunningCypress } from "app/config";
import { StripePublicKey } from "app/stripe";
import { FieldNode, OperationDefinitionNode } from "graphql";

// Runs a mutation that is expected to return a stripe checkout session ID as a result
// Redirects to that checkout session
export const useStripeCheckout = (checkoutSessionMutation: DocumentNode, bypassStripe = true) => {
    const [generateCheckoutSession] = useMutation(checkoutSessionMutation);

    // Retrieve the mutation name, since the result object will have a key with the same name
    const mutationDefinition = checkoutSessionMutation.definitions[0] as OperationDefinitionNode;
    const mutationField = mutationDefinition.selectionSet.selections[0] as FieldNode;
    const mutationName = mutationField.name.value;

    return async (options: MutationFunctionOptions, successUrl: string) => {
        const result = await generateCheckoutSession(options);
        const sessionId = result.data[mutationName];

        if (isRunningCypress()) {
            (window as any).stripeSessionId = sessionId;
        } else if (bypassStripe && process.env.REACT_APP_STRIPE_BYPASS === "true") {
            window.location.href = successUrl;
        } else {
            const stripe = await loadStripe(StripePublicKey);
            stripe?.redirectToCheckout({ sessionId });
        }
    };
};
