import { Session, SessionType } from "@app/shared/types";
import { Button } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { analyticsTrack } from "app/analytics/track";
import classNames from "classnames";
import { useUserTimezone } from "hooks/useUserTimezone";
import { useEffect } from "react";
import smoothscroll from "smoothscroll-polyfill";
import { getEventAnalyticsData, renderAddToCalendarMetadata } from "./AddToCalendarMenuItem";

smoothscroll.polyfill();

const useStyles = makeStyles((theme) => ({
    addToCalendarButton: {
        marginBottom: theme.spacing(3),
        minWidth: 300,
    },
    addToCalendarContainer: {
        display: "inline-block",
    },
}));

export enum CalendarType {
    apple = "apple",
    google = "google",
    office365 = "office365",
    outlook = "outlook",
    outlookcom = "outlookcom",
    yahoo = "yahoo",
}

const titleMap: Record<CalendarType, String> = {
    [CalendarType.apple]: "Apple",
    [CalendarType.google]: "Google",
    [CalendarType.office365]: "Office 365",
    [CalendarType.outlook]: "Outlook",
    [CalendarType.outlookcom]: "Outlook.com",
    [CalendarType.yahoo]: "Yahoo",
};

export const AddToSingleCalendarButton = (props: {
    calendarType: CalendarType;
    onClick: VoidFunction;
    event: Session;
}) => {
    const { calendarType, event, onClick } = props;
    const classes = useStyles();
    const timezone = useUserTimezone();

    useEffect(() => {
        (window as any).addeventatc?.refresh();
    }, []);

    return (
        <div
            title={`Add to calendar - ${titleMap[calendarType]}`}
            className={classNames("addeventatc", classes.addToCalendarContainer)}
            data-styling="none"
            data-direct={calendarType}
            data-testid="addToCalendarButton"
            onClick={() => {
                setTimeout(() => {
                    analyticsTrack("addToCalendarButton.click", {
                        calendarType,
                        ...getEventAnalyticsData(event),
                    });
                    onClick();
                }, 0);
            }}
        >
            <Button variant="secondary" className={classes.addToCalendarButton}>
                {titleMap[calendarType]}
            </Button>

            {renderAddToCalendarMetadata(
                event,
                timezone,
                event.type === SessionType.INTEREST_GROUP,
            )}
        </div>
    );
};
