import { UTMParameters } from "@app/shared/types";
import _ from "lodash";
import QueryString from "query-string";

/**
 * Code here should match the UTM parameter tracking in our Webflow site and blog: https://github.com/cloudsangha/webflow-typescript-webpack/blob/master/src/components/getUtmParameters.ts
 */

export const getUtmParameters = (
    query = QueryString.parse(window.location.search),
): UTMParameters | null => {
    const utmParameters: UTMParameters = {
        utm_campaign: getUtmParameterFromQuery(query, "utm_campaign"),
        utm_content: getUtmParameterFromQuery(query, "utm_content"),
        utm_id: getUtmParameterFromQuery(query, "utm_id"),
        utm_medium: getUtmParameterFromQuery(query, "utm_medium"),
        utm_source: getUtmParameterFromQuery(query, "utm_source"),
        utm_term: getUtmParameterFromQuery(query, "utm_term"),
        gclid: getUtmParameterFromQuery(query, "gclid"),
    };

    // Return null if none of the parameters are set
    return _.values(utmParameters).some(Boolean) ? utmParameters : null;
};

// On typeform, the default for hidden fields is "_____", so we'd want to treat this as an empty value here
const TYPEFORM_EMPTY_PARAM_VALUE = "_____";
const getUtmParameterFromQuery = (
    query: QueryString.ParsedQuery<string>,
    parameterName: string,
) => {
    const value = query[parameterName]?.toString();
    return value === TYPEFORM_EMPTY_PARAM_VALUE ? undefined : value;
};

export enum UtmPrefix {
    NONE = "",
    AFFILIATE = "affiliate_",
}

const storeUtmParametersInLocalStorage = (
    params: UTMParameters,
    keyPrefix: UtmPrefix = UtmPrefix.NONE,
) => {
    const keys = Object.keys(params) as (keyof UTMParameters)[];
    keys.forEach((key) => {
        const value = params[key];
        const localStorageKey = `${keyPrefix}${key}`;
        if (value) {
            localStorage.setItem(localStorageKey, value);
        } else {
            localStorage.removeItem(localStorageKey);
        }
    });
};

export const getUtmParametersFromLocalStorage = (
    keyPrefix: UtmPrefix = UtmPrefix.NONE,
): UTMParameters => {
    const getParam = (key: string) => localStorage.getItem(`${keyPrefix}${key}`) || undefined;
    return {
        utm_campaign: getParam("utm_campaign"),
        utm_content: getParam("utm_content"),
        utm_id: getParam("utm_id"),
        utm_medium: getParam("utm_medium"),
        utm_source: getParam("utm_source"),
        utm_term: getParam("utm_term"),
        gclid: getParam("gclid"),
    };
};

const isAffiliatePartner = (params: UTMParameters) =>
    params.utm_medium?.toLowerCase() === "affiliate";

export const trackUtmParameters = (params: UTMParameters) => {
    storeUtmParametersInLocalStorage(params);

    if (isAffiliatePartner(params)) {
        storeUtmParametersInLocalStorage(params, UtmPrefix.AFFILIATE);
    }
};
