import makeStyles from "@mui/styles/makeStyles";
import { CSDialog } from "components/CSDialog";
import MembershipChargeExplanationPopup from "../MembershipChargeExplanationPopup";

const useStyles = makeStyles((theme) => ({}));

export const MembershipChargeExplanationGlobalModal = (props: {}) => {
    const classes = useStyles();

    return (
        <CSDialog open={true} transitionDuration={700}>
            <MembershipChargeExplanationPopup />
        </CSDialog>
    );
};
