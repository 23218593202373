import { UserProfile } from "@app/shared/types";
import { Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { analyticsTrack } from "app/analytics/track";
import { routes } from "app/routes";
import { LIMITED_WIDTH_LARGE } from "app/styles";
import DocumentContext from "components/DocumentContext";
import PageWrapper from "components/PageWrapper";
import { push } from "connected-react-router";
import { selectIsTeacherOrAdmin, selectUserProfile } from "features/auth/auth";
import { useScrollToAnchorOnLoad } from "hooks/useScrollToAnchorOnLoad";
import editIcon from "images/icons/edit.svg";
import { useDispatch, useSelector } from "react-redux";
import { MembershipStripeAlerts } from "../membership/MembershipStripeAlerts";
import { MyMembership } from "../membership/MyMembership";
import { UserProfileHighlight } from "../profile/UserProfileHighlight";
import { NotificationPreferences } from "./NotificationPreferences";

const useStyles = makeStyles((theme) => ({
    limitedWidth: {
        maxWidth: LIMITED_WIDTH_LARGE,
    },

    title: {
        marginTop: theme.spacing(10),

        "&:first-child": {
            marginTop: 0,
        },
    },

    userProfileCard: {
        maxWidth: "none",
        backgroundColor: theme.palette.background.contrast,
        color: theme.palette.grey900,
    },
}));

export const MemberAccountPage = () => {
    const classes = useStyles();

    const dispatch = useDispatch();

    const isTeacherOrAdmin = useSelector(selectIsTeacherOrAdmin);
    const userProfile = useSelector(selectUserProfile);

    useScrollToAnchorOnLoad(); // TODO: figure out how to use this at top-level

    const renderMembershipSection = () => {
        return (
            <>
                <Typography variant="h2" className={classes.title}>
                    Membership
                </Typography>
                <MyMembership />
            </>
        );
    };

    const renderEditProfileSection = (profile: UserProfile) => (
        <>
            <DocumentContext addBrandNameSuffix title="Account settings" />
            <Typography variant="h2" className={classes.title}>
                Your profile
            </Typography>
            <UserProfileHighlight
                profile={profile}
                iconButton={{
                    src: editIcon,
                    alt: "Edit",
                }}
                onClick={() => {
                    dispatch(push(routes.editProfilePage()));
                    analyticsTrack("member.settings.editProfile.open");
                }}
                classes={{
                    card: classes.userProfileCard,
                }}
            />
        </>
    );

    return (
        <PageWrapper>
            <MembershipStripeAlerts />

            <div className={classes.limitedWidth}>
                {userProfile && renderEditProfileSection(userProfile)}

                {!isTeacherOrAdmin && renderMembershipSection()}

                <Typography variant="h2" className={classes.title} id="notification-preferences">
                    Email notifications
                </Typography>
                <NotificationPreferences />
            </div>
        </PageWrapper>
    );
};
