import { Session, UserProfile } from "@app/shared/types";
import { Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { useUserTimezone } from "hooks/useUserTimezone";
import { DateTime } from "luxon";

const useStyles = makeStyles((theme) => ({
    rsvpPanel: {
        position: "absolute",
        bottom: 0,
        left: 0,
        zIndex: "1",
        width: "100%",
        alignSelf: "flex-start",
        alignItems: "center",
        backgroundColor: theme.palette.background.default,
        boxShadow: theme.shadow.default,
        borderRadius: theme.borderRadius.default,
    },
    rsvpBlock1: {
        width: "100%",
        display: "inline-block",
        [theme.breakpoints.down("md")]: {
            width: "100%",
            display: "block",
            paddingRight: 0,
            paddingTop: theme.spacing(2),
            marginTop: 0,
            textAlign: "center",
        },
        textAlign: "center",
        marginTop: theme.spacing(3),
    },
    eventDate: {
        marginBottom: 0,
    },
    eventTime: {
        marginBottom: theme.spacing(2),
    },
}));

export const RsvpPanel = (props: {
    event: Session;
    rsvpUsers?: UserProfile[];
    renderEventTime: (event: Session) => {};
    classes?: Partial<ReturnType<typeof useStyles>>;
}) => {
    const classes = useStyles(props);
    const { event } = props;

    const timezone = useUserTimezone();
    const eventDate = DateTime.fromISO(event.time, {
        zone: timezone,
    });

    return (
        <div className={classes.rsvpPanel}>
            <div className={classes.rsvpBlock1}>
                <Typography variant="body1" className={classes.eventDate}>
                    {eventDate.toLocaleString({
                        weekday: "long",
                        month: "long",
                        day: "numeric",
                    })}
                </Typography>

                <Typography variant="h4" className={classes.eventTime}>
                    {props.renderEventTime(event)}
                </Typography>
            </div>
        </div>
    );
};
