import { useQuery } from "@apollo/client";
import { Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { GRAPHQL_QUERY_COURSES } from "app/queries";
import { Course, CourseAvailability } from "@app/shared/types";
import DocumentContext from "components/DocumentContext";
import PageWrapper from "components/PageWrapper";
import { theme } from "app/theme";
import { useSelector } from "react-redux";
import { selectCanAccessMemberZone, selectIsLoggedIn } from "features/auth/auth";
import { MembershipUpsellSection } from "./MembershipUpsellSection";
import { Redirect, useLocation } from "react-router";
import { routes } from "app/routes";
import LoadingPage from "features/pages/LoadingPage";
import { GenericErrorPage } from "components/GenericErrorPage";
import CourseCard from "components/CourseCard";

const useStyles = makeStyles(() => ({
  cardsContainer: {
    display: "grid",
    gridTemplateColumns: "repeat(auto-fill, minmax(350px, 1fr))",
    gap: theme.spacing(3),
    [theme.breakpoints.up("md")]: {
      gridTemplateColumns: "repeat(2, 1fr)",
    },
    [theme.breakpoints.up("lg")]: {
      gridTemplateColumns: "repeat(3, 1fr)",
    },
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4),
  },
}));

export const CoursesDashboard = () => {
  const classes = useStyles();
  const canAccessMemberZone = useSelector(selectCanAccessMemberZone);
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const showMembershipUpsell = !canAccessMemberZone && isLoggedIn;
  const pageLocation = useLocation();
  const shouldRedirect = isLoggedIn && pageLocation.pathname === routes.coursesPublicDashboard();

  const {
    data: coursesData,
    loading: coursesLoading,
    error: coursesError,
  } = useQuery(GRAPHQL_QUERY_COURSES, { fetchPolicy: "network-only" });

  if (shouldRedirect) {
    return <Redirect to={routes.coursesDashboard()} />;
  }

  const courses: Course[] | undefined = coursesData?.courses;

  if (coursesLoading || !coursesData) {
    return <LoadingPage />;
  }

  if (coursesError) {
    return <GenericErrorPage />;
  }

  // Filter out the courses that the user is enrolled
  const enrolledCourses = courses?.filter((course) => course?.myCourseMembership);

  const courseIsPublic = (course: Course) =>
    course.availability === CourseAvailability.PUBLIC ||
    course.availability === CourseAvailability.PUBLIC_NEEDS_APPROVAL;

  // Filter out the courses that the user is not enrolled
  const exploreCourses = courses?.filter(
    (course) => !course?.myCourseMembership && courseIsPublic(course),
  );

  return (
    <>
      <PageWrapper data-testid="coursesDashboardPage">
        <DocumentContext addBrandNameSuffix title="Courses" />

        <Typography variant="h1">Courses</Typography>

        {!!enrolledCourses && enrolledCourses.length > 0 && (
          <>
            <Typography variant="h2">My Courses</Typography>
            <div className={classes.cardsContainer} data-testid="myCoursesSection">
              {enrolledCourses?.map((course) => (
                <CourseCard key={course.id} course={course} />
              ))}
            </div>
          </>
        )}

        {!!exploreCourses && exploreCourses.length > 0 && (
          <>
            <Typography variant="h2">Explore</Typography>
            <div className={classes.cardsContainer} data-testid="exploreCoursesSection">
              {exploreCourses?.map((course) => (
                <CourseCard key={course.id} course={course} />
              ))}
            </div>
          </>
        )}
      </PageWrapper>
      {!!showMembershipUpsell && <MembershipUpsellSection />}
    </>
  );
};
