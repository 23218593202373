import { Session, SessionType } from "@app/shared/types";
import { dateTimeFromString } from "@app/shared/utils";
import { Box, Typography } from "@mui/material";
import { GRAPHQL_QUERY_MY_SCHEDULE, GRAPHQL_QUERY_UPCOMING_EVENTS } from "app/queries";
import DocumentContext from "components/DocumentContext";
import PageWrapper from "components/PageWrapper";
import { PayItForwardButton } from "features/donation/PayItForwardButton";
import { useCurrentServerTime } from "hooks/useCurrentServerTime";
import { DateTime } from "luxon";
import { EventListLoader } from "../eventDisplay/EventListLoader";

export const sessionTypesForSessionsRightNow = [
    SessionType.COMMUNITY_SIT,
    SessionType.COMMUNITY_TALK,
    SessionType.WELCOME_SESSION,
    SessionType.SILENT_SIT,
    SessionType.INTEREST_GROUP,
    SessionType.TEACHER_ONLY_SESSION, // These are filtered out on the back-end for members
    SessionType.CONFLICT_RESOLUTION_PRACTICE,
    SessionType.QA_SESSION,
];

// Filter out MFGs that started more than 10 minutes ago
export const filterSessionsRightNow = (event: Session) => {
    if (event.type !== SessionType.INTEREST_GROUP) {
        return true;
    }

    return DateTime.fromISO(event.time) > DateTime.now().minus({ minutes: 10 });
};

export const MemberSessionsRightNowPage = () => {
    const currentServerTime = useCurrentServerTime();

    return (
        <div>
            <DocumentContext title="Sessions Right Now - Cloud Sangha" />

            <span>
                <PayItForwardButton />
            </span>

            <PageWrapper data-testid="sessionsRightNowPage">
                <Box sx={{ mb: 10 }}>
                    <Typography variant="h2">Sessions Right Now</Typography>

                    <EventListLoader
                        currentServerTime={currentServerTime}
                        eventsQuery={GRAPHQL_QUERY_UPCOMING_EVENTS}
                        queryOptions={{
                            variables: {
                                includeTypes: sessionTypesForSessionsRightNow,
                                startsWithinMinutes: 15,
                            },
                        }}
                        filterEvents={filterSessionsRightNow}
                    />
                </Box>
            </PageWrapper>
        </div>
    );
};
