import { CircularProgress, Typography } from "@mui/material";
import { usePodAssignmentNumber } from "./usePodAssignmentNumber";

export const PodAssignmentReminder = (props: { sanghaId: string }) => {
    const { loading, podAssignment } = usePodAssignmentNumber(props.sanghaId);

    if (loading) {
        return <CircularProgress />;
    }

    // As a quick fix, the server returns -1 as the podAssignment for drop-in members to allow us to differentiate them without restructuring queries
    if (podAssignment === -1) {
        // Drop-in members
        return (
            <>
                <Typography variant="h1">You’ll be in a drop-in pod today</Typography>
                <Typography variant="body1">
                    Enjoy meeting and sharing the practice with new people each week.
                </Typography>
            </>
        );
    } else if (podAssignment) {
        // Dedicated pod members
        return (
            <>
                <Typography variant="h1">You’ll be in a dedicated pod today</Typography>
                <Typography variant="body1">
                    Enjoy getting to know your pod members over time and deepening your bond.
                </Typography>
            </>
        );
    } else {
        // Dedicated pod members who are unassigned
        return (
            <>
                <Typography variant="h1">We’re working to find a dedicated pod for you</Typography>
                <Typography variant="body1">
                    You will be placed in a drop-in pod until there are enough new members to create
                    a new dedicated pod
                </Typography>
            </>
        );
    }
};
