import makeStyles from "@mui/styles/makeStyles";
import { FadeCarousel } from "components/FadeCarousel";
import * as React from "react";

const defaultImageAspectRatio = 0.7;

const useStyles = makeStyles((theme) => ({
    nowrap: {
        whiteSpace: "nowrap",
    },

    container: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",

        [theme.breakpoints.up("md")]: {
            flexDirection: "row",
            justifyContent: "normal",
        },
    },

    text: (props: SectionImageWithTextProps) => ({
        order: 2,
        textAlign: "left",

        [theme.breakpoints.up("md")]: {
            order: props.textFirst ? 1 : 2,
            width: "55%",
            textAlign: "left",
            display: "flex",
            flexDirection: "column",
        },

        "& > :first-child": {
            marginTop: 0,
        },

        "& > :last-child": {
            marginBottom: 0,
        },
    }),
    images: (props: SectionImageWithTextProps) => ({
        order: 1,
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-around",
        alignItems: "flex-end",

        [theme.breakpoints.up("md")]: {
            order: props.textFirst ? 2 : 1,
            flexGrow: 0,
            width: "45%",
        },
    }),
    image: (props: SectionImageWithTextProps) => {
        const imageAspectRatio = props.imageAspectRatio || defaultImageAspectRatio;
        return {
            borderTopLeftRadius: "10% 15%",
            borderTopRightRadius: "10% 15%",
            borderBottomLeftRadius: "10% 15%",
            borderBottomRightRadius: "10% 15%",

            width: "60%",
            paddingTop: `${imageAspectRatio * 60}%`,
            margin: theme.spacing(4, "auto", 2, "auto"),

            [theme.breakpoints.up("md")]: {
                margin: 0,
                width: "80%",
                paddingTop: `${imageAspectRatio * 80}%`,
            },
        };
    },
}));

interface SectionImageWithTextProps {
    children: React.ReactNode;
    images: string[];
    imageAspectRatio?: number;
    textFirst?: boolean;
    classes?: Partial<ReturnType<typeof useStyles>>;
}

export const SectionImageWithText = React.memo((props: SectionImageWithTextProps) => {
    const classes = useStyles(props);

    return (
        <div className={classes.container}>
            <div className={classes.text}>{props.children}</div>
            <div className={classes.images}>
                <FadeCarousel className={classes.image} images={props.images} interval={5000} />
            </div>
        </div>
    );
});
