import { Membership } from "@app/shared/types";
import { DateTime } from "luxon";

export const isMembershipCohortSponsored = (membership: Membership) => membership?.cohort;

// Note that "isFreeTrialActiveForMembership" only includes members who signed up for a free trial and not those who are sponsored by a cohort
export const membershipUsesFreeTrialInternally = (membership: Membership) =>
    DateTime.fromISO(membership.freeTrialEndDate) > DateTime.now();
export const isFreeTrialActiveForMembership = (membership: Membership) =>
    !isMembershipCohortSponsored(membership) && membershipUsesFreeTrialInternally(membership);
