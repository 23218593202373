import { MessageThread, MessageType, Sangha, UserProfile } from "@app/shared/types";
import makeStyles from "@mui/styles/makeStyles";
import _ from "lodash";
import { DateTime } from "luxon";
import { memo } from "react";
import { MessageAuthor } from "./MessageAuthor";
import { MessagePanel } from "./MessagePanel";
import { OnDeleteMessageFunction } from "./MessageThreadPanelDeleteButton";
import { alpha } from "@mui/material";
import { Typography } from "@mui/material";

const useStyles = makeStyles((theme) => ({
    thread: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
    },

    header: {
        textAlign: "center",
        position: "relative",

        "&:before": {
            content: "''",
            position: "absolute",
            left: 0,
            right: 0,
            top: "50%",
            height: 1,
            borderTop: `1px solid ${alpha(theme.palette.grey700, 0.2)}`,
        },
    },

    threadDate: {
        color: theme.palette.grey400,
        position: "relative",
        padding: theme.spacing(0, 4),
        display: "inline-block",
        backgroundColor: theme.palette.background.default,
    },
}));

interface MessageThreadPanelProps {
    thread: MessageThread;
    sangha: Sangha;
    onDeleteMessage: OnDeleteMessageFunction;
}

const getThreadContent = (props: MessageThreadPanelProps) => {
    const { thread, sangha, onDeleteMessage } = props;

    let currentAuthor: UserProfile | null = null;

    const content = [];

    for (const message of messagesForThread(thread)) {
        if (message.author.id !== currentAuthor?.id) {
            currentAuthor = message.author;
            content.push(
                <MessageAuthor
                    key={`${message.author.id}-${message.id}`}
                    sangha={sangha}
                    author={message.author}
                />,
            );
        }

        content.push(
            <MessagePanel
                key={message.id}
                message={message}
                messageThreadId={thread.id}
                onDeleteMessage={onDeleteMessage}
            />,
        );
    }

    return content;
};

const messagesForThread = (thread: MessageThread) => {
    // Filter out reflection questions from the messages to be displayed
    const filteredMessages = (thread.messages || []).filter(
        (x) => x.type !== MessageType.REFLECTION_QUESTION,
    );

    // Sort by date
    return _.orderBy(filteredMessages, ["date"], ["asc"]);
};

const getFormattedThreadDate = (thread: MessageThread) => {
    if (!thread.sanghaSession?.time) {
        return null;
    }

    return DateTime.fromISO(thread.sanghaSession.time).toLocaleString({
        month: "short",
        day: "numeric",
        year: "numeric",
    });
};

export const MessageThreadPanel = memo((props: MessageThreadPanelProps) => {
    const classes = useStyles();

    const { thread } = props;
    const content = getThreadContent(props);

    if (thread.messages.length === 0) {
        return null;
    }

    return (
        <section className={classes.thread}>
            <header className={classes.header}>
                <Typography variant="body2" className={classes.threadDate}>
                    {getFormattedThreadDate(thread)}
                </Typography>
            </header>
            {content}
        </section>
    );
});
