import { BRAND_NAME_AND_LEGAL_NAME } from "@app/shared/constants";
import { Container, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import DocumentContext from "components/DocumentContext";

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(4),
    },
}));

export const CookiePolicyPage = () => {
    const classes = useStyles();

    return (
        <Container className={classes.root} maxWidth="lg">
            <DocumentContext addBrandNameSuffix title="Cookie Policy" />
            <Typography variant="h2">Cookie Policy</Typography>

            <Typography variant="h3">Does {BRAND_NAME_AND_LEGAL_NAME} use cookies?</Typography>
            <Typography variant="body1">
                Yes. {BRAND_NAME_AND_LEGAL_NAME} uses cookies and other similar technologies to
                ensure everyone who uses the website has the best possible experience.
            </Typography>

            <Typography variant="h3">What is a cookie?</Typography>
            <Typography variant="body1">
                A cookie is a small text file that is placed on your hard drive by a web page
                server. Cookies contain information that can later be read by a web server in the
                domain that issued the cookie to you. You can find out more about each cookie by
                viewing our current cookie list below. We update this list every six months, so
                there may be additional cookies not yet listed. Web beacons, tags, and scripts may
                be used on the website or in emails to help us to deliver cookies, count visits,
                understand usage and advertising campaign effectiveness, and determine whether an
                email has been opened and clicked on. We may receive reports based on the use of
                these technologies by our service/analytics providers on an individual and
                aggregated basis.
            </Typography>

            <Typography variant="h3">Why does {BRAND_NAME_AND_LEGAL_NAME} use cookies?</Typography>
            <Typography variant="body1">
                When you visit our website, we may place a number of cookies in your browser. These
                are first-party cookies, and they allow us to hold session information as you
                navigate within the site. For example, we use cookies on our website to understand
                visitor and user preferences, improve their experience, and track and analyze usage,
                navigational, and other statistical information. You can control the use of cookies
                at the individual browser level. If you choose not to activate cookies or to later
                disable them, you can still visit our website, but your ability to use some features
                may be limited.
            </Typography>

            <Typography variant="h3">How do I disable cookies?</Typography>
            <Typography variant="body1">
                You can generally activate or later deactivate the use of cookies through your web
                browser. Find your browser below to learn more about how to manage your cookie
                settings.
            </Typography>

            <Typography variant="body1">
                <ul>
                    <li>
                        Firefox: Click here to learn more about “Private Browsing” and managing
                        cookie settings.
                    </li>
                    <li>
                        Chrome: Click here to learn more about “Incognito” and managing cookie
                        settings.
                    </li>
                    <li>
                        Internet Explorer: Click here to learn more about “InPrivate” and managing
                        cookie settings.
                    </li>
                    <li>
                        Safari: Click here to learn more about “Private Browsing” and managing
                        cookie settings.
                    </li>
                </ul>
            </Typography>
            <Typography variant="body1">
                If you want to learn more about cookies or how to control, disable, or delete them,
                please visit{" "}
                <a
                    href="https://www.aboutcookies.org/"
                    target="_blank"
                    rel="external nofollow noreferrer"
                >
                    https://www.aboutcookies.org/
                </a>{" "}
                for detailed guidance.
            </Typography>

            <Typography variant="body1">
                In addition, certain third party advertising networks, including Google, allow users
                to opt out of or customize preferences associated with your internet browsing. To
                learn more about this feature from Google, click here.
            </Typography>

            <Typography variant="h3">Cookies we use</Typography>
            <Typography variant="body1">
                Many jurisdictions require or recommend that website operators disclose the types of
                cookies they use and occasionally get consent from users before placing certain
                cookies.
            </Typography>

            <Typography variant="body1">
                We may use any of the following categories of cookies on our website as detailed
                below. Each cookie falls within one of these four categories:
            </Typography>

            <Typography variant="h4">Essential Cookies</Typography>
            <Typography variant="body1">
                Essential cookies (first-party cookies) are sometimes called “strictly necessary” as
                without them we cannot provide many services that you need on the website. For
                example, essential cookies help remember your preferences as you move around the
                website.
            </Typography>
            <Typography variant="h4">Analytics Cookies</Typography>
            <Typography variant="body1">
                These cookies track information about website visits so we can make improvements and
                report our performance. For example, we analyze visitor and user behavior to provide
                more relevant content or suggest certain activities. These cookies collect
                information about how visitors use the website, which site the user came from, the
                number of each user’s visits, and how long a user stays on the website. We might
                also use analytics cookies to test new ads, pages, or features to see how users
                react to them.
            </Typography>
            <Typography variant="h4">Functionality or Preference Cookies</Typography>
            <Typography variant="body1">
                During your visit to the website, these cookies are used to remember information you
                have entered or choices you make such as your username, language, or region. They
                also store your preferences when personalizing the website to optimize your use of{" "}
                {BRAND_NAME_AND_LEGAL_NAME}, for example, your preferred language. These preferences
                are remembered through the use of the persistent cookies, and the next time you
                visit the website you won’t have to set them again.
            </Typography>
            <Typography variant="h4">Targeting or Advertising Cookies</Typography>
            <Typography variant="body1">
                These third-party cookies are placed by third-party advertising platforms or
                networks in order to deliver ads and track ad performance, enabling advertising
                networks to deliver ads that may be relevant to you based upon your activities (this
                is sometimes called behavioral, tracking, or targeted advertising) on the website.
                They may subsequently use information about your visit to show you ads that you may
                be interested on our website and other websites. For example, these cookies remember
                which browsers have visited the website.
            </Typography>
        </Container>
    );
};

export default CookiePolicyPage;
