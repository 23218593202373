import { priceAfterDiscount } from "@app/shared/prices";
import { Membership } from "@app/shared/types";
import { formatTimestamp } from "@app/shared/utils";
import { Typography } from "@mui/material";
import { displayPrice } from "components/displayPrice";
import { DateTime } from "luxon";

interface DiscountExplanationProps {
    membership: Membership;
}

export const DiscountExplanation = (props: DiscountExplanationProps) => {
    const { membership } = props;

    const discount = membership.discount;

    const donationAmount = membership.billingChoice.donationAmount;
    const baseAmount = membership.billingChoice.plan.baseAmount;
    const totalPrice = donationAmount ? baseAmount + donationAmount : baseAmount;

    const actualPrice = priceAfterDiscount(totalPrice, discount);
    const discountEndDate = discount.endDate ? DateTime.fromISO(discount.endDate) : null;

    let moneyOff: string | undefined;
    if (discount?.amountOff) {
        moneyOff = displayPrice(discount.amountOff / 100);
    } else if (discount?.percentOff) {
        moneyOff = `${discount?.percentOff}%`;
    }

    if (discount.duration === "repeating" && discountEndDate) {
        return (
            <>
                <Typography variant="overline">
                    Repeating discount of {moneyOff} applied until{" "}
                    {formatTimestamp(discountEndDate.toMillis(), {
                        month: "long",
                        day: "numeric",
                    })}
                </Typography>
                <Typography variant="h5">
                    You're contributing {displayPrice(actualPrice)} per{" "}
                    {membership.billingChoice.plan.billedEvery} until{" "}
                    {formatTimestamp(discountEndDate.toMillis(), {
                        month: "long",
                        day: "numeric",
                    })}
                    , and then {displayPrice(totalPrice)}
                </Typography>
            </>
        );
    } else if (discount.duration === "once") {
        return (
            <>
                <Typography variant="overline">One-time discount of {moneyOff} applied</Typography>
                <Typography variant="h5">
                    You're contributing {displayPrice(actualPrice)} next{" "}
                    {membership.billingChoice.plan.billedEvery}, and then {displayPrice(totalPrice)}{" "}
                </Typography>
            </>
        );
    } else if (discount.duration === "forever") {
        return (
            <>
                <Typography variant="overline">Lifetime discount of {moneyOff} applied </Typography>
                <Typography variant="h5">
                    You're contributing {displayPrice(actualPrice)} per{" "}
                    {membership.billingChoice.plan.billedEvery}
                </Typography>
            </>
        );
    } else {
        return (
            <>
                <Typography variant="overline">Discount applied</Typography>
                <Typography variant="h5">
                    You're contributing {displayPrice(actualPrice)} per{" "}
                    {membership.billingChoice.plan.billedEvery}
                </Typography>
            </>
        );
    }
};
