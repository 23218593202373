import { SessionType } from "@app/shared/types";
import { Chip } from "@mui/material";

export const SessionTypeChip = ({ sessionType }: { sessionType: SessionType }) => {
    if (sessionType === SessionType.COMMUNITY_TALK) {
        return <Chip label="Talk" variant="green" />;
    } else if (sessionType === SessionType.COMMUNITY_SIT) {
        return <Chip label="Meditation" variant="communityTalk" />;
    } else if (sessionType === SessionType.INTEREST_GROUP) {
        return <Chip label="Mindful Friends Group" variant="interestGroup" />;
    } else if (sessionType === SessionType.SILENT_SIT) {
        return <Chip label="Silent Meditation" variant="silentSit" />;
    } else if (sessionType === SessionType.WELCOME_SESSION) {
        return <Chip label="Welcome Session" variant="welcomeSession" />;
    } else if (sessionType === SessionType.QA_SESSION) {
        return <Chip label="Q&A Session" variant="qaSession" />;
    } else if (sessionType === SessionType.SANGHA_SESSION) {
        return <Chip label="Mentorship Group" variant="sanghaSession" />;
    } else {
        return null;
    }
};
