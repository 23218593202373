import { routes } from "app/routes";
import DocumentContext from "components/DocumentContext";
import { Switch, Route } from "react-router";
import { InterestGroupConfirmationPage } from "./InterestGroupConfirmationPage";
import { InterestGroupListPage } from "./InterestGroupListPage";
import { InterestGroupPage } from "./InterestGroupPage";

export const SetupInterestGroupFlow = (props: { location: { search: any; state: any } }) => {
    return (
        <>
            <DocumentContext title="Cloud Sangha" />
            <Switch>
                <Route
                    path={routes.setupConfirmInterestGroup(":groupId")}
                    component={InterestGroupConfirmationPage}
                />
                <Route
                    path={routes.setupAddInterestGroup(":groupId")}
                    component={InterestGroupPage}
                />

                <Route>
                    <InterestGroupListPage />
                </Route>
            </Switch>
        </>
    );
};
