import { createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";

export type FetchedObjectState<T> = {
    loading: boolean;
    loaded: boolean;
    error: string | null;
    result: T | undefined;
};

export type FetchedObjectMapState<T> = {
    [key: string]: FetchedObjectState<T>;
};

export const fetchedObjectFulfilledState = <T>(result: T | undefined) => ({
    loading: false,
    loaded: true,
    error: null,
    result: result,
});

export const fetchedObjectPendingState = <T>(priorResult?: T) => ({
    loaded: false,
    loading: true,
    error: null,
    result: priorResult || undefined,
});

export const fetchedObjectFailedState = (error: string) => ({
    loading: false,
    loaded: true,
    error: error,
    result: undefined,
});

export const defaultFetchedState = <T>() =>
    ({
        loading: false,
        loaded: false,
        error: null,
        result: undefined,
    } as FetchedObjectState<T>);

export const fetchedObjectResultForKey = <T>(
    queryResultMap: FetchedObjectMapState<T>,
    key: string,
): T | undefined => {
    return (queryResultMap[key] || {}).result;
};

export const fetchedObjectIsLoading = <T>(
    queryResultMap: FetchedObjectMapState<T>,
    key: string,
): boolean => {
    return (queryResultMap[key] || {}).loading;
};

export const fetchedObjectHasLoaded = <T>(
    queryResultMap: FetchedObjectMapState<T>,
    key: string,
): boolean => {
    return (queryResultMap[key] || {}).loaded;
};

export const fetchedObjectReducers = <T>(
    asyncAction: any,
    localStateSelector: (state: any) => FetchedObjectState<T>,
) => {
    return {
        [asyncAction.pending.toString()]: (state: any, action: any) => {
            const localState = localStateSelector(state);
            Object.assign(localState, fetchedObjectPendingState(localState.result));
        },
        [asyncAction.fulfilled.toString()]: (state: any, action: PayloadAction<T>) => {
            const localState = localStateSelector(state);
            Object.assign(localState, fetchedObjectFulfilledState(action.payload));
        },
        [asyncAction.rejected.toString()]: (state: any, action: any) => {
            const localState = localStateSelector(state);
            Object.assign(localState, fetchedObjectFailedState(action.error));
        },
    };
};

export const fetchedObjectMapReducers = <T>(
    asyncAction: any,
    localStateSelector: (state: any) => FetchedObjectMapState<T>,
    keyFn: (args: { [key: string]: any }) => string,
) => {
    return {
        [asyncAction.pending.toString()]: (state: any, action: any) => {
            const fetchedObjectMap = localStateSelector(state);
            const key = keyFn(action.meta.arg);
            if (!fetchedObjectMap[key]) {
                fetchedObjectMap[key] = defaultFetchedState();
            }
            const priorResult = fetchedObjectResultForKey(fetchedObjectMap, key);
            Object.assign(fetchedObjectMap[key], fetchedObjectPendingState(priorResult));
        },
        [asyncAction.fulfilled.toString()]: (state: any, action: any) => {
            const fetchedObjectMap = localStateSelector(state);
            const key = keyFn(action.meta.arg);
            fetchedObjectMap[key] = fetchedObjectFulfilledState(action.payload);
        },
        [asyncAction.rejected.toString()]: (state: any, action: any) => {
            const fetchedObjectMap = localStateSelector(state);
            const key = keyFn(action.meta.arg);
            fetchedObjectMap[key] = fetchedObjectFailedState(action.error);
        },
    };
};

export const createErrorLoggingAsyncThunk = createAsyncThunk;
