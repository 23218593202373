import { Typography } from "@mui/material";
import { routes } from "../../../app/routes";
import PageWrapper from "components/PageWrapper";
import { NavLink } from "../../navigation/NavLink";

export const MembershipNotFound = () => {
    return (
        <PageWrapper>
            <Typography variant="h2">Oops, we couldn't find what you're looking for!</Typography>
            <Typography variant="body1">
                We weren't able to locate your membership information. Please{" "}
                <NavLink to={routes.contactUs()}>contact us</NavLink> and we'll get this sorted out.
            </Typography>
        </PageWrapper>
    );
};
