import { alpha, Button, ButtonProps } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { LinkButton, LinkButtonProps } from "../features/navigation/LinkButton";
import ChevronLeftRoundedIcon from "@mui/icons-material/ChevronLeftRounded";
import { useHistory } from "react-router";

const useStyles = makeStyles((theme) => ({
    root: {
        marginBottom: theme.spacing(2),
        backgroundColor: alpha(theme.palette.grey700, 0.05),
        color: alpha(theme.palette.grey700, 0.5),
        padding: theme.spacing(1, 2),

        "&:hover": {
            backgroundColor: alpha(theme.palette.grey700, 0.1),
        },
    },
    startIcon: {
        marginRight: 0,
    },
}));

export const BackLinkButton = (props: LinkButtonProps) => {
    const classes = useStyles();

    return (
        <LinkButton
            variant="secondary"
            size="small"
            disableElevation
            className={classes.root}
            startIcon={<ChevronLeftRoundedIcon />}
            classes={{
                startIcon: classes.startIcon,
            }}
            {...props}
        >
            {props.children || "Back"}
        </LinkButton>
    );
};

export const BackButton = (props: ButtonProps) => {
    const classes = useStyles();

    const history = useHistory();

    return (
        <Button
            variant="secondary"
            size="small"
            disableElevation
            className={classes.root}
            startIcon={<ChevronLeftRoundedIcon />}
            classes={{
                startIcon: classes.startIcon,
            }}
            onClick={() => history.goBack()}
            {...props}
        >
            {props.children || "Back"}
        </Button>
    );
};
