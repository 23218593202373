import { useMutation, useQuery } from "@apollo/client";
import { DEFAULT_NOTIFICATION_FREQUENCY } from "@app/shared/constants";
import {
    NotificationFrequency,
    NotificationMode,
    NotificationPreference,
    NotificationType,
} from "@app/shared/types";
import { Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { analyticsTrack } from "app/analytics/track";
import {
    GRAPHQL_MUTATION_UPDATE_MESSAGE_NOTIFICATION_EMAIL_FREQUENCY,
    GRAPHQL_MUTATION_UPDATE_NOTIFICATION_PREFERENCE,
    GRAPHQL_QUERY_NOTIFICATION_PREFERENCES,
} from "app/queries";
import { GenericErrorMessage } from "components/GenericErrorMessage";
import { LoadingBarMinimal } from "components/LoadingBarMinimal";
import { ToggleChip } from "components/ToggleChip";
import { useEffect, useState } from "react";

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(6),
        borderRadius: theme.borderRadius.default,
        backgroundColor: theme.palette.background.contrast,
    },

    label: {
        "&:first-child": {
            marginTop: 0,
        },
        marginTop: theme.spacing(2),
    },
    notificationToggle: {
        margin: theme.spacing(0, 1, 1, 0),
    },
}));

const labels: Record<NotificationType, string> = {
    [NotificationType.SANGHA_MESSAGE]: "Group messages",
    [NotificationType.REFLECTION_QUESTION]: "Reflection question",
    [NotificationType.SESSION_REMINDER]: "Upcoming group reminders",
    [NotificationType.FEEDBACK]: "Surveys",

    // Teacher only notifications - not yet added to UI
    [NotificationType.ABSENCE_REVIEW]: "",
    [NotificationType.GROUP_CHANGE_AND_FEEDBACK_UPDATE]: "",
    [NotificationType.TEACHER_BILLING_UPDATE]: "",
};

const order: Record<NotificationType, number> = {
    [NotificationType.SANGHA_MESSAGE]: 0,
    [NotificationType.REFLECTION_QUESTION]: 1,
    [NotificationType.SESSION_REMINDER]: 2,
    [NotificationType.FEEDBACK]: 3,

    // Teacher only notifications - not yet added to UI
    [NotificationType.ABSENCE_REVIEW]: 100,
    [NotificationType.GROUP_CHANGE_AND_FEEDBACK_UPDATE]: 101,
    [NotificationType.TEACHER_BILLING_UPDATE]: 102,
};

const frequencyLabels: Record<NotificationFrequency, string> = {
    [NotificationFrequency.DAILY]: "Once a day",
    [NotificationFrequency.MULTIPLE_TIMES_PER_DAY]: "Several times a day",
    [NotificationFrequency.IMMEDIATE]: "As soon as they are posted",
};

const frequencyOptions = [
    NotificationFrequency.IMMEDIATE,
    NotificationFrequency.MULTIPLE_TIMES_PER_DAY,
    NotificationFrequency.DAILY,
];

export const NotificationPreferences = () => {
    const classes = useStyles();

    const [notificationPreferences, setNotificationPreferences] = useState<
        NotificationPreference[]
    >([]);

    const {
        data: notificationPreferenceData,
        loading,
        error,
    } = useQuery(GRAPHQL_QUERY_NOTIFICATION_PREFERENCES, {
        fetchPolicy: "network-only",
    });

    const [updateNotificationPreference] = useMutation(
        GRAPHQL_MUTATION_UPDATE_NOTIFICATION_PREFERENCE,
    );

    const [updateMessageNotificationEmailFrequency] = useMutation(
        GRAPHQL_MUTATION_UPDATE_MESSAGE_NOTIFICATION_EMAIL_FREQUENCY,
    );

    const [loaded, setLoaded] = useState(false);
    useEffect(() => {
        setNotificationPreferences(notificationPreferenceData?.notificationPreferences || []);
        if (notificationPreferenceData?.notificationPreferences && !loaded) {
            analyticsTrack("member.notificationPreferences.viewed");
            setLoaded(true);
        }
    }, [notificationPreferenceData?.notificationPreferences, loaded]);

    if (error) {
        return <GenericErrorMessage />;
    }

    if (loading) {
        return <LoadingBarMinimal />;
    }

    const emailPreferences = notificationPreferences
        .filter((preference) => preference.mode === NotificationMode.EMAIL)
        .sort((a, b) => order[a.type] - order[b.type]);

    const onChangePreference = (changedPref: NotificationPreference, newValue: boolean) => {
        updateNotificationPreference({
            variables: {
                id: changedPref.id,
                enabled: newValue,
            },
        });
        const updatedPreferences = [
            ...notificationPreferences.filter((x) => x.id !== changedPref.id),
            {
                ...changedPref,
                enabled: newValue,
            },
        ];
        setNotificationPreferences(updatedPreferences);
        analyticsTrack("member.notificationPreferences.email.changed", {
            type: changedPref.type,
            newValue,
        });
    };

    const onChangeMessageNotificationFrequency = (newFrequency: NotificationFrequency) => {
        updateMessageNotificationEmailFrequency({
            variables: { frequency: newFrequency },
        });
        const updatedPreferences = notificationPreferences.map((pref) =>
            pref.type === NotificationType.SANGHA_MESSAGE && pref.mode === NotificationMode.EMAIL
                ? {
                      ...pref,
                      frequency: newFrequency,
                  }
                : pref,
        );
        setNotificationPreferences(updatedPreferences);
        analyticsTrack("member.notificationPreferences.email.messageFrequency.changed", {
            newFrequency,
        });
    };

    const renderMessageFrequencyControls = () => {
        const messageEmailPreference = emailPreferences.find(
            (x) => x.type === NotificationType.SANGHA_MESSAGE,
        );

        if (!messageEmailPreference?.enabled) {
            return null;
        }

        const messageEmailFrequency =
            messageEmailPreference.frequency || DEFAULT_NOTIFICATION_FREQUENCY;

        return (
            <>
                <Typography variant="h3" className={classes.label} sx={{ mb: 2 }}>
                    Email me about my Mentorship group messages...
                </Typography>

                {frequencyOptions.map((frequency) => (
                    <div key={frequency}>
                        <ToggleChip
                            size="medium"
                            className={classes.notificationToggle}
                            data-testid="messageNotificationFrequency"
                            data-frequency={frequency}
                            label={frequencyLabels[frequency]}
                            selected={frequency === messageEmailFrequency}
                            onChange={(value: boolean) => {
                                if (value) {
                                    onChangeMessageNotificationFrequency(frequency);
                                }
                            }}
                        />
                    </div>
                ))}
            </>
        );
    };

    return (
        <div className={classes.root}>
            <Typography variant="h3" className={classes.label} sx={{ mb: 2 }}>
                What do you want to receive?
            </Typography>

            {emailPreferences.map((preference) => (
                <ToggleChip
                    size="medium"
                    data-testid="notificationPreference"
                    data-type={preference.type}
                    data-mode={preference.mode}
                    data-enabled={preference.enabled.toString()}
                    key={`${preference.mode}-${preference.type}`}
                    label={labels[preference.type]}
                    selected={preference.enabled}
                    onChange={(value: boolean) => onChangePreference(preference, value)}
                    className={classes.notificationToggle}
                />
            ))}

            {renderMessageFrequencyControls()}
        </div>
    );
};
