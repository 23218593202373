import { SanghaType, SessionType } from "@app/shared/types";
import { AdvancedImage, responsive } from "@cloudinary/react";
import { Avatar } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { cloudinary } from "app/cloudinary";
import classNames from "classnames";
import { FadeCarousel } from "./FadeCarousel";

const useStyles = makeStyles((theme) => ({
    card: {
        backgroundColor: theme.palette.grey100,
        color: theme.palette.grey800,
        padding: theme.spacing(3),
        paddingTop: theme.spacing(4),
        paddingRight: theme.spacing(4),
        borderRadius: theme.borderRadius.default,
        boxSizing: "border-box",
        [theme.breakpoints.down("md")]: {
            height: "100%",
        },
    },
    cancelledCard: {
        "& $contentWrapper": {
            opacity: 0.3,
        },
    },
    contentWrapper: {
        display: "flex",
        position: "relative",
        alignItems: "flex-start",
        flexDirection: "row",
        gap: theme.spacing(1),
        height: "100%",
        [theme.breakpoints.down("sm")]: {
            alignItems: "flex-start",
        },
    },
    cardImage: {
        width: 90,
        height: 90,
        borderRadius: theme.borderRadius.small,
        marginRight: theme.spacing(1),
        flexShrink: 0,
        objectFit: "cover",
    },
    content: {
        width: "100%",
        height: "100%",
    },
    actionBar: {
        textAlign: "right",
        [theme.breakpoints.down("md")]: {
            textAlign: "center",
        },
    },
}));

export const EventCard = (props: {
    pictures: string[];
    pictureOnClick?: () => void;
    information: React.ReactNode;
    eventType: SessionType | SanghaType;
    actionBar?: React.ReactNode;
    containerAttributes?: any;
    cancelled?: boolean;
    classes?: any;
}) => {
    const classes = useStyles(props);
    const {
        pictures,
        information,
        actionBar,
        containerAttributes,
        pictureOnClick,
        cancelled,
        eventType,
    } = props;

    const cloudinaryImage = cloudinary.image(pictures[0]).format("auto");

    return (
        <div
            className={classNames(classes.card, {
                [classes.cancelledCard]: cancelled,
            })}
            {...containerAttributes}
        >
            <div className={classes.contentWrapper}>
                {pictures.length > 1 ? (
                    <FadeCarousel
                        images={pictures}
                        interval={5000}
                        classes={{
                            container: classes.cardImage,
                        }}
                    />
                ) : eventType === "INTEREST_GROUP" ? (
                    <AdvancedImage
                        data-testid="cardImage"
                        cldImg={cloudinaryImage}
                        className={classes.cardImage}
                        onClick={pictureOnClick}
                        plugins={[responsive({ steps: 200 })]}
                    />
                ) : (
                    <Avatar
                        data-testid="teacherAvatar"
                        src={pictures[0]}
                        variant="rounded"
                        onClick={pictureOnClick}
                        classes={{
                            root: classes.cardImage,
                        }}
                        sx={{ width: 90, height: 90 }}
                    />
                )}

                <div className={classes.content}>{information}</div>
            </div>
            <div className={classes.actionBar}>{actionBar}</div>
        </div>
    );
};

export default EventCard;
