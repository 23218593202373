import makeStyles from "@mui/styles/makeStyles";
import { theme } from "app/theme";

const useStyles = makeStyles(() => ({
    accentBarContainer: {
        height: theme.spacing(4),
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(4),
    },
    accentBar: {
        position: "absolute",
        left: 0,
        width: "40%",
        height: theme.spacing(4),
        background: theme.palette.accentPeach,
        borderRadius: "0 30px 30px 0",
    },
}));

export const AccentBar = () => {
    const classes = useStyles();

    return (
        <div className={classes.accentBarContainer}>
            <div className={classes.accentBar} />
        </div>
    );
};
