import { Typography } from "@mui/material";
import Guidelines from "components/Guidelines";
import { ConfirmationDialogProps } from "../community/ButtonWithConfirmationDialogs";
import { PodAssignmentReminder } from "../community/PodAssignmentReminder";

export const getInterestGroupConfirmationDialogs = (
    sanghaId: string,
): ConfirmationDialogProps[] => {
    return [
        {
            localStorageKey: "interestGroupGuidelinesSeen",
            enableConfirmButtonDelay: 0,
            button: "I agree",
            content: (
                <>
                    <Typography variant="h1">Please take a moment to review</Typography>
                    <Guidelines />
                </>
            ),
        },
        {
            enableConfirmButtonDelay: 0,
            button: "Continue to session",
            content: (
                <>
                    <PodAssignmentReminder sanghaId={sanghaId} />
                </>
            ),
        },
    ];
};
