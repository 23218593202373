import makeStyles from "@mui/styles/makeStyles";
import { memo } from "react";

const useStyles = makeStyles((theme) => ({
    root: {
        ...theme.typography.body2,
        textAlign: "right",
        color: theme.palette.grey400,
        width: "100%",
        maxWidth: 690,
    },
}));

type Props = {
    value?: string;
    maxLength: number;
    classes?: any;
};

export const FormFieldTextCounter = memo((props: Props) => {
    const classes = useStyles(props);

    return (
        <div className={classes.root}>
            <strong>{props.maxLength - (props.value || "").length} characters remaining</strong>
        </div>
    );
});
