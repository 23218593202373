import { routes } from "app/routes";
import { ProgressBar } from "features/signup/ProgressBar";

export const SetupProgressBar = (props: {
    currentStep: number;
    onStepClick?: (step: number) => void;
}) => {
    enum SetupStep {
        COMMUNITY_GUIDELINES = "COMMUNITY_GUIDELINES",
        SET_PROFILE = "SET_PROFILE",
        CHOOSE_SESSION = "CHOOSE_SESSION",
        SHOW_AROUND = "SHOW_AROUND",
        CHOOSE_GROUP = "CHOOSE_GROUP",
    }

    const setupStepData = {
        [SetupStep.COMMUNITY_GUIDELINES]: {
            label: "Community Guidelines",
            route: routes.memberOnboarding(),
        },
        [SetupStep.SET_PROFILE]: {
            label: "Set your profile",
            route: routes.setupEditProfilePage(),
        },
        [SetupStep.CHOOSE_SESSION]: {
            label: "Choose a welcome session",
            route: routes.setupWelcomeSession(),
        },
        [SetupStep.SHOW_AROUND]: { label: "Show you around", route: routes.setupShowYouAround() },
        [SetupStep.CHOOSE_GROUP]: {
            label: "Choose your group",
            route: routes.setupAllInterestGroups(),
        },
    };

    const setupStepOrder = [
        SetupStep.COMMUNITY_GUIDELINES,
        SetupStep.SET_PROFILE,
        SetupStep.CHOOSE_SESSION,
        SetupStep.SHOW_AROUND,
        SetupStep.CHOOSE_GROUP,
    ];

    return (
        <ProgressBar
            {...props}
            stepData={setupStepData}
            stepOrder={setupStepOrder}
            nonClickableSteps={[SetupStep.COMMUNITY_GUIDELINES]}
        />
    );
};
