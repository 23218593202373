import { IntentionInput } from "@app/shared/types";
import styled from "@emotion/styled";
import ChevronLeftRoundedIcon from "@mui/icons-material/ChevronLeftRounded";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import {
    Card,
    CardActionArea,
    CardContent,
    Chip,
    Theme,
    Typography,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { analyticsTrack } from "app/analytics/track";
import { LoggedInUserProfileContext } from "features/auth/RequireAuthentication";
import { LinkButton } from "features/navigation/LinkButton";
import { useContext, useEffect, useRef, useState } from "react";
import Slider, { Settings } from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { RefreshIntentionButton } from "./RefreshIntentionButton";
import { SetIntentionButton } from "./SetIntentionButton";

const useStyles = makeStyles((theme) => ({
    card: {
        backgroundColor: theme.palette.neutralCool,
        color: theme.palette.grey900,
        borderRadius: 0,
        boxShadow: "none",

        "&:hover": {
            transform: "scale(1.025)",
            transition: "transform .3s",
            boxShadow: theme.shadow.spread,
        },
    },
    actionArea: {
        display: "flex",
        flexDirection: "column",
        cursor: "default",
        height: "100%",
    },
    cardOverlay: {
        position: "absolute",
        bottom: 0,
        width: "100%",
        height: "100%",
        background:
            "linear-gradient(355deg, rgba(0, 0, 0, 0.70) 15.94%, rgba(0, 0, 0, 0.38) 63.59%, rgba(0, 0, 0, 0.28) 85.51%)",
        zIndex: 2,
        [theme.breakpoints.down("md")]: {
            height: "100%",
        },
    },
    cardImage: {
        position: "absolute",
        height: "100%",
        width: "100%",
        objectFit: "cover",
        zIndex: 1,
    },
    announcementChip: {
        position: "absolute",
        top: 15,
        left: theme.spacing(5),
        zIndex: 4,
        lineHeight: "normal",
        padding: theme.spacing(1),
    },
    cardContent: {
        paddingRight: theme.spacing(3),
        paddingLeft: theme.spacing(3),
        paddingBottom: theme.spacing(5),
        marginTop: theme.spacing(5.5),
        display: "flex",
        justifyContent: "flex-end",
        flexDirection: "column",
        flex: "1 0 auto",
        boxSizing: "border-box",
        width: "100%",
        zIndex: 3,
        color: theme.palette.neutralWhite,
        minHeight: 470, // This height should be adjusted considering the card with the most content
    },
    announcementTitle: {
        marginBottom: theme.spacing(0),
    },
    bottomContainer: {
        boxSizing: "border-box",
        width: "100%",
    },
    announcementDescription: {
        marginBottom: theme.spacing(1),
    },
    announcementsContainer: {
        paddingLeft: theme.spacing(4),
        [theme.breakpoints.up("lg")]: {
            marginLeft: "auto",
            maxWidth: "calc(100% - (100% - 1272px) / 2)", // 1272px is the max width PageWrapper on the MemberHomePage, this component might not work as expected in other pages
        },
    },
    announcementsHeader: {
        color: theme.palette.grey800,
        marginTop: theme.spacing(2),
        [theme.breakpoints.down("md")]: {
            paddingLeft: theme.spacing(2),
        },
        [theme.breakpoints.down("sm")]: {
            paddingRight: theme.spacing(2),
        },
    },
    sliderContainer: {
        paddingBottom: theme.spacing(4),
        marginBottom: theme.spacing(6), // To avoid overlap with the OnboardingPopup

        [theme.breakpoints.down("md")]: {
            paddingLeft: theme.spacing(2),
        },
        [theme.breakpoints.down("sm")]: {
            paddingRight: theme.spacing(2),
        },
    },
}));

const StyleWrapper = styled.div<{ theme: Theme }>`
    .slick-arrow {
        height: 100%;
        width: 25px;
        z-index: 4;
        color: ${(props) => props.theme.palette.neutralWhite};
    }
    .slick-prev {
        left: 0;
        background: linear-gradient(
            267deg,
            rgba(0, 0, 0, 0) 7.74%,
            rgba(0, 0, 0, 0.7) 92.02%
        ) !important;
    }
    .slick-next {
        right: 0;
        background: linear-gradient(
            93deg,
            rgba(0, 0, 0, 0) 7.74%,
            rgba(0, 0, 0, 0.7) 92.02%
        ) !important;
    }
    .slick-prev:hover,
    .slick-prev:focus,
    .slick-next:hover,
    .slick-next:focus {
        color: ${(props) => props.theme.palette.neutralWhite};
        outline: none;
    }
`;

type Announcement = {
    title: string;
    description: string;
    ctaLabel: string;
    imageUrl: string;
    announcementTypeLabel: string;
    buttonURL?: string;
    customButton?: JSX.Element;
};

const AnnouncementCard = ({
    announcement,
    primaryChip,
    isTouchDevice,
}: {
    announcement: Announcement;
    primaryChip: boolean;
    isTouchDevice: boolean;
}) => {
    const classes = useStyles();
    const [hover, setHover] = useState(false);

    const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));

    return (
        <Card
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            className={classes.card}
            data-testid="announcementCard"
            raised={true}
        >
            <CardActionArea className={classes.actionArea}>
                <Chip
                    variant={primaryChip ? "lightGrey" : "darkGreen"}
                    className={classes.announcementChip}
                    label={announcement.announcementTypeLabel}
                />
                <CardContent className={classes.cardContent}>
                    <Typography variant="h4" className={classes.announcementTitle}>
                        {announcement.title}
                    </Typography>
                    {(hover || isMobile || isTouchDevice) && (
                        <div className={classes.bottomContainer}>
                            <Typography variant="body1" className={classes.announcementDescription}>
                                {announcement.description}
                            </Typography>
                            {announcement.customButton ? (
                                announcement.customButton
                            ) : (
                                <LinkButton
                                    to={announcement.buttonURL || ""}
                                    variant="primary"
                                    size="small"
                                    fullWidth={false}
                                    onClick={() =>
                                        analyticsTrack("Announcement Button Clicked", {
                                            label: announcement.ctaLabel,
                                            title: announcement.title,
                                            type: announcement.announcementTypeLabel,
                                            location: "AnnouncementCard",
                                        })
                                    }
                                >
                                    {announcement.ctaLabel}
                                </LinkButton>
                            )}
                        </div>
                    )}
                </CardContent>
                <img src={announcement.imageUrl} className={classes.cardImage} />
                <div className={classes.cardOverlay} />
            </CardActionArea>
        </Card>
    );
};

export const AnnouncementsCarousel = (props: { intention: IntentionInput }) => {
    const { intention } = props;
    const classes = useStyles();
    const styleWrapperRef = useRef<HTMLDivElement>(null);
    const theme = useTheme();

    const [isTouchDevice, setIsTouchDevice] = useState(false);

    const user = useContext(LoggedInUserProfileContext);
    const hasIntention = Boolean(props?.intention?.fullIntention);

    useEffect(() => {
        const isTouch = "ontouchstart" in window || navigator.maxTouchPoints > 0;
        setIsTouchDevice(isTouch);
    }, []);

    const customIntentionButton = hasIntention ? (
        <RefreshIntentionButton
            userId={user.id}
            userEmail={user.email}
            customPathGoals={intention?.customPathGoals}
            fullIntention={intention?.fullIntention}
        />
    ) : (
        <SetIntentionButton size="small" userId={user.id} userEmail={user.email} showIcon={false} />
    );

    const annoucementItems: Announcement[] = [
        {
            title: "TRY ME: The Banyan Lounge",
            description:
                "Introducing The Banyan Lounge—a private space where members connect, learn, and practice anytime outside weekly groups. Explore community posts, directory, private messaging, and more!",
            ctaLabel: "Join the Lounge",
            imageUrl: "https://assets.cloudsangha.co/images/announcement-cards/greenFlorest.jpg",
            buttonURL: "https://banyantogether.com/member/lounge",
            announcementTypeLabel: "Share Your Thoughts",
        },
        {
            title: "Intention Setting + Tracking",
            description:
                "Discover how far you've come with our intention-setting tool, designed to help you reflect on your unique journey and celebrate your personal growth each month.",
            ctaLabel: "Learn More",
            imageUrl: "https://assets.cloudsangha.co/images/announcement-cards/sunset.png",
            announcementTypeLabel: "Tools for Growth",
            customButton: customIntentionButton,
        },
        {
            title: "NEW! LGBTQIA+ Affinity Group",
            description:
                "Join our LGBTQIA+ Affinity Group, led by Eileen Tupaz, to find support, share insights, and build meaningful connections with others who share your experiences.",
            ctaLabel: "Explore This Group",
            imageUrl: "https://assets.cloudsangha.co/images/announcement-cards/heart.png",
            buttonURL:
                "https://banyantogether.com/member/mindful-friend-groups/group/1Wa81sjtURqWRDe8pzbG",
            announcementTypeLabel: "Featured",
        },
    ];

    const sliderSettings: Settings = {
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        accessibility: true,
        arrows: true,
        prevArrow: <ChevronLeftRoundedIcon />,
        nextArrow: <ChevronRightRoundedIcon />,
        responsive: [
            {
                breakpoint: 960,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };
    return (
        <div className={classes.announcementsContainer}>
            <Typography sx={{ mt: 5 }} className={classes.announcementsHeader} variant="h3">
                What is new
            </Typography>
            <StyleWrapper
                theme={theme}
                ref={styleWrapperRef}
                style={{ height: "100%" }}
                className={classes.sliderContainer}
            >
                <Slider {...sliderSettings}>
                    {annoucementItems.map((announcement, index) => (
                        <AnnouncementCard
                            key={index}
                            announcement={announcement}
                            primaryChip={index % 2 === 0 ? true : false}
                            isTouchDevice={isTouchDevice}
                        />
                    ))}
                </Slider>
            </StyleWrapper>
        </div>
    );
};
