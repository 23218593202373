import { Box, Typography } from "@mui/material";
import { analyticsTrack } from "app/analytics/track";
import PageWrapper from "components/PageWrapper";
import { useState } from "react";
import { ButtonWithConfirmationDialogs } from "../community/ButtonWithConfirmationDialogs";
import { getInterestGroupConfirmationDialogs } from "./getInterestGroupConfirmationDialogs";

// Used to test specific issues where members are unable to get past the community guidelines box
export const InterestGroupConfirmationDialogTest = () => {
    const [success, setSuccess] = useState(false);

    const alreadySeen = localStorage.getItem("interestGroupGuidelinesSeen");

    if (alreadySeen || success) {
        return (
            <PageWrapper>
                <Typography variant="h2">Accepted community guidelines already</Typography>
            </PageWrapper>
        );
    }

    return (
        <PageWrapper>
            <Box>
                <ButtonWithConfirmationDialogs
                    onConfirm={() => setSuccess(true)}
                    onOpenDialog={(dialogIndex) => {
                        analyticsTrack("interestGroup.confirmationDialog.test.open", {
                            dialogIndex,
                        });
                    }}
                    confirmationDialogs={[getInterestGroupConfirmationDialogs("test")[0]]}
                >
                    Test community guidelines popup
                </ButtonWithConfirmationDialogs>
            </Box>
        </PageWrapper>
    );
};
