import { Teacher, UserProfile } from "@app/shared/types";
import { Box, Button, Tooltip, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { theme } from "app/theme";
import classNames from "classnames";
import { CSDialog } from "components/CSDialog";
import _ from "lodash";
import { useState } from "react";

interface MemberCardProps {
    userProfile: UserProfile;
}

const CARD_IMAGE_HEIGHT = 270;

const useStyles = makeStyles((theme) => ({
    card: {
        position: "relative",
        borderRadius: theme.borderRadius.default,
        boxShadow: theme.shadow.default,
        flexGrow: 1,
        backgroundColor: theme.palette.grey100,
    },
    teacherImage: {
        width: "100%",
        height: CARD_IMAGE_HEIGHT,
        borderRadius: theme.borderRadius.default,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        objectFit: "cover",
        objectPosition: "center",
    },
    cardContent: {
        padding: theme.spacing(1, 3, 2, 3),
    },
    infoTextBox: {
        display: "flex",
        alignItems: "center",
    },
    infoIcons: {
        fontSize: theme.typography.pxToRem(18),
        marginRight: theme.spacing(1),
    },
}));

export const MemberCard = (props: MemberCardProps) => {
    const { userProfile } = props;
    const classes = useStyles();

    const [isMemberDialogOpen, setIsMemberDialogOpen] = useState(false);

    const shortenedBio = _.truncate(userProfile.bio || "", {
        length: 90,
        separator: " ",
    });

    return (
        <>
            <Box className={classes.card}>
                <img
                    className={classes.teacherImage}
                    src={userProfile.picture || undefined}
                    alt="member"
                />
                <Box className={classes.cardContent}>
                    <Typography variant="h3" sx={{ mb: 0.5 }}>
                        {userProfile.nickName}
                    </Typography>
                    {!!userProfile.location && (
                        <Typography
                            variant="body1"
                            className={classes.infoTextBox}
                            sx={{ mb: 0.5 }}
                        >
                            <span
                                className={classNames(
                                    "material-symbols-rounded",
                                    classes.infoIcons,
                                )}
                            >
                                location_on
                            </span>
                            {userProfile.location}
                        </Typography>
                    )}
                    {shortenedBio.length > 0 && (
                        <Typography variant="body1">{shortenedBio}</Typography>
                    )}
                    <Button
                        variant="tertiary"
                        onClick={() => setIsMemberDialogOpen(true)}
                        data-testid="learnMoreButton"
                    >
                        Learn more
                    </Button>
                </Box>
            </Box>
            <CSDialog open={isMemberDialogOpen} onClose={() => setIsMemberDialogOpen(false)}>
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        gap: 4,
                        flexDirection: {
                            xs: "column",
                            md: "row",
                        },
                    }}
                >
                    <img
                        src={userProfile.picture || undefined}
                        alt="member"
                        style={{
                            width: "280px",
                            height: "280px",
                            objectFit: "cover",
                            borderRadius: theme.borderRadius.default,
                            flexShrink: 0,
                        }}
                    />
                    <Box sx={{ flexGrow: 1 }}>
                        <Typography variant="h2" sx={{ mb: 0 }}>
                            {userProfile.nickName}
                        </Typography>
                        {!!userProfile.pronouns && (
                            <Typography
                                variant="body1"
                                sx={{ mb: 0.5, color: theme.palette.grey700 }}
                            >
                                {userProfile.pronouns}
                            </Typography>
                        )}
                        {!!userProfile.location && (
                            <Typography
                                variant="body1"
                                className={classes.infoTextBox}
                                sx={{ mb: 1 }}
                            >
                                <span
                                    className={classNames(
                                        "material-symbols-rounded",
                                        classes.infoIcons,
                                    )}
                                >
                                    location_on
                                </span>
                                {userProfile.location}
                            </Typography>
                        )}
                        {!!userProfile.bio && (
                            <Typography variant="body1">{userProfile.bio}</Typography>
                        )}
                    </Box>
                </Box>
            </CSDialog>
        </>
    );
};
