import { Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { CSTextField } from "components/CSTextField";
import { CancellationFlow } from "./CancellationFlow";
import { routes } from "app/routes";
import { push } from "connected-react-router";
import { useDispatch } from "react-redux";
import { useState, useCallback } from "react";
// import { useMutation } from "@apollo/client";
// import { GRAPHQL_MUTATION_SET_CANCELLATION_RESPONSE } from "app/queries";

const useStyles = makeStyles((theme) => ({
    textInput: {
        marginTop: theme.spacing(4),
        [theme.breakpoints.down("xl")]: {
            paddingBottom: theme.spacing(4),
        },
    },
}));

export const CancellationFlowPage5 = (props: {
    saveData: (data: { doneDifferently: string }) => void;
}): JSX.Element => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [response, setResponse] = useState<string>("");
    const handleNext = useCallback(() => {
        if (response) {
            props.saveData({
                doneDifferently: response,
            });
        }
        dispatch(push(routes.cancelMembershipPage6()));
    }, [dispatch, props, response]);
    const handleResponseChange = useCallback(
        (value: string) => {
            setResponse(value);
        },
        [setResponse],
    );

    return (
        <CancellationFlow
            onNext={handleNext}
            pageId="doneDifferently"
            continueButtonText="Continue"
            content={
                <>
                    <Typography variant="body1">No problem. </Typography>
                    <Typography variant="h2">
                        Is there anything we could have done differently to meet your needs?
                    </Typography>
                    <CSTextField
                        label={""}
                        value={response}
                        multiline={true}
                        rows={5}
                        onChange={handleResponseChange}
                        placeholder="Optional"
                        className={classes.textInput}
                    />
                </>
            }
        />
    );
};
