import { darken, Paper, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { LIMITED_WIDTH } from "app/styles";
import { displayPrice } from "components/displayPrice";
import { DateTime } from "luxon";

const useStyles = makeStyles((theme) => ({
    root: {
        marginBottom: theme.spacing(2),
        padding: theme.spacing(6),
        borderRadius: theme.borderRadius.default,
        transition: "background-color 300ms ease",
        backgroundColor: theme.palette.background.contrast,
        maxWidth: LIMITED_WIDTH,
    },
    priceLine: {
        marginTop: 0,
        marginBottom: theme.spacing(1),
    },

    title: {
        display: "inline-block",
        marginRight: theme.spacing(3),
    },
}));

export const FreeTrialMembershipExplanation = (props: {
    freeTrialEndDate: DateTime;

    basePrice: number;
    billingPeriod: string;
    classes?: any;
}) => {
    const classes = useStyles(props);
    const { basePrice, billingPeriod } = props;

    const numDaysRemaining = Math.ceil(
        props.freeTrialEndDate.diff(DateTime.now(), "days").toObject().days ?? 1,
    ); //=> { days: 1 }

    const getBillingText = (billingPeriod: string) => {
        switch (billingPeriod) {
            case "year":
                return "annual";
            case "month":
                return "monthly";
            default:
                return billingPeriod;
        }
    };

    return (
        <Paper elevation={0} className={classes.root} data-testid="freeTrialMembershipExplanation">
            <div>
                <Typography variant="h4" className={classes.title}>
                    Free Trial
                </Typography>
                <Typography variant="body1" className={classes.title}>
                    {numDaysRemaining} {numDaysRemaining > 1 ? "days" : "day"} remaining
                </Typography>
            </div>

            <Typography variant="body1">
                You’re paying <strong>{displayPrice(0)}</strong>. You chose the{" "}
                {getBillingText(billingPeriod)} plan, so your first bill will be{" "}
                <strong>{displayPrice(basePrice)}</strong> on{" "}
                {props.freeTrialEndDate.toLocaleString(DateTime.DATE_MED)}.
            </Typography>
        </Paper>
    );
};

export default FreeTrialMembershipExplanation;
