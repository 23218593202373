import { Sangha, SanghaSessionCycle } from "@app/shared/types";
import {
    firstSessionForScheduleAfterDate,
    formatTimestamp,
    getFormattedDate,
    nthMonthlyWeekdayForDate,
} from "@app/shared/utils";
import { Typography } from "@mui/material";
import { renderUserTimezone } from "features/member/community/shared";
import _ from "lodash";
import { DateTime, DateTimeFormatOptions } from "luxon";

const timeOfDay = (date: DateTime, timezone?: string, format?: DateTimeFormatOptions) =>
    getFormattedDate(date, timezone, format);

const dayOfWeek = (date: DateTime, timezone?: string) =>
    formatTimestamp(date.toMillis(), {
        weekday: "long",
        timeZone: timezone || date.zoneName,
    });

export const SanghaDayAndTimeDescription = (props: {
    sangha: Sangha;
    overrideStartTime?: DateTime;
    overrideEndTime?: DateTime;
    userTimeZone: string;
    renderTimeZone?: boolean;
}) => {
    const { overrideStartTime, overrideEndTime, userTimeZone, sangha, renderTimeZone } = props;

    const sanghaCycle = sangha.cycle;
    const sanghaTimeZone = sangha.timeZone;

    const getNextSession = () =>
        firstSessionForScheduleAfterDate(
            DateTime.fromISO(sangha.firstSessionDate),
            DateTime.local(),
            sanghaTimeZone,
            sanghaCycle,
        );

    const sessionStartTime = overrideStartTime || getNextSession();
    const sessionEndTime = overrideEndTime || getNextSession().plus({ minutes: sangha.duration });

    const originalDayOfWeek = dayOfWeek(sessionStartTime, sanghaTimeZone);
    const localDayOfWeek = dayOfWeek(sessionStartTime, userTimeZone);
    const localWeekdayDiffersFromOriginal = localDayOfWeek !== originalDayOfWeek;

    const timeFormat: DateTimeFormatOptions = {
        hour: "2-digit",
        minute: "2-digit",
    };
    const localTimeOfDayStart = timeOfDay(sessionStartTime, userTimeZone, timeFormat);
    const localTimeOfDayEnd = timeOfDay(sessionEndTime, userTimeZone, timeFormat);

    const dayDescription = () => {
        if (sanghaCycle === SanghaSessionCycle.weekly) {
            return `${localDayOfWeek}s`;
        } else if (sanghaCycle === SanghaSessionCycle.monthly) {
            if (localWeekdayDiffersFromOriginal) {
                return `a ${localDayOfWeek}`;
            } else {
                const weekOfMonth = nthMonthlyWeekdayForDate(sessionStartTime);
                const weekOfMonthDisplay = {
                    1: "first",
                    2: "second",
                    3: "third",
                    4: "fourth",
                    5: "fifth",
                }[weekOfMonth];

                return `${_.upperFirst(weekOfMonthDisplay)} ${localDayOfWeek}s`;
            }
        } else if (sanghaCycle === SanghaSessionCycle.twiceMonthly) {
            if (localWeekdayDiffersFromOriginal) {
                return `a ${localDayOfWeek}`;
            } else {
                const weekOfMonth = nthMonthlyWeekdayForDate(sessionStartTime);

                const weekOfMonthDisplay = {
                    1: "first and third",
                    2: "second and fourth",
                    3: "first and third",
                    4: "second and fourth",
                    5: "",
                }[weekOfMonth];

                return `${_.upperFirst(weekOfMonthDisplay)} ${localDayOfWeek}s`;
            }
        }
    };

    return (
        <Typography variant="caption">
            {dayDescription()}, {localTimeOfDayStart} - {localTimeOfDayEnd}{" "}
            {renderTimeZone ? renderUserTimezone(userTimeZone) : undefined}
        </Typography>
    );
};
