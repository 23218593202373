import { Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import userCircleIcon from "images/icons/user-circle.svg";
import heartIcon from "images/icons/heart.svg";
import meditationIcon from "images/icons/meditation.svg";

const useStyles = makeStyles((theme) => ({
    modalRow: {
        display: "flex",
        alignItems: "center",

        "& p": {
            margin: theme.spacing(2, 0),
        },
        marginBottom: 30,
    },

    modalRowImage: {
        width: 72,
        textAlign: "center",
        marginRight: 30,
    },
}));
export const MembershipChargeExplanationPopup = () => {
    const classes = useStyles();

    return (
        <div>
            <Typography variant="h3">How is my contribution used?</Typography>

            <Typography variant="body1">
                You help us keep this community running and growing in these ways:
            </Typography>

            <div className={classes.modalRow}>
                <div className={classes.modalRowImage}>
                    <img src={meditationIcon} alt="" />
                </div>
                <Typography variant="body1">
                    <strong>Paying our meditation teachers</strong>
                    <br />A large portion of your contribution goes towards compensating our
                    skillful teachers fairly.
                </Typography>
            </div>
            <div className={classes.modalRow}>
                <div className={classes.modalRowImage}>
                    <img src={heartIcon} alt="" />
                </div>
                <Typography variant="body1">
                    <strong>Helping people who can’t afford memberships on their own</strong>
                    <br />
                    When you pay more than the monthly base membership fee, you help cover
                    membership costs for people who want to join our community but currently can’t
                    afford to.
                </Typography>
            </div>
            <div className={classes.modalRow}>
                <div className={classes.modalRowImage}>
                    <img src={userCircleIcon} alt="" />
                </div>
                <Typography variant="body1">
                    <strong>Supporting our staff and infrastructure</strong>
                    <br />
                    You help us pay our expert team and maintain and build our infrastructure.
                </Typography>
            </div>
        </div>
    );
};

export default MembershipChargeExplanationPopup;
