import { useQuery } from "@apollo/client";
import { GRAPHQL_QUERY_POD_ASSIGNMENT } from "app/queries";

export const usePodAssignmentNumber = (sanghaId: string) => {
    const { data, loading } = useQuery(GRAPHQL_QUERY_POD_ASSIGNMENT, {
        pollInterval: 1000 * 60 * 30, // refresh pod assignment since these may change 2 hours before the session starts
        fetchPolicy: "network-only",
        variables: {
            sanghaId,
        },
    });

    const podAssignment = data?.podAssignment as number | undefined;

    return { loading, podAssignment };
};
