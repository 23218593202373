import { CircularProgress, Typography } from "@mui/material";
import PageWrapper from "components/PageWrapper";
import { useLocation } from "react-router";
import { MentorshipGroupList } from "./MentorshipGroupList";
import { isUserInSetupFlow } from "features/signup/signupHelpers";
import { SetupProgressBar } from "features/setup/SetupProgressBar";
import { MentorshipGroupFilter } from "./MentorshipGroupFilter";
import { useQueryParams } from "use-query-params";
import { CustomArrayParam } from "../filters/customQueryParamDefinitions";
import { GRAPHQL_QUERY_AVAILABLE_GROUPS } from "app/queries";
import { useQuery } from "@apollo/client";
import { GenericErrorMessage } from "components/GenericErrorMessage";
import { Sangha } from "@app/shared/types";
import { usePreferredGroup } from "hooks/usePreferredGroup";
import { filterSanghas } from "../filters/filterHelpers";
import { useUserTimezone } from "hooks/useUserTimezone";
import LoadingPage from "features/pages/LoadingPage";

export const AddOnMentorshipGroupPage = () => {
    const location = useLocation();
    const isInSetupFlow = isUserInSetupFlow(location);
    const timezone = useUserTimezone();

    const [query, setQuery] = useQueryParams({
        timeOfDay: CustomArrayParam,
        dayOfWeek: CustomArrayParam,
        teacher: CustomArrayParam,
        audience: CustomArrayParam,
    });

    const preferredGroupId = usePreferredGroup();

    const {
        data: groupData,
        loading: groupsLoading,
        error: groupsError,
    } = useQuery(GRAPHQL_QUERY_AVAILABLE_GROUPS);

    if (groupsError) {
        return <GenericErrorMessage />;
    }

    let availableGroups: Sangha[] | undefined = groupData?.availableImmersionGroups;

    if (groupsLoading || !availableGroups) {
        return <LoadingPage />;
    }

    availableGroups = availableGroups
        .filter((x) => x.nextSession)
        .sort((a, b) => b.openSpots - a.openSpots);

    // List preferred group on top
    const preferredGroups = availableGroups.filter((x) => x.id === preferredGroupId);
    if (preferredGroups.length > 0) {
        availableGroups = [
            ...preferredGroups,
            ...availableGroups.filter((x) => x.id !== preferredGroupId),
        ];
    }

    const resetFilters = () => {
        setQuery({
            timeOfDay: undefined,
            dayOfWeek: undefined,
            teacher: undefined,
            audience: undefined,
        });
    };

    const filteredSanghas = availableGroups
        ? filterSanghas(availableGroups, query, timezone)
        : undefined;

    return (
        <>
            {isInSetupFlow && <SetupProgressBar currentStep={4} />}
            <PageWrapper>
                <Typography variant="h2">
                    Add a mentorship group to deepen your practice.
                </Typography>

                <Typography variant="body1">
                    Meet regularly with a small group and a teacher to learn, support each other,
                    and keep your practice consistent.
                </Typography>

                <MentorshipGroupFilter
                    filters={query}
                    setFilters={setQuery}
                    groupCount={0}
                    resetFilters={resetFilters}
                    groups={availableGroups}
                />

                <MentorshipGroupList groups={filteredSanghas || availableGroups} />
            </PageWrapper>
        </>
    );
};
