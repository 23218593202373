import { User } from "@app/shared/types";
import { analyticsTrack } from "app/analytics/track";
import LoadingPage from "features/pages/LoadingPage";
import { useEffect, useState } from "react";
import * as React from "react";
import { useSelector } from "react-redux";
import { Redirect, useLocation } from "react-router";
import { routes } from "../../app/routes";
import { selectAuthAndUserLoadCompleted, selectUser } from "./auth";

export const LoggedInUserProfileContext = React.createContext({} as User);

export const RequireAuthentication = function ({ children }: { children: React.ReactNode }): any {
    const location = useLocation();

    const authComplete = useSelector(selectAuthAndUserLoadCompleted);
    const user = useSelector(selectUser);

    const [eventLogged, setEventLogged] = useState(false);
    useEffect(() => {
        if (!user && authComplete && !eventLogged) {
            analyticsTrack(`page.unauthenticated`, {
                page: location.pathname,
            });
            setEventLogged(true);
        }
    }, [user, authComplete, eventLogged, location]);

    // [!user] is added here to prevent resetting the page on auth token refreshes
    // See: https://github.com/cloudsangha/cloudsangha/pull/323
    if (!user && !authComplete) {
        return <LoadingPage />;
    }

    if (!user) {
        return (
            <Redirect
                to={{
                    pathname: routes.login(),
                    state: {
                        from: location.pathname,
                        params: location.search,
                        hash: location.hash,
                    },
                }}
            />
        );
    }

    return (
        <LoggedInUserProfileContext.Provider value={user}>
            {children}
        </LoggedInUserProfileContext.Provider>
    );
};
