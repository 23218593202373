import { useQuery } from "@apollo/client";
import { SessionType } from "@app/shared/types";
import { GRAPHLQ_QUERY_HAS_USER_ATTENDED_SESSION_TYPE } from "app/queries";

export const useHasUserAttendedSessionType = (sessionType: SessionType, skip?: boolean) => {
    const { data, loading } = useQuery(GRAPHLQ_QUERY_HAS_USER_ATTENDED_SESSION_TYPE, {
        variables: { sessionType },
        skip,
    });

    if (skip) {
        return { loading: false, attended: true };
    }

    const hasUserAttendedSessionType: boolean = data?.hasUserAttendedSessionType ?? false;
    return { loading, attended: hasUserAttendedSessionType };
};
