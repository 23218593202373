import { Sangha } from "@app/shared/types";

export interface TeacherPostSessionFlowData {
    recap: {
        message: string;
        signature: string;
    };
    reflectionQuestion: string;
}

export enum TeacherPostSessionFlowStep {
    RECAP,
    REFLECTION_QUESTION,
    SUBMIT,
}

export type OnSubmitStepFunction = (nextStep: TeacherPostSessionFlowStep) => void;

export type OnChangeFunction = (newData: Partial<TeacherPostSessionFlowData>) => void;

export interface StepProps {
    sangha: Sangha;
    teacherName: string;
    data: TeacherPostSessionFlowData;
    onChange: OnChangeFunction;
    onSubmitStep: OnSubmitStepFunction;
}
