import { Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router";
import { routes } from "../../app/routes";
import DocumentContext from "components/DocumentContext";
import LoadingPage from "../pages/LoadingPage";
import PageWrapper from "components/PageWrapper";
import { NavLink } from "../navigation/NavLink";
import {
    selectAuthAndUserLoadCompleted,
    selectIsLoggedIn,
    selectLoginTargetParams,
    selectLoginTargetPath,
    setLoginTargetPath,
} from "./auth";
import { LoginForm } from "./LoginForm";
import { BRAND_NAME } from "@app/shared/constants";

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        padding: theme.spacing(4, 8),
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        [theme.breakpoints.down("sm")]: {
            paddingTop: 0,
        },
    },
}));

export const afterLoginRedirectPath = (
    fromPath: string | null,
    urlParams: string | null,
    urlHash: string | null,
    presetTargetPath: string | null,
    presetTargetParams: string | null,
): {} => {
    if (fromPath) {
        return urlParams
            ? { pathname: fromPath, search: urlParams, hash: urlHash }
            : { pathname: fromPath, hash: urlHash };
    } else if (presetTargetPath) {
        return presetTargetParams
            ? { pathname: presetTargetPath, search: presetTargetParams }
            : { pathname: presetTargetPath };
    } else {
        return { pathname: routes.memberZone() };
    }
};

export const Login = (props: { location: { search: string | null; state: any } }): JSX.Element => {
    const redirectWhenAlreadyLoggedIn = true;
    const authComplete = useSelector(selectAuthAndUserLoadCompleted);
    const isLoggedIn = useSelector(selectIsLoggedIn);
    const presetTargetPath = useSelector(selectLoginTargetPath);
    const presetTargetParams = useSelector(selectLoginTargetParams);

    const fromPath = (props.location.state || {}).from;
    const urlParams = (props.location.state || {}).params;
    const urlHash = (props.location.state || {}).hash;
    const dispatch = useDispatch();
    useEffect(() => {
        if (fromPath) {
            dispatch(
                setLoginTargetPath({
                    targetPath: fromPath,
                    params: urlParams,
                    hash: urlHash,
                }),
            );
        }
    }, [dispatch, fromPath, urlParams, urlHash]);

    const classes = useStyles();

    if (isLoggedIn && redirectWhenAlreadyLoggedIn) {
        return (
            <Redirect
                to={afterLoginRedirectPath(
                    fromPath,
                    urlParams,
                    urlHash,
                    presetTargetPath,
                    presetTargetParams,
                )}
            />
        );
    }

    if (!authComplete) {
        return <LoadingPage />;
    }

    return (
        <PageWrapper className={classes.paper} maxWidth="sm">
            <DocumentContext
                addBrandNameSuffix
                title="Login"
                description={`Login to ${BRAND_NAME} to join the community in meditations, talk to teachers, and go deeper in intimate groups with dedicated teachers.`}
            />
            <Typography variant="h2">Welcome back!</Typography>

            <Typography>
                Not a member yet?{" "}
                <NavLink to={routes.signup()} data-testid="signUpLink">
                    Sign up
                </NavLink>
            </Typography>

            <LoginForm />
        </PageWrapper>
    );
};
