import { CONFLICT_RESOLUTION_EMAIL } from "@app/shared/constants";
import { Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { routes } from "app/routes";
import DocumentContext from "components/DocumentContext";
import PageWrapper from "components/PageWrapper";
import { NavLink } from "features/navigation/NavLink";

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(4),
    },
}));

export const ConflictResolutionPage = () => {
    const classes = useStyles();

    return (
        <PageWrapper className={classes.root}>
            <DocumentContext title="Navigating Issues with Care" addBrandNameSuffix />
            <Typography variant="h1">Confidential Support For Conflict Resolution</Typography>

            <Typography variant="h2">The support we offer</Typography>

            <Typography variant="body1">
                Within our global community, we come from a variety of backgrounds and life
                experiences. To create an environment where we can build trust and share
                authentically & vulnerably, it’s important to have some shared agreements about how
                to relate mindfully. Our{" "}
                <NavLink to={routes.communityGuidelines()}>
                    Community Guidelines for Mindful Relating
                </NavLink>{" "}
                help to guide and connect us along this journey. For many of us, practicing wise
                speech and deep listening is a skill we haven’t been taught. Even when we have been
                taught, it takes lots of practice, and we can't expect ourselves or others to be
                perfect. That said, if you experience something in a session that feels
                inappropriate or unsafe, please reach out to us confidentially at{" "}
                <NavLink href={`mailto:${CONFLICT_RESOLUTION_EMAIL}`}>
                    {CONFLICT_RESOLUTION_EMAIL}
                </NavLink>
                . We're here to offer support. 🙏🏾💗
            </Typography>

            <Typography variant="h2">How it works</Typography>

            <Typography variant="body1">
                When a member reaches out to our{" "}
                <NavLink href={`mailto:${CONFLICT_RESOLUTION_EMAIL}`}>
                    {CONFLICT_RESOLUTION_EMAIL}
                </NavLink>{" "}
                email, it goes directly to our Community Operations/Support Manager. The email is
                reviewed and depending on the nature of the concern, the Support Manager will reach
                out to other members involved. For example, if you reach out because you are
                concerned that another member is having a hard time not using “I” statements, or is
                managing the breakout room in a way that feels inappropriate, we may ask to have a
                call with you to better understand what occurred, and then we will reach out to the
                other member to share the feedback confidentially and work with them to recommit to
                the community guidelines around mindful relating. We recognize there may be times
                when the Banyan community does not give the support that a member needs. Banyan is
                not equipped and is not the space to process active trauma or address serious mental
                health concerns. If a member is unable to recommit to or relate within the community
                guidelines, we will ask that they seek additional outside support before they rejoin
                the Banyan community.
            </Typography>
        </PageWrapper>
    );
};

export default ConflictResolutionPage;
