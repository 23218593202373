import {
    ApolloClient,
    ApolloLink,
    createHttpLink,
    InMemoryCache,
    NormalizedCacheObject,
    Observable,
    ServerError,
} from "@apollo/client";
import { RetryLink } from "@apollo/client/link/retry";
import * as Sentry from "@sentry/react";
import { onError } from "@apollo/client/link/error";
import { SentryLink } from "apollo-link-sentry";
import ApolloLinkTimeout from "apollo-link-timeout";
import { GRAPHQL_MUTATION_REFRESH_AUTH_TOKEN } from "app/queries";
import {
    selectAuthToken,
    selectLoginAs,
    selectRefreshToken,
    updateAuthToken,
} from "features/auth/auth";
import { OperationDefinitionNode } from "graphql";
import _, { isString } from "lodash";
import { analyticsTrack } from "./analytics/track";
import { isProduction, isRunningCypress } from "./config";
import { generateId } from "./generate-id";
import store from "./store";

const REQUEST_TIMEOUT_MS = 7000;
const TIMEOUT_INCREASE_PER_RETRY_MS = 9000;
// Override timeouts for unusually long running queries/mutations here
const timeoutOverrides: Record<string, number> = {
    SubmitTeacherPostSessionData: 60000,
};

const INITIAL_RETRY_DELAY_MS = 1000;
const MAX_RETRY_DELAY_MS = 60000;
const MAX_RETRIES = 4;
const ERRORS_TO_RETRY = [
    "The network connection was lost.",
    "Timeout exceeded",
    "NetworkError when attempting to fetch resource.",
    "Failed to fetch",
    "The Internet connection appears to be offline.",
];

let apolloClient: ApolloClient<NormalizedCacheObject>;

const promiseToObservable = (promise: Promise<unknown>) => {
    return new Observable((subscriber) => {
        promise.then(
            (value) => {
                if (subscriber.closed) {
                    return;
                }
                subscriber.next(value);
                subscriber.complete();
            },
            (err) => {
                subscriber.error(err);
            },
        );
    });
};

const apolloServerUri = process.env.REACT_APP_APOLLO_SERVER_URL;

const httpLink = createHttpLink({
    uri: (operation) => {
        const { requestProperties } = operation.getContext();
        return `${apolloServerUri}?requestId=${requestProperties?.requestId}`;
    },
});

const timeoutLink = new ApolloLinkTimeout(REQUEST_TIMEOUT_MS);

const createErrorLink = ({
    analyticsPrefix,
    handleRefreshToken,
}: {
    analyticsPrefix: string;
    handleRefreshToken: boolean;
}) =>
    onError(({ forward, networkError, graphQLErrors, operation, response }) => {
        const error = _.first(response?.errors)?.message;
        const refreshToken = selectRefreshToken(store.getState());

        if (error === "Token expired" && refreshToken && handleRefreshToken) {
            const refreshPromise = apolloClient.mutate({
                mutation: GRAPHQL_MUTATION_REFRESH_AUTH_TOKEN,
                variables: {
                    refreshToken,
                },
            });

            return promiseToObservable(refreshPromise).flatMap((result: any) => {
                const { authToken } = result.data.refreshAuthToken;

                // update store and request headers
                store.dispatch(updateAuthToken({ authToken: authToken }));
                operation.setContext(({ headers = {} }) => ({
                    headers: {
                        ...headers,
                        authorization: `Bearer ${authToken}`,
                    },
                }));

                // retry request
                return forward(operation);
            });
        } else {
            const { startTime, attemptStartTime, requestProperties, attemptCount } =
                operation.getContext();
            const latency = Date.now() - attemptStartTime;
            const totalLatency = Date.now() - startTime;
            const {
                statusCode,
                message = error || "message not defined",
                result,
            } = (networkError as ServerError) || {};
            const errors = isString(result) ? null : result?.errors;
            const { variables } = operation;

            // This may not be reliable (eg: connected to LAN but not internet)
            const isOffline = !window.navigator.onLine;

            const userError = graphQLErrors?.every((x) => x.extensions?.code === "UNAUTHENTICATED");

            if (!userError && !isOffline) {
                // Do not report authentication errors to Sentry
                Sentry.captureException(networkError || _.first(graphQLErrors), {
                    contexts: {
                        request: {
                            ...requestProperties,
                            variables,
                            statusCode,
                            message,
                            errors,
                            latency,
                            totalLatency,
                            attemptCount,
                            graphQLErrors,
                        },
                    },
                });
            }

            const analyticsProperties = {
                ...requestProperties,
                statusCode,
                message,
                errors,
                latency,
                totalLatency,
                attemptCount,
                userError,
                isOffline,
                networkError,
                error,
            };

            if (
                error === "Incorrect password" ||
                error === "Can't find a user with that email address." ||
                error ===
                    "Too many failed login attempts. Please wait for some time and then try again or reset your password." ||
                error === "Please enter a valid email address."
            ) {
                analyticsTrack(`${analyticsPrefix}.loginUserError`, analyticsProperties);
            } else if (error === "The email address is already in use by another account.") {
                analyticsTrack(`${analyticsPrefix}.signupUserError`, analyticsProperties);
            } else {
                analyticsTrack(`${analyticsPrefix}`, analyticsProperties);
            }
        }
    });

const authLink = () =>
    new ApolloLink((operation, forward) => {
        const token = selectAuthToken(store.getState());
        const loginAsParameter = selectLoginAs(store.getState());

        // add the recent-activity custom header to the headers
        operation.setContext(({ headers = {} }) => ({
            headers: {
                ...headers,
                ...(loginAsParameter ? { "x-login-as": loginAsParameter } : {}),
                authorization: token ? `Bearer ${token}` : "",
            },
        }));

        // respond to auth token expiration
        return forward(operation);
    });

const loggingLink = new ApolloLink((operation, forward) => {
    const requestId = generateId();
    const operationName = operation.operationName || "unknown";
    let innerOp = operation.query.definitions.find((x) => x.kind === "OperationDefinition") as
        | OperationDefinitionNode
        | undefined;
    let operationType = "";

    if (innerOp) {
        operationType = innerOp.operation;
    }

    const properties = {
        name: operationName,
        type: operationType,
        requestId,
    };

    analyticsTrack("request.sent", properties);

    operation.setContext(({ headers = {} }) => ({
        startTime: Date.now(),
        requestProperties: properties,
        headers: {
            ...headers,
            "x-request-id": requestId || "",
        },
    }));

    return forward(operation).map((result) => {
        const { attemptStartTime, attemptCount, startTime, requestProperties } =
            operation.getContext();
        const latency = Date.now() - attemptStartTime;
        const totalLatency = Date.now() - startTime;

        const analyticsProperties = {
            ...requestProperties,
            latency,
            totalLatency,
            attemptCount,
        };

        if (result.data) {
            analyticsTrack("request.success", analyticsProperties);

            if (operationName === "unknown") {
                // If the graphQL operation is not named, log keys in the data to help us identify the query and add a name to it
                analyticsTrack("request.debugName", {
                    ...analyticsProperties,
                    keys: Object.keys(result.data),
                });

                if (!isProduction()) {
                    // eslint-disable-next-line no-console
                    console.log(
                        "Unnamed GraphQL operation should be given a name, result of operation:",
                        result.data,
                    );
                }
            }
        }

        if (result.errors) {
            analyticsTrack("request.successWithErrors", {
                ...analyticsProperties,
                errors: result.errors,
            });
        }

        return result;
    });
});

const retryLink = new RetryLink({
    delay: {
        initial: INITIAL_RETRY_DELAY_MS,
        max: MAX_RETRY_DELAY_MS,
        jitter: true,
    },
    attempts: {
        max: MAX_RETRIES,
        retryIf: (error, _operation) => ERRORS_TO_RETRY.includes(error.message),
    },
});

const retryConfigLink = new ApolloLink((operation, forward) => {
    const { startTime, requestProperties } = operation.getContext();
    const prevAttemptCount = operation.getContext().attemptCount || 0;
    const attemptCount = prevAttemptCount + 1;
    const attemptStartTime = Date.now();

    const baseTimeout = timeoutOverrides[requestProperties.name as string] || REQUEST_TIMEOUT_MS;
    const timeout = baseTimeout + prevAttemptCount * TIMEOUT_INCREASE_PER_RETRY_MS;

    analyticsTrack(`request.attempt.start`, {
        ...requestProperties,
        attemptCount,
        startTime,
        attemptStartTime,
        timeout,
    });

    operation.setContext(({ headers = {} }) => ({
        attemptCount,
        attemptStartTime,
        timeout,
        headers: {
            ...headers,
            "x-attempt-count": attemptCount,
        },
    }));
    return forward(operation);
});

apolloClient = new ApolloClient({
    link: ApolloLink.from([
        // Overall error handling - logs errors that persist after retries, handles refresh token issues
        createErrorLink({ analyticsPrefix: "request.error", handleRefreshToken: true }),

        // Connects to sentry logging
        new SentryLink(),

        // Handles request sent and success logging for the overall request (outside of retries)
        loggingLink,

        // Sets up the retry mechanism, and logs analytics & increases the timeout on each retry start
        retryLink,
        retryConfigLink,

        // This error link sends analytics logs when there are errors on each retry
        createErrorLink({ analyticsPrefix: "request.attempt.error", handleRefreshToken: false }),

        // Sets up the request so that it is cancelled after the timeout time set in the operation context
        timeoutLink,

        // Adds auth headers
        authLink(),

        // Terminating link, configures the fetch request
        httpLink,
    ]),
    cache: new InMemoryCache({
        addTypename: false,
        typePolicies: {},
    }),
});

export { apolloClient };

// Expose apollo client to cypress for integration testing of graphql queries
if (isRunningCypress()) {
    (window as any).apolloClient = apolloClient;
}
