import { MembershipBillingChoice } from "@app/shared/types";
import { EmbeddedCheckout, EmbeddedCheckoutProvider } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { analyticsTrack } from "app/analytics/track";
import { StripePublicKey } from "app/stripe";
import { useEffect } from "react";
import { getSignupAnalyticsMetadataFromPlan } from "./signupHelpers";
import { SignupProgressBar } from "./SignupProgressBar";

const stripePromise = loadStripe(StripePublicKey);

export interface CheckoutPageProps {
    clientSecret: string;
    billingChoice: MembershipBillingChoice;
}

export const CheckoutPage = (props: CheckoutPageProps) => {
    const { clientSecret, billingChoice } = props;
    const options = { clientSecret };

    useEffect(() => {
        const analyticsData = getSignupAnalyticsMetadataFromPlan(billingChoice);
        analyticsTrack("checkoutPage.loaded", analyticsData);
    }, []);

    return (
        <div data-testid="embeddedCheckout">
            <SignupProgressBar currentStep={1} />
            <EmbeddedCheckoutProvider stripe={stripePromise} options={options}>
                <EmbeddedCheckout />
            </EmbeddedCheckoutProvider>
        </div>
    );
};
