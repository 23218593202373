import { Session } from "@app/shared/types";
import { dateTimeFromString } from "@app/shared/utils";
import { Box, Typography } from "@mui/material";
import { scrollToElement } from "components/scrollToElement";
import { useQueryParams } from "hooks/useQueryParams";
import { useUserTimezone } from "hooks/useUserTimezone";
import { DateTime } from "luxon";
import React, { useLayoutEffect } from "react";
import smoothscroll from "smoothscroll-polyfill";
import { EventList } from "./EventList";
smoothscroll.polyfill();

export interface EventListByDayProps {
    events: Session[];
    currentServerTime: DateTime;
    showSpecificDate?: boolean;
    rsvpedSessions: { id: string }[];
    onSessionAttend?: () => void;
}

export const EventListByDay = (props: EventListByDayProps): JSX.Element => {
    const { events, currentServerTime, showSpecificDate, rsvpedSessions, onSessionAttend } = props;

    const timezone = useUserTimezone();
    const now = currentServerTime.setZone(timezone);

    const getEventsForDate = (date: DateTime) =>
        events.filter((event) => {
            const eventDate = dateTimeFromString(event.time, timezone);

            return (
                eventDate.day === date.day &&
                eventDate.month === date.month &&
                eventDate.year === date.year &&
                eventDate > now
            );
        });

    const getEventsHappeningNow = () =>
        events.filter((event) => {
            const eventDate = dateTimeFromString(event.time, timezone);
            const eventEndDate = dateTimeFromString(event.endTime, timezone);

            return eventDate <= now && eventEndDate > now;
        });

    const today = now;
    const tomorrow = now.plus({ day: 1 });
    const futureDates = [];
    // TODO: could optimize this by passing through the list of events only once, since it is already sorted
    // populate for the next 6 months
    for (let i = 2; i < 182; i++) {
        const date = now.plus({ day: i });
        futureDates.push(date);
    }

    const renderSection = (date: string, events: Session[]) => {
        if (events.length < 1) {
            return null;
        }
        return (
            <Box sx={{ mb: 10 }}>
                <Typography variant="h4">{date}</Typography>

                <EventList
                    currentServerTime={currentServerTime}
                    events={events}
                    showSpecificDate={showSpecificDate}
                    rsvpedSessions={rsvpedSessions}
                    onSessionAttend={onSessionAttend}
                />
            </Box>
        );
    };

    return (
        <div>
            {renderSection("Happening Now", getEventsHappeningNow())}
            {renderSection("Today", getEventsForDate(today))}
            {renderSection("Tomorrow", getEventsForDate(tomorrow))}
            {futureDates.map((date: DateTime, i) => (
                <React.Fragment key={i}>
                    {renderSection(
                        date.toLocaleString({
                            weekday: "long",
                            month: "long",
                            day: "numeric",
                        }),
                        getEventsForDate(date),
                    )}
                </React.Fragment>
            ))}
        </div>
    );
};
