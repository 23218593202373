import { useMutation } from "@apollo/client";
import { analyticsTrack } from "app/analytics/track";
import { isRunningCypress } from "app/config";
import { GRAPHQL_MUTATION_CREATE_COURSE_CHECKOUT_SESSION } from "app/queries";
import LoadingPage from "features/pages/LoadingPage";
import { useEffect, useState } from "react";
import { CourseCheckoutPage } from "./CourseCheckoutPage";
import { Course } from "@app/shared/types";
import { showSnackbarAlertOnRedirect } from "components/AlertSnackBar";
import { routes } from "app/routes";
import { useDispatch } from "react-redux";
import { push } from "connected-react-router";
import { UtmPrefix, getUtmParametersFromLocalStorage } from "app/utmParameters";
import { useQueryParams } from "hooks/useQueryParams";

interface CourseStripeClientSecretProviderProps {
    course: Course;
}

export const CourseStripeClientSecretProvider = (props: CourseStripeClientSecretProviderProps) => {
    const { course } = props;
    const dispatch = useDispatch();

    // temporary scrappy solution to allow a hardcoded payment plan with 3 installments for MMT3
    const queryParams = useQueryParams();
    const { installments } = queryParams;
    let numberOfInstallments: number | undefined = undefined;
    if (installments && installments === "3") {
        numberOfInstallments = 3;
    }

    const [generateCheckoutSession] = useMutation(GRAPHQL_MUTATION_CREATE_COURSE_CHECKOUT_SESSION);

    const [clientSecret, setClientSecret] = useState("");

    const onCompleteBilling = async () => {
        try {
            const result = await generateCheckoutSession({
                variables: {
                    courseId: course.id,
                    utmParameters: getUtmParametersFromLocalStorage(),
                    affiliateUtmParameters: getUtmParametersFromLocalStorage(UtmPrefix.AFFILIATE),
                    installments: numberOfInstallments,
                },
            });

            const { sessionId, clientSecret } = result.data.createCourseCheckoutSession;

            setClientSecret(clientSecret);

            if (isRunningCypress()) {
                (window as any).stripeSessionId = sessionId;
            }
        } catch (e) {
            if (e instanceof Error) {
                if (e.message?.includes("User already has a membership for this course")) {
                    analyticsTrack("courses.signup.checkout.payment.active-membership");
                    showSnackbarAlertOnRedirect("You are already enrolled in this course.");
                    dispatch(push(routes.coursesDashboard()));
                } else {
                    analyticsTrack("courses.signup.checkout.payment.unknown-error", {
                        message: e.message,
                    });
                }
            } else {
                analyticsTrack("courses.signup.checkout.payment.unknown-error", { message: e });
            }
        }
    };

    useEffect(() => {
        onCompleteBilling();
    }, []);

    if (!clientSecret) {
        return <LoadingPage />;
    } else {
        return <CourseCheckoutPage clientSecret={clientSecret} course={course} />;
    }
};
