import _ from "lodash";
import { NotificationFrequency, OnboardingItemType, SessionType } from "./types";

export class MembershipStatus {
    static Active = "Active" as const;
    static Canceled = "Canceled" as const;
    static Declined = "Declined" as const;
    static Expired = "Expired" as const;
    static Invited = "Invited" as const;
    static Pending = "Pending" as const;
    static AwaitingPayment = "Awaiting payment" as const;
    static PendingCancel = "Pending cancel" as const;

    static AllEnded = [
        MembershipStatus.Canceled,
        MembershipStatus.Declined,
        MembershipStatus.Expired,
    ];
    static AllInactive = [
        MembershipStatus.Canceled,
        MembershipStatus.Pending,
        MembershipStatus.AwaitingPayment,
        MembershipStatus.Expired,
        MembershipStatus.Declined,
    ];

    static ActiveOrPending = [
        MembershipStatus.Active,
        MembershipStatus.Pending,
    ] as typeof MembershipStatus.All;

    static AllActive = [
        MembershipStatus.Active,
        MembershipStatus.PendingCancel,
    ] as typeof MembershipStatus.All;

    static AllActiveOrPending = [
        MembershipStatus.Active,
        MembershipStatus.Pending,
        MembershipStatus.PendingCancel,
        MembershipStatus.AwaitingPayment,
    ];
    static All = [
        MembershipStatus.Active,
        MembershipStatus.Canceled,
        MembershipStatus.Declined,
        MembershipStatus.Expired,
        MembershipStatus.Invited,
        MembershipStatus.Pending,
        MembershipStatus.PendingCancel,
        MembershipStatus.AwaitingPayment,
    ];

    static isActive = (status: any) => {
        return _.includes(MembershipStatus.AllActive, status);
    };

    static isActiveOrPending = (status: any) => {
        return _.includes(MembershipStatus.AllActiveOrPending, status);
    };
}

export enum SanghaMembershipStatus {
    ACTIVE = "Active",
    ENDED = "Ended",
    INVITED = "Invited",
    PENDING = "Scheduled to start",
    PENDING_CANCEL = "Scheduled to end",
    EXPIRED = "Expired",
    DECLINED = "Declined",
    COMMITTED = "Committed",
}

export const SANGHA_MEMBERSHIP_STATUSES_ALL = [
    SanghaMembershipStatus.ACTIVE,
    SanghaMembershipStatus.ENDED,
    SanghaMembershipStatus.INVITED,
    SanghaMembershipStatus.PENDING,
    SanghaMembershipStatus.PENDING_CANCEL,
    SanghaMembershipStatus.DECLINED,
    SanghaMembershipStatus.EXPIRED,
];

export const SANGHA_MEMBERSHIP_STATUSES_INACTIVE = [
    SanghaMembershipStatus.ENDED,
    SanghaMembershipStatus.DECLINED,
    SanghaMembershipStatus.EXPIRED,
];

export const SANGHA_MEMBERSHIP_STATUSES_INACTIVE_OR_PENDING_CANCEL = [
    SanghaMembershipStatus.ENDED,
    SanghaMembershipStatus.DECLINED,
    SanghaMembershipStatus.EXPIRED,
    SanghaMembershipStatus.PENDING_CANCEL,
];

export const SANGHA_MEMBERSHIP_STATUSES_ACTIVE = [
    SanghaMembershipStatus.ACTIVE,
    SanghaMembershipStatus.PENDING_CANCEL,
];

export const SANGHA_MEMBERSHIP_STATUSES_ACTIVE_OR_PENDING = [
    SanghaMembershipStatus.ACTIVE,
    SanghaMembershipStatus.PENDING,
    SanghaMembershipStatus.PENDING_CANCEL,
];

export const SANGHA_MEMBERSHIP_STATUSES_ACTIVE_OR_PENDING_WITHOUT_END_DATE = [
    SanghaMembershipStatus.ACTIVE,
    SanghaMembershipStatus.PENDING,
];

// Message notifications are currently treated the same way as website notifications
// because we aggregate them for emails, so they need to be stored.
// TODO: Separate them out into a different table/type and treat them differently
export enum WebsiteNotificationType {
    NEW_MESSAGE = "new_group_message",
    FEEDBACK_REQUEST = "feedback_request",
    PMF_SURVEY_REQUEST = "pmf_survey_request",
}

export const MAX_SESSION_LENGTH_IN_MINUTES = 120;
export const SANGHA_SESSION_LENGTH_IN_MINUTES = 90;
export const MAX_MEMBERS_PER_MENTORSHIP_GROUP = 12;

export const INTEREST_GROUP_SESSION_LENGTH_IN_MINUTES = 60;

export const REFLECTION_QUESTION_MAX_LENGTH = 100;

export const DEFAULT_NOTIFICATION_FREQUENCY = NotificationFrequency.DAILY;

// Hard code the special teacher sangha
export const TEACHER_SANGHA_ID = "6YP8INuW2Pzc6shcpIck";

export enum EthnicityOptions {
    Asian_or_Pacific_Islander = "Asian or Pacific Islander",
    Black_or_African_American = "Black or African American",
    Hispanic_or_Latinx = "Hispanic or Latinx",
    Mixed_race = "Mixed race",
    Native_American_or_American_Indian = "Native American or American Indian",
    Southwest_Asian_or_North_African = "Southwest Asian or North African",
    White = "White",
    Prefer_not_to_say = "Prefer not to say",
}
export const AllEthnicityOptions = [
    EthnicityOptions.Asian_or_Pacific_Islander,
    EthnicityOptions.Black_or_African_American,
    EthnicityOptions.Hispanic_or_Latinx,
    EthnicityOptions.Mixed_race,
    EthnicityOptions.Native_American_or_American_Indian,
    EthnicityOptions.Southwest_Asian_or_North_African,
    EthnicityOptions.White,
    EthnicityOptions.Prefer_not_to_say,
];

export const POC_ONLY_SESSION_NOTE = "POC_ONLY_SESSION";
export const MEN_ONLY_SESSION_NOTE = "MEN_ONLY_SESSION";
export const COMMUNITY_EVENT_TYPES_FOR_MEMBERS = [
    SessionType.COMMUNITY_SIT,
    SessionType.COMMUNITY_TALK,
    SessionType.WELCOME_SESSION,
    SessionType.SILENT_SIT,
    SessionType.CONFLICT_RESOLUTION_PRACTICE,
    SessionType.QA_SESSION,
];

export const EVENT_TYPES_FOR_TEACHERS = [SessionType.TEACHER_ONLY_SESSION];

export enum Cohort {
    Joyous = "Joyous",
}

export const DEFAULT_FREE_TRIAL_DAYS = 14;

export const BRAND_NAME = "Banyan";
export const BRAND_NAME_AND_LEGAL_NAME = "Banyan (by Cloud Sangha)";
export const FULL_LEGAL_NAME = "Cloud Sangha PBC";
export const SHORT_LEGAL_NAME = "Cloud Sangha";
export const PRODUCTION_DOMAIN = "banyantogether.com";
export const EMAIL_DOMAIN = "banyantogether.com";
export const CONTACT_EMAIL = `hello@${EMAIL_DOMAIN}`;
export const DEFAULT_SOURCE_EMAIL = CONTACT_EMAIL;
export const SENDER_NAME_AND_EMAIL = `${BRAND_NAME} <${CONTACT_EMAIL}>`;
export const TEACHER_SUPPORT_EMAIL = `teachersupport@${EMAIL_DOMAIN}`;
export const CONFLICT_RESOLUTION_EMAIL = `concerns@${EMAIL_DOMAIN}`;
export const PRIMARY_ADMIN_ACCOUNT_NAME = "Banyan";
export const PRIMARY_ADMIN_EMAIL = "admin@cloudsangha.co";
export const ENGINEERING_EMAILS_TO_NOTIFY = [
    "rahul@banyantogether.com",
    "daniel@banyantogether.com",
    "chris@banyantogether.com",
    "gustavo@banyantogether.com",
];

export const SanghaAudienceDisplayMap = {
    "lgbtq+": "LGBTQIA+",
    "people of color": "BIPOC",
    "lgbtqia+": "LGBTQIA+",
    women: "Women",
    "all meditators": "All Meditators",
    "meditation teachers": "Meditation Teachers",
    men: "Men",
} as { [key: string]: string };

export const ALL_ONBOARDING_ITEMS = [
    OnboardingItemType.FIRST_WELCOME_SESSION,
    OnboardingItemType.FIRST_MFG,
    OnboardingItemType.FIRST_TALK,
    OnboardingItemType.FIRST_MEDITATION,
    OnboardingItemType.MINDFUL_RELATING_COURSE,
];

export const MINDFUL_RELATING_COURSE_ID = "enfs1d5DLCE5TCGm6gtV";
export const MINDFULNESS_MENTOR_TRAINING_COURSE_ID = "da2ge5aGuwptyfxVpbC8";

export const EXPLORER_MEMBERSHIP_PRODUCT_ID = "prod_L7GvDBbh2TmtKI";
export const IMMERSION_MEMBERSHIP_PRODUCT_ID = "prod_I79sbb4egrbAJ8";
export const IMMERSION_GRADUATE_MEMBERSHIP_PRODUCT_ID = "prod_J990rasl49JJCy";
export const ALL_MEMBERSHIP_PRODUCT_IDS = [
    EXPLORER_MEMBERSHIP_PRODUCT_ID,
    IMMERSION_MEMBERSHIP_PRODUCT_ID,
    IMMERSION_GRADUATE_MEMBERSHIP_PRODUCT_ID,
];

export enum CheckoutSessionType {
    PAY_FOR_MEMBERSHIP = "PAY_FOR_MEMBERSHIP",
    UPDATE_MEMBERSHIP_PAYMENT_METHOD = "UPDATE_MEMBERSHIP_PAYMENT_METHOD",
    PAY_FOR_ONE_TIME_DONATION = "PAY_FOR_ONE_TIME_DONATION",
    PAY_FOR_GIFT_CARD = "PAY_FOR_GIFT_CARD",
    PAY_FOR_IMMERSION_APPLICATION = "PAY_FOR_IMMERSION_APPLICATION",
    PAY_FOR_COURSE = "PAY_FOR_COURSE",
}

export const MY_GROUPS_SPACE_GROUP_ID = "511347";

export const LOUNGE_BASE_URL = "https://community.banyantogether.com";

export const MINDFUL_MOVEMENT_TOPIC_ID = "wIyJ1le5ak6b5EaQcT8d";

export const COURSES_WITH_EXCLUSIVE_CIRCLE_SPACES = [MINDFULNESS_MENTOR_TRAINING_COURSE_ID];
export const LOUNGE_SPACE_GROUP_ID = "436186";

export const CUSTOM_PATH_TYPEFORM_URL_PRODUCTION =
    "https://banyantogether.typeform.com/to/qQ3Rj9yk";
export const CUSTOM_PATH_TYPEFORM_URL_DEV = "https://banyantogether.typeform.com/to/duWs6cqO";

export const STARTING_SOON_MINUTES = 240;

export const INTENTION_SUMMARY_CHAR_LIMIT = 45;
