import _ from "lodash";
import { encodeDelimitedArray, decodeDelimitedArray } from "use-query-params";

export const CustomArrayParam = {
    encode: (value: (string | null)[] | null | undefined) => encodeDelimitedArray(value, ","),
    decode: (value: string | (string | null)[] | null | undefined) =>
        _.compact(decodeDelimitedArray(value, ",")),
};

export const StatusParam = {
    encode: (value: (string | null)[] | null | undefined) =>
        encodeDelimitedArray(value ?? ["OPEN"], ","),
    decode: (value: string | (string | null)[] | null | undefined) =>
        _.compact(decodeDelimitedArray(value ?? ["OPEN"], ",")),
};
