import { OutlinedTextFieldProps, TextField } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import classNames from "classnames";
import { Controller } from "react-hook-form";
import _ from "lodash";
import { CSTextField } from "./CSTextField";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        width: "100%",
        maxWidth: 400,
        marginBottom: theme.spacing(4),
    },

    wide: {
        maxWidth: 800,
    },
}));

const useInputStyles = makeStyles((theme) => ({
    root: {
        marginBottom: 0,
    },
}));

export interface FormInputOutlinedTextFieldProps
    extends Omit<OutlinedTextFieldProps, "variant" | "onChange" | "error" | "helperText"> {
    name: string;
    control: any;
    label: string | JSX.Element;
    setValue?: any;
    type?: any;
    classes?: Partial<ReturnType<typeof useStyles>>;
}

export const FormInputText = (props: FormInputOutlinedTextFieldProps) => {
    const classes = useStyles(props);
    const inputClasses = useInputStyles();
    const { name, label, control, type } = props;
    return (
        <Controller
            name={name}
            control={control}
            render={({ field, fieldState: { error } }) => (
                <CSTextField
                    {..._.omit(
                        props,
                        "control",
                        "InputProps",
                        "name",
                        "onChange",
                        "onBlur",
                        "value",
                    )}
                    {...field}
                    type={type}
                    error={error?.message}
                    showError={!!error}
                    label={label}
                    InputProps={{
                        classes: inputClasses,
                        ...props.InputProps,
                    }}
                    classes={{
                        root: classNames(classes.root, {
                            [classes.wide]: props.multiline,
                        }),
                    }}
                />
            )}
        />
    );
};
