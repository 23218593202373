import makeStyles from "@mui/styles/makeStyles";
import * as React from "react";

const defaultImageAspectRatio = 0.7;

const useStyles = makeStyles((theme) => ({
    container: {
        padding: theme.spacing(15, 10),

        display: "flex",
        justifyContent: "center",
        gap: theme.spacing(10),

        [theme.breakpoints.down("sm")]: {
            flexDirection: "column",
            // gap: theme.spacing(2),
        },
    },
    column: {
        flex: "0 1 500px",

        [theme.breakpoints.down("sm")]: {
            flex: "0 0 auto",
        },
    },
}));

interface SectionTextWithTextProps {
    column1: React.ReactNode;
    column2: React.ReactNode;
    classes?: Partial<ReturnType<typeof useStyles>>;
}

export const SectionTextWithText = React.memo((props: SectionTextWithTextProps) => {
    const classes = useStyles(props);

    return (
        <div className={classes.container}>
            <div className={classes.column}>{props.column1}</div>

            <div className={classes.column}>{props.column2}</div>
        </div>
    );
});
