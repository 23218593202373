import { useQuery } from "@apollo/client";
import { ProductFeature, Sangha, SanghaType, Teacher } from "@app/shared/types";
import { DEFAULT_FREE_TRIAL_DAYS, MINDFUL_MOVEMENT_TOPIC_ID } from "@app/shared/constants";
import { MFG_TYPEFORM_SCHOLARSHIP_URL } from "features/member/interestGroups/constants";
import { getFormattedDateFromString } from "@app/shared/utils";
import { AdvancedImage, responsive } from "@cloudinary/react";
import { Box, Button, IconButton, Typography, alpha } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { analyticsTrack } from "app/analytics/track";
import { cloudinary } from "app/cloudinary";
import { localImageSrc } from "app/images";
import { GRAPHQL_QUERY_INTEREST_GROUP } from "app/queries";
import { routes } from "app/routes";
import { CSDialog } from "components/CSDialog";
import { CSPanel } from "components/CSPanel";
import { FormInputText } from "components/FormInputText";
import { GenericErrorPage } from "components/GenericErrorPage";
import { SectionImageWithText } from "components/SectionImageWithText";
import { SectionTextCentered } from "components/SectionTextCentered";
import { SectionTextWithText } from "components/SectionTextWithText";
import { TeacherBioCard } from "components/TeacherBioCard";
import { push } from "connected-react-router";
import { selectUser } from "features/auth/auth";
import LoadingPage from "features/pages/LoadingPage";
import { SanghaDayAndTimeDescription } from "features/sangha/SanghaDayAndTimeDescription";
import { useLogEventOnLoad } from "hooks/useLogEventOnLoad";
import { useUserTimezone } from "hooks/useUserTimezone";
import { DateTime } from "luxon";
import { useState } from "react";
import { useForm } from "react-hook-form";
import Carousel from "react-material-ui-carousel";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router";
import { SELECTED_INTEREST_GROUP_FIELD } from "./constants";
import PageWrapper from "components/PageWrapper";
import {
    getCurrentCohort,
    isUserInFreeTrialSession,
    isUserInSetupFlow,
    isUserOutsideOfMemberZone,
} from "features/signup/signupHelpers";
import { SetupProgressBar } from "features/setup/SetupProgressBar";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import classNames from "classnames";
import { NavLink } from "features/navigation/NavLink";
import { TeachersPageImageCard } from "../teachers/TeachersPageImageCard";

const useStyles = makeStyles((theme) => ({
    container: {},

    coverContainer: {
        position: "relative",
        display: "flex",
        marginTop: theme.spacing(-1),
    },
    coverImage: {
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        objectFit: "cover",
    },
    backButton: {
        height: theme.spacing(5),
        width: theme.spacing(5),
    },
    backButtonIcon: {
        fontSize: theme.typography.pxToRem(30),
        color: theme.palette.neutralWhite,
    },
    coverText: {
        position: "relative",
        flexGrow: 1,
        width: "fit-content",
        maxWidth: "50%",
        color: theme.palette.neutralWhite,
        paddingLeft: theme.spacing(10),
        paddingTop: theme.spacing(4),
        paddingRight: theme.spacing(16),
        paddingBottom: theme.spacing(4),
        zIndex: 3,
        [theme.breakpoints.down("lg")]: {
            maxWidth: "70%",
        },
        [theme.breakpoints.down("md")]: {
            paddingRight: theme.spacing(8),
            backgroundPosition: "left center",
            maxWidth: "100%",
            width: "100%",
        },
        [theme.breakpoints.down("sm")]: {
            paddingLeft: theme.spacing(4),
        },
    },

    coverHeader: {
        margin: 0,
    },
    coverSubtitle: {
        marginBottom: theme.spacing(2),
    },
    coverEventTime: {
        marginBottom: theme.spacing(1),
    },
    groupIcon: {
        width: theme.spacing(4),
        height: theme.spacing(4),
        marginLeft: theme.spacing(1),
        padding: theme.spacing(0.5),
        marginRight: theme.spacing(1),
    },
    memberCountContainer: {
        display: "flex",
        alignItems: "center",
        whiteSpace: "nowrap",
    },
    buttonContainer: {
        marginTop: theme.spacing(2),
        display: "flex",
        gap: theme.spacing(2),
        flexWrap: "wrap",
        [theme.breakpoints.down("sm")]: {
            justifyContent: "center",
        },
    },
    image: {
        width: "50%",
        display: "block",
        [theme.breakpoints.down("sm")]: {
            width: "100%",
        },
    },

    section: {
        margin: theme.spacing(10, "auto"),
        maxWidth: 1020,
        padding: theme.spacing(0, 2),
    },
    quoteSection: {
        marginBottom: theme.spacing(6),
    },
    quote: {
        margin: 0,
        marginBottom: theme.spacing(2),
    },
    membershipBenefitsSection: {
        backgroundColor: theme.palette.grey800,
        color: theme.palette.neutralWarm,
    },
    membershipValueSection: {
        marginBottom: theme.spacing(12),
        [theme.breakpoints.down("md")]: {
            marginBottom: theme.spacing(6),
        },
    },

    teacherSection: {
        marginBottom: theme.spacing(12),
        [theme.breakpoints.down("md")]: {
            marginBottom: theme.spacing(6),
        },
    },
    guide: {
        textAlign: "center",
    },
    panel: {
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        flexDirection: "column",
        gap: theme.spacing(1),
        textAlign: "center",
        padding: theme.spacing(2, 4),
        zIndex: theme.zIndex.appBar,
    },
    eventTime: {},
    eventTimeDisplay: {
        marginBottom: 0,
    },
    miniSectionContainer: {
        margin: "0 auto",
        [theme.breakpoints.down("sm")]: {
            width: "100%",
        },
    },
    teacherCarousel: {
        marginTop: theme.spacing(8),
        minHeight: 420,
        padding: theme.spacing(0, 10),

        [theme.breakpoints.down("lg")]: {
            minHeight: 380,
        },
        [theme.breakpoints.down("md")]: {
            minHeight: 340,
        },
        [theme.breakpoints.down("sm")]: {
            minHeight: 260,
            padding: theme.spacing(0, 4),
        },
    },
    membershipBenefitsTitle: {
        marginTop: 0,
    },
    whoIsThisFor: {},
    modalSubtitle: {
        display: "block",
        size: theme.typography.pxToRem(20),
        color: theme.palette.grey700,
        marginBottom: theme.spacing(2),
    },
    formContainer: {
        maxWidth: 600,
        margin: "0 auto",
    },
    emailForm: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    emailFormInput: {
        width: "100%",
        maxWidth: "unset",
        marginBottom: theme.spacing(2),
    },
    emailDialogHeading: {
        marginTop: 0,
        marginBottom: 0,
    },
    noMarginBottom: {
        marginBottom: 0,
    },
    freeTrialInfo: {
        margin: 0,
        color: theme.palette.accentGold,
    },
    headerOverlay: {
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        zIndex: 2,
        background: "linear-gradient(90deg, #000000 0%, rgba(0,0,0,0) 100%)",
    },
}));

export const InterestGroupPage = () => {
    const classes = useStyles();
    const timezone = useUserTimezone();
    const dispatch = useDispatch();
    const user = useSelector(selectUser);

    const { groupId } = useParams<{ groupId: string }>();

    const [isEmailModalOpen, setEmailModalOpen] = useState(false);

    const isActiveMember = user?.features.includes(ProductFeature.CORE_MEMBERSHIP);

    const location = useLocation();
    const isSetupFlow = isUserInSetupFlow(location);
    const isOutsideOfMemberZone = isUserOutsideOfMemberZone(location);

    const history = useHistory();

    const filterQuery = new URLSearchParams(location.search).toString();

    const isFreeTrialSignupFlow = isUserInFreeTrialSession() && (!isActiveMember || isSetupFlow);

    const { handleSubmit, control } = useForm();

    // placeholder variable for A/B testing. If legacyPathA is true, we will show the legacy sign up flow without the new email modal.
    const legacyPathA = true;

    const {
        data: interestGroupData,
        loading: interestGroupLoading,
        error: interestGroupError,
    } = useQuery(GRAPHQL_QUERY_INTEREST_GROUP, {
        variables: { groupId: groupId },
    });

    const interestGroup = interestGroupData?.interestGroup as Sangha | undefined;

    useLogEventOnLoad("View Group", {
        interestGroupId: interestGroup?.id,
        interestGroupName: interestGroup?.name,
        interestGroupTopic: interestGroup?.topicData?.name,
        isFreeTrial: isFreeTrialSignupFlow,
        cohort: getCurrentCohort(),
    });

    if (interestGroupLoading || !interestGroup) {
        return <LoadingPage />;
    }

    if (interestGroupError) {
        return <GenericErrorPage />;
    }

    const teachers = [interestGroup.teacher, ...(interestGroup.additionalTeachers ?? [])];

    const groupIsFull =
        interestGroup.availableSpots <= 0 || interestGroup.hasAvailability === false;

    const onEmailSubmit = (data: any) => {
        sessionStorage.setItem("userEmail", data.email);
        setEmailModalOpen(false);
        // here we need to add the analytics event that tracks the users email
        dispatch(push(routes.signup()));
    };

    const renderSingleTeacher = (teacher: Teacher) => (
        <>
            <TeacherBioCard key={teacher.id} teacher={teacher} />
        </>
    );

    const renderMultipleTeachers = (teachers: Teacher[]) => (
        <Box sx={{ display: "flex", gap: 4, justifyContent: "center" }}>
            {teachers.map((teacher) => {
                return (
                    <TeachersPageImageCard
                        key={teacher.id}
                        teacher={teacher}
                    />
                );
            })}
        </Box>
    );

    const renderTeachers = () => {
        return teachers.length > 1
            ? renderMultipleTeachers(teachers)
            : renderSingleTeacher(teachers[0]);
    };

    const handleButtonClick = () => {
        analyticsTrack("Button Clicked", {
            label: "Let's do it",
            id: interestGroup.id,
            type: SanghaType.InterestGroup,
            location: "InterestGroupPage",
            isFreeTrial: isFreeTrialSignupFlow,
            cohort: getCurrentCohort(),
        });

        if (isFreeTrialSignupFlow) {
            analyticsTrack("Start Free Trial Button Clicked", {
                groupId: interestGroup.id,
                location: "InterestGroupPage",
            });
        }

        window.sessionStorage.setItem(SELECTED_INTEREST_GROUP_FIELD, interestGroup.id);

        if (isSetupFlow) {
            dispatch(push(routes.setupConfirmInterestGroup(interestGroup.id)));
        } else if (isOutsideOfMemberZone) {
            dispatch(push(routes.signup()));
        } else {
            dispatch(push(routes.memberConfirmInterestGroup(interestGroup.id)));
        }

        // If the user is in the member zone (thus, not in the setup flow, but in the product), or legacyPathA is true, continue with the normal flow.
        if (!isSetupFlow || legacyPathA) {
            return;
        }

        // If the user is not logged in (thus, in the sign up flow), and legacyPathA is false, open the email modal.
        setEmailModalOpen(true);
    };

    const cldImage = cloudinary.image(interestGroup.topicData?.imageId).format("auto");

    const numberOfMembers = interestGroup.maxSpots
        ? interestGroup.maxSpots - interestGroup.availableSpots
        : undefined;

    const handleBackButtonClick = () => {
        dispatch(push(routes.setupAllInterestGroups()));
    };

    const getCTAButtonLabel = () => {
        if (groupIsFull) {
            return "Group is full";
        }
        if (isFreeTrialSignupFlow) {
            return "Try it for free";
        }
        return "Choose this group";
    };

    return (
        <>
            {isSetupFlow && <SetupProgressBar currentStep={4} />}
            {/* Cover section */}
            <div className={classes.coverContainer}>
                <div className={classes.headerOverlay} />
                <AdvancedImage
                    className={classes.coverImage}
                    cldImg={cldImage}
                    plugins={[responsive({ steps: 200 })]}
                />
                <div className={classes.coverText}>
                    {isSetupFlow && (
                        <IconButton
                            onClick={handleBackButtonClick}
                            aria-label="go back"
                            className={classes.backButton}
                        >
                            <ArrowBackIcon className={classes.backButtonIcon} />
                        </IconButton>
                    )}
                    {isFreeTrialSignupFlow && (
                        <Typography variant="body1" className={classes.freeTrialInfo}>
                            <strong>{DEFAULT_FREE_TRIAL_DAYS}-day free trial</strong>
                        </Typography>
                    )}
                    <Typography variant="h3" className={classes.coverHeader}>
                        {interestGroup.name}
                    </Typography>
                    <Typography variant="body1" className={classes.coverSubtitle}>
                        {interestGroup.presentationData?.subtitle}
                    </Typography>
                    <Typography
                        variant="body1"
                        className={classes.coverEventTime}
                        data-testid="eventTime"
                    >
                        <strong>
                            <SanghaDayAndTimeDescription
                                sangha={interestGroup}
                                userTimeZone={timezone}
                                renderTimeZone={true}
                            />
                        </strong>
                    </Typography>
                    {numberOfMembers ? (
                        <div className={classes.memberCountContainer}>
                            <span
                                className={classNames(
                                    "material-symbols-rounded",
                                    classes.groupIcon,
                                )}
                            >
                                group
                            </span>{" "}
                            <Typography variant="body1" sx={{ mb: 0 }}>
                                {`${numberOfMembers} ${numberOfMembers > 1 ? "Members" : "Member"}`}
                            </Typography>
                        </div>
                    ) : null}
                    <div className={classes.buttonContainer}>
                        <Button
                            variant="primary"
                            disabled={groupIsFull}
                            onClick={handleButtonClick}
                            disableElevation
                            data-testid="chooseGroupButton"
                        >
                            {getCTAButtonLabel()}
                        </Button>
                        <Button
                            variant="secondary"
                            onClick={() => {
                                if (isSetupFlow) {
                                    history.push(
                                        `${routes.setupAllInterestGroups()}?${filterQuery}`,
                                    );
                                } else if (isOutsideOfMemberZone) {
                                    history.push(`${routes.allInterestGroups()}?${filterQuery}`);
                                } else {
                                    history.push(`${routes.memberSessions()}?${filterQuery}`);
                                }
                            }}
                        >
                            Find another group
                        </Button>
                    </div>
                </div>
            </div>

            <PageWrapper>
                {/* Quote section */}
                <SectionTextCentered>
                    <Typography variant="h2" className={classes.quote}>
                        {interestGroup.presentationData?.quotes[0]}
                    </Typography>
                    <Typography variant="body1">
                        {interestGroup.presentationData?.description}
                    </Typography>
                </SectionTextCentered>

                {/* Who is this for Section */}
                {interestGroup.presentationData?.whoIsThisFor && (
                    <>
                        <SectionImageWithText
                            images={[localImageSrc("landing/Mentorship_groups.jpg", 570)]}
                            classes={{ container: classes.membershipValueSection }}
                            textFirst={true}
                        >
                            <Typography variant="h3" className={classes.noMarginBottom}>
                                Who’s this for?
                            </Typography>
                            <Typography
                                variant="body1"
                                component="div"
                                className={classes.whoIsThisFor}
                                dangerouslySetInnerHTML={{
                                    __html: interestGroup.presentationData?.whoIsThisFor[0] + "",
                                }}
                            />
                        </SectionImageWithText>
                    </>
                )}

                {/* Teacher section */}
                <div className={classes.teacherSection}>
                    <Typography variant="h3" className={classes.noMarginBottom}>
                        Meet your {teachers.length > 1 ? "teachers" : "teacher"}
                    </Typography>
                    <Typography variant="body1">
                        All our sessions are facilitated by expert teachers
                    </Typography>
                    <div className={classes.miniSectionContainer}>{renderTeachers()}</div>
                </div>

                {/* Designed for connection section */}
                <SectionImageWithText
                    images={[localImageSrc("landing/Discussion_groups.jpg", 570)]}
                    classes={{ container: classes.membershipValueSection }}
                    textFirst={true}
                >
                    <Typography variant="h3" className={classes.noMarginBottom}>
                        Designed for connection
                    </Typography>
                    <Typography variant="body1">
                        The format for each group session is:
                        {/* For the mindful movement MFG, we display a different group format */}
                        {interestGroup.topicData?.id === MINDFUL_MOVEMENT_TOPIC_ID ? (
                            <ul>
                                <li>5 minutes welcome</li>
                                <li>30 minutes movement</li>
                                <li>10 minutes meditation</li>
                                <li>15 minutes whole group sharing and Q&A</li>
                            </ul>
                        ) : (
                            <ul>
                                <li>5 minutes welcome</li>
                                <li>10-15 minutes meditation</li>
                                <li>
                                    10-15 minutes short teaching and introduction of today's prompt
                                </li>
                                <li>25 minutes sharing with your pod (breakout room)</li>
                                <li>10 minutes whole group sharing and Q&A</li>
                            </ul>
                        )}
                        You can join this group at any time, on a rolling basis. There are no start
                        and end dates for a group.
                    </Typography>
                </SectionImageWithText>
            </PageWrapper>

            {/* Price section */}
            {!user && (
                <SectionTextWithText
                    classes={{ container: classes.membershipBenefitsSection }}
                    column1={
                        <>
                            <Typography variant="h4" className={classes.membershipBenefitsTitle}>
                                How much is membership?
                            </Typography>
                            <Typography variant="body1">
                                Just $49 per month. Unlimited live sessions for less than the cost
                                of a single coaching or therapy session. We offer scholarships to
                                those in need. Our goal is to make mindfulness practice accessible.
                                <NavLink to={MFG_TYPEFORM_SCHOLARSHIP_URL}>
                                    {" "}
                                    Request a scholarship
                                </NavLink>
                            </Typography>
                        </>
                    }
                    column2={
                        <>
                            <Typography variant="h4" className={classes.membershipBenefitsTitle}>
                                Your membership also includes
                            </Typography>
                            <Typography variant="body1">
                                Unlimited groups
                                <br />
                                Drop-in meditations
                                <br />
                                Drop-in talks
                            </Typography>
                        </>
                    }
                />
            )}
            <CSPanel
                classes={{
                    panel: classes.panel,
                }}
            >
                <div>
                    <div className={classes.eventTime}>
                        <Typography
                            variant="body1"
                            className={classes.eventTimeDisplay}
                            data-testid="eventTime"
                        >
                            <SanghaDayAndTimeDescription
                                sangha={interestGroup}
                                userTimeZone={timezone}
                                renderTimeZone={true}
                            />
                        </Typography>
                    </div>
                    {DateTime.fromISO(interestGroup.firstSessionDate) > DateTime.now() && (
                        <Typography variant="body2">
                            First session on{" "}
                            {getFormattedDateFromString(
                                interestGroup.firstSessionDate,
                                timezone,
                                DateTime.DATE_SHORT,
                            )}
                        </Typography>
                    )}
                </div>
                {isFreeTrialSignupFlow && (
                    <Typography variant="body1" className={classes.freeTrialInfo}>
                        <strong>{DEFAULT_FREE_TRIAL_DAYS}-day free trial</strong>
                    </Typography>
                )}
                <Button
                    variant="primary"
                    disabled={groupIsFull}
                    onClick={handleButtonClick}
                    disableElevation
                    data-testid="chooseGroupButton2"
                >
                    {getCTAButtonLabel()}
                </Button>
            </CSPanel>

            <CSDialog onClose={() => setEmailModalOpen(false)} open={isEmailModalOpen}>
                <div className={classes.formContainer}>
                    <Typography variant="h4" className={classes.emailDialogHeading}>
                        What is your email address?
                    </Typography>
                    <span className={classes.modalSubtitle}>
                        Enter your email address to continue with this group. We'll also add you to
                        our bi-monthly newsletter.
                    </span>
                    <div className={classes.emailForm}>
                        <FormInputText
                            id="username"
                            name="email"
                            data-testid="emailField"
                            control={control}
                            label="Email"
                            className={classes.emailFormInput}
                        />
                        <Button variant="primary" fullWidth onClick={handleSubmit(onEmailSubmit)}>
                            Continue
                        </Button>
                    </div>
                </div>
            </CSDialog>
        </>
    );
};
