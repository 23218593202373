// Account for screens with a higher device pixel ratio
export const idealImageWidth = (baseWidth: number) => {
    const dpr = window.devicePixelRatio || 1;
    if (dpr < 1) {
        return baseWidth;
    }

    return baseWidth * dpr;
};

export const externalImageSrc = (url: string, w: number) =>
    `/images/?url=${encodeURIComponent(url)}&source=external&w=${idealImageWidth(w)}`;

export const localImageSrc = (path: string, w?: number) => {
    if (!w) {
        return `/images/${path}`;
    } else {
        return `/images/${path}?w=${idealImageWidth(w)}`;
    }
};
