import { useQuery } from "@apollo/client";
import { analyticsTrack } from "app/analytics/track";
import { GRAPHQL_QUERY_COURSE_MEMBERSHIP } from "app/queries";
import { routes } from "app/routes";
import LoadingPage from "features/pages/LoadingPage";
import { SignupProgressBar } from "features/signup/SignupProgressBar";
import { useQueryParams } from "hooks/useQueryParams";
import { useEffect, useState } from "react";
import { Redirect } from "react-router";

export const CoursePaymentSuccess = () => {
    const query = useQueryParams();
    const [successLogged, setSuccessLogged] = useState(false);

    const { sessionId, courseId, customerId } = query;

    const analyticsData = {
        courseId,
        sessionId,
        customerId,
    };

    useEffect(() => {
        const rfsn = {
            cart: customerId,
            id: localStorage.getItem("rfsn_v4_id"),
            url: window.location.href,
            aid: localStorage.getItem("rfsn_v4_aid"),
            cs: localStorage.getItem("rfsn_v4_cs"),
        };
        (window as any).r?.sendCheckoutEvent(rfsn.cart, rfsn.id, rfsn.url, rfsn.aid, rfsn.cs);

        analyticsTrack("courses.signup.success.load", {
            ...analyticsData,
        });
    }, []);

    const { data: courseMembershipData } = useQuery(GRAPHQL_QUERY_COURSE_MEMBERSHIP, {
        pollInterval: 5000,
        fetchPolicy: "network-only",
        variables: {
            courseId: courseId,
        },
    });

    const courseMembership = courseMembershipData?.courseMembership;

    useEffect(() => {
        if (courseMembership) {
            analyticsTrack("courses.signup.success.complete", {
                ...analyticsData,
            });
            setSuccessLogged(true);
        }
    }, [successLogged, courseMembership, analyticsData, sessionId]);

    if (courseMembership) {
        return <Redirect to={routes.coursesDashboard()} />;
    } else {
        return (
            <>
                <SignupProgressBar currentStep={1} />
                <LoadingPage caption="Processing" data-testid="processingLabel" />;
            </>
        );
    }
};
