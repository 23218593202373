import { RefObject, useEffect, useState } from "react";

export function useElementPosition(ref: RefObject<HTMLElement>): number {
    const [position, setPosition] = useState(0);

    useEffect(() => {
        const updatePosition = () => {
            if (ref.current) {
                const pos = ref.current.getBoundingClientRect().top + window.scrollY;
                setPosition(pos);
            }
        };

        updatePosition();

        window.addEventListener("scroll", updatePosition);
        window.addEventListener("resize", updatePosition);

        return () => {
            window.removeEventListener("scroll", updatePosition);
            window.removeEventListener("resize", updatePosition);
        };
    }, [ref, ref.current]);

    return position;
}
