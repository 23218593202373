import { SanghaMembership } from "@app/shared/types";
import { Typography } from "@mui/material";
import { routes } from "app/routes";
import PageWrapper from "components/PageWrapper";
import { LinkButton } from "features/navigation/LinkButton";
import { useUserTimezone } from "hooks/useUserTimezone";
import { SanghaFirstSessionDate } from "../sangha/SanghaFirstSessionDate";

export const MentorshipGroupAddOnSuccess = (props: { sanghaMembership: SanghaMembership }) => {
    const timeZone = useUserTimezone();

    return (
        <PageWrapper>
            <Typography variant="h2">Congrats! You're in</Typography>
            <Typography variant="body1">
                Your first session is on{" "}
                <SanghaFirstSessionDate
                    sanghaMembership={props.sanghaMembership}
                    timeZone={timeZone}
                />{" "}
            </Typography>

            <LinkButton
                variant="primary"
                to={routes.memberSanghas()}
                disableElevation
            >
                See your group
            </LinkButton>
        </PageWrapper>
    );
};
