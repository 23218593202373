import { Message } from "@app/shared/types";
import { Avatar, Divider, Hidden, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { externalImageSrc } from "app/images";
import { memo } from "react";

const useStyles = makeStyles((theme) => ({
    root: {
        [theme.breakpoints.down("md")]: {
            textAlign: "center",
        },
    },
    teacherAvatar: {
        width: 100,
        height: 100,
        marginBottom: theme.spacing(3),
    },

    question: {
        margin: 0,
    },
}));

export const ReflectionQuestionSection = memo((props: { reflectionQuestionMessage: Message }) => {
    const { reflectionQuestionMessage } = props;
    const classes = useStyles();

    return (
        <div data-testid="reflectionQuestion" className={classes.root}>
            <Hidden mdDown>
                <Avatar
                    alt={reflectionQuestionMessage.author.nickName}
                    src={
                        reflectionQuestionMessage.author.picture
                            ? externalImageSrc(reflectionQuestionMessage.author.picture, 260)
                            : ""
                    }
                    variant="rounded"
                    className={classes.teacherAvatar}
                >
                    {reflectionQuestionMessage.author.nickName[0]}
                </Avatar>
            </Hidden>
            <Typography variant="h4" className={classes.question}>
                {reflectionQuestionMessage.content}
            </Typography>
            <Hidden mdDown>
                <Divider />
            </Hidden>
        </div>
    );
});
