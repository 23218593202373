import makeStyles from "@mui/styles/makeStyles";
import MuiAlert from "@mui/material/Alert";
import { useQueryParams } from "hooks/useQueryParams";

const useStyles = makeStyles((theme) => ({
    alert: {
        marginBottom: theme.spacing(2),
    },
}));

export const MembershipStripeAlerts = () => {
    const classes = useStyles();

    const query = useQueryParams();
    const stripeResult = query.stripeResult;

    return (
        <>
            {stripeResult && stripeResult === "failed" && (
                <MuiAlert variant="outlined" severity="error" className={classes.alert}>
                    Something went wrong updating your credit card. Please try again.
                </MuiAlert>
            )}
            {stripeResult && stripeResult === "success" && (
                <MuiAlert variant="outlined" severity="info" className={classes.alert}>
                    Your credit card was successfully updated.
                </MuiAlert>
            )}
        </>
    );
};
