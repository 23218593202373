import { Sangha, UserProfile } from "@app/shared/types";
import { userDisplayNameWithinSangha } from "@app/shared/users";
import makeStyles from "@mui/styles/makeStyles";
import { getSanghaMemberProfiles } from "../sangha/getSanghaMemberProfiles";
import { Typography } from "@mui/material";

const useStyles = makeStyles((theme) => ({
    messageAuthor: {
        "&:first-child": {
            marginTop: 0,
        },
    },
}));

export const MessageAuthor = (props: { author: UserProfile; sangha: Sangha }) => {
    const { author, sangha } = props;

    const usersInSangha = [...getSanghaMemberProfiles(sangha), sangha.teacher.userProfile];

    const classes = useStyles();
    return (
        <div className={classes.messageAuthor} data-testid="messageAuthor">
            <Typography variant="h6">
                {userDisplayNameWithinSangha(author, usersInSangha)}
            </Typography>
        </div>
    );
};
