import {
    selectAuthAndUserLoadCompleted,
    selectIsLoggedIn,
    selectUserProfile,
} from "features/auth/auth";
import LoadingPage from "features/pages/LoadingPage";
import * as React from "react";
import { useSelector } from "react-redux";
import { CreateAccount, CreateAccountProps } from "./CreateAccount";

export const RequireAccountCreation = (
    props: CreateAccountProps & { children: React.ReactNode },
) => {
    const isLoggedIn = useSelector(selectIsLoggedIn);
    const authComplete = useSelector(selectAuthAndUserLoadCompleted);
    const userProfile = useSelector(selectUserProfile);

    if (!isLoggedIn && !authComplete) {
        return <LoadingPage />;
    }

    // If the user is not logged in show them the account creation step
    if (!isLoggedIn || !userProfile) {
        return <CreateAccount {...props} />;
    } else {
        // Otherwise yield the passed children
        return <>{props.children}</>;
    }
};
