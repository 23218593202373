import { Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { NAVBAR_HEIGHT } from "app/styles";

const useStyles = makeStyles((theme) => ({
    emptyThread: {
        opacity: 0.4,
        display: "flex",
        flexDirection: "column",
        textAlign: "center",
        alignItems: "center",
        justifyContent: "center",
        maxWidth: 275,
        margin: "0 auto",
        minHeight: `calc(100vh - ${NAVBAR_HEIGHT + 200}px)`,
    },
}));

export const EmptyMessageThread = () => {
    const classes = useStyles();
    return (
        <div className={classes.emptyThread}>
            <Typography variant="body1">Be the first to write to your group this week.</Typography>
        </div>
    );
};
