import makeStyles from "@mui/styles/makeStyles";
import { analyticsTrack } from "app/analytics/track";
import { videoAnalyticsEvents } from "features/landingPage/FunnelMVP/videoHelpers";

const useStyles = makeStyles((theme) => ({
    videoContainer: {
        overflow: "hidden",
        // height: "80vh",
        marginBottom: theme.spacing(2),

        display: "flex",
        flexDirection: "column",
    },
    video: {
        width: "100%",
        height: "auto",
        maxHeight: "100%",
        display: "block",
        margin: "auto",
        borderRadius: theme.borderRadius.default,
        "&::cue": {
            fontSize: "70%",
            [theme.breakpoints.down("md")]: {
                fontSize: "100%",
            },
        },
    },
}));

export type CSVideoProps = {
    onNext?: () => void;
    continueAfterVideoEnds?: boolean;
    videoUrl: string;
    thumbnailUrl: string;
    captionsUrl?: string;
    videoId: string;
    autoPlay?: boolean;
    classes?: Partial<ReturnType<typeof useStyles>>;
};

export const CSVideo = (props: CSVideoProps) => {
    const classes = useStyles(props);

    return (
        <div className={classes.videoContainer}>
            <video
                autoPlay={props.autoPlay}
                playsInline
                controls
                className={classes.video}
                data-testid={`video.${props.videoId}`}
                key={props.videoUrl}
                poster={props.thumbnailUrl}
                {...videoAnalyticsEvents(props.videoId)}
                onEnded={() => {
                    analyticsTrack(`video.${props.videoId}.ended`);
                    if (props.continueAfterVideoEnds && props.onNext) {
                        props.onNext();
                    }
                }}
            >
                <source src={props.videoUrl} type="video/mp4" />
                {props.captionsUrl && (
                    <track
                        label="English"
                        kind="subtitles"
                        srcLang="en"
                        src={props.captionsUrl}
                        default
                    ></track>
                )}
                Sorry, your browser doesn't support embedded videos.
            </video>
        </div>
    );
};
