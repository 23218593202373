import { routes } from "app/routes";
import { NOTION_FAQ_PAGE } from "features/pages/FAQPage";

export interface FooterLink {
    name: string;
    to: string;
    external?: boolean;
}

export interface FooterSection {
    title: string;
    links: FooterLink[];
}

const baseCommunityLinks: FooterLink[] = [
    {
        name: "Guidelines",
        to: routes.communityGuidelines(),
    },
    {
        name: "Diversity",
        to: routes.diversity(),
    },
    {
        name: "Meditation Library",
        external: true,
        to: "/free-meditation-library",
    },
];

const defaultCommunityLinks: FooterLink[] = [
    {
        name: "Join",
        to: routes.join(),
    },
    ...baseCommunityLinks,
];

const landingPageCommunityLinks: FooterLink[] = [
    {
        name: "FAQs",
        to: NOTION_FAQ_PAGE,
        external: true,
    },
    ...baseCommunityLinks,
];

const companyLinks: FooterLink[] = [
    {
        name: "About Us",
        to: routes.about(),
    },
    {
        name: "Careers",
        to: routes.careers(),
    },
    {
        name: "Contact Us",
        to: routes.contactUs(),
    },
];

const legalLinks: FooterLink[] = [
    {
        name: "Terms of service",
        to: routes.termsOfService(),
    },
    {
        name: "Privacy policy",
        to: routes.privacyPolicy(),
    },
];

export const defaultFooterSections: FooterSection[] = [
    {
        title: "Community",
        links: defaultCommunityLinks,
    },
    {
        title: "Company",
        links: companyLinks,
    },
    {
        title: "Legal",
        links: legalLinks,
    },
];
