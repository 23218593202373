import { scrollToElement } from "components/scrollToElement";
import { useEffect } from "react";
import { useLocation } from "react-router";
import smoothscroll from "smoothscroll-polyfill";
smoothscroll.polyfill();

// Hook that scrolls to an element that matches location hash when the component loads
// Returns nothing
export const useScrollToAnchorOnLoad = (): void => {
    const location = useLocation();

    useEffect(() => {
        if (location.hash) {
            const element = document.getElementById(location.hash.slice(1));
            if (element) {
                scrollToElement(element, { offset: 10 });
            }
        }
    }, [location.hash]);
};
