import { MenuItem } from "@mui/material";
import _ from "lodash";
import { CSTextField, CSTextFieldProps } from "./CSTextField";

export type CSSelectFieldItem =
    | string
    | {
          value: string;
          label: string;
          key?: string;
      };

export interface CSSelectFieldProps extends Omit<CSTextFieldProps, "select" | "children"> {
    options: Readonly<CSSelectFieldItem[]>;
}

const getValue = (item: CSSelectFieldItem) => (_.isString(item) ? item : item.value);
const getLabel = (item: CSSelectFieldItem) => (_.isString(item) ? item : item.label);
const getKey = (item: CSSelectFieldItem) => (_.isString(item) ? item : item.key || item.value);

export const CSSelectField = (props: CSSelectFieldProps) => {
    const renderOption = (item: CSSelectFieldItem) => (
        <MenuItem key={getKey(item)} value={getValue(item)}>
            {getLabel(item)}
        </MenuItem>
    );
    return (
        <CSTextField {..._.omit(props, "options")} select={true}>
            {props.options.map(renderOption)}
        </CSTextField>
    );
};
