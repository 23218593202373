import { analyticsTrack } from "app/analytics/track";
import { routes } from "app/routes";
import { GenericErrorPage } from "components/GenericErrorPage";
import { selectUserProfile } from "features/auth/auth";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Redirect } from "react-router";
import { SignupCommitmentsStep } from "./SignupCommitmentsStep";

export const NewMemberOnboarding = () => {
    const userProfile = useSelector(selectUserProfile);

    useEffect(() => {
        if (!userProfile) {
            analyticsTrack("onboarding.missing-user-profile");
        }
    }, [userProfile]);

    if (!userProfile) {
        return <GenericErrorPage />;
    }

    if (!userProfile.acceptedCommitments) {
        // If the user hasn't yet accepted community commitments, show them the page to accept commitments
        return <SignupCommitmentsStep analyticsPrefix="signup" />;
    }
    return <Redirect to={routes.setupEditProfilePage()} />;
};
