import { Paper } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { LIMITED_WIDTH } from "app/styles";
import { SanghaDescription, SanghaDescriptionProps } from "./SanghaDescription";

const useStyles = makeStyles((theme) => ({
    root: {
        marginBottom: theme.spacing(2),
        padding: theme.spacing(3, 5),
        borderRadius: theme.borderRadius.default,
        transition: "background-color 300ms ease",
        backgroundColor: theme.palette.background.contrast,
        maxWidth: LIMITED_WIDTH,
    },

    name: {
        "& h4": {
            marginTop: 0,
        },
    },

    basePrice: {
        marginBottom: 0,
    },
}));

export const SanghaTimeslot = (props: SanghaDescriptionProps) => {
    const classes = useStyles();

    return (
        <Paper elevation={0} className={classes.root} data-testid="sanghaTimeslot">
            <SanghaDescription {...props} />
        </Paper>
    );
};

export default SanghaTimeslot;
