import { useEffect, useState } from "react";

const DEFAULT_THRESHOLD = 50;

export function useDetectScroll(options?: {
    threshold?: number;
    detectOnlyOnce?: boolean;
    hideOnScrollUp?: boolean;
}) {
    const [hasScrolled, setHasScrolled] = useState(false);
    const [lastScrollPos, setLastScrollPos] = useState(0);

    const {
        threshold = DEFAULT_THRESHOLD,
        detectOnlyOnce = false,
        hideOnScrollUp = false,
    } = options || {};

    useEffect(() => {
        const respondToScroll = () => {
            const currentScrollPos = window.scrollY;

            if (currentScrollPos > lastScrollPos && currentScrollPos > threshold) {
                setHasScrolled(true);
                if (detectOnlyOnce) {
                    window.removeEventListener("scroll", respondToScroll);
                }
            } else if (hideOnScrollUp && currentScrollPos < lastScrollPos) {
                setHasScrolled(false);
            }

            setLastScrollPos(currentScrollPos);
        };

        window.addEventListener("scroll", respondToScroll);

        return () => {
            window.removeEventListener("scroll", respondToScroll);
        };
    }, [threshold, detectOnlyOnce, hideOnScrollUp, lastScrollPos]);

    return hasScrolled;
}
