import { UserProfile } from "@app/shared/types";
import { Typography } from "@mui/material";
import { CSDialog } from "components/CSDialog";
import * as React from "react";

export const MemberDialog = (props: {
    member: UserProfile;
    open: boolean;
    onClose: (ev: React.MouseEvent<any>) => void;
}) => {
    const { member, open, onClose } = props;
    return (
        <CSDialog onClose={onClose} open={open}>
            <Typography variant="h3"> {member.nickName}</Typography>
            <Typography variant="body2" style={{ marginBottom: 32 }}>
                Location: {member.location}
            </Typography>
            <Typography variant="body1">{member.bio}</Typography>
        </CSDialog>
    );
};

export default MemberDialog;
