import { Sangha, Session, SessionType, UserProfile } from "@app/shared/types";
import { isSessionACommunityEvent } from "@app/shared/utils";
import { Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import classNames from "classnames";

interface NumberOfMembersBadgeProps {
    event: Session;
    rsvpUsers: UserProfile[];
    userRsvpedToSession: boolean;
    userIsTeacherOfEvent: boolean;
    sangha?: Sangha;
}

const useStyles = makeStyles((theme) => ({
    membersCountContainer: {
        display: "flex",
        alignItems: "center",
        color: theme.palette.grey800,
    },
    membersCountIcon: {
        width: 20,
        height: 20,
        fontSize: theme.typography.pxToRem(20),
        marginRight: theme.spacing(0.5),
        verticalAlign: "middle",
    },
    membersCountText: {
        display: "inline-block",
        verticalAlign: "middle",
        whiteSpace: "nowrap",
        marginBottom: 0,
    },
    cancelledEventTextColor: {
        color: `${theme.palette.primaryLeaves} !important`,
    },
}));

export const NumberOfMembersBadge = (props: NumberOfMembersBadgeProps) => {
    const { rsvpUsers, userRsvpedToSession, event, userIsTeacherOfEvent, sangha } = props;
    const classes = useStyles();

    const isInterestGroup = event.type === SessionType.INTEREST_GROUP;
    const membersCount = event.sangha?.maxSpots
        ? event.sangha.maxSpots - event.sangha.availableSpots
        : 0;
    const userIsPartOfGroup = !!event.sanghaMembership;
    const isCommunityEvent = isSessionACommunityEvent(event);

    if (
        (isInterestGroup && membersCount > 1 && !userIsPartOfGroup) ||
        (isCommunityEvent && rsvpUsers.length > 1 && !userRsvpedToSession)
    ) {
        return (
            <div
                className={classNames(classes.membersCountContainer, {
                    [classes.cancelledEventTextColor]: event.cancelled,
                })}
            >
                <span className={classNames("material-symbols-rounded", classes.membersCountIcon)}>
                    group
                </span>
                <Typography variant="caption" className={classes.membersCountText}>
                    {isInterestGroup
                        ? `${membersCount} members`
                        : `${rsvpUsers.length} participants`}
                </Typography>
            </div>
        );
    } else if (
        event.type === SessionType.SANGHA_SESSION &&
        !userIsPartOfGroup &&
        !userIsTeacherOfEvent
    ) {
        return (
            <div
                className={classNames(classes.membersCountContainer, {
                    [classes.cancelledEventTextColor]: event.cancelled,
                })}
            >
                <span className={classNames("material-symbols-rounded", classes.membersCountIcon)}>
                    group
                </span>
                <Typography variant="caption" className={classes.membersCountText}>
                    {sangha?.openSpots ?? 0} of{" "}
                    {(sangha?.memberships?.length ?? 0) + (sangha?.openSpots ?? 0)} spots available
                </Typography>
            </div>
        );
    } else {
        return null;
    }
};
