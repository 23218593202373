import { Session } from "@app/shared/types";
import { MenuItem } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { useState } from "react";
import { RequestSubstituteDialog } from "./RequestSubstituteDialog";

const useStyles = makeStyles((theme) => ({
    menuItem: {},
}));

interface RequestSubstituteMenuItemProps {
    classes?: Partial<ReturnType<typeof useStyles>>;
    onClose: VoidFunction;
    session: Session;
}

export const RequestSubstituteMenuItem = (props: RequestSubstituteMenuItemProps) => {
    const classes = useStyles(props);
    const [dialogOpen, setDialogOpen] = useState(false);

    return (
        <>
            <MenuItem
                className={classes.menuItem}
                data-testid="requestSubMenuItem"
                onClick={() => {
                    setDialogOpen(true);
                }}
            >
                I need a substitute
            </MenuItem>
            <RequestSubstituteDialog
                session={props.session}
                onClose={() => {
                    setDialogOpen(false);
                    props.onClose();
                }}
                open={dialogOpen}
            />
        </>
    );
};
