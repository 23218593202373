import makeStyles from "@mui/styles/makeStyles";
import DocumentContext from "components/DocumentContext";
import Guidelines from "components/Guidelines";
import PageWrapper from "components/PageWrapper";
import { SetupMindfulCommunicationVideoPage } from "features/setup/SetupMindfulCommunicationVideoPage";

const useStyles = makeStyles((theme) => ({
    root: {},
}));

export const GuidelinesPage = () => {
    const classes = useStyles();

    return (
        <PageWrapper className={classes.root}>
            <DocumentContext
                addBrandNameSuffix
                title="Community Guidelines"
                description="We believe that safety and belonging create the greatest opportunities for understanding, connection, and growth."
            />

            <Guidelines />
            <SetupMindfulCommunicationVideoPage />
        </PageWrapper>
    );
};

export default GuidelinesPage;
