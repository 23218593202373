import { Chip, ChipProps, alpha } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import classNames from "classnames";
import _ from "lodash";

const useStyles = makeStyles((theme) => ({
    root: {
        cursor: "pointer",

        color: theme.palette.grey700,
        background: theme.palette.grey200,

        "&:hover": {
            backgroundColor: theme.palette.grey100,
        },
    },
    selected: {
        color: theme.palette.neutralWhite,
        backgroundColor: theme.palette.primaryLeaves,

        "&:hover": {
            color: theme.palette.neutralWhite,
            backgroundColor: theme.palette.accentEarthy,
        },
    },
}));

export interface ToggleChipProps extends Omit<ChipProps, "onChange"> {
    selected: boolean;
    onChange: (value: boolean) => void;
}

export const ToggleChip = (props: ToggleChipProps) => {
    const classes = useStyles();
    const chipProps = _.omit(props, "onChange", "enabled");
    return (
        <Chip
            {...chipProps}
            clickable={false}
            data-selected={props.selected.toString()}
            classes={{
                root: classNames(classes.root, {
                    [classes.selected]: props.selected,
                }),
            }}
            onClick={() => props.onChange(!props.selected)}
        />
    );
};
