import { Message, MessageType } from "@app/shared/types";
import { alpha, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { LoggedInUserProfileContext } from "features/auth/AuthRoute";
import _ from "lodash";
import { DateTime } from "luxon";
import { useContext, useEffect, useState } from "react";
import {
    MessageThreadPanelDeleteButton,
    OnDeleteMessageFunction,
} from "./MessageThreadPanelDeleteButton";
import { Anchorme } from "react-anchorme";

import stickyIcon from "images/icons/sticky.svg";

const useStyles = makeStyles((theme) => ({
    message: {
        position: "relative",
        backgroundColor: alpha(theme.palette.grey700, 0.05),
        borderRadius: theme.borderRadius.default,
        padding: theme.spacing(4),
        paddingBottom: theme.spacing(5),
        marginBottom: theme.spacing(2),
    },

    messageTime: {
        position: "absolute",
        bottom: theme.spacing(1),
        right: theme.spacing(2),
        color: alpha(theme.palette.grey700, 0.4),
        marginBottom: 0,
    },

    messageContent: {
        whiteSpace: "pre-line",
        overflowWrap: "break-word",
        marginBottom: 0,
    },

    stickyIcon: {
        position: "absolute",
        top: 0,
        right: 0,
        transform: "translate(30%, -30%)",
        display: "none",
    },
}));

export const MessagePanel = (props: {
    messageThreadId: string;
    message: Message;
    onDeleteMessage: OnDeleteMessageFunction;
}) => {
    const classes = useStyles();
    const { message, messageThreadId, onDeleteMessage } = props;
    const user = useContext(LoggedInUserProfileContext);

    // State is updated regularly to re-render the component and update the relative times displayed
    const [, setDate] = useState(Date.now());
    const refreshFrequency = 60 * 1000; // Refresh every minute
    useEffect(() => {
        const updateInterval = setInterval(() => setDate(Date.now()), refreshFrequency);
        return () => {
            clearInterval(updateInterval);
        };
    }, [refreshFrequency]);

    const formatDate = (dateStr: string, timezone: string) => {
        const date = DateTime.fromISO(dateStr);
        if (date.plus({ minutes: 1 }) > DateTime.now()) {
            return "just now";
        } else if (date.plus({ days: 1 }) < DateTime.now()) {
            return date.toLocaleString({
                month: "short",
                day: "numeric",
                hour: "numeric",
                minute: "2-digit",
            });
        } else {
            return date.toRelative();
        }
    };

    const canDeleteMessage =
        !message.type && (message.author.id === user.profile.id || _.includes(user.roles, "admin"));
    return (
        <>
            <article className={classes.message} data-testid="message">
                <header>
                    {message.type === MessageType.RECAP && (
                        <img src={stickyIcon} alt="" className={classes.stickyIcon} />
                    )}
                </header>

                <Typography
                    variant="body1"
                    data-testid="messageContent"
                    className={classes.messageContent}
                >
                    <Anchorme target="_blank" rel="external nofollow">
                        {message.content.trim()}
                    </Anchorme>
                </Typography>

                <footer>
                    {canDeleteMessage && (
                        <MessageThreadPanelDeleteButton
                            messageThreadId={messageThreadId}
                            message={message}
                            onDeleteMessage={onDeleteMessage}
                        />
                    )}
                    <Typography variant="body1" className={classes.messageTime}>
                        {formatDate(message.date, user.timeZone)}
                    </Typography>
                </footer>
            </article>
        </>
    );
};
