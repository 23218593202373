import { useMutation } from "@apollo/client";
import { Typography } from "@mui/material";
import { analyticsTrack } from "app/analytics/track";
import { GRAPHQL_MUTATION_SUBMIT_TEACHER_POST_SESSION_DATA } from "app/queries";
import { routes } from "app/routes";
import { BackLinkButton } from "components/BackButton";
import { GenericErrorMessage } from "components/GenericErrorMessage";
import LoadingBar from "components/LoadingBar";
import { PageWrapper } from "components/PageWrapper";
import { LinkButton } from "features/navigation/LinkButton";
import { SuccessPageLayout } from "layouts/SuccessPageLayout";
import _ from "lodash";
import { useEffect, useState } from "react";
import { StepProps } from "./postSessionFlowTypes";

export const SubmitTeacherPostSessionFlow = (props: StepProps) => {
    const [submit, { loading, called, error }] = useMutation(
        GRAPHQL_MUTATION_SUBMIT_TEACHER_POST_SESSION_DATA,
        {
            refetchQueries: ["MessageThread"],
            onError: _.noop,
            variables: {
                sanghaId: props.sangha.id,
                data: {
                    recapMessage: props.data.recap.message,
                    recapSignature: props.data.recap.signature,
                    reflectionQuestion: props.data.reflectionQuestion,
                },
            },
        },
    );

    useEffect(() => {
        submit();
    }, [submit]);

    const [loaded, setLoaded] = useState(false);
    useEffect(() => {
        if (!loaded && props.sangha && props.teacherName) {
            analyticsTrack(`teacher.postSession.submitted`, {
                sanghaName: props.sangha.name,
                sanghaId: props.sangha.id,
                sanghaCycle: props.sangha.cycle,
                teacherName: props.teacherName,
            });
            setLoaded(true);
        }
    }, [props.sangha, props.teacherName, loaded]);

    if (error) {
        analyticsTrack("teacher.postSession.error", {
            error: error,
            errorName: error.name,
            errorMessage: error.message,
            errorStack: error.stack,
            sanghaName: props.sangha.name,
            sanghaId: props.sangha.id,
            teacherName: props.teacherName,
            data: props.data,
        });

        return (
            <PageWrapper>
                <BackLinkButton to={routes.teacherPostSessionReflection(props.sangha.id)} />
                <div>
                    <GenericErrorMessage
                        alternateInstruction={`An error occurred while submitting the reflection. Please verify if the message was sent successfully before attempting to resubmit. Also, please copy the error message (${error.message}) and`}
                    />
                </div>
            </PageWrapper>
        );
    }

    if (loading || !called) {
        return (
            <PageWrapper>
                <LoadingBar />
            </PageWrapper>
        );
    }

    return (
        <SuccessPageLayout>
            <Typography variant="h2">Sent to {props.sangha.name}</Typography>
            <Typography variant="body1">Thank you for your guidance.</Typography>

            <LinkButton
                data-testid="messageBoardButton"
                variant="primary"
                to={routes.memberSanghaDetails(props.sangha.id)}
                disableElevation
            >
                See message board
            </LinkButton>
        </SuccessPageLayout>
    );
};
