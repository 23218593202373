import { trueColor } from "@cloudinary/url-gen/qualifiers/colorSpace";
import {
    Checkbox,
    CircularProgress,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
} from "@mui/material";
import _ from "lodash";

export type CheckboxItem = {
    label: string;
    value: string;
    color?: { default: string; light: string };
    audience?: string;
};

export interface CheckboxListItemProps {
    checked: boolean;
    item: CheckboxItem;
    onToggle: (checked: boolean) => void;
    disabled?: boolean;
}

export const CheckboxListItem = (props: CheckboxListItemProps): JSX.Element => {
    const { checked, item, onToggle, disabled } = props;
    const labelId = `checkbox-list-label-${_.camelCase(item.value)}`;

    return (
        <ListItem disablePadding>
            <ListItemButton
                role={undefined}
                onClick={() => onToggle(!checked)}
                dense
                disabled={disabled}
            >
                <ListItemIcon>
                    {disabled ? (
                        <CircularProgress size={15} sx={{ margin: "13.5px 0px 13.5px 0px" }} />
                    ) : (
                        <Checkbox
                            edge="start"
                            checked={checked}
                            tabIndex={-1}
                            disableRipple
                            inputProps={{ "aria-labelledby": labelId }}
                            style={{ color: item.color?.default }}
                        />
                    )}
                </ListItemIcon>
                <ListItemText id={labelId} primary={item.label} />
            </ListItemButton>
        </ListItem>
    );
};
