import { Button, Typography } from "@mui/material";
import PageWrapper from "components/PageWrapper";
import makeStyles from "@mui/styles/makeStyles";
import { useLocation } from "react-router";
import { useLogEventOnLoad } from "hooks/useLogEventOnLoad";
import { push } from "connected-react-router";
import { useDispatch } from "react-redux";
import { routes } from "app/routes";

const useStyles = makeStyles((theme) => {
    return {
        customWrapper: {
            minHeight: "70vh",
            textAlign: "center",
        },
        button: {
            margin: "0 auto",
            display: "block",
            marginTop: theme.spacing(3),
        },
    };
});

export const NotFoundPage = () => {
    const classes = useStyles();
    const reactRouterLocation = useLocation();
    const dispatch = useDispatch();


    useLogEventOnLoad("page.not.found", {
        location: reactRouterLocation.pathname,
        search: reactRouterLocation.search,
        hash: reactRouterLocation.hash,
        referrer: document.referrer,
    });

    return (
        <PageWrapper className={classes.customWrapper}>
            <Typography variant="h1" sx={{ mb: 0, mt: 10 }}>
                404
            </Typography>
            <Typography variant="h2" sx={{ mb: 0, mt: 0 }}>
                Page not found
            </Typography>
            <Button
                variant="primary"
                className={classes.button}
                onClick={() => dispatch(push(routes.home()))}
            >
                Go to homepage
            </Button>
        </PageWrapper>
    );
};

export default NotFoundPage;
