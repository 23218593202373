import { Fade } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import classNames from "classnames";
import { useEffect, useState } from "react";

const useStyles = makeStyles((theme) => ({
    container: {
        position: "relative",
        overflow: "hidden",
    },

    image: {
        position: "absolute",
        left: 0,
        top: 0,
        right: 0,
        bottom: 0,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "50% 50%",
        backgroundColor: theme.palette.background.contrast,
    },
}));

export interface FadeCarouselProps {
    images: string[];
    interval: number;
    className?: string;
    fadeTime?: number;
    startDelay?: number;
    classes?: Partial<ReturnType<typeof useStyles>>;
}

export const FadeCarousel = (props: FadeCarouselProps) => {
    const [current, setCurrent] = useState(0);

    const classes = useStyles(props);

    const numItems = props.images.length;

    useEffect(() => {
        let interval: NodeJS.Timeout;

        const timeout = setTimeout(() => {
            interval = setInterval(() => {
                setCurrent((prev) => (prev + 1) % numItems);
            }, props.interval);
        }, props.startDelay || 0);

        return () => {
            clearTimeout(timeout);
            if (interval) {
                clearInterval(interval);
            }
        };
    }, [props.interval, props.startDelay, numItems]);

    return (
        <div className={classNames(props.className, classes.container)}>
            {props.images.map((image, index) => (
                <Fade key={index} in={current === index} timeout={props.fadeTime || 1000}>
                    <div
                        className={classes.image}
                        style={{
                            backgroundImage: `url("${image}")`,
                        }}
                    />
                </Fade>
            ))}
        </div>
    );
};
