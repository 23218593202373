import { SessionStarted } from "@app/shared/types";
import { DateTime, Interval } from "luxon";

export const isSessionStarted = (args: {
    sessionStart: DateTime | null;
    sessionEnd: DateTime | null;
    currentServerTime: DateTime;
}) => {
    if (!args.sessionStart || !args.sessionEnd) {
        return SessionStarted.NOT_YET_STARTED;
    }

    const sessionInterval = Interval.fromDateTimes(args.sessionStart, args.sessionEnd);
    const sessionAboutToStartInterval = Interval.before(args.sessionStart, {
        minutes: 15,
    });

    const currentTime = args.currentServerTime;

    if (sessionInterval.contains(currentTime)) {
        return SessionStarted.IN_PROGRESS;
    } else if (sessionAboutToStartInterval.contains(currentTime)) {
        return SessionStarted.ABOUT_TO_START;
    } else if (sessionAboutToStartInterval.isAfter(currentTime)) {
        return SessionStarted.NOT_YET_STARTED;
    } else if (sessionInterval.isBefore(currentTime)) {
        return SessionStarted.FINISHED;
    } else {
        return SessionStarted.NOT_YET_STARTED;
    }
};
