import { SanghaMembership } from "@app/shared/types";
import { firstSessionForScheduleAfterDate, humanReadableTimeZone } from "@app/shared/utils";
import { DateTime } from "luxon";

export const SanghaFirstSessionDate = (props: {
    sanghaMembership: SanghaMembership;
    timeZone: string;
}) => {
    const { sanghaMembership, timeZone } = props;

    const firstSessionDate = sanghaMembership
        ? firstSessionForScheduleAfterDate(
              DateTime.fromISO(sanghaMembership.sangha.firstSessionDate),
              DateTime.fromISO(sanghaMembership.startDate),
              sanghaMembership.sangha.timeZone,
              sanghaMembership.sangha.cycle,
          ).setZone(timeZone)
        : null;

    if (firstSessionDate) {
        const date = firstSessionDate.toLocaleString(DateTime.DATE_FULL);
        const startTime = firstSessionDate.toLocaleString(DateTime.TIME_SIMPLE);
        const endTime = firstSessionDate
            .plus({ minutes: sanghaMembership.sangha.duration })
            .toLocaleString(DateTime.TIME_SIMPLE);

        const timeZoneName = humanReadableTimeZone(timeZone);

        return (
            <span>
                <strong>
                    {date}, from {startTime} - {endTime}
                </strong>
                {` in ${timeZoneName}`}
            </span>
        );
    } else {
        return null;
    }
};
