import { validationResultHasErrors, ValidationResultMap } from "app/validation";
import { CSSelectField, CSSelectFieldProps } from "components/CSSelectField";
import { CSTextField, CSTextFieldProps } from "components/CSTextField";
import { scrollToFirstError } from "components/scrollToFirstError";
import * as React from "react";
import { useState } from "react";

export function useFormBuilder<T>(options: {
    defaults: T;
    preValidationFunction?: (value: any) => T;
    validationFunction: (value: any) => ValidationResultMap;
    onSave: (data: T) => void;
    disableFields?: boolean;
    textFieldProps?: Partial<CSTextFieldProps>;
}) {
    const {
        defaults,
        preValidationFunction,
        validationFunction,
        onSave,
        disableFields,
        textFieldProps,
    } = options;

    const [data, setData] = useState<T>(defaults);
    const [submitted, setSubmitted] = useState(false);

    const preValidatedResult = preValidationFunction ? preValidationFunction(data) : data;

    const validationResult = validationFunction(preValidatedResult);

    const updateField = (field: keyof T, value: string) => {
        setData((prev) => ({
            ...prev,
            [field]: value,
        }));
    };

    const onSubmit = (ev?: React.MouseEvent) => {
        if (ev?.preventDefault) {
            ev.preventDefault();
        }

        setSubmitted(true);

        if (validationResultHasErrors(validationResult)) {
            scrollToFirstError();
            return;
        }

        onSave(preValidatedResult);
    };

    const getTextFieldProps = (
        field: keyof T,
        label: string,
        extraProps: Partial<CSTextFieldProps> = {},
    ): CSTextFieldProps => {
        return {
            ...textFieldProps,
            ...extraProps,
            label: label,
            showError: submitted,
            error: (validationResult as { [P in keyof T]?: string })[field],
            disabled: disableFields,
            value: data[field] || "",
            onChange: (value) => {
                updateField(field, value);
                if (extraProps?.onChange) {
                    extraProps.onChange(value);
                }
            },
        };
    };

    const makeTextField = (
        field: keyof T,
        label: string,
        extraProps: Partial<CSTextFieldProps> = {},
        testId = `${String(field)}Field`,
    ) => {
        return (
            <CSTextField {...getTextFieldProps(field, label, extraProps)} data-testid={testId} />
        );
    };

    const makeSelectField = (
        field: keyof T,
        label: string,
        options: CSSelectFieldProps["options"],
        extraProps: Partial<CSTextFieldProps> = {},
        testId = `${String(field)}Field`,
    ) => {
        return (
            <CSSelectField
                {...getTextFieldProps(field, label, extraProps)}
                options={options}
                data-testid={testId}
            />
        );
    };

    return {
        data,
        setData,
        updateField,
        submitted,
        onSubmit,
        makeTextField,
        makeSelectField,
        validationResult,
    };
}
