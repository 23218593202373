import { UserProfile } from "@app/shared/types";
import { Button, Divider, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { analyticsTrack } from "app/analytics/track";
import {
    AgeRangeOptions,
    EthnicityOptions,
    GenderOptions,
    SexualOrientationOptions,
} from "app/constants";
import { useFormBuilder } from "features/formBuilder/useFormBuilder";
import _ from "lodash";
import { useContext, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectUserProfile, updateAccount } from "../../auth/auth";
import { validateProfile } from "./memberProfileHelpers";
import { DateTime } from "luxon";
import { LoggedInUserProfileContext } from "features/auth/AuthRoute";
import { IANATimezones } from "@app/shared/utils";
import ProfilePicture from "./ProfilePicture";
import { saveUserProfile } from "./memberProfileSlice";
import { CSSelectField } from "components/CSSelectField";
import { FixedCTAContainer } from "features/signup/FixedCTAContainer";
import PageWrapper from "components/PageWrapper";

const useStyles = makeStyles((theme) => ({
    contentContainer: {
        paddingBottom: theme.spacing(14),
        [theme.breakpoints.down("sm")]: {
            paddingBottom: theme.spacing(8),
        },
    },
    form: {
        width: "100%", // Fix IE 11 issue.
        position: "relative",
    },
    formField: {
        marginBottom: theme.spacing(2),
    },

    avatar: {
        width: 150,
        height: 150,
        cursor: "pointer",
        marginBottom: theme.spacing(4),

        "&:hover": {
            boxShadow: `0px 0px 10px ${theme.palette.grey800}`,
        },
    },

    avatarLoading: {
        width: "100%",
        height: 120,
        paddingTop: theme.spacing(4),
        marginBottom: theme.spacing(4),
    },

    profilePicContainer: {
        [theme.breakpoints.up("sm")]: {
            alignItems: "center",
        },
    },

    profilePicImage: {
        paddingRight: theme.spacing(4),
    },

    timezoneDropdown: { marginBottom: "1rem" },
}));

type Fields = Pick<
    UserProfile,
    | "fullName"
    | "nickName"
    | "location"
    | "bio"
    | "pronouns"
    | "ageRange"
    | "ethnicity"
    | "sexualOrientation"
    | "gender"
>;

const EditableFields = [
    "fullName",
    "nickName",
    "location",
    "bio",
    "pronouns",
    "ageRange",
    "ethnicity",
    "sexualOrientation",
    "gender",
] as const;

interface EditProfileFormProps {
    header: React.ReactNode;
    onSaveComplete: () => void;
    progressBar?: React.ReactNode;
}

export const EditProfileForm = (props: EditProfileFormProps) => {
    const { header, progressBar, onSaveComplete } = props;
    const dispatch = useDispatch();
    const classes = useStyles();
    const user = useContext(LoggedInUserProfileContext);
    const [timezone, setTimezone] = useState(user.timeZone || DateTime.local().zoneName);

    const userProfile = useSelector(selectUserProfile) as UserProfile;

    const formDefaults = _.pick(userProfile, EditableFields);

    const { makeTextField, makeSelectField, onSubmit, submitted, validationResult, setData } =
        useFormBuilder<Fields>({
            defaults: formDefaults,
            validationFunction: (value) => ({
                ...validateProfile(value),
                picture: userProfile.picture ? null : "Please upload a profile picture",
            }),
            onSave: async (data) => {
                const result = await dispatch(
                    saveUserProfile({
                        id: userProfile.id,
                        userProfile: data,
                    }),
                );

                const { payload } = result as any;

                if (payload) {
                    analyticsTrack("member.sangha.my_profile.saved");
                }
                dispatch(updateAccount({ timeZone: timezone }));

                onSaveComplete();
            },
            textFieldProps: {
                className: classes.formField,
            },
        });

    const renderTimezoneDropdown = () => {
        return (
            <CSSelectField
                options={IANATimezones}
                value={timezone}
                onChange={(value) => {
                    setTimezone(value);
                }}
                label="Time Zone"
                className={classes.timezoneDropdown}
                required
            />
        );
    };

    return (
        <>
            {progressBar}
            <PageWrapper>
                <div className={classes.contentContainer}>
                    {header}
                    <form className={classes.form}>
                        <Typography variant="h2">1. Putting yourself out there</Typography>

                        <div className={classes.profilePicContainer}>
                            <ProfilePicture />
                            {submitted && validationResult.picture && (
                                <Typography
                                    variant="body2"
                                    color="error"
                                    className="validatedFormFieldError"
                                >
                                    {validationResult.picture}
                                </Typography>
                            )}
                        </div>

                        <Divider />

                        <Typography variant="h2">2. Some basic information</Typography>
                        <Typography variant="body1">
                            Everything but your time zone and full name will be public to other
                            members.
                        </Typography>

                        {renderTimezoneDropdown()}
                        {makeTextField("fullName", "Your full name", {
                            required: true,
                        })}
                        {makeTextField("nickName", "What you go by", {
                            required: true,
                        })}

                        {makeTextField("pronouns", "Your pronouns")}
                        {makeTextField("location", "Your location")}
                        {makeTextField("bio", "Your short intro", {
                            multiline: true,
                        })}

                        <Divider />

                        <Typography variant="h2">3. A little more info</Typography>
                        <Typography variant="body1">
                            This helps us understand the diversity of the community and share
                            relevant groups with you. This info is <strong>private</strong> from
                            other members.
                        </Typography>

                        {makeSelectField("ageRange", "Age Group", AgeRangeOptions, {
                            required: true,
                        })}
                        {makeSelectField("gender", "Gender Identity", GenderOptions, {
                            required: true,
                        })}
                        {makeSelectField("ethnicity", "Ethnic Identity", EthnicityOptions, {
                            required: true,
                        })}
                        {makeSelectField(
                            "sexualOrientation",
                            "Sexual Orientation",
                            SexualOrientationOptions,
                            {
                                required: true,
                            },
                        )}

                        <FixedCTAContainer>
                            <Button
                                variant="primary"
                                onClick={onSubmit}
                                type="submit"
                                role="button"
                                data-testid="saveProfileButton"
                            >
                                Save changes
                            </Button>
                        </FixedCTAContainer>
                    </form>
                </div>
            </PageWrapper>
        </>
    );
};

export default EditProfileForm;
