import { dictionaryValidator, stringValidator } from "app/validation";

export const validateProfile = dictionaryValidator({
    fullName: stringValidator({ required: true }),
    nickName: stringValidator({ required: true }),
    ageRange: stringValidator({ required: true }),
    ethnicity: stringValidator({ required: true }),
    sexualOrientation: stringValidator({ required: true }),
    gender: stringValidator({ required: true }),
    picture: stringValidator({ required: true }),
});
