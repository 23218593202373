import { EmbeddedCheckout, EmbeddedCheckoutProvider } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { analyticsTrack } from "app/analytics/track";
import { StripePublicKey } from "app/stripe";
import { useEffect } from "react";
import { SignupProgressBar } from "../signup/SignupProgressBar";
import { Course } from "@app/shared/types";

const stripePromise = loadStripe(StripePublicKey);

export interface CourseCheckoutPageProps {
    clientSecret: string;
    course: Course;
}

export const CourseCheckoutPage = (props: CourseCheckoutPageProps) => {
    const { clientSecret, course } = props;
    const options = { clientSecret };

    useEffect(() => {
        const analyticsData = course;
        analyticsTrack("courses.signup.checkout.load", analyticsData);
    }, []);

    return (
        <div data-testid="embeddedCheckout">
            <SignupProgressBar currentStep={1} />
            <EmbeddedCheckoutProvider stripe={stripePromise} options={options}>
                <EmbeddedCheckout />
            </EmbeddedCheckoutProvider>
        </div>
    );
};
