import { Typography } from "@mui/material";
import { LIMITED_WIDTH } from "app/styles";
import { SuccessPageLayout } from "layouts/SuccessPageLayout";

export const VisitorDonateSuccess = () => {
    return (
        <SuccessPageLayout centered={true}>
            <Typography variant="body1" sx={{ maxWidth: LIMITED_WIDTH }}>
                Thank you very much. Your contribution gives fuel to the movement and helps shapes
                the energy we all surround ourselves with.
            </Typography>
            <Typography variant="body1" sx={{ maxWidth: LIMITED_WIDTH }}>
                Stay in touch to see what your contribution is going towards
            </Typography>
        </SuccessPageLayout>
    );
};
