import { Sangha } from "@app/shared/types";
import { useQueryParams } from "hooks/useQueryParams";
import { useMemo } from "react";

export const usePreferredGroup = (): Sangha["id"] | undefined => {
    const query = useQueryParams();
    const preferredGroupId = query?.preferredGroupId;

    return useMemo(() => {
        if (preferredGroupId) {
            if (Array.isArray(preferredGroupId)) {
                return preferredGroupId[0];
            }
            return preferredGroupId;
        }
        return undefined;
    }, [preferredGroupId]);
};
