/* eslint-disable @typescript-eslint/comma-dangle */
import { AffiliatePartnerPromotion, affiliatePartners } from "@app/shared/partners";
import { useEffect, useMemo } from "react";
import { useLocalStorage, writeStorage } from "@rehooks/local-storage";

export const LOCAL_STORAGE_PARTNER_ID_KEY = "partnerId";

/**
 * [DEPRECATED: use `useAffiliatePartner` instead ] It returns an affiliate partner promotion if
 * the partner id in local storage matches one of the affiliate partner ids
 * @returns the affiliatePartnerPromotion object that matches the partnerId in the local storage.
 */
export const useAffiliatePartnerTracking = (): AffiliatePartnerPromotion | undefined => {
    const [partnerId] = useLocalStorage<string | undefined>(LOCAL_STORAGE_PARTNER_ID_KEY);

    return useMemo(
        () =>
            affiliatePartners.find((affiliatePartner) => affiliatePartner.partnerId === partnerId),
        [partnerId],
    );
};

/**
 * It returns an array of four values: the partner ID, the partner object, a function to set the
 * partner ID, and a function to delete the partner ID
 * @returns An array of 4 items.
 */
export const useAffiliatePartner = (): {
    partnerId: string | null | undefined;
    partner: AffiliatePartnerPromotion | undefined;
    setPartnerId: (newValue: string | null | undefined) => void;
    deletePartnerId: () => void;
} => {
    const [partnerId, setPartnerId, deletePartnerId] = useLocalStorage<string | undefined>(
        LOCAL_STORAGE_PARTNER_ID_KEY,
    );

    return {
        partnerId,
        partner: useMemo(
            () =>
                affiliatePartners.find(
                    (affiliatePartner) => affiliatePartner.partnerId === partnerId,
                ),
            [partnerId],
        ),
        setPartnerId,
        deletePartnerId,
    };
};

/**
 * It takes a promotion code as a parameter, and if it's not undefined, it writes it to local storage
 * @param {undefined | string} promotionCode - The promotion code that the user entered.
 */
export const useSetAffilatePartner = (promotionCode: undefined | string): void => {
    useEffect(() => {
        if (promotionCode) {
            writeStorage(LOCAL_STORAGE_PARTNER_ID_KEY, promotionCode);
        }
    }, [promotionCode]);
};
