import { alpha } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { CSDialog } from "components/CSDialog";
import { CSVideo, CSVideoProps } from "components/CSVideo";

const useStyles = makeStyles((theme) => ({
    plansDialogContainer: {
        padding: theme.spacing(0),
    },
    dialog: {
        marginTop: theme.spacing(6),
        marginBottom: theme.spacing(6),
        marginRight: theme.spacing(6),
        marginLeft: theme.spacing(6),
        [theme.breakpoints.down("md")]: {
            margin: 0,
        },
        flexDirection: "column",
    },
    videoContainer: {
        textAlign: "center",
        margin: 0,

        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-around",
    },
    dialogPaper: {
        borderRadius: theme.borderRadius.default,
    },
}));

export const VideoModal = (props: CSVideoProps) => {
    const classes = useStyles();

    return (
        <CSDialog
            open={true}
            fullScreen={true}
            closeButtonSize="large"
            transitionDuration={700}
            classes={{
                dialogContainer: classes.plansDialogContainer,
                dialog: classes.dialog,
            }}
            dialogClasses={{
                paper: classes.dialogPaper,
            }}
        >
            <CSVideo classes={{ videoContainer: classes.videoContainer }} {...props} />
        </CSDialog>
    );
};
