import makeStyles from "@mui/styles/makeStyles";
import { LIMITED_WIDTH_LARGE } from "app/styles";
import * as React from "react";

const defaultImageAspectRatio = 0.7;

const useStyles = makeStyles((theme) => ({
    container: {
        margin: theme.spacing(0, "auto"),
        padding: theme.spacing(0, 2),
        textAlign: "center",
        maxWidth: LIMITED_WIDTH_LARGE,
        marginBottom: theme.spacing(12),
        [theme.breakpoints.down("md")]: {
            marginBottom: theme.spacing(6),
        },
    },
}));

interface SectionTextCenteredProps {
    children: React.ReactNode;

    classes?: Partial<ReturnType<typeof useStyles>>;
}

export const SectionTextCentered = React.memo((props: SectionTextCenteredProps) => {
    const classes = useStyles(props);

    return <div className={classes.container}>{props.children}</div>;
});
