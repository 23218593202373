import { Typography } from "@mui/material";
import { routes } from "app/routes";
import { push } from "connected-react-router";
import { useDispatch } from "react-redux";
import { CancellationFlow } from "./CancellationFlow";
import { useCallback, useContext, useState } from "react";
import { DateTime } from "luxon";
import { MemberContext } from "../MemberZone";
import { formatTimestamp } from "@app/shared/utils";
import _ from "lodash";
import { OptionBox } from "components/OptionBox";
import makeStyles from "@mui/styles/makeStyles";
import { useMutation } from "@apollo/client";
import { analyticsTrack } from "app/analytics/track";
import { GRAPHQL_MUTATION_CANCEL_MEMBERSHIP, GRAPHQL_QUERY_MEMBERSHIPS } from "app/queries";
import { showSnackbarAlertOnRedirect } from "components/AlertSnackBar";
import LoadingBar from "components/LoadingBar";
import { BRAND_NAME } from "@app/shared/constants";
import { ToggleChip } from "components/ToggleChip";
import { CardToggle } from "components/CardToggle";

export enum CancellationDateType {
    TODAY = "TODAY",
    END_OF_BILLING_CYCLE = "END_OF_BILLING_CYCLE",
}

const useStyles = makeStyles((theme) => ({
    title: {
        marginBottom: 0,
    },
    cardContainer: {
        display: "flex",
        flexDirection: "column",
        gap: theme.spacing(2),
    },
}));

export const CancellationFlowPage7 = (props: {
    setCancellationDate: (dateType: CancellationDateType) => any;
    cancellationDateType: CancellationDateType;
}): JSX.Element => {
    const classes = useStyles();

    const dispatch = useDispatch();
    const [todaySelected, setTodaySelected] = useState<boolean>(false);
    const [endOfBillingSelected, setEndOfBillingSelected] = useState<boolean>(false);
    const [cancellingMembership, setIsCancellingMembership] = useState(false);

    const memberContext = useContext(MemberContext);
    const activeMemberships = memberContext.memberships;
    const membership = _.first(activeMemberships);

    const [cancelMembership] = useMutation(GRAPHQL_MUTATION_CANCEL_MEMBERSHIP, {
        variables: {
            membershipId: membership?.id,
            cancelDate:
                props.cancellationDateType === CancellationDateType.TODAY
                    ? DateTime.now()
                    : membership?.renewalDate,
        },

        refetchQueries: [
            {
                query: GRAPHQL_QUERY_MEMBERSHIPS,
            },
        ],
    });

    const handleNext = useCallback(async () => {
        setIsCancellingMembership(true);
        await cancelMembership();
        showSnackbarAlertOnRedirect("Your membership has been cancelled");
        analyticsTrack(`cancellationFlow.cancelButton.click`, {
            cancellationDateTypeProp: props.cancellationDateType,
            todaySelectedState: todaySelected,
            endOfBillingSelectedState: endOfBillingSelected,
        });
        dispatch(push(routes.memberHomePage()));
    }, [dispatch, props]);

    const renewalDate = formatTimestamp(
        DateTime.fromISO(membership?.renewalDate ?? "").toMillis(),
        {
            month: "long",
            day: "numeric",
            year: "numeric",
        },
    );

    return (
        <CancellationFlow
            continueButtonText="Confirm cancellation"
            continueButtonDisabled={!todaySelected && !endOfBillingSelected}
            pageId="cancellationDateChoice"
            onNext={handleNext}
            content={
                <>
                    <Typography variant="h2">
                        How long do you want to retain access to {BRAND_NAME}?{" "}
                    </Typography>

                    <div className={classes.cardContainer}>
                        <CardToggle
                            onClick={() => {
                                props.setCancellationDate(
                                    CancellationDateType.END_OF_BILLING_CYCLE,
                                );
                                setEndOfBillingSelected(true);
                                if (todaySelected) {
                                    setTodaySelected(false);
                                }
                            }}
                            isSelected={endOfBillingSelected}
                            data-testid="endOfBillingCycle"
                            radioValue={CancellationDateType.END_OF_BILLING_CYCLE}
                        >
                            <div>
                                <Typography variant="body1" className={classes.title}>
                                    <strong>Until {renewalDate}</strong>, the end of my billing
                                    cycle
                                </Typography>
                            </div>
                        </CardToggle>
                        <CardToggle
                            onClick={() => {
                                props.setCancellationDate(CancellationDateType.TODAY);
                                setTodaySelected(true);
                                if (endOfBillingSelected) {
                                    setEndOfBillingSelected(false);
                                }
                            }}
                            isSelected={todaySelected}
                            data-testid="today"
                            radioValue={CancellationDateType.TODAY}
                        >
                            <div>
                                <Typography variant="body1" sx={{ mb: 0 }}>
                                    <strong> End my access today</strong>
                                </Typography>

                                <Typography variant="caption">
                                    If you have extenuating circumstances, you can contact us after
                                    your cancellation to ask about a refund
                                </Typography>
                            </div>
                        </CardToggle>
                    </div>
                    {cancellingMembership && <LoadingBar />}
                </>
            }
        />
    );
};
