import makeStyles from "@mui/styles/makeStyles";
import { NAVBAR_HEIGHT, NAVBAR_HEIGHT_MOBILE } from "app/styles";
import classNames from "classnames";
import PageWrapper from "components/PageWrapper";
import * as React from "react";

const useStyles = makeStyles((theme) => ({
    container: {
        minHeight: "100vh",
        display: "flex",
        alignItems: "center",
    },
    hasNavBar: {
        paddingTop: NAVBAR_HEIGHT,
        marginTop: -1 * NAVBAR_HEIGHT,

        [theme.breakpoints.down("md")]: {
            paddingTop: NAVBAR_HEIGHT_MOBILE,
            marginTop: -1 * NAVBAR_HEIGHT_MOBILE,
        },
    },
    centered: {
        justifyContent: "center",
        textAlign: "center",
    },
}));

export interface SuccessPageLayoutProps {
    children: React.ReactNode;
    centered?: boolean;
    hasNavBar?: boolean;
    classes?: Partial<ReturnType<typeof useStyles>>;
}

export const SuccessPageLayout = (props: SuccessPageLayoutProps) => {
    const classes = useStyles(props);

    return (
        <PageWrapper
            className={classNames(classes.container, {
                [classes.centered]: props.centered,
                [classes.hasNavBar]: props.hasNavBar,
            })}
        >
            <div>{props.children}</div>
        </PageWrapper>
    );
};
