import { OutlinedTextFieldProps, TextField } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import classNames from "classnames";
import _ from "lodash";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        width: "100%",
        maxWidth: 400,
        marginBottom: theme.spacing(2),
    },

    wide: {
        maxWidth: 800,
    },
    noMaxWidth: {
        maxWidth: "none",
    },
}));

const useInputStyles = makeStyles((theme) => ({
    root: {
        marginBottom: 0,
    },
}));

export interface CSTextFieldProps
    extends Omit<OutlinedTextFieldProps, "variant" | "onChange" | "error" | "helperText"> {
    onChange?: (value: string) => void;
    showError?: boolean;
    error?: string | null;
    type?: string;
    classes?: Partial<ReturnType<typeof useStyles>>;
    hasNoMaxWidth?: boolean;
}

export const CSTextField = (props: CSTextFieldProps) => {
    const classes = useStyles(props);
    const inputClasses = useInputStyles();
    return (
        <TextField
            {..._.omit(props, "onChange", "error", "showError")}
            variant="outlined"
            InputProps={{
                classes: inputClasses,
            }}
            classes={{
                root: classNames(classes.root, {
                    [classes.wide]: props.multiline,
                    [classes.noMaxWidth]: props.hasNoMaxWidth,
                }),
            }}
            type={props.type}
            onChange={(ev) => props.onChange && props.onChange(ev.target.value)}
            error={props.showError && Boolean(props.error)}
            helperText={props.showError && props.error}
        />
    );
};
