import { Button, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { GRAPHQL_QUERY_UPCOMING_EVENTS } from "app/queries";
import DocumentContext from "components/DocumentContext";
import PageWrapper from "components/PageWrapper";
import { useCurrentServerTime } from "hooks/useCurrentServerTime";
import { EventListLoader } from "../member/eventDisplay/EventListLoader";
import { SessionType } from "@app/shared/types";
import { routes } from "app/routes";
import { useDispatch } from "react-redux";
import { push } from "connected-react-router";
import { SetupProgressBar } from "./SetupProgressBar";
import { FixedCTAContainer } from "features/signup/FixedCTAContainer";

const useStyles = makeStyles((theme) => ({
    contentContainer: {
        paddingBottom: theme.spacing(14),
        [theme.breakpoints.down("sm")]: {
            paddingBottom: theme.spacing(8),
        },
    },
    fixedCTAContainer: {
        padding: theme.spacing(0, 2),
    },
}));

export const SetupWelcomeSessionPage = () => {
    const currentServerTime = useCurrentServerTime();

    const classes = useStyles();

    const dispatch = useDispatch();

    return (
        <div>
            <DocumentContext title="Welcome &amp; Orientation Sessions" addBrandNameSuffix />

            <SetupProgressBar currentStep={2} />
            <PageWrapper>
                <div className={classes.contentContainer}>
                    <Typography variant="h1">Choose a Welcome & Orientation Session</Typography>

                    <Typography>
                        These sessions are designed to connect you with your deepest aspiration to
                        join this community at this juncture in your life, connect you to fellow
                        members and teachers, help you to understand our community guidelines and
                        our various offerings, and answer any questions you may have. These sessions
                        are required. We kindly ask that all new members participate in them within
                        the first 2 weeks of their membership.
                    </Typography>

                    <EventListLoader
                        eventsQuery={GRAPHQL_QUERY_UPCOMING_EVENTS}
                        queryOptions={{
                            variables: {
                                includeTypes: [SessionType.WELCOME_SESSION],
                            },
                        }}
                        data-testid="dropInSessionsMeditationsList"
                        currentServerTime={currentServerTime}
                        splitEventsByDay={true}
                    />
                </div>

                <FixedCTAContainer className={classes.fixedCTAContainer}>
                    <Button
                        variant="secondary"
                        data-testid="continueButton"
                        fullWidth={true}
                        onClick={() => dispatch(push(routes.setupShowYouAround()))}
                    >
                        Skip for now
                    </Button>
                </FixedCTAContainer>
            </PageWrapper>
        </div>
    );
};
