import { useLazyQuery } from "@apollo/client";
import { MessageThread, Sangha } from "@app/shared/types";
import { CircularProgress, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { analyticsTrack } from "app/analytics/track";
import { GRAPHQL_QUERY_OLD_MESSAGE_THREAD } from "app/queries";
import { NAVBAR_HEIGHT } from "app/styles";
import classNames from "classnames";
import _ from "lodash";
import { useCallback, useEffect, useRef, useState } from "react";
import * as React from "react";
import { EmptyMessageThread } from "./EmptyMessageThread";
import { MessageThreadPanel } from "./MessageThreadPanel";
import { OnDeleteMessageFunction } from "./MessageThreadPanelDeleteButton";

const useStyles = makeStyles((theme) => ({
    container: { minHeight: `calc(100vh - ${NAVBAR_HEIGHT + 260}px)` },

    loader: {
        color: theme.palette.grey400,
        textAlign: "center",
    },

    loaderHidden: {
        visibility: "hidden",
    },

    spinner: {
        marginRight: theme.spacing(1),
    },
}));

const scrollByThreadHeight = (threadRef: React.RefObject<HTMLDivElement>) => {
    if (threadRef.current) {
        window.scrollBy({ top: threadRef.current.clientHeight });
    }
};

export const MessageThreadContainer = (props: { activeThread: MessageThread; sangha: Sangha }) => {
    const classes = useStyles();
    const { activeThread, sangha } = props;

    // Store all threads loaded so far in this piece of state - initialize this with the active thread
    const [threadsLoaded, setThreadsLoaded] = useState<MessageThread[]>([activeThread]);
    // State to flag whether there are more threads available to be loaded
    const [hasMore, setHasMore] = useState(true);

    // Ref attached to the element displaying the topmost thread (most recently loaded)
    const topmostThreadRef = useRef<HTMLDivElement>(null);

    // Resets the threads loaded, starting with just the active thread
    const resetMessages = useCallback(() => {
        setThreadsLoaded([activeThread]);
        setHasMore(true);

        // When threads are reset, scroll down to the end of the active thread
        setTimeout(() => {
            scrollByThreadHeight(topmostThreadRef);
        }, 0);
    }, [activeThread, topmostThreadRef]);

    useEffect(() => {
        // Reset when the active thread is updated (this happens after a sangha session is completed when a new thread is created, or when a message is posted/deleted)
        resetMessages();
    }, [activeThread, resetMessages]);

    const onDeleteMessage: OnDeleteMessageFunction = (messageThreadId) => {
        if (messageThreadId !== activeThread.id) {
            // Reset messages when a message from an older thread is deleted, since we'll need to refetch that data
            resetMessages();
        }
    };

    // Query to load older threads and update the state when they load
    const [getOldMessageThread, messageThreadQueryStatus] = useLazyQuery(
        GRAPHQL_QUERY_OLD_MESSAGE_THREAD,
        {
            fetchPolicy: "network-only",
            onCompleted: (data) => {
                const { messageThread } = data;
                if (messageThread) {
                    analyticsTrack(`member.sangha.messages.oldThreadLoaded`, {
                        sanghaId: sangha.id,
                        threadsLoaded: threadsLoaded.length + 1,
                        threadId: messageThread.id,
                        activeThreadId: activeThread.id,
                    });
                    setThreadsLoaded((prev) => [messageThread, ...prev]);
                } else {
                    analyticsTrack(`member.sangha.messages.allThreadsLoaded`, {
                        sanghaId: sangha.id,
                        threadsLoaded: threadsLoaded.length,
                        activeThreadId: activeThread.id,
                    });
                    setHasMore(false);
                }
            },
        },
    );

    const mostRecentlyLoadedThreadId = threadsLoaded[0]?.id;
    useEffect(() => {
        // When a new thread is loaded, scroll down by the height of the element for that thread
        scrollByThreadHeight(topmostThreadRef);
    }, [topmostThreadRef, mostRecentlyLoadedThreadId]);

    // Ref attached to the loader element at the top of the container
    const loaderRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
        if (!window.IntersectionObserver) {
            return;
        }

        const loadNextThread = () => {
            // Trigger loading the next thread if it isn't already loading, or if it wasn't just loaded
            if (
                messageThreadQueryStatus.loading ||
                messageThreadQueryStatus.variables?.nextThreadId === mostRecentlyLoadedThreadId
            ) {
                return;
            }

            getOldMessageThread({
                variables: { nextThreadId: mostRecentlyLoadedThreadId },
            });
        };

        // Create an observer that loads a new thread when the loader element enters the browser viewport
        // This oberver is re-created each time new threads are loaded (i.e. threadsLoaded changes)
        const observerOptions = {
            root: null,
            threshold: 0,
        };
        const observer = new IntersectionObserver((entries) => {
            if (entries[0]?.isIntersecting && hasMore) {
                loadNextThread();
            }
        }, observerOptions);
        if (loaderRef.current) {
            observer.observe(loaderRef.current);
        }
        return () => observer.disconnect();
    }, [mostRecentlyLoadedThreadId, messageThreadQueryStatus, getOldMessageThread, hasMore]);

    // If there are no more threads to be loaded, and none of the loaded threads have any messages, we want to show an empty message
    const sanghaHasNoMessages =
        !hasMore &&
        _.every(threadsLoaded, (thread) => !thread.messages || thread.messages.length === 0);

    return (
        <div data-testid="messageThreadContainer">
            <div className={classes.container} data-testid="messagePanel">
                <div
                    className={classNames(classes.loader, {
                        [classes.loaderHidden]: !hasMore,
                    })}
                    data-testid="messageThreadLoader"
                    ref={loaderRef}
                >
                    <CircularProgress size={12} className={classes.spinner} />{" "}
                    <Typography variant="body2">loading...</Typography>
                </div>

                {sanghaHasNoMessages && <EmptyMessageThread />}

                {threadsLoaded.map((thread, index) => (
                    <div
                        key={thread.id}
                        data-testid={thread.id === activeThread.id ? "activeThread" : "oldThread"}
                        data-thread-id={thread.id}
                        ref={index === 0 ? topmostThreadRef : null}
                    >
                        <MessageThreadPanel
                            thread={thread}
                            sangha={sangha}
                            onDeleteMessage={onDeleteMessage}
                        />
                    </div>
                ))}
            </div>
        </div>
    );
};
