import { ApolloProvider } from "@apollo/client/react";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { isRunningCypress } from "app/config";
import { RawGenericErrorPage } from "components/RawGenericErrorPage";
import { ConnectedRouter } from "connected-react-router";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { ReactReduxFirebaseProvider } from "react-redux-firebase";
import { AnalyticsProvider } from "use-analytics";
import { QueryParamProvider } from "use-query-params";
import { ReactRouter5Adapter } from "use-query-params/adapters/react-router-5";
import App from "./App";
import analytics from "./app/analytics/analytics";
import { apolloClient } from "./app/apollo";
import history from "./app/history";
import { firebaseProviderProps, store } from "./app/store";
import ScrollToTop from "./components/ScrollToTop";
import * as serviceWorker from "./serviceWorker";
import "./styles/addEventTheme.css";
import "./styles/index.css";
import { GenericErrorMessageProvider } from "components/GenericErrorMessageContextProvider";

Sentry.init({
    dsn: "https://025b1a53cea941618ba263d327fe9a0e@o495249.ingest.sentry.io/5567692",
    autoSessionTracking: true,
    environment: process.env.REACT_APP_ENVIRONMENT,
    release: process.env.REACT_APP_VERSION,
    integrations: [new Integrations.BrowserTracing()],
    normalizeDepth: 8,
    enabled: !isRunningCypress(),

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
});

ReactDOM.render(
    <Sentry.ErrorBoundary fallback={<RawGenericErrorPage />}>
        <Provider store={store}>
            <ApolloProvider client={apolloClient}>
                <AnalyticsProvider instance={analytics}>
                    <ConnectedRouter history={history}>
                        <GenericErrorMessageProvider>
                            <QueryParamProvider adapter={ReactRouter5Adapter}>
                                <ScrollToTop />
                                <ReactReduxFirebaseProvider {...firebaseProviderProps}>
                                    <App />
                                </ReactReduxFirebaseProvider>
                            </QueryParamProvider>
                        </GenericErrorMessageProvider>
                    </ConnectedRouter>
                </AnalyticsProvider>
            </ApolloProvider>
        </Provider>
    </Sentry.ErrorBoundary>,
    document.getElementById("root"),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
