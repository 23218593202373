import { makeStyles } from "@mui/styles";
import classNames from "classnames";

const useStyles = makeStyles((theme) => ({
    checkCircle: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: 26,
        height: 26,
        borderRadius: theme.borderRadius.circular,
        border: `2px solid ${theme.palette.primaryBanyan}`,
        flexShrink: 0,
        "&.checked": {
            backgroundColor: theme.palette.primaryLeaves,
            color: "white",
        },
    },
    checkMark: {
        fontSize: 18,
    },
}));

interface CheckCircleProps {
    checked: boolean;
}

export const CheckCircle = (props: CheckCircleProps) => {
    const { checked } = props;

    const classes = useStyles();

    return (
        <div
            className={`${classes.checkCircle} ${checked ? "checked" : ""}`}
            data-testid="checkCircle"
        >
            {checked && (
                <span className={classNames("material-symbols-rounded", classes.checkMark)}>
                    check
                </span>
            )}
        </div>
    );
};
