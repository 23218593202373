import Button, { ButtonProps } from "@mui/material/Button";
import { ConfirmationDialog } from "components/ConfirmationDialog";
import _ from "lodash";
import { useState } from "react";

export interface ConfirmationDialogProps {
    localStorageKey?: string; // Remember in localStorage whether the confirmation was seen and accepted (if this is not set, we show twhe modal every time)
    content: React.ReactNode;
    button: string;
    enableConfirmButtonDelay?: number;
}

export interface ButtonWithConfirmationDialogsProps extends ButtonProps {
    onOpenDialog?: (dialogIndex: number) => void;
    onConfirm: VoidFunction;
    confirmationDialogs: ConfirmationDialogProps[];
}

export const ButtonWithConfirmationDialogs = (props: ButtonWithConfirmationDialogsProps) => {
    const { onOpenDialog, onConfirm } = props;

    const confirmationDialogs = props.confirmationDialogs || [];

    const [confirmationDialogStates, setConfirmationDialogStates] = useState(
        confirmationDialogs.map((x) => false),
    );

    const setConfirmationDialogOpen = (index: number, newValue: boolean) => {
        setConfirmationDialogStates((states) => {
            const clonedStates = [...states];
            clonedStates[index] = newValue;
            return clonedStates;
        });
    };

    const isConfirmationDialogOpen = (index: number) => confirmationDialogStates[index];

    // Triggers a confirmation modal
    const triggerConfirmationModal = (dialogIndex: number) => {
        const dialogProps = confirmationDialogs[dialogIndex];

        // Check if the modal has been viewed before (we store this in localStorage)
        const dialogSeen = dialogProps?.localStorageKey
            ? window.localStorage.getItem(dialogProps.localStorageKey)
            : null;

        if (dialogProps && !dialogSeen) {
            if (onOpenDialog) {
                onOpenDialog(dialogIndex);
            }
            setConfirmationDialogOpen(dialogIndex, true);
        } else {
            // Otherwise trigger the next modal in line
            if (confirmationDialogs.length > dialogIndex + 1) {
                triggerConfirmationModal(dialogIndex + 1);
            } else {
                // If there are no more modals to be triggered, trigger the final action
                onConfirm();
            }
        }
    };

    return (
        <>
            <Button
                {..._.omit(props, "onOpenDialog", "onConfirm", "confirmationDialogs")}
                onClick={(e) => {
                    e.preventDefault();
                    triggerConfirmationModal(0);
                }}
            />
            {confirmationDialogs.map((dialogProps, dialogIndex) => (
                <ConfirmationDialog
                    key={dialogIndex}
                    open={isConfirmationDialogOpen(dialogIndex)}
                    onClose={() => {
                        setConfirmationDialogOpen(dialogIndex, false);
                    }}
                    scroll="body"
                    onConfirm={() => {
                        setConfirmationDialogOpen(dialogIndex, false);

                        // Store in localStorage that the modal was seen, if necessary
                        if (dialogProps.localStorageKey) {
                            window.localStorage.setItem(dialogProps.localStorageKey, "true");
                        }

                        // Trigger the next modal in line (or starts/joins the session if there are no more)
                        triggerConfirmationModal(dialogIndex + 1);
                    }}
                    confirmButtonText={dialogProps.button}
                    children={dialogProps.content}
                    enableConfirmButtonDelay={dialogProps.enableConfirmButtonDelay}
                />
            ))}
        </>
    );
};
