import { Typography } from "@mui/material";
import { routes } from "../../../app/routes";
import PageWrapper from "components/PageWrapper";
import { NavLink } from "../../navigation/NavLink";

export const MembershipDeclined = (props: { email: string }) => {
    return (
        <PageWrapper>
            <Typography variant="h2">Alright, we'll keep looking!</Typography>
            <Typography variant="body1">
                You’re back on the waitlist. Check back at <strong>{props.email}</strong> for
                updates. If you’d like, you can also update your application
            </Typography>
            <Typography variant="body1">
                <NavLink to={routes.memberSanghas()}>Update my application</NavLink>
            </Typography>
        </PageWrapper>
    );
};
