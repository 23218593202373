import { useQuery } from "@apollo/client";
import { analyticsTrack } from "app/analytics/track";
import { GRAPHQL_QUERY_CURRENT_TIME } from "app/queries";
import { DateTime } from "luxon";
import { useEffect, useState } from "react";

export const useServerTimeDelta = () => {
    const [serverTimeDeltaInSeconds, setServerTimeDeltaInSeconds] = useState<number>(0);

    const { data, loading, refetch } = useQuery(GRAPHQL_QUERY_CURRENT_TIME, {
        fetchPolicy: "network-only",
    });

    useEffect(() => {
        if (data?.currentTime) {
            const serverTime = DateTime.fromISO(data.currentTime);
            const delta = serverTime.toSeconds() - DateTime.now().toSeconds();
            setServerTimeDeltaInSeconds(delta);
        }
    }, [data]);

    useEffect(() => {
        if (!loading && !data?.currentTime) {
            // Retry if we failed to retrieve the time
            refetch();

            // Metric to see how often this happens - we might need a more robust refetch mechanism
            analyticsTrack("serverTime.failedToFetch");
        }
    }, [data, loading, refetch]);

    return serverTimeDeltaInSeconds;
};
