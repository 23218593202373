import { Divider, List } from "@mui/material";
import { useMemo } from "react";
import { CheckboxListItem, CheckboxItem } from "./CheckboxListItem";
import { CheckboxListInner, CheckboxListInnerProps } from "./CheckboxListInner";

type CheckboxListProps = CheckboxListInnerProps & {
    emptyLabel?: string;
    disabled?: boolean;
};

export const CheckboxList = (props: CheckboxListProps) => {
    const { emptyLabel, items, selectedValues, onChange, disabled } = props;

    const emptyItem = useMemo<CheckboxItem>(() => {
        const label = emptyLabel || "Anything";
        return {
            label,
            value: label,
        };
    }, [props.emptyLabel]);

    return (
        <List sx={{ p: 0 }}>
            {emptyLabel && (
                <>
                    <CheckboxListItem
                        checked={selectedValues.length === 0}
                        onToggle={() => onChange([])}
                        item={emptyItem}
                        disabled={disabled}
                    />
                    <Divider />
                </>
            )}

            <CheckboxListInner items={items} selectedValues={selectedValues} onChange={onChange} disabled={disabled} />
        </List>
    );
};
