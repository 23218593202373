/* eslint-disable @typescript-eslint/comma-dangle */
import { useLocalStorage, writeStorage } from "@rehooks/local-storage";
import { useEffect } from "react";

export const LOCAL_STORAGE_IS_MEMBER = "isMember";

/**
 * It returns a tuple of three values, the first being the current value of the local storage item, the
 * second being a function that sets the value of the local storage item, and the third being a
 * function that deletes the local storage item
 * @returns An array of three functions.
 */
export const useMemberTracking = (): [
    string | null | undefined,
    (newValue: string | null | undefined) => void,
    () => void,
] => {
    const [isMember, setIsMember, deleteIsMember] = useLocalStorage<string | undefined>(
        LOCAL_STORAGE_IS_MEMBER,
    );

    return [isMember, setIsMember, deleteIsMember];
};

/**

 * 
 * It says that the function takes a
 * boolean value and returns nothing
 * @param {boolean} value - boolean - The value to set.
 */
export const SetIsMember = (value: boolean): void => {
    useEffect(() => {
        if (value) {
            writeStorage(LOCAL_STORAGE_IS_MEMBER, value ? "1" : "0");
        }
    }, [value]);
};
