import { Container, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { routes } from "../../app/routes";
import { NavLink } from "../navigation/NavLink";
import DocumentContext from "components/DocumentContext";
import {
    CONTACT_EMAIL,
    FULL_LEGAL_NAME,
    PRODUCTION_DOMAIN,
    SHORT_LEGAL_NAME,
} from "@app/shared/constants";

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(4),
    },
}));

export const TermsOServicePage = () => {
    const classes = useStyles();

    return (
        <Container className={classes.root} maxWidth="lg">
            <DocumentContext addBrandNameSuffix title="Terms Of Service" />
            <Typography variant="h2">Terms of Service</Typography>
            <Typography variant="overline">Last updated: July, 2020</Typography>
            <p>
                Thank you for visiting! {FULL_LEGAL_NAME} (“{SHORT_LEGAL_NAME}”) provides our
                website located at {PRODUCTION_DOMAIN} (the “Site”) for our community. To make these
                Terms easier to read, the Site and our services are collectively called the
                “Services.” Please read these Terms of Service (the “Terms”) carefully because they
                govern your use of our Services.
            </p>

            <Typography variant="h3">Agreement to Terms</Typography>
            <p>
                By using our Services, you agree to be bound by these Terms. If you don’t agree to
                be bound by these Terms, do not use the Services.
            </p>

            <Typography variant="h3">Privacy Policy</Typography>
            <p>
                Please refer to our <NavLink to={routes.privacyPolicy()}>Privacy Policy</NavLink>{" "}
                for information on how we collect, use and disclose information from our users. You
                acknowledge and agree that your use of the Services is subject to our Privacy
                Policy.
            </p>

            <Typography variant="h3">Changes to Terms or Services</Typography>
            <p>
                We may modify the Terms at any time, in our sole discretion. If we do so, we’ll let
                you know either by posting the modified Terms on the Site or through other
                communications. It’s important that you review the Terms whenever we modify them
                because if you continue to use the Services after we have posted modified Terms on
                the Site, you are indicating to us that you agree to be bound by the modified Terms.
                If you don’t agree to be bound by the modified Terms, then you may not use the
                Services anymore. Because our Services are evolving over time we may change or
                discontinue all or any part of the Services, at any time and without notice, at our
                sole discretion.
            </p>

            <Typography variant="h3">Arbitration Notice</Typography>

            <p>
                Unless you opt out of arbitration within 30 days of the date you first agree to
                these terms by following the opt-out procedure specified in the “Arbitration”
                section below, and except for certain types of disputes described in the
                “Arbitration“ section below, you agree that disputes between you and{" "}
                {SHORT_LEGAL_NAME} will be resolved by binding, individual arbitration and you are
                waiving your right to a trial by jury or to participate as a plaintiff or class
                member in any purported class action or representative proceeding.
            </p>

            <Typography variant="h3">Who May Use the Services</Typography>
            <Typography variant="h4">Eligibility</Typography>
            <p>
                You may use the Services only if you are 13 years or older and are not barred from
                using the Services under applicable law. To make a purchase via the Services
                (described in the Section titled “Purchases” below), you must be 18 years or older
                and capable of forming a binding contract.
            </p>

            <Typography variant="h4">Registration and Your Information</Typography>
            <p>
                If you want to use certain features of the Services you’ll have to create an account
                (“Account”). You can do this via the Site or through your account with certain
                third-party services such as Google or Facebook (each, an “Third Party Account”). If
                you choose the Third Party Account option we’ll create your Account by extracting
                from your Third Party Account certain personal information such as your name and
                email address and other personal information that your privacy settings on the Third
                Party Account permit us to access.
            </p>

            <p>
                It’s important that you provide us with accurate, complete and up-to-date
                information for your Account and you agree to update such information, as needed, to
                keep it accurate, complete and up-to-date. If you don’t, we might have to suspend or
                terminate your Account. You agree that you won’t disclose your Account password to
                anyone and you’ll notify us immediately of any unauthorized use of your Account.
                You’re responsible for all activities that occur under your Account, whether or not
                you know about them.
            </p>

            <Typography variant="h3">Using the Services</Typography>
            <p>
                Via the Services, users can select from a variety of options to create customized
                relaxation and self-improvement sessions. You can also use the paid functionality of
                the Services to utilize the enhanced functionality of the Services or purchase items
                that are offered for sale through the Services (the “Products”), as described in
                further detail under the Section titled “Purchases” below.
            </p>

            <p>
                You understand and agree that the Services, Products and any other information you
                learn from {SHORT_LEGAL_NAME} are not intended, designed, or implied to diagnose,
                prevent, or treat any condition or disease, to ascertain the state of your health,
                or to be a substitute for professional medical care. Not all activities described on
                the Services or Products are suitable for everyone. Do not use the Services while
                driving, operating heavy machinery, or performing other tasks that require attention
                and concentration. You understand and agree that you are solely responsible for your
                use of the Services.
            </p>

            <Typography variant="h3">Feedback</Typography>
            <p>
                We welcome feedback, comments, and suggestions for improvements to the Services or
                Products (“Feedback”). You can submit Feedback by emailing us at {CONTACT_EMAIL}.
                You grant to us a non-exclusive, worldwide, perpetual, irrevocable, fully-paid,
                royalty-free, sub-licensable and transferable license under any and all intellectual
                property rights that you own or control to use, copy, modify, create derivative
                works based upon and otherwise exploit the Feedback for any purpose.
            </p>

            <Typography variant="h3">Content and Content Rights</Typography>
            <p>
                For purposes of these Terms, (i) “Content” means text, graphics, images, music,
                software, audio, video, works of authorship of any kind, and information or other
                materials that are posted, generated, provided or otherwise made available through
                the Services; and (ii) “User Content” means any Content that users (including you)
                provide to be made available through the Services. Content includes without
                limitation User Content.
            </p>

            <Typography variant="h4">Content Ownership</Typography>
            <p>
                {SHORT_LEGAL_NAME} does not claim any ownership rights in any User Content and
                nothing in these Terms will be deemed to restrict any rights that you may have to
                use and exploit your User Content. Subject to the foregoing, {SHORT_LEGAL_NAME} and
                its licensors exclusively own all right, title and interest in and to the Services
                and Content, including all associated intellectual property rights. You acknowledge
                that the Services and Content are protected by copyright, trademark, and other laws
                of the United States and foreign countries. You agree not to remove, alter or
                obscure any copyright, trademark, service mark or other proprietary rights notices
                incorporated in or accompanying the Services or Content.
            </p>

            <Typography variant="h4">Rights in User Content Granted by You</Typography>
            <p>
                By making any User Content available through Services you hereby grant to Cloud
                Sangha a non-exclusive, transferable, sublicensable, worldwide, royalty-free license
                to use, copy, modify, create derivative works based upon, distribute, publicly
                display, publicly perform and distribute your User Content in connection with
                operating and providing the Services and Content to you and to other users.
            </p>

            <Typography variant="h4">Rights in Content Granted by {SHORT_LEGAL_NAME}</Typography>
            <p>
                Subject to your compliance with these Terms, {SHORT_LEGAL_NAME} grants you a
                limited, non-exclusive, non-transferable, non-sublicensable license to download,
                view, copy and display the Content solely in connection with your permitted use of
                the Services and solely for your personal and non-commercial purposes
            </p>

            <Typography variant="h3">Prohibitions</Typography>
            <p>You agree not to do any of the following:</p>
            <ul>
                <li>
                    Post, upload, publish, submit or transmit any Content that: (i) infringes,
                    misappropriates or violates a third party’s patent, copyright, trademark, trade
                    secret, moral rights or other intellectual property rights, or rights of
                    publicity or privacy; (ii) violates, or encourages any conduct that would
                    violate, any applicable law or regulation or would give rise to civil liability;
                    (iii) is fraudulent, false, misleading or deceptive; (iv) is defamatory,
                    obscene, pornographic, vulgar or offensive; (v) promotes discrimination,
                    bigotry, racism, hatred, harassment or harm against any individual or group;
                    (vi) is violent or threatening or promotes violence or actions that are
                    threatening to any person or entity; or (vii) promotes illegal or harmful
                    activities or substances;
                </li>
                <li>
                    Use, display, mirror or frame the Services, or any individual element within the
                    Services, {SHORT_LEGAL_NAME}’s name, any {SHORT_LEGAL_NAME} trademark, logo or
                    other proprietary information, or the layout and design of any page or form
                    contained on a page, without {SHORT_LEGAL_NAME}’s express written consent;
                </li>
                <li>
                    Access, tamper with, or use non-public areas of the Services, {SHORT_LEGAL_NAME}
                    ’s computer systems, or the technical delivery systems of {SHORT_LEGAL_NAME}’s
                    providers;
                </li>
                <li>
                    Attempt to probe, scan, or test the vulnerability of any {SHORT_LEGAL_NAME}{" "}
                    system or network or breach any security or authentication measures;
                </li>
                <li>
                    Avoid, bypass, remove, deactivate, impair, descramble or otherwise circumvent
                    any technological measure implemented by {SHORT_LEGAL_NAME} or any of{" "}
                    {SHORT_LEGAL_NAME}’s providers or any other third party (including another user)
                    to protect the Services;
                </li>
                <li>
                    Attempt to access or search the Services or download Collective Content from the
                    Services through the use of any engine, software, tool, agent, device or
                    mechanism (including spiders, robots, crawlers, data mining tools or the like)
                    other than the software and/or search agents provided by {SHORT_LEGAL_NAME} or
                    other generally available third party web browsers;
                </li>
                <li>
                    Send any unsolicited or unauthorized advertising, promotional materials, email,
                    junk mail, spam, chain letters or other form of solicitation;
                </li>
                <li>
                    Use any meta tags or other hidden text or metadata utilizing a{" "}
                    {SHORT_LEGAL_NAME}
                    trademark, logo URL or product name without {SHORT_LEGAL_NAME}’s express written
                    consent;
                </li>
                <li>
                    Use the Services for any commercial purpose or the benefit of any third party or
                    in any manner not permitted by these Terms;
                </li>
                <li>
                    Forge any TCP/IP packet header or any part of the header information in any
                    email or newsgroup posting, or in any way use the Services to send altered,
                    deceptive or false source-identifying information;
                </li>
                <li>
                    Attempt to decipher, decompile, disassemble or reverse engineer any of the
                    software used to provide the Services;
                </li>
                <li>
                    Interfere with, or attempt to interfere with, the access of any user, host or
                    network, including, without limitation, sending a virus, overloading, flooding,
                    spamming, or mail-bombing the Services;
                </li>
                <li>
                    Collect or store any personally identifiable information from the Services from
                    other users of the Services without their express permission;
                </li>
                <li>Impersonate or misrepresent your affiliation with any person or entity;</li>
                <li>Violate any applicable law or regulation; or</li>
                <li>Encourage or enable any other individual to do any of the foregoing.</li>
            </ul>

            <p>
                Although we’re not obligated to monitor access to or use of the Services or Content
                or to review or edit any Content, we have the right to do so for the purpose of
                operating the Services, to ensure compliance with these Terms, and to comply with
                applicable law or other legal requirements. We reserve the right, but are not
                obligated, to remove or disable access to any Content, at any time and without
                notice, including, but not limited to, if we, at our sole discretion, consider any
                Content to be objectionable or in violation of these Terms. We have the right to
                investigate violations of these Terms or conduct that affects the Services. We may
                also consult and cooperate with law enforcement authorities to prosecute users who
                violate the law.
            </p>

            <Typography variant="h3">Links to Third Party Websites or Resources</Typography>

            <p>
                The Services may contain links to third-party websites or resources. We provide
                these links only as a convenience and are not responsible for the content, products
                or services on or available from those websites or resources or links displayed on
                such websites. You acknowledge sole responsibility for and assume all risk arising
                from, your use of any third-party websites or resources.
            </p>

            <Typography variant="h3">Termination</Typography>
            <p>
                We may terminate your access to and use of the Services, at our sole discretion, at
                any time and without notice to you. You may cancel your Account at any time by
                sending an email to us at {CONTACT_EMAIL}. Upon any termination, discontinuation or
                cancellation of Services or your Account, all provisions of these Terms which by
                their nature should survive will survive, including, without limitation, ownership
                provisions, warranty disclaimers, limitations of liability, and dispute resolution
                provisions.
            </p>

            <Typography variant="h3">Warranty Disclaimers</Typography>
            <p>
                The Services, Products and Content are provided “as is,” without warranty of any
                kind. Without limiting the foregoing, we explicitly disclaim any warranties of
                merchantability, fitness for a particular purpose, quiet enjoyment or
                non-infringement and any warranties arising out of course of dealing or usage of
                trade.
            </p>

            <p>
                We make no warranty that the Services or Products will meet your requirements or be
                available on an uninterrupted, secure, or error-free basis. We make no warranty
                regarding the quality, accuracy, timeliness, truthfulness, completeness or
                reliability of any Content.
            </p>

            <Typography variant="h3">Indemnity</Typography>
            <p>
                You will indemnify and hold harmless {SHORT_LEGAL_NAME} and its officers, directors,
                employee and agents, from and against any claims, disputes, demands, liabilities,
                damages, losses, and costs and expenses, including, without limitation, reasonable
                legal and accounting fees, arising out of or in any way connected with (i) your
                access to or use of the Services or Content or (ii) your violation of these Terms.
            </p>

            <Typography variant="h3">Limitation of Liability</Typography>
            <p>
                Neither {SHORT_LEGAL_NAME} nor any other party involved in creating, producing, or
                delivering the Services, Products or Content will be liable for any incidental,
                special, exemplary or consequential damages, including, but not limited to, lost
                profits, loss of data or goodwill, service interruption, computer damage or system
                failure or the cost of substitute Services or Products arising out of or in
                connection with these terms or from the use of or inability to use the Services,
                Products or Content, whether based on warranty, contract, tort (including
                negligence), product liability or any other legal theory, and whether or not Cloud
                Sangha has been informed of the possibility of such damage, even if a limited remedy
                set forth herein is found to have failed of its essential purpose. Some
                jurisdictions do not allow the exclusion or limitation of liability for
                consequential or incidental damages, so the above limitation may not apply to you.
            </p>

            <p>
                In no event will {SHORT_LEGAL_NAME}’s total liability arising out of or in
                connection with these terms or from the use of or inability to use the Services,
                Products or Content exceed the amounts you have paid to {SHORT_LEGAL_NAME} for use
                of the Services, Products or Content or fifty dollars ($50), if you have not had any
                payment obligations to {SHORT_LEGAL_NAME}, as applicable. The exclusion and
                limitations of damages set forth above are fundamental elements of the basis of the
                bargain between {SHORT_LEGAL_NAME} and you.
            </p>

            <Typography variant="h3">Dispute Resolution</Typography>
            <Typography variant="h4">Governing Law</Typography>
            <p>
                These Terms and any action related thereto will be governed by the laws of the State
                of California without regard to its conflict of laws provisions.
            </p>

            <Typography variant="h4">Agreement to Arbitrate</Typography>
            <p>
                You and {SHORT_LEGAL_NAME} agree that any dispute, claim or controversy arising out
                of or relating to these Terms or the breach, termination, enforcement,
                interpretation or validity thereof or the use of the Services, Products or Content
                (collectively, “Disputes”) will be settled by binding arbitration, except that each
                party retains the right: (i) to bring an individual action in small claims court and
                (ii) to seek injunctive or other equitable relief in a court of competent
                jurisdiction to prevent the actual or threatened infringement, misappropriation or
                violation of a party’s copyrights, trademarks, trade secrets, patents or other
                intellectual property rights (the action described in the foregoing clause (ii), an
                “IP Protection Action”). Without limiting the preceding sentence, you will also have
                the right to litigate any other Dispute if you provide {SHORT_LEGAL_NAME} with
                written notice of your desire to do so by email at {CONTACT_EMAIL} within thirty
                (30) days following the date you first agree to these Terms (such notice, an
                “Arbitration Opt-out Notice”). If you don’t provide {SHORT_LEGAL_NAME} with an
                Arbitration Opt-out Notice within the thirty (30) day period, you will be deemed to
                have knowingly and intentionally waived your right to litigate any Dispute except as
                expressly set forth in clauses (i) and (ii) above. The exclusive jurisdiction and
                venue of any IP Protection Action or, if you timely provide {SHORT_LEGAL_NAME} with
                an Arbitration Opt-out Notice, will be the state and federal courts located in the
                Northern District of California and each of the parties hereto waives any objection
                to jurisdiction and venue in such courts. Unless you timely provide{" "}
                {SHORT_LEGAL_NAME} with an Arbitration Opt-out Notice, you acknowledge and agree
                that you and {SHORT_LEGAL_NAME} are each waiving the right to a trial by jury or to
                participate as a plaintiff or class member in any purported class action or
                representative proceeding. Further, unless both you and {SHORT_LEGAL_NAME} otherwise
                agree in writing, the arbitrator may not consolidate more than one person’s claims,
                and may not otherwise preside over any form of any class or representative
                proceeding. If this specific paragraph is held unenforceable, then the entirety of
                this “Dispute Resolution” section will be deemed void. Except as provided in the
                preceding sentence, this “Dispute Resolution” section will survive any termination
                of these Terms.
            </p>

            <Typography variant="h4">Arbitration Rules</Typography>
            <p>
                The arbitration will be administered by the American Arbitration Association (“AAA”)
                in accordance with the Commercial Arbitration Rules and the Supplementary Procedures
                for Consumer Related Disputes (the “AAA Rules”) then in effect, except as modified
                by this “Dispute Resolution” section. (The AAA Rules are available at
                www.adr.org/arb_med or by calling the AAA at 1-800-778-7879.) The Federal
                Arbitration Act will govern the interpretation and enforcement of this Section.
            </p>

            <Typography variant="h4">Arbitration Process</Typography>
            <p>
                A party who desires to initiate arbitration must provide the other party with a
                written Demand for Arbitration as specified in the AAA Rules. (The AAA provides a
                general Demand for Arbitration form and a separate form for Demand for Arbitration
                for California residents.) The arbitrator will be either a retired judge or an
                attorney licensed to practice law and will be selected by the parties from the AAA’s
                roster of arbitrators. If the parties are unable to agree upon an arbitrator within
                seven (7) days of delivery of the Demand for Arbitration, then the AAA will appoint
                the arbitrator in accordance with the AAA Rules.
            </p>

            <Typography variant="h4">Arbitration Location and Procedure</Typography>
            <p>
                Unless you and {SHORT_LEGAL_NAME} otherwise agree, the arbitration will be conducted
                in the county where you reside. If your claim does not exceed $10,000, then the
                arbitration will be conducted solely on the basis of the documents that you and{" "}
                {SHORT_LEGAL_NAME} submit to the arbitrator, unless you request a hearing or the
                arbitrator determines that a hearing is necessary. If your claim exceeds $10,000,
                your right to a hearing will be determined by the AAA Rules. Subject to the AAA
                Rules, the arbitrator will have the discretion to direct a reasonable exchange of
                information by the parties, consistent with the expedited nature of the arbitration.
            </p>

            <Typography variant="h4">Arbitrator’s Decision</Typography>
            <p>
                The arbitrator will render an award within the time frame specified in the AAA
                Rules. The arbitrator’s decision will include the essential findings and conclusions
                upon which the arbitrator based the award. Judgment on the arbitration award may be
                entered in any court having jurisdiction thereof. The arbitrator’s award of damages
                must be consistent with the terms of the “Limitation of Liability” section above as
                to the types and amounts of damages for which a party may be held liable. The
                arbitrator may award declaratory or injunctive relief only in favor of the claimant
                and only to the extent necessary to provide relief warranted by the claimant’s
                individual claim. If you prevail in arbitration you will be entitled to an award of
                attorneys’ fees and expenses, to the extent provided under applicable law. Cloud
                Sangha will not seek, and hereby waives all rights it may have under applicable law
                to recover, attorneys’ fees and expenses if it prevails in arbitration.
            </p>
            <Typography variant="h4">Fees</Typography>
            <p>
                Your responsibility to pay any AAA filing, administrative and arbitrator fees will
                be solely as set forth in the AAA Rules. However, if your claim for damages does not
                exceed $75,000, {SHORT_LEGAL_NAME} will pay all such fees unless the arbitrator
                finds that either the substance of your claim or the relief sought in your Demand
                for Arbitration was frivolous or was brought for an improper purpose (as measured by
                the standards set forth in Federal Rule of Civil Procedure 11(b)).
            </p>
            <Typography variant="h4">Changes</Typography>
            <p>
                Notwithstanding the provisions of the “Modification” section above, if{" "}
                {SHORT_LEGAL_NAME}
                changes this “Dispute Resolution” section after the date you first accepted these
                Terms (or accepted any subsequent changes to these Terms), you may reject any such
                change by sending us written notice (including by email to {CONTACT_EMAIL}) within
                30 days of the date such change became effective, as indicated in the “Last Updated”
                date above or in the date of {SHORT_LEGAL_NAME}’s email to you notifying you of such
                change. By rejecting any change, you are agreeing that you will arbitrate any
                Dispute between you and {SHORT_LEGAL_NAME} in accordance with the provisions of this
                “Dispute Resolution” section as of the date you first accepted these Terms (or
                accepted any subsequent changes to these Terms).
            </p>

            <Typography variant="h3">General Terms</Typography>

            <p>
                These Terms constitute the entire and exclusive understanding and agreement between{" "}
                {SHORT_LEGAL_NAME} and you regarding the Services, Products and Content, and these
                Terms supersede and replace any and all prior oral or written understandings or
                agreements between {SHORT_LEGAL_NAME} and you regarding the Services, Products and
                Content. If any provision of these Terms is held invalid or unenforceable (either by
                an arbitrator appointed pursuant to the terms of the “Arbitration” section above or
                by court of competent jurisdiction, but only if you timely opt out of arbitration by
                sending us an Arbitration Opt-out Notice in accordance with the terms set forth
                above), that provision will be enforced to the maximum extent permissible and the
                other provisions of these Terms will remain in full force and effect.
            </p>

            <p>
                You may not assign or transfer these Terms, by operation of law or otherwise,
                without {SHORT_LEGAL_NAME}’s prior written consent. Any attempt by you to assign or
                transfer these Terms, without such consent, will be null and of no effect. Cloud
                Sangha may freely assign or transfer these Terms without restriction. Subject to the
                foregoing, these Terms will bind and inure to the benefit of the parties, their
                successors and permitted assigns.
            </p>

            <p>
                Any notices or other communications provided by {SHORT_LEGAL_NAME} under these
                Terms, including those regarding modifications to these Terms, will be given: (i) by
                Cloud Sangha via email; or (ii) by posting to the Services. For notices made by
                email, the date of receipt will be deemed the date on which such notice is
                transmitted.
            </p>

            <p>
                {SHORT_LEGAL_NAME}’s failure to enforce any right or provision of these Terms will
                not be considered a waiver of such right or provision. The waiver of any such right
                or provision will be effective only if in writing and signed by a duly authorized
                representative of {SHORT_LEGAL_NAME}. Except as expressly set forth in these Terms,
                the exercise by either party of any of its remedies under these Terms will be
                without prejudice to its other remedies under these Terms or otherwise.
            </p>

            <Typography variant="h3">Contact Information</Typography>
            <p>
                If you have any questions about these Terms or the Services or Products, please
                contact {SHORT_LEGAL_NAME} at {CONTACT_EMAIL}.
            </p>
        </Container>
    );
};

export default TermsOServicePage;
