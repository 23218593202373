import { useQuery } from "@apollo/client";
import { analyticsTrack } from "app/analytics/track";
import { GRAPHQL_QUERY_CURRENT_TIME } from "app/queries";
import { DateTime } from "luxon";
import { useEffect, useState } from "react";

export const useCurrentServerTime = (updateFrequency = 30000) => {
    const [currentTime, setCurrentTime] = useState(DateTime.now());
    const [serverTimeDeltaInSeconds, setServerTimeDeltaInSeconds] = useState<number>(0);

    const { data, loading, refetch } = useQuery(GRAPHQL_QUERY_CURRENT_TIME, {
        fetchPolicy: "network-only",
    });

    useEffect(() => {
        if (data?.currentTime) {
            const serverTime = DateTime.fromISO(data.currentTime);
            const delta = serverTime.toSeconds() - DateTime.now().toSeconds();
            setServerTimeDeltaInSeconds(delta);
        }
    }, [data]);

    useEffect(() => {
        if (!loading && !data?.currentTime) {
            // Retry if we failed to retrieve the time
            refetch();

            // Metric to see how often this happens - we might need a more robust refetch mechanism
            analyticsTrack("serverTime.failedToFetch");
        }
    }, [data, loading, refetch]);

    // We update the state based on the passed updateFrequency, which will trigger a re-render of any components that use this hook
    useEffect(() => {
        const updateCurrentTime = () => {
            const adjustedTimeInSeconds = DateTime.now().toSeconds() + serverTimeDeltaInSeconds;
            setCurrentTime(DateTime.fromSeconds(adjustedTimeInSeconds));
        };

        updateCurrentTime();
        const interval = setInterval(updateCurrentTime, updateFrequency);
        return () => clearInterval(interval);
    }, [serverTimeDeltaInSeconds, updateFrequency]);

    return currentTime;
};
