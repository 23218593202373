import { DateTime } from "luxon";
import { useEffect, useState } from "react";

export const useUpdateCurrentTime = (
    serverTimeDeltaInSeconds = 0,
    updateFrequency = 5000,
    disableUpdates?: boolean,
) => {
    const [currentTime, setCurrentTime] = useState(DateTime.now());

    // We update the state based on the passed updateFrequency, which will trigger a re-render of any components that use this hook
    useEffect(() => {
        if (disableUpdates) {
            return;
        }

        const updateCurrentTime = () => {
            const adjustedTimeInSeconds = DateTime.now().toSeconds() + serverTimeDeltaInSeconds;
            setCurrentTime(DateTime.fromSeconds(adjustedTimeInSeconds));
        };

        updateCurrentTime();
        const interval = setInterval(updateCurrentTime, updateFrequency);
        return () => clearInterval(interval);
    }, [serverTimeDeltaInSeconds, updateFrequency]);

    return currentTime;
};
