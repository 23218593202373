import { useMutation } from "@apollo/client";
import { Typography } from "@mui/material";
import { GRAPHQL_MUTATION_SET_LAST_DONATION_DATE } from "app/queries";
import { routes } from "app/routes";
import { LIMITED_WIDTH } from "app/styles";
import { setUserProfile } from "features/auth/auth";
import { LinkButton } from "features/navigation/LinkButton";
import { SuccessPageLayout } from "layouts/SuccessPageLayout";
import { useEffect } from "react";
import { useDispatch } from "react-redux";

export const DonationSuccessPage = () => {
    const dispatch = useDispatch();

    const [setLastDonationDate] = useMutation(GRAPHQL_MUTATION_SET_LAST_DONATION_DATE, {
        update: (cache, { data: { updateLastDateOfDonation } }) => {
            dispatch(setUserProfile(updateLastDateOfDonation));
        },
    });

    useEffect(() => {
        setLastDonationDate();
    }, []);

    return (
        <SuccessPageLayout centered={true}>
            <Typography variant="body1" sx={{ maxWidth: LIMITED_WIDTH }}>
                We're in deep gratitude. This directly supports our mission of bringing peace, love,
                and awareness to the world.
            </Typography>

            <LinkButton
                variant="primary"
                to={routes.home()}
                disableElevation
            >
                Back to home
            </LinkButton>
        </SuccessPageLayout>
    );
};
