import { Snackbar } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { useEffect } from "react";

const SNACKBAR_TIMEOUT = 7000;
export const SESSION_STORAGE_SNACKBAR_ALERT_MESSAGE_KEY = "snackbarAlertMessage";

export const showSnackbarAlertOnRedirect = (message: string) => {
    window.sessionStorage.setItem(SESSION_STORAGE_SNACKBAR_ALERT_MESSAGE_KEY, message);
};

const useStyles = makeStyles((theme) => {
    return {
        snackBarContent: {
            minWidth: "fit-content",
        },
    };
});

// NOTE: this is instantiated in Routes.tsx - just call the showSnackbarAlertOnRedirect function to utilize that
export const AlertSnackBar = () => {
    const classes = useStyles();

    const [snackBarOpen, setSnackBarOpen] = React.useState<boolean>(false);
    const [message, setMessage] = React.useState<string>("");

    const snackBarMessage = window.sessionStorage.getItem(
        SESSION_STORAGE_SNACKBAR_ALERT_MESSAGE_KEY,
    );

    useEffect(() => {
        if (snackBarMessage) {
            setMessage(snackBarMessage);
            window.sessionStorage.setItem(SESSION_STORAGE_SNACKBAR_ALERT_MESSAGE_KEY, "");
            setSnackBarOpen(true);
        }
        const initialfadeOut = setTimeout(() => {
            setSnackBarOpen(false);
        }, SNACKBAR_TIMEOUT);

        return () => {
            clearTimeout(initialfadeOut);
        };
    }, [snackBarMessage]);

    useEffect(() => {
        if (message) {
            setSnackBarOpen(true);
        }
    }, [message]);

    return (
        <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            open={snackBarOpen}
            onClose={() => {
                setSnackBarOpen(false);
            }}
            ContentProps={{
                className: classes.snackBarContent,
            }}
            message={message}
            data-testid="alertSnackBar"
        />
    );
};
