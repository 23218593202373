// Queries data and polls it to make sure it is up to date

import { DocumentNode, QueryHookOptions, TypedDocumentNode, useQuery } from "@apollo/client";
import { useRefetchStaleDataOnFocus } from "./useRefetchStaleDataOnFocus";

// Also makes sure the data is updated if the user switches to another tab and then switches back
export const useFetchDataAndKeepUpdated = ({
    query,
    options,
    pollIntervalInMinutes,
}: {
    query: DocumentNode | TypedDocumentNode;
    options?: QueryHookOptions;
    pollIntervalInMinutes: number;
}) => {
    const queryResult = useQuery(query, {
        ...options,
        pollInterval: 1000 * 60 * pollIntervalInMinutes,
        fetchPolicy: "network-only",
    });

    // Refetch data if the tab is re-focused and if our data is stale
    useRefetchStaleDataOnFocus({
        data: queryResult.data,
        refetchData: () => {
            queryResult.refetch();
        },
        timeWindow: { minutes: pollIntervalInMinutes },
    });

    return queryResult;
};
