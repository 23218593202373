import { OptimizelyContext } from "@optimizely/react-sdk";
import { useContext, useEffect } from "react";

export function useOptimizelyTrack() {
    const { optimizely } = useContext(OptimizelyContext);
    return (eventKey: string) => {
        if (optimizely) {
            optimizely.onReady().then(() => {
                optimizely.track(eventKey);
            });
        }
    };
}

export const useOptimizelyTrackOnLoad = (eventKey: string) => {
    const optimizelyTrack = useOptimizelyTrack();

    useEffect(() => {
        optimizelyTrack(eventKey);
    }, [optimizelyTrack]);
};
