import { useMediaQuery } from "@mui/material";
import { theme } from "app/theme";
import makeStyles from "@mui/styles/makeStyles";
import { Cohort } from "@app/shared/constants";

interface PartnerLogoProps {
    cohort: string;
}

const useStyles = makeStyles((theme) => ({
    partnerLogo: {
        marginRight: theme.spacing(4),
    },
}));

export const PartnerLogo = (props: PartnerLogoProps) => {
    const { cohort } = props;
    const classes = useStyles();
    const isMobileScreen = useMediaQuery(theme.breakpoints.down("sm"));

    if (cohort === Cohort.Joyous) {
        return (
            <img
                src={
                    isMobileScreen
                        ? "/images/partner/joyous-logo-small.png"
                        : "/images/partner/joyous-logo.png"
                }
                className={classes.partnerLogo}
                alt="joyousLogo"
            />
        );
    } else {
        return null;
    }
};
