import { Session } from "@app/shared/types";
import { dateTimeFromString } from "@app/shared/utils";
import makeStyles from "@mui/styles/makeStyles";
import { useUserTimezone } from "hooks/useUserTimezone";
import { DateTime } from "luxon";
import smoothscroll from "smoothscroll-polyfill";
import { CommunityEventCard } from "../community/CommunityEventCard";
smoothscroll.polyfill();

export interface EventListProps {
    events: Session[];
    currentServerTime: DateTime;
    showSpecificDate?: boolean;
    rsvpedSessions: { id: string }[];
    onSessionAttend?: () => void;
}

const useStyle = makeStyles((theme) => ({
    cardContainer: {
        display: "grid",
        gridTemplateColumns: "repeat(auto-fill, minmax(350px, 1fr))",
        gap: theme.spacing(3),
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(4),
    },
}));

export const EventList = (props: EventListProps): JSX.Element => {
    const { events, currentServerTime, showSpecificDate, rsvpedSessions, onSessionAttend } = props;
    const classes = useStyle();

    const timezone = useUserTimezone();

    const now = currentServerTime.setZone(timezone);

    // Filter out any events that are now over
    const filteredEvents = events.filter(
        (event) => dateTimeFromString(event.endTime, timezone) > now,
    );

    return (
        <div className={classes.cardContainer}>
            {filteredEvents.map((event: Session) => (
                <CommunityEventCard
                    key={event.id}
                    event={event}
                    timezone={timezone}
                    currentServerTime={currentServerTime}
                    showSpecificDate={showSpecificDate}
                    userRsvpedToSession={rsvpedSessions?.some(
                        (rsvpedSession) => rsvpedSession.id === event.id,
                    )}
                    onAttend={onSessionAttend}
                />
            ))}
        </div>
    );
};
