import { BRAND_NAME } from "@app/shared/constants";
import { routes } from "app/routes";
import DocumentContext from "components/DocumentContext";
import { Route, Switch } from "react-router";
import { InterestGroupListPage } from "./InterestGroupListPage";
import { InterestGroupPage } from "./InterestGroupPage";

export const InterestGroupFlow = (props: { location: { search: any; state: any } }) => {
    return (
        <>
            <DocumentContext title={BRAND_NAME} />
            <Switch>
                <Route path={routes.addInterestGroup(":groupId")} component={InterestGroupPage} />

                <Route>
                    <InterestGroupListPage />
                </Route>
            </Switch>
        </>
    );
};
