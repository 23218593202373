import { useQuery } from "@apollo/client";
import { MembershipStatus } from "@app/shared/constants";
import {
    Membership,
    MembershipBillingChoice,
    MembershipBillingCycle,
    MembershipBillingPlan,
} from "@app/shared/types";
import { GRAPHQL_QUERY_MEMBERSHIPS, GRAPHQL_QUERY_SIGN_UP_BILLING_PLAN_OPTIONS } from "app/queries";
import { push } from "connected-react-router";
import LoadingPage from "features/pages/LoadingPage";
import { useQueryParams } from "hooks/useQueryParams";
import _ from "lodash";
import { useDispatch } from "react-redux";
import { routes } from "../../app/routes";
import { RequireAccountCreation } from "./RequireAccountCreation";
import { StripeClientSecretProvider } from "./StripeClientSecretProvider";
import { useState } from "react";
import { GenericErrorPage } from "components/GenericErrorPage";
import { getSignupAnalyticsMetadataFromPlanName } from "./signupHelpers";
import { ChooseMonthlyOrAnnualPage } from "./ChooseMonthlyOrAnnualPage";
import { SiteFeature, isFeatureEnabled } from "app/featureFlags";

export const SignupFlow = () => {
    const dispatch = useDispatch();
    const query = useQueryParams();

    const { data: queryData } = useQuery(GRAPHQL_QUERY_SIGN_UP_BILLING_PLAN_OPTIONS);

    const [billingCycle, setBillingCycle] = useState<MembershipBillingCycle>();

    const [allowedEmail] = useState(query.email as string | undefined);

    const { data: membershipData, loading: membershipsLoading } =
        useQuery(GRAPHQL_QUERY_MEMBERSHIPS);
    const memberships = (membershipData?.myMemberships || []) as Membership[];
    const activeOrPending = memberships.filter((x) =>
        _.includes(MembershipStatus.ActiveOrPending, x.status),
    );
    if (activeOrPending.length > 0) {
        dispatch(push(routes.memberZone()));
        return <LoadingPage />;
    }

    if (query.cycle && !billingCycle) {
        setBillingCycle(query.cycle as MembershipBillingCycle);
    }

    const customSignupFlow = query.flow;
    if (Array.isArray(customSignupFlow)) {
        localStorage.setItem("signupFlow", customSignupFlow[0]);
    } else if (customSignupFlow) {
        localStorage.setItem("signupFlow", customSignupFlow);
    }

    // Allow for a 30-day trial via custom link "?trialDays=30"
    let trialDays: number | undefined;
    if (query.trialDays === "30") {
        trialDays = Number(query.trialDays);
    }

    const billingPlanOptions: MembershipBillingPlan[] | undefined =
        queryData?.signupBillingPlanOptions.filter((x: MembershipBillingPlan) => x.available);

    if (membershipsLoading || !billingPlanOptions) {
        return <LoadingPage />;
    }

    const monthlyPlan = billingPlanOptions.find(
        (billingPlan) => billingPlan.planName === "explorerSlidingScaleMonthly49",
    );

    const annualPlan = billingPlanOptions.find(
        (billingPlan) => billingPlan.planName === "baseAnnual",
    );

    if (!monthlyPlan || !annualPlan) {
        return <GenericErrorPage />;
    }

    const chosenPlan = billingCycle === MembershipBillingCycle.year ? annualPlan : monthlyPlan;

    const billingChoice: MembershipBillingChoice = { plan: chosenPlan, donationAmount: 0 };

    const analyticsData = getSignupAnalyticsMetadataFromPlanName(chosenPlan.planName);

    return (
        <RequireAccountCreation
            analyticsPrefix="signup"
            analyticsData={analyticsData}
            allowedEmail={allowedEmail}
            optimizelyEventKey="explorerSignupViewed"
        >
            {billingCycle || !isFeatureEnabled(SiteFeature.AnnualBillingPlan) ? (
                <StripeClientSecretProvider billingChoice={billingChoice} trialDays={trialDays} />
            ) : (
                <ChooseMonthlyOrAnnualPage onSelect={setBillingCycle} />
            )}
        </RequireAccountCreation>
    );
};
