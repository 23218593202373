import { useApolloClient, useMutation, useQuery } from "@apollo/client";
import { Session } from "@app/shared/types";
import { Button, Typography } from "@mui/material";
import { GRAPHQL_MUTATION_REQUEST_SUBSTITUTE, GRAPHQL_QUERY_TEACHERS } from "app/queries";
import { CSCheckboxField } from "components/CSCheckboxField";
import { CSDialog, CSDialogProps } from "components/CSDialog";
import { CSSelectField } from "components/CSSelectField";
import {
    getTitle,
    renderSessionDate,
    renderSessionTime,
    renderUserTimezone,
} from "features/member/community/shared";
import { sortTeachersByLastName } from "features/member/teachers/sortTeachers";
import { useUserTimezone } from "hooks/useUserTimezone";
import { useState } from "react";

type RequestSubstituteDialogProps = Omit<CSDialogProps, "children"> & {
    session: Session;
};

export const RequestSubstituteDialog = (props: RequestSubstituteDialogProps) => {
    const { session, ...dialogProps } = props;
    const timezone = useUserTimezone();
    const { data } = useQuery(GRAPHQL_QUERY_TEACHERS);
    const teachers = sortTeachersByLastName(data?.teachers || []);
    const teacherOptions = [
        {
            value: "",
            label: "Select a teacher",
        },
        ...teachers.map((teacher) => ({
            value: teacher.id,
            label: teacher.name,
        })),
    ];
    const apolloClient = useApolloClient();

    const [requestSubstituteMutation, { loading: isSaving, error: saveError }] = useMutation(
        GRAPHQL_MUTATION_REQUEST_SUBSTITUTE,
    );

    const [subPreArranged, setSubPreArranged] = useState(false);
    const [selectedTeacherId, setSelectedTeacherId] = useState<string | null>("");

    const selectedTeacher = teachers.find((x) => x.id === selectedTeacherId);

    const confirmEnabled = Boolean(!subPreArranged || selectedTeacher);

    const onConfirm = async (ev: React.MouseEvent) => {
        await requestSubstituteMutation({
            variables: {
                sessionId: session.id,
                substituteTeacherId: selectedTeacherId,
            },
        });

        if (props.onClose) {
            props.onClose(ev);
        }

        // Refetch all data since this may affect many queries
        apolloClient.refetchQueries({
            include: "all",
        });
    };

    return (
        <CSDialog {...dialogProps}>
            <Typography variant="h2">Request a substitute</Typography>
            <Typography variant="body1">
                <strong>
                    {getTitle(session)}
                    <br />
                    {renderSessionDate(session, timezone)} ({renderSessionTime(session, timezone)}{" "}
                    {renderUserTimezone(timezone)})
                </strong>
            </Typography>

            <CSCheckboxField
                value={subPreArranged}
                onChange={setSubPreArranged}
                data-testid="subPreArrangedCheckbox"
                label="I have already arranged for another teacher to be my substitute"
            />

            {!subPreArranged && (
                <Typography variant="body1">
                    If a teacher does not claim this session, it will be automatically canceled, and
                    all members will be notified via email. Mentorship group members will receive
                    credits for canceled sessions.
                </Typography>
            )}

            {subPreArranged && (
                <>
                    <CSSelectField
                        value={selectedTeacherId}
                        onChange={setSelectedTeacherId}
                        SelectProps={{ displayEmpty: true }}
                        options={teacherOptions}
                        data-testid="selectSubTeacher"
                        placeholder="Select a teacher"
                    />

                    {selectedTeacher && (
                        <Typography variant="body1">
                            Once you confirm, this session will immediately be transferred to{" "}
                            {selectedTeacher.name}. Please make sure they are aware of this in
                            advance.
                        </Typography>
                    )}
                </>
            )}

            {saveError && (
                <Typography variant="body2" color="error">
                    {saveError.message}
                </Typography>
            )}

            <div>
                <Button
                    variant="primary"
                    disabled={!confirmEnabled || isSaving}
                    onClick={onConfirm}
                    data-testid="confirmButton"
                >
                    {isSaving ? "Saving..." : "Confirm"}
                </Button>
            </div>
        </CSDialog>
    );
};
