import { Session } from "@app/shared/types";
import { scrollToElement } from "components/scrollToElement";
import { useQueryParams } from "hooks/useQueryParams";
import { useLayoutEffect } from "react";

export const useScrollToEvent = (events: Session[] | undefined, enableScroll = true) => {
    const query = useQueryParams();

    useLayoutEffect(() => {
        if (enableScroll && query.communityEventId) {
            const element = document.getElementById(query.communityEventId as string);
            if (element) {
                scrollToElement(element, { offset: 10 });
            }
        }
    }, [query.communityEventId, enableScroll, events]);
};
