/* eslint-disable no-console */
// These feature flags allow us to turn on and off parts of the code while they're in development

// To force enable certain features (to test in prod or for automated tests), set a query param "enable" to
// a comma separated list of the features that should be enabled when loading the site.
// Eg: https://cloudsangha.co?enable=FreeTrial,Indicator

// To force disable certain features, similarly set a query param "disable"

// You could also set the query param "?devMode=true" to activate development features in production

import QueryString from "qs";

export enum SiteFeature {
    // Toggles the UX and functionality for the free trial feature
    Indicator = "Indicator",
    Courses = "Courses",
    OnboardingPopup = "OnboardingPopup",
    AnnualBillingPlan = "AnnualBillingPlan",
    Lounge = "Lounge",
}

type FeatureMap = Record<SiteFeature, boolean>;

const productionFeatureMap: FeatureMap = {
    [SiteFeature.Indicator]: false,
    [SiteFeature.Courses]: true,
    [SiteFeature.OnboardingPopup]: true,
    [SiteFeature.AnnualBillingPlan]: true,
    [SiteFeature.Lounge]: true,
};

const devFeatureMap: FeatureMap = {
    ...productionFeatureMap,
    [SiteFeature.Indicator]: true,
    [SiteFeature.Courses]: true,
    [SiteFeature.OnboardingPopup]: true,
    [SiteFeature.AnnualBillingPlan]: true,
    [SiteFeature.Lounge]: true,
};

const queryParams = QueryString.parse(window.location.search, {
    ignoreQueryPrefix: true,
});
const forceDevMode = queryParams.devMode;

if (forceDevMode) {
    console.log("Overriding feature flags to development features");
}

const freeTrialMode = queryParams.freetrial;
if (freeTrialMode) {
    localStorage.setItem("isFreeTrial", "true");
}

const getArrayFromQueryParam = (param: string) =>
    (queryParams[param] as string | undefined)?.split(",").map((x) => x.trim());

const overrideFeaturesEnabled = getArrayFromQueryParam("enable");
const overrideFeaturesDisabled = getArrayFromQueryParam("disable");

if (overrideFeaturesEnabled) {
    console.log(`Overriding feature flags with: ${overrideFeaturesEnabled}`);
}

const featureFlagConfig: { [key: string]: FeatureMap } = {
    development: devFeatureMap,
    staging: devFeatureMap,
    dogfood: devFeatureMap,
    production: forceDevMode ? devFeatureMap : productionFeatureMap,
};

const featuresEnabled = () => {
    const environment = process.env.REACT_APP_ENVIRONMENT || "production";

    return featureFlagConfig[environment] || productionFeatureMap;
};

export const isFeatureEnabled = (feature: SiteFeature) => {
    if (overrideFeaturesEnabled?.includes(feature)) {
        return true;
    }

    if (overrideFeaturesDisabled?.includes(feature)) {
        return false;
    }

    return featuresEnabled()[feature];
};
