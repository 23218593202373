import { Teacher } from "@app/shared/types";

export const sortTeachersByLastName = (teacherList: Teacher[]) =>
    [...teacherList].sort((a, b) => {
        const aLastName = a.name.split(" ").pop() || "";
        const bLastName = b.name.split(" ").pop() || "";
        return aLastName.localeCompare(bLastName);
    });

export const sortSimplifiedTeachersByLastName = (
    teacherList: Teacher[] | { id: string; name: string }[],
) =>
    [...teacherList].sort((a, b) => {
        const aLastName = a.name.split(" ").pop() || "";
        const bLastName = b.name.split(" ").pop() || "";
        return aLastName.localeCompare(bLastName);
    });
