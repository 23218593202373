import { useQuery } from "@apollo/client";
import { Sangha, SanghaMembership, SanghaType } from "@app/shared/types";
import { Divider, List, ListItem, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { GRAPHQL_QUERY_MY_SANGHAS, GRAPHQL_QUERY_PENDING_SANGHA_MEMBERSHIPS } from "app/queries";
import PageWrapper from "components/PageWrapper";
import { Redirect } from "react-router";
import { routes } from "../../../app/routes";
import { NavLink } from "../../navigation/NavLink";
import LoadingPage from "../../pages/LoadingPage";
import { AddOnMentorshipGroupPage } from "../addOnMentorshipGroup/AddOnMentorshipGroupPage";
import { SanghaMembershipPendingPage } from "../sangha/SanghaMembershipPendingPage";
import { useHasMentorshipGroup } from "./useHasMentorshipGroup";

const useStyles = makeStyles((theme) => ({
    root: {
        textAlign: "center",
        padding: theme.spacing(4),
        marginTop: theme.spacing(4),
    },
}));

const innerContent = (sanghas: Sangha[] | undefined) => {
    if (!sanghas) {
        return (
            <Typography>
                Oops! Something went fetching your group. Please try refreshing the page.
            </Typography>
        );
    } else if (sanghas.length > 0) {
        return (
            <List>
                {sanghas.map((sangha, i) => (
                    <ListItem key={i}>
                        <NavLink color="textPrimary" to={routes.memberSanghaDetails(sangha.id)}>
                            {sangha.name}
                        </NavLink>
                    </ListItem>
                ))}
            </List>
        );
    } else {
        return <Typography>You haven't joined any sanghas yet!</Typography>;
    }
};

export const MySanghas = () => {
    const classes = useStyles();

    const hasMentorshipGroup = useHasMentorshipGroup();

    const { data: pendingSanghaMembershipsData, loading: pendingSanghaMembershipsLoading } =
        useQuery(GRAPHQL_QUERY_PENDING_SANGHA_MEMBERSHIPS);
    const pendingSanghaMemberships = pendingSanghaMembershipsData?.pendingSanghaMemberships as
        | SanghaMembership[]
        | undefined;

    const { data: mySanghasData, loading: mySanghasLoading } = useQuery(GRAPHQL_QUERY_MY_SANGHAS);

    let sanghas = mySanghasData?.mySanghas as Sangha[] | undefined;
    sanghas = sanghas?.filter((x) => x.type === SanghaType.MentorshipGroup);

    if (!hasMentorshipGroup) {
        // For members without a mentorship group, show the mentorship group add-on page
        return <AddOnMentorshipGroupPage />;
    }

    // loading state
    if (mySanghasLoading || pendingSanghaMembershipsLoading) {
        return <LoadingPage />;
    }

    // if only one sangha, forward to details page
    if (sanghas && sanghas.length === 1) {
        return <Redirect to={routes.memberSanghaDetails(sanghas[0].id)} />;
    }

    if (
        (!sanghas || sanghas.length === 0) &&
        pendingSanghaMemberships &&
        pendingSanghaMemberships.length > 0
    ) {
        return <SanghaMembershipPendingPage sanghaMembership={pendingSanghaMemberships[0]} />;
    }

    // show list
    return (
        <PageWrapper className={classes.root} data-testid="mySanghas">
            <Typography variant="h2">My groups</Typography>

            <Divider />
            {innerContent(sanghas)}
        </PageWrapper>
    );
};

export default MySanghas;
