import FavoriteRoundedIcon from "@mui/icons-material/FavoriteRounded";
import { darken, lighten } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { analyticsTrack } from "app/analytics/track";
import { routes } from "app/routes";
import { FloatingButton } from "components/FloatingButton";
import { useBreakpointQuery } from "hooks/useBreakPointQuery";
import { useLocation } from "react-router";
import payItForwardIcon from "images/icons/pay-it-forward.svg";
import classNames from "classnames";

const useStyles = makeStyles((theme) => ({
    root: {
        ...theme.typography.button,
        padding: theme.spacing(2, 6),
        minWidth: 0,
        backgroundColor: theme.palette.neutralWarm,
        color: theme.palette.primaryLeaves,
        boxShadow: theme.shadow.darker,
        bottom: theme.spacing(4),

        "&:hover": {
            backgroundColor: darken(theme.palette.neutralWarm, 0.05),
            boxShadow: theme.shadow.darker,
        },
    },
    compact: {
        backgroundColor: theme.palette.primaryLeaves,
        padding: theme.spacing(2),
        "&:hover": {
            backgroundColor: lighten(theme.palette.primaryLeaves, 0.15),
        },

        [theme.breakpoints.down("sm")]: {
            left: "auto",
            bottom: theme.spacing(1),
            right: theme.spacing(1),
        },
        position: "fixed",
    },
    compactIcon: {
        position: "relative",
        left: 4,
        top: 1,
    },
}));

export const PayItForwardButton = () => {
    const classes = useStyles();

    const location = useLocation();

    const isCompact = useBreakpointQuery("lg");

    return (
        <FloatingButton
            className={classNames(classes.root, { [classes.compact]: isCompact })}
            data-testid="payItForwardButton"
            startIcon={isCompact ? null : <FavoriteRoundedIcon />}
            to={routes.memberContribute()}
            onClick={() => {
                analyticsTrack("payItForward.clicked", { path: location.pathname });
            }}
        >
            {isCompact ? (
                <img className={classes.compactIcon} src={payItForwardIcon} alt="Pay it forward" />
            ) : (
                "Pay it forward"
            )}
        </FloatingButton>
    );
};
