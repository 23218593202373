import { Typography } from "@mui/material";
import { analyticsTrack } from "app/analytics/track";
import PageWrapper from "components/PageWrapper";
import { useQueryParams } from "hooks/useQueryParams";
import { useEffect } from "react";

export const IntentionsSubmittedPage = () => {
    const { email, form } = useQueryParams();

    useEffect(() => {
        analyticsTrack("intentions.submitted", { email, form });
    }, []);

    return (
        <PageWrapper>
            <Typography variant="h2">Thank you for your dedication.</Typography>
            <Typography variant="body1">
                We sent you an email with your intentions so you can refer back to it. We'll circle
                back in a month to check in on how things went for you.
            </Typography>
        </PageWrapper>
    );
};
