import { Typography } from "@mui/material";
import { routes } from "app/routes";
import { push } from "connected-react-router";
import { useDispatch } from "react-redux";
import { LandingFlowOptionList } from "features/landingPage/FunnelMVP/LandingFlowOptionList";
import { CancellationFlow } from "./CancellationFlow";
import { useCallback, useState } from "react";

const ChoiceGroup: string[] = [
    "I don't have enough time",
    "The session times don't work for me.",
    "I'm unable to attend for a while, or going on vacation",
    "It's too expensive",
    "I'm unable to afford it",
    "I didn't feel connected to the teachers",
    "I didn't feel connected to other members",
    "The session style didn't meet my needs",
    "I am returning to an in-person Sangha",
    "I am reducing screen time",
    "Something not listed here",
];

export const CancellationFlowReason = (props: {
    saveData: (data: { reasonsForLeaving: string[] }) => void;
}): JSX.Element => {
    const dispatch = useDispatch();
    const [selected, setSelected] = useState<string[]>([]);
    const handleNext = useCallback(() => {
        props.saveData({
            reasonsForLeaving: selected,
        });
        dispatch(push(routes.cancelMembershipPage5()));
    }, [dispatch, props, selected]);

    return (
        <CancellationFlow
            continueButtonText="Continue"
            continueButtonDisabled={selected.length === 0}
            pageId="reasonsForLeaving"
            onNext={handleNext}
            content={
                <>
                    <Typography variant="h2">What makes you want to leave?</Typography>

                    <LandingFlowOptionList
                        items={ChoiceGroup}
                        isMultiSelect={true}
                        onSelect={setSelected}
                        analyticsId="cancellationFlow.selectReason"
                    />
                </>
            }
        />
    );
};
