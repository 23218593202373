import { Button, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { routes } from "app/routes";
import DocumentContext from "components/DocumentContext";
import PageWrapper from "components/PageWrapper";
import { push } from "connected-react-router";
import { useDispatch } from "react-redux";
import { SetupProgressBar } from "./SetupProgressBar";
import { FixedCTAContainer } from "features/signup/FixedCTAContainer";
import { isUserInMMTSignupFlow } from "features/signup/signupHelpers";

const useStyles = makeStyles((theme) => ({
    contentContainer: {
        paddingBottom: theme.spacing(14),
        [theme.breakpoints.down("sm")]: {
            paddingBottom: theme.spacing(8),
        },
    },
    videoPlayerContainer: {
        position: "relative",
        paddingBottom: "62.5%",
        height: 0,
        borderRadius: theme.borderRadius.default,
    },
    videoPlayer: {
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        borderRadius: theme.borderRadius.default,
    },
}));

export const SetupShowYouAroundPage = () => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const isInMMTSignupFlow = isUserInMMTSignupFlow();

    return (
        <div>
            <DocumentContext title="Show you around" addBrandNameSuffix />

            <SetupProgressBar currentStep={3} />
            <PageWrapper>
                <div className={classes.contentContainer}>
                    <Typography variant="h1">Let us show you around</Typography>
                    <Typography variant="body1">
                        Here is a 2-minute visual walk-through of your membership.
                    </Typography>
                    <div className={classes.videoPlayerContainer}>
                        <iframe
                            src="https://www.youtube.com/embed/Rjfhne_sTRk?si=qJhCavt3UcKoKopS"
                            title="YouTube video player"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            allowFullScreen
                            className={classes.videoPlayer}
                        ></iframe>
                    </div>
                </div>
            </PageWrapper>
            <FixedCTAContainer>
                <Button
                    variant="primary"
                    data-testid="continueButton"
                    onClick={() => {
                        dispatch(
                            push(
                                isInMMTSignupFlow
                                    ? routes.setupAllMentorshipGroups()
                                    : routes.setupAllInterestGroups(),
                            ),
                        );
                    }}
                >
                    Continue
                </Button>
            </FixedCTAContainer>
        </div>
    );
};
