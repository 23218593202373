import { useMutation } from "@apollo/client";
import { Message } from "@app/shared/types";
import { Button, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { analyticsTrack } from "app/analytics/track";
import {
    GRAPHQL_MUTATION_DELETE_MESSAGE,
    GRAPHQL_QUERY_MESSAGE_THREAD,
    GRAPHQL_QUERY_OLD_MESSAGE_THREAD,
} from "app/queries";
import { CSDialog } from "components/CSDialog";
import * as React from "react";

const useStyles = makeStyles((theme) => ({
    deleteMessageButton: {
        position: "absolute",
        color: "red",
        cursor: "pointer",
        right: theme.spacing(2),
        top: theme.spacing(2),
        opacity: 0.5,
        transition: "opacity 300ms ease",

        "&:hover": {
            opacity: 1,
        },
    },

    modalButtonContainer: {
        textAlign: "right",

        "& .MuiButton-root": {
            marginLeft: theme.spacing(2),
        },
    },
}));

export type OnDeleteMessageFunction = (messageThreadId: string, messageId: string) => void;

export const MessageThreadPanelDeleteButton = (props: {
    messageThreadId: string;
    message: Message;
    onDeleteMessage: OnDeleteMessageFunction;
}) => {
    const classes = useStyles();
    const { message, messageThreadId } = props;
    const [open, setOpen] = React.useState(false);

    const [deleteMessageMutation] = useMutation(GRAPHQL_MUTATION_DELETE_MESSAGE, {
        refetchQueries: [GRAPHQL_QUERY_MESSAGE_THREAD, GRAPHQL_QUERY_OLD_MESSAGE_THREAD],
    });

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const onDeleteMessage = async (ev: React.MouseEvent<any>) => {
        ev.preventDefault();
        deleteMessageMutation({
            variables: { thread: messageThreadId, message: message.id },
        });
        analyticsTrack("member.sangha.message.deleted", {
            message: message.id,
            thread: messageThreadId,
        });
        handleClose();

        props.onDeleteMessage(messageThreadId, message.id);
    };

    return (
        <div>
            <DeleteForeverIcon
                className={classes.deleteMessageButton}
                data-testid="deleteMessage"
                onClick={handleClickOpen}
            />
            <CSDialog open={open} onClose={handleClose}>
                <Typography variant="h3">Are you sure you want to delete this message?</Typography>
                <div className={classes.modalButtonContainer}>
                    <Button
                        data-testid="messageDeleteButton"
                        onClick={onDeleteMessage}
                        variant="primary"
                    >
                        Yes
                    </Button>
                    <Button
                        data-testid="messageDontDeleteButton"
                        onClick={handleClose}
                        variant="secondary"
                    >
                        No
                    </Button>
                </div>
            </CSDialog>
        </div>
    );
};
