import { Button } from "@mui/material";
import { trackOpeningLounge } from "./loungeHelpers";
import classNames from "classnames";
import { useContext } from "react";
import { LoggedInUserProfileContext } from "features/auth/RequireAuthentication";
import { LOUNGE_BASE_URL } from "@app/shared/constants";
import { routes } from "app/routes";
import { theme } from "app/theme";

interface OpenLoungeButtonProps {
    buttonCopy?: string;
    buttonVariant?: "primary" | "secondary";
    sanghaId?: string;
}

export const OpenLoungeButton = (props: OpenLoungeButtonProps) => {
    const { buttonCopy = "Open Chat", buttonVariant = "primary", sanghaId } = props;
    const loggedInUser = useContext(LoggedInUserProfileContext);
    const acceptedLoungeGuidelines = loggedInUser.profile.acceptedLoungeGuidelines;

    let openChatUrl: string;

    if (sanghaId) {
        openChatUrl = acceptedLoungeGuidelines
            ? `${LOUNGE_BASE_URL}/c/${sanghaId.toLowerCase()}/`
            : `${routes.lounge()}?id=${sanghaId.toLowerCase()}`;
    } else {
        openChatUrl = acceptedLoungeGuidelines ? `${LOUNGE_BASE_URL}/c/feed/` : routes.lounge();
    }

    return (
        <Button
            variant={buttonVariant}
            size="small"
            onClick={() => {
                if (acceptedLoungeGuidelines) {
                    trackOpeningLounge(openChatUrl);
                    window.open(openChatUrl);
                } else {
                    window.location.href = openChatUrl;
                }
            }}
            fullWidth
        >
            <span
                className={classNames("material-symbols-rounded")}
                style={{ fontSize: theme.typography.pxToRem(18), marginRight: theme.spacing(1) }}
            >
                open_in_new
            </span>
            {buttonCopy}
        </Button>
    );
};
