import { Typography } from "@mui/material";
import PageWrapper from "components/PageWrapper";
import { useLogEventOnLoad } from "hooks/useLogEventOnLoad";
import { useState } from "react";
import { StringParam, useQueryParams } from "use-query-params";
import { CustomArrayParam, StatusParam } from "../filters/customQueryParamDefinitions";
import { InterestGroupFilter } from "./InterestGroupFilter";
import { InterestGroupList } from "./InterestGroupList";
import {
    getCurrentCohort,
    isUserInFreeTrialSession,
    isUserInSetupFlow,
} from "features/signup/signupHelpers";
import makeStyles from "@mui/styles/makeStyles";
import { SetupProgressBar } from "features/setup/SetupProgressBar";
import { useLocation } from "react-router";
import { useSelector } from "react-redux";
import { selectUser } from "features/auth/auth";
import { ProductFeature, Sangha } from "@app/shared/types";
import { DEFAULT_FREE_TRIAL_DAYS } from "@app/shared/constants";
import InfoIcon from "@mui/icons-material/Info";
import { GRAPHQL_QUERY_PUBLICALLY_AVAILABLE_INTEREST_GROUPS } from "app/queries";
import { useQuery } from "@apollo/client";
import { GenericErrorMessage } from "components/GenericErrorMessage";
import LoadingBar from "components/LoadingBar";
import { useUserTimezone } from "hooks/useUserTimezone";
import { filterSanghas } from "../filters/filterHelpers";

const useStyles = makeStyles((theme) => {
    return {
        heading: {
            marginBottom: 0,
            [theme.breakpoints.down("lg")]: {
                display: "none",
            },
        },
        description: {},
        subtitle: {},
        infoBox: {
            backgroundColor: theme.palette.neutralCool,
            padding: theme.spacing(3, 6),
            borderRadius: theme.borderRadius.default,
            marginBottom: theme.spacing(2),
            position: "relative",
        },
        infoIcon: {
            position: "absolute",
            left: -10,
            top: -10,
            height: 40,
            width: 40,
            color: theme.palette.accentGold,
        },
    };
});

export const InterestGroupListPage = () => {
    const [query, setQuery] = useQueryParams({
        topic: CustomArrayParam,
        timeOfDay: CustomArrayParam,
        dayOfWeek: CustomArrayParam,
        status: StatusParam,
        partner: StringParam,
        teacher: CustomArrayParam,
        audience: CustomArrayParam,
    });

    const classes = useStyles();

    const [groupCount, setGroupCount] = useState(0);

    const user = useSelector(selectUser);
    const isActiveMember = user?.features.includes(ProductFeature.CORE_MEMBERSHIP);

    const location = useLocation();
    const isSetupFlow = isUserInSetupFlow(location);

    const timezone = useUserTimezone();

    const isFreeTrialSignupFlow = isUserInFreeTrialSession() && (!isActiveMember || isSetupFlow);

    useLogEventOnLoad("View Groups", {
        isFreeTrial: isFreeTrialSignupFlow,
        cohort: getCurrentCohort(),
    });

    const {
        data: groupData,
        loading: groupsLoading,
        error: groupsError,
    } = useQuery(GRAPHQL_QUERY_PUBLICALLY_AVAILABLE_INTEREST_GROUPS);

    if (groupsError) {
        return <GenericErrorMessage />;
    }

    let allGroups: Sangha[] | undefined = groupData?.availableInterestGroups;

    const resetFilters = () => {
        setQuery({
            topic: undefined,
            timeOfDay: undefined,
            dayOfWeek: undefined,
            status: undefined,
            teacher: undefined,
            audience: undefined,
        });
    };

    const getSubtitle = () => {
        if (isFreeTrialSignupFlow) {
            return `Join as many groups as you like during your ${DEFAULT_FREE_TRIAL_DAYS}-day free trial. Choose your first group to get started.`;
        } else {
            return "Join as many groups as you like. Choose your first group to get started.";
        }
    };

    const filteredGroups = allGroups ? filterSanghas(allGroups, query, timezone) : undefined;

    return (
        <>
            {isSetupFlow && <SetupProgressBar currentStep={4} />}
            <PageWrapper>
                <div className={classes.infoBox}>
                    <InfoIcon className={classes.infoIcon} />
                    <Typography variant="h3" className={classes.heading}>
                        What are Mindful Friends Groups?
                    </Typography>
                    <Typography variant="body1" className={classes.description} sx={{ mb: 0 }}>
                        Mindful Friends Groups meet weekly online and are guided by highly-trained
                        teachers. They offer spaces for reflection and connection. Sessions include
                        meditation, practice in intimate breakouts, and engaging group sharing.
                    </Typography>
                </div>
                <Typography variant="h2" className={classes.heading}>
                    Choose your first group
                </Typography>
                <Typography variant="body1" className={classes.subtitle}>
                    {getSubtitle()}
                </Typography>

                {groupsLoading || !allGroups ? (
                    <LoadingBar />
                ) : (
                    <>
                        <InterestGroupFilter
                            filters={query}
                            setFilters={setQuery}
                            groupCount={groupCount}
                            resetFilters={resetFilters}
                            groups={allGroups}
                        />
                        <InterestGroupList
                            filters={query}
                            resetFilters={resetFilters}
                            setGroupCount={setGroupCount}
                            groups={filteredGroups || allGroups}
                        />
                    </>
                )}
            </PageWrapper>
        </>
    );
};
