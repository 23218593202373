import { useQuery } from "@apollo/client";
import { BRAND_NAME, MembershipStatus } from "@app/shared/constants";
import { Membership, ProductFeature } from "@app/shared/types";
import { GRAPHQL_QUERY_MEMBERSHIPS } from "app/queries";
import DocumentContext from "components/DocumentContext";
import { GenericErrorMessage } from "components/GenericErrorMessage";
import { GenericErrorPage } from "components/GenericErrorPage";
import { LoggedInUserProfileContext } from "features/auth/RequireAuthentication";
import { ContributePage } from "features/donation/ContributePage";
import { DonatePage } from "features/donation/DonatePage";
import { DonationSuccessPage } from "features/donation/DonationSuccessPage";
import ConflictResolutionPage from "features/pages/ConflictResolutionPage";
import { IntentionDetailPage } from "features/pages/IntentionDetailPage";
import { SetupShowYouAroundPage } from "features/setup/SetupShowYouAroundPage";
import { SetupWelcomeSessionPage } from "features/setup/SetupWelcomeSessionPage";
import { useIsUserEnrolledInCourses } from "hooks/useIsUserEnrolledInCourses";
import { SetIsMember } from "hooks/useMemberTracking";
import _ from "lodash";
import { useContext } from "react";
import { useSelector } from "react-redux";
import { Redirect, Route, Switch, useLocation } from "react-router";
import { routes } from "../../app/routes";
import {
    selectCanAccessMemberZone,
    selectIsMember,
    selectIsTeacherOrAdmin,
    selectUser,
} from "../auth/auth";
import LoadingPage from "../pages/LoadingPage";
import { MemberAccountPage } from "./account/MemberAccountPage";
import { AddOnMentorshipGroupPage } from "./addOnMentorshipGroup/AddOnMentorshipGroupPage";
import { ReviewMentorshipAddOn } from "./addOnMentorshipGroup/ReviewMentorshipAddOn";
import { MemberHomePage } from "./dashboard/MemberHomePage";
import { MemberSchedulePage } from "./dashboard/MemberSchedulePage";
import { MemberSessionsRightNowPage } from "./dashboard/MemberSessionsRightNowPage";
import { InterestGroupConfirmationPage } from "./interestGroups/InterestGroupConfirmationPage";
import { InterestGroupPage } from "./interestGroups/InterestGroupPage";
import { LoungePage } from "./lounge/LoungePage";
import { MemberContext } from "./MemberContext";
import { CancellationModal } from "./membership/CancellationModal";
import { MembershipCanceled } from "./membership/MembershipCanceled";
import { MembershipDeclined } from "./membership/MembershipDeclined";
import { MembershipNotFound } from "./membership/MembershipNotFound";
import UpdatePaymentMethod from "./membership/UpdatePaymentMethod";
import MySanghas from "./mySanghas/MySanghas";
import { MemberZoneEditProfilePage } from "./profile/MemberZoneEditProfilePage";
import { SetupEditProfilePage } from "./profile/SetupEditProfilePage";
import { InterestGroupConfirmationDialogTest } from "./sangha/InterestGroupConfirmationDialogTest";
import { MemberSangha } from "./sangha/MemberSangha";
import { SessionsPage } from "./sessions/SessionsPage";
import { SanghaGroupDetailPage } from "./sangha/SanghaGroupDetailPage";
import { TeachersPage } from "./teachers/TeachersPage";
import { RecommendationsListPage } from "features/pages/RecommendationsListPage";

export { MemberContext };

export const EnsureActiveMembership = (props: { children: JSX.Element }) => {
    const user = useContext(LoggedInUserProfileContext);
    const userProfile = user.profile;

    const isImmersion = user.features.includes(ProductFeature.DEDICATED_SANGHA);

    const {
        data: membershipData,
        loading: membershipsAreLoading,
        error: membershipsError,
    } = useQuery(GRAPHQL_QUERY_MEMBERSHIPS, {
        fetchPolicy: "network-only",
    });

    const memberships = (membershipData?.myMemberships || []) as Membership[];
    const activeMemberships = memberships.filter((m) => MembershipStatus.isActive(m.status));
    const canceledMemberships = memberships.filter((m) => m.status === MembershipStatus.Canceled);
    const pendingMemberships = memberships.filter((m) => m.status === MembershipStatus.Pending);
    const declinedMemberships = memberships.filter((m) => m.status === MembershipStatus.Declined);

    if (membershipsError) {
        return <GenericErrorPage />;
    }

    if (membershipsAreLoading) {
        return <LoadingPage />;
    }
    if (activeMemberships.length > 0) {
        if (!userProfile.acceptedCommitments) {
            return <Redirect to={routes.memberOnboarding()} />;
        }

        if (userProfile.isComplete) {
            const context = {
                memberships: activeMemberships,
            };
            return (
                <MemberContext.Provider value={context}>{props.children}</MemberContext.Provider>
            );
        } else {
            return <Redirect to={routes.setupEditProfilePage()} />;
        }
    } else if (pendingMemberships.length > 0) {
        const pendingMembership = _.last(pendingMemberships) as Membership;

        if (!isImmersion) {
            // We should only have immersion members in the pending state
            return <GenericErrorPage />;
        }

        return <SetupEditProfilePage />;
    } else if (canceledMemberships.length > 0) {
        return <MembershipCanceled />;
    } else if (declinedMemberships.length > 0) {
        const declinedMembership = declinedMemberships[0];
        return <MembershipDeclined email={declinedMembership.email} />;
    } else if (!user.features.length) {
        // bugfix: if member doesn't finish billing, take them to the MFG page
        return <Redirect to={routes.signup()} />;
    } else {
        return <MembershipNotFound />;
    }
};

const MemberZoneInner = () => {
    const location = useLocation();

    return (
        <>
            <DocumentContext title={BRAND_NAME} />
            <Switch>
                <Route
                    path={routes.addMentorshipGroup(":sanghaId")}
                    component={ReviewMentorshipAddOn}
                />
                <Route
                    path={routes.memberSanghaDetailsLegacy(":sanghaId")}
                    component={MemberSangha}
                />
                <Route
                    path={routes.memberSanghaDetails(":sanghaId")}
                    component={SanghaGroupDetailPage}
                />

                <Route path={routes.memberSanghas()} component={MySanghas} />
                <Route path={routes.memberSanghasDeprecated()} component={MySanghas} />
                <Route
                    path={routes.memberMembership(":stripeResult")}
                    component={MemberAccountPage}
                />
                <Route path={routes.memberMembership()} component={MemberAccountPage} />
                <Route path={routes.cancelMembership()} component={CancellationModal} />
                <Route path={routes.memberSchedulePage()} component={MemberSchedulePage} />
                <Route
                    path={routes.memberSessionsRightNow()}
                    component={MemberSessionsRightNowPage}
                />

                <Route
                    exact
                    path={routes.editProfilePage()}
                    component={MemberZoneEditProfilePage}
                />
                <Route
                    exact
                    path={routes.setupEditProfilePage()}
                    component={SetupEditProfilePage}
                />
                <Route
                    exact
                    path={routes.setupWelcomeSession()}
                    component={SetupWelcomeSessionPage}
                />
                <Route
                    exact
                    path={routes.setupShowYouAround()}
                    component={SetupShowYouAroundPage}
                />

                <Route path={routes.memberTeachersPage()} component={TeachersPage} />
                <Route path={routes.conflictResolution()} component={ConflictResolutionPage} />
                <Route path={routes.memberContribute()} component={ContributePage} />
                <Route path={routes.memberDonationSuccess()} component={DonationSuccessPage} />
                <Route path={routes.memberDonate()} component={DonatePage} />

                <Route
                    path={routes.memberConfirmInterestGroup(":groupId")}
                    component={InterestGroupConfirmationPage}
                />
                <Route
                    path={routes.memberAddInterestGroup(":groupId")}
                    component={InterestGroupPage}
                />

                <Route
                    path={routes.setupAddMentorshipGroup(":sanghaId")}
                    component={ReviewMentorshipAddOn}
                />

                <Route
                    path={routes.setupAllMentorshipGroups()}
                    component={AddOnMentorshipGroupPage}
                />

                <Route
                    path={routes.testInterestGroupGuidelines()}
                    component={InterestGroupConfirmationDialogTest}
                />
                <Route path={routes.memberHomePage()} component={MemberHomePage} />
                <Route path={routes.memberUpdatePaymentMethod()} component={UpdatePaymentMethod} />
                {/* <Route path={routes.courseEmbeddedLandingPage(":courseId")} component={CourseEmbeddedLandingPage} /> */}

                <Route path={routes.memberSessions()} component={SessionsPage} />
                <Route path={routes.lounge()} component={LoungePage} />

                <Route path={routes.intentionDetailPage()} component={IntentionDetailPage} />
                <Route path={routes.recommendationsListPage()} component={RecommendationsListPage} />

                <Redirect to={routes.memberHomePage()} />
            </Switch>
        </>
    );
};

export const MemberZone = (props: { location: { search: any; state: any } }) => {
    const isMember = useSelector(selectIsMember); // isMember does not say if someone has a membership, but only if they are a user that is not a teacher or admin
    const isTeacherOrAdmin = useSelector(selectIsTeacherOrAdmin);
    const isEnrolledInCourses = useIsUserEnrolledInCourses();
    const canAccessMemberZone = useSelector(selectCanAccessMemberZone);
    const user = useSelector(selectUser);

    if (!user) {
        return <LoadingPage />;
    }

    if (isMember && !canAccessMemberZone && isEnrolledInCourses) {
        return <Redirect to={routes.coursesDashboard()} />;
    } else if (isMember) {
        /* Sets a cookie to track whether the user is a member or not. */
        SetIsMember(isMember);
        return (
            <EnsureActiveMembership>
                <MemberZoneInner />
            </EnsureActiveMembership>
        );
    } else if (isTeacherOrAdmin) {
        return <MemberZoneInner />;
    } else {
        return <GenericErrorMessage />;
    }
};

export default MemberZone;
