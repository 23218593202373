import LoadingPage from "./LoadingPage";

// const useStyles = makeStyles((theme) => ({
//     root: {
//         padding: theme.spacing(4),
//     },

//     teamTiles: {
//         marginTop: theme.spacing(4),
//     },

//     section: {
//         paddingTop: theme.spacing(4),
//         paddingBottom: theme.spacing(10),
//         maxWidth: faqWidth,
//         margin: "0 auto",
//     },
// }));

export const NOTION_FAQ_PAGE =
    "https://banyantogether.notion.site/Frequently-Asked-Questions-2d9bb331c7744ce7920967af4b646216";

export const FAQPage = () => {
    window.location.href = NOTION_FAQ_PAGE;
    return <LoadingPage />;

    // const classes = useStyles();

    // if (isFeatureEnabled(SiteFeature.MembershipRedesign_ExplorerOnboarding)) {
    //     window.location.href = NOTION_FAQ_PAGE;
    //     return <LoadingPage />;
    // }

    // return (
    //     <PageWrapper className={classes.root}>
    //         <DocumentContext title="Frequently asked questions" />
    //         <Typography variant="h2">Frequently asked questions</Typography>

    //         <Box className={classes.section}>
    //             <Typography variant="h3">Mentorship groups</Typography>

    //             <FaqSection
    //                 questions={faqQuestions["sangha"]}
    //                 faqSectionId="faq-page"
    //             />
    //         </Box>

    //         <Box className={classes.section}>
    //             <Typography variant="h3">Membership</Typography>

    //             <FaqSection
    //                 questions={faqQuestions["membership"]}
    //                 faqSectionId="faq-page"
    //             />
    //         </Box>

    //         <FloatingButton to={routes.join()}>
    //             Join our community
    //         </FloatingButton>
    //     </PageWrapper>
    // );
};

export default FAQPage;
