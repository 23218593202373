import { Button, Link } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { analyticsTrack } from "app/analytics/track";
import { useEffect, useState } from "react";
import * as React from "react";
import { CSDialog, CSDialogProps } from "./CSDialog";

const useStyles = makeStyles((theme) => ({
    icon: {
        verticalAlign: "middle",
        marginRight: theme.spacing(1),
    },
}));

export interface InfoPopupProps {
    linkText: string;
    // The amplitude event ID is added to the analytics event logged when the popup is opened
    amplitudeEventId: string;
    hideInfoIcon?: boolean;
    children: React.ReactNode;
    modalProps?: Partial<CSDialogProps>;
    classes?: Partial<ReturnType<typeof useStyles>>;
    modalOpen?: boolean;
    dataTestId?: string;
}

export const InfoPopup = React.memo((props: InfoPopupProps) => {
    const [modalOpen, setModalOpen] = useState(false);

    useEffect(() => {
        if (props.modalOpen !== undefined) {
            setModalOpen(props.modalOpen);
        }
    }, [props.modalOpen]);

    const classes = useStyles(props);

    return (
        <>
            <Button
                variant="tertiary"
                onClick={(ev: React.MouseEvent) => {
                    analyticsTrack("infoPopup.open", {
                        id: props.amplitudeEventId,
                    });
                    setModalOpen(true);
                    ev.preventDefault();
                }}
                data-testid={props.dataTestId}
            >
                {!props.hideInfoIcon && <InfoOutlinedIcon className={classes.icon} />}
                {props.linkText}
            </Button>
            <CSDialog {...props.modalProps} onClose={() => setModalOpen(false)} open={modalOpen}>
                {props.children}
            </CSDialog>
        </>
    );
});
