import { Button, Fade, TextField, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { routes } from "app/routes";
import { TwoColumnLayout } from "layouts/TwoColumnLayout";
import { BackLinkButton } from "components/BackButton";
import {
    StepProps,
    TeacherPostSessionFlowData,
    TeacherPostSessionFlowStep,
} from "./postSessionFlowTypes";
import clsx from "clsx";
import { CSTextField } from "components/CSTextField";

const useStyles = makeStyles((theme) => ({
    heading: {
        marginTop: theme.spacing(6),
    },

    signatureBox: {
        textAlign: "right",
    },

    signatureInput: {
        width: 180,
    },

    signatureInputRoot: {
        marginBottom: 0,
    },

    signatureName: {
        marginTop: 0,
    },
    fixedPosition: {
        position: "absolute",
    },
}));

const FADE_TIME = 500;

export const RecapSection = (props: StepProps) => {
    const classes = useStyles();

    const { recap } = props.data;

    const onContinue = () => {
        props.onSubmitStep(TeacherPostSessionFlowStep.REFLECTION_QUESTION);
    };

    const mkChangeHandler =
        <T extends keyof TeacherPostSessionFlowData["recap"]>(field: T) =>
        (value: TeacherPostSessionFlowData["recap"][T]) => {
            props.onChange({
                ...props.data,
                recap: {
                    ...recap,
                    [field]: value,
                },
            });
        };

    const renderSignature = () => (
        <Fade in={Boolean(recap.message)} timeout={FADE_TIME}>
            <div className={classes.signatureBox}>
                <CSTextField
                    data-testid="signatureInput"
                    className={classes.signatureInput}
                    value={recap.signature}
                    inputProps={{
                        style: { textAlign: "right" },
                    }}
                    InputProps={{
                        classes: { root: classes.signatureInputRoot },
                    }}
                    onChange={(value) => mkChangeHandler("signature")(value)}
                />
                <Typography
                    variant="h4"
                    data-testid="signatureName"
                    className={classes.signatureName}
                >
                    {props.teacherName}
                </Typography>
            </div>
        </Fade>
    );

    const renderRightColumn = () => (
        <Fade in={Boolean(recap.message)} timeout={FADE_TIME}>
            <Button
                data-testid="continueButton"
                variant="primary"
                disabled={!props.data.recap.signature || !props.data.recap.message}
                onClick={onContinue}
            >
                Continue to reflection question
            </Button>
        </Fade>
    );

    return (
        <TwoColumnLayout secondaryColumn={renderRightColumn()}>
            <BackLinkButton to={routes.memberSanghaDetails(props.sangha.id)} />
            <Fade in={!recap.message} timeout={FADE_TIME}>
                <Typography variant="h4" className={clsx(classes.heading, classes.fixedPosition)}>
                    Write some takeaways for your group
                </Typography>
            </Fade>

            <Fade in={Boolean(recap.message)} timeout={FADE_TIME}>
                <Typography variant="h4" className={classes.heading}>
                    Dear Sangha
                </Typography>
            </Fade>

            <CSTextField
                data-testid="recapInput"
                value={recap.message}
                onChange={(value) => mkChangeHandler("message")(value)}
                multiline
                fullWidth
                placeholder="Message"
                label="Message"
            />

            <Fade in={!recap.message} timeout={FADE_TIME}>
                <Typography variant="body1" className={classes.fixedPosition}>
                    It'll be sent to them and posted to the message board.
                </Typography>
            </Fade>

            {renderSignature()}
        </TwoColumnLayout>
    );
};
