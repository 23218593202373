import { Typography } from "@mui/material";
import { theme } from "app/theme";
import classNames from "classnames";
import { NavLink } from "features/navigation/NavLink";
import PageWrapper from "components/PageWrapper";
import { trackOpeningLounge } from "./loungeHelpers";

interface LoungeOpenedSuccessMessageProps {
    loungeUrl: string;
}

export const LoungeOpenedSuccessMessage = (props: LoungeOpenedSuccessMessageProps) => {
    const { loungeUrl } = props;

    return (
        <PageWrapper>
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                }}
            >
                <Typography
                    variant="h3"
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        color: theme.palette.primaryLeaves,
                        marginTop: theme.spacing(4),
                    }}
                >
                    <span
                        className={classNames("material-symbols-rounded")}
                        style={{
                            marginRight: theme.spacing(2),
                            display: "flex",
                            alignItems: "center",
                        }}
                    >
                        check
                    </span>
                    Tab opened successfully
                </Typography>
                <Typography variant="subtitle1">
                    Cannot find it?{" "}
                    <NavLink to={loungeUrl} onClick={() => trackOpeningLounge(loungeUrl)}>
                        Click here to open
                    </NavLink>
                </Typography>
            </div>
        </PageWrapper>
    );
};
