/* eslint-disable react/prop-types */

import { isValidEmail } from "@app/shared/utils";
import _ from "lodash";

export type ValidationResult = string | null;
export type ValidationResultMap = { [key: string]: ValidationResultBasicOrMap };
export type ValidationResultBasicOrMap = ValidationResult | ValidationResultMap;
export type Validator = (value: any) => ValidationResultBasicOrMap;

export const stringValidator = (props: { required: boolean }) => (value: string | undefined) => {
    if (props.required && (!value || value.length < 1)) {
        return "Please complete this field";
    }
    return null;
};
export const emailValidator = (props: { required: boolean }) => (value: string | undefined) => {
    if (props.required && (!value || value.length < 1)) {
        return "Please complete this field";
    }
    if (!value || !isValidEmail(value)) {
        return "Please enter a valid email address";
    }
    return null;
};
export const nonNullValidator = (props: { required: boolean }) => (value: string | undefined) => {
    if (props.required && !value) {
        return "Please complete this field";
    }
    return null;
};

export const noopValidator = (value: any) => {
    return null;
};
export const tosValidator = (value: boolean | undefined) => {
    if (!value) {
        return "Please accept the legal stuff above before continuing";
    }
    return null;
};

export const arrayValidator = (props: { required: boolean }) => (value: Array<any> | undefined) => {
    if (props.required && (!value || value.length < 1)) {
        return "Please complete this field";
    }
    return null;
};

export const dictionaryValidator =
    (validators: { [key: string]: Validator }) =>
    (value: any): ValidationResultMap =>
        _.reduce(
            validators,
            (prev, validator, fieldName) => {
                // run each validator if the field exists in value
                const field = value ? value[fieldName] : undefined;
                return { ...prev, [fieldName]: validator(field) };
            },
            {},
        );

export const validationResultHasErrors = (
    validationResult: ValidationResultBasicOrMap,
): boolean => {
    if (validationResult === null) {
        return false;
    }
    if (typeof validationResult === "string") {
        return true;
    }

    return _.find(validationResult as ValidationResultMap, validationResultHasErrors) !== undefined;
};
