import { Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { CSTextField } from "components/CSTextField";
import { CancellationFlow } from "./CancellationFlow";
import { routes } from "app/routes";
import { push } from "connected-react-router";
import { useDispatch } from "react-redux";
import { useState, useCallback, useContext } from "react";
import { LoggedInUserProfileContext } from "features/auth/RequireAuthentication";
import { ProductFeature } from "@app/shared/types";
import { analyticsTrack } from "app/analytics/track";
import { showSnackbarAlertOnRedirect } from "components/AlertSnackBar";
import LoadingBar from "components/LoadingBar";
import { useMutation } from "@apollo/client";
import { GRAPHQL_MUTATION_CANCEL_MEMBERSHIP, GRAPHQL_QUERY_MEMBERSHIPS } from "app/queries";
import { DateTime } from "luxon";
import { CancellationDateType } from "./CancellationFlowPage7";
import { MemberContext } from "../MemberContext";
import _ from "lodash";

const useStyles = makeStyles((theme) => ({
    textInput: {
        marginTop: theme.spacing(4),
        [theme.breakpoints.down("xl")]: {
            paddingBottom: theme.spacing(4),
        },
    },
}));

export const CancellationFlowPage6 = (props: {
    saveData: (data: { anythingElse: string }) => void;
    cancellationDateType: CancellationDateType;
}): JSX.Element => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [response, setResponse] = useState("");
    const [cancellingMembership, setIsCancellingMembership] = useState(false);

    const memberContext = useContext(MemberContext);
    const activeMemberships = memberContext.memberships;
    const membership = _.first(activeMemberships);

    const user = useContext(LoggedInUserProfileContext);

    const isImmersion = user.features.includes(ProductFeature.DEDICATED_SANGHA);

    const [cancelMembership] = useMutation(GRAPHQL_MUTATION_CANCEL_MEMBERSHIP, {
        variables: {
            membershipId: membership?.id,
            cancelDate:
                props.cancellationDateType === CancellationDateType.TODAY
                    ? DateTime.now()
                    : membership?.renewalDate,
        },

        refetchQueries: [
            {
                query: GRAPHQL_QUERY_MEMBERSHIPS,
            },
        ],
    });

    const handleNext = useCallback(async () => {
        if (response) {
            props.saveData({
                anythingElse: response,
            });
        }
        if (isImmersion) {
            dispatch(push(routes.cancelMembershipPage7()));
        } else {
            setIsCancellingMembership(true);
            await cancelMembership();
            showSnackbarAlertOnRedirect("Your membership has been cancelled");
            analyticsTrack(`cancellationFlow.cancelButton.click`);
            dispatch(push(routes.memberHomePage()));
        }
    }, [dispatch, props, response]);
    const handleResponseChange = useCallback(
        (value: string) => {
            setResponse(value);
        },
        [setResponse],
    );

    return (
        <CancellationFlow
            onNext={handleNext}
            pageId="anythingElse"
            continueButtonText={isImmersion ? "Continue" : "Confirm cancellation"}
            content={
                <>
                    <Typography variant="h2">
                        Is there anything else you'd like to share?
                    </Typography>
                    <Typography variant="body1">
                        If there's any final requests you have, let us know and we'll get back to
                        you via email.{" "}
                    </Typography>
                    <CSTextField
                        label={""}
                        value={response}
                        multiline={true}
                        rows={5}
                        onChange={handleResponseChange}
                        placeholder="Optional"
                        className={classes.textInput}
                    />
                    {cancellingMembership && <LoadingBar />}
                </>
            }
        />
    );
};
