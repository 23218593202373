import { useMutation, useQuery } from "@apollo/client";
import { ALL_ONBOARDING_ITEMS } from "@app/shared/constants";
import { Course, OnboardingItemType, SessionType } from "@app/shared/types";
import {
    alpha,
    Box,
    Divider,
    IconButton,
    LinearProgress,
    Paper,
    Popper,
    Typography,
    useMediaQuery,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { analyticsTrack } from "app/analytics/track";
import { GRAPHQL_MUTATION_MARK_ONBOARDING_ITEM_COMPLETE, GRAPHQL_QUERY_COURSE } from "app/queries";
import { routes } from "app/routes";
import { NAVBAR_HEIGHT_MOBILE, NAVBAR_HEIGHT_WITHOUT_SHADOW } from "app/styles";
import { theme } from "app/theme";
import classNames from "classnames";
import { CircularProgressContainer } from "components/CircularProgressContainer";
import { LoggedInUserProfileContext } from "features/auth/RequireAuthentication";
import { useHasUserAttendedSessionType } from "hooks/useHasUserAttendedSessionType";
import { MINDFUL_RELATING_COURSE_ID } from "@app/shared/constants";
import React, { useContext, useEffect, useMemo } from "react";
import { MindfulRelatingOnboardingItem } from "./MindfulRelatingOnboardingItem";
import { OnboardingItem } from "./OnboardingItem";

export const MAX_HEIGHT_FOR_POPUP = 770;
const maxHeightMediaQuery = `@media (max-height: ${MAX_HEIGHT_FOR_POPUP}px)`;

const useStyles = makeStyles(() => ({
    popup: {
        zIndex: theme.zIndex.modal,
        maxWidth: 370,
        [theme.breakpoints.down("sm")]: {
            overflow: "scroll",
            position: "fixed",
            maxWidth: "100%",
            width: "100%",
            height: "100%",
            top: NAVBAR_HEIGHT_MOBILE - 8,
            left: 0,
            borderRadius: 0,
        },
        [maxHeightMediaQuery]: {
            overflow: "scroll",
            position: "fixed",
            maxWidth: "100%",
            width: "100%",
            height: "100%",
            top: NAVBAR_HEIGHT_MOBILE - 8,
            left: 0,
            borderRadius: 0,
        },
    },
    popupHeader: {
        borderRadius: `${theme.borderRadius.default}px ${theme.borderRadius.default}px 0 0`,
        backgroundColor: theme.palette.neutralCool,
        paddingTop: 24,
        paddingLeft: 32,
        paddingRight: 32,
        paddingBottom: 16,
        display: "flex",
        alignItems: "flex-start",
        gap: 16,
        [theme.breakpoints.down("sm")]: {
            borderRadius: 0,
        },
        [maxHeightMediaQuery]: {
            borderRadius: 0,
        },
    },
    arrowLeft: {
        padding: 0,
    },
    popupHeading: {
        display: "flex",
    },
    popupBody: {
        padding: "0px 10px 10px 10px",
        [theme.breakpoints.down("sm")]: {
            height: "100%",
        },
        [maxHeightMediaQuery]: {
            height: "100%",
        },
    },
    closeButton: {
        position: "absolute",
        right: 5,
    },
}));

interface OnboardingPopupProps {
    popupOpen: boolean;
    setPopupOpen: (open: boolean) => void;
    anchorEl: HTMLElement | null;
    onCompletionUpdate: (uncompletedCount: number) => void;
}

export const OnboardingPopup = (props: OnboardingPopupProps) => {
    const { popupOpen, setPopupOpen, anchorEl, onCompletionUpdate } = props;
    const classes = useStyles();
    const user = useContext(LoggedInUserProfileContext);
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const windowHasSmallHeight = useMediaQuery(`(max-height: ${MAX_HEIGHT_FOR_POPUP}px)`, {
        noSsr: true,
    });
    const showFullScreenPopup = isMobile || windowHasSmallHeight;

    const [markOnboardingItemComplete] = useMutation(
        GRAPHQL_MUTATION_MARK_ONBOARDING_ITEM_COMPLETE,
    );

    const completeOnboardingItem = async (onboardingItem: string) => {
        await markOnboardingItemComplete({
            variables: {
                onboardingItem,
            },
        });
    };

    const {
        data: mindfulRelatingCourseData,
        loading: loadingCourse,
        refetch: refetchCourse,
    } = useQuery(GRAPHQL_QUERY_COURSE, {
        variables: { id: MINDFUL_RELATING_COURSE_ID },
    });
    const mindfulRelatingCourse = (mindfulRelatingCourseData?.course as Course) ?? null;

    const completedItemsFromUserProfile = user.profile.completedOnboardingItems;

    const hasAttendedWelcomeSessionFromUserProfile = completedItemsFromUserProfile?.includes(
        OnboardingItemType.FIRST_WELCOME_SESSION,
    );

    const hasAttendedMFGFromUserProfile = completedItemsFromUserProfile?.includes(
        OnboardingItemType.FIRST_MFG,
    );

    const hasAttendedTalkFromUserProfile = completedItemsFromUserProfile?.includes(
        OnboardingItemType.FIRST_TALK,
    );

    const hasAttendedMeditationFromUserProfile = completedItemsFromUserProfile?.includes(
        OnboardingItemType.FIRST_MEDITATION,
    );

    const { loading: loadingWelcomeSession, attended: hasAttendedWelcomeSession } =
        useHasUserAttendedSessionType(
            SessionType.WELCOME_SESSION,
            hasAttendedWelcomeSessionFromUserProfile,
        );

    const { loading: loadingMFG, attended: hasAttendedMFG } = useHasUserAttendedSessionType(
        SessionType.INTEREST_GROUP,
        hasAttendedMFGFromUserProfile,
    );

    const { loading: loadingTalk, attended: hasAttendedTalk } = useHasUserAttendedSessionType(
        SessionType.COMMUNITY_TALK,
        hasAttendedTalkFromUserProfile,
    );

    const { loading: loadingMeditation, attended: hasAttendedMeditation } =
        useHasUserAttendedSessionType(
            SessionType.COMMUNITY_SIT,
            hasAttendedMeditationFromUserProfile,
        );

    const hasCompletedMindfulRelatingCourse =
        mindfulRelatingCourse?.myCourseMembership?.status === "COMPLETED" ||
        mindfulRelatingCourse?.myCourseMembership?.status === "OPTED_OUT";

    const isLoading =
        loadingCourse || loadingWelcomeSession || loadingMFG || loadingTalk || loadingMeditation;

    // Array of data representing the onboarding items
    const onboardingData = useMemo(
        () => [
            {
                id: 1,
                title: "Attend a welcome & orientation session",
                completed: hasAttendedWelcomeSession,
                link: `${routes.memberSessions()}?onlyMyEvents=&sessionType=WELCOME_SESSION&teacher=&topic=&timeOfDay=&dayOfWeek=`,
                type: OnboardingItemType.FIRST_WELCOME_SESSION,
                userProfileValue: hasAttendedWelcomeSessionFromUserProfile,
            },
            {
                id: 2,
                title: "Attend your first mindful friends group",
                completed: hasAttendedMFG,
                link: `${routes.memberSessions()}?onlyMyEvents=&sessionType=INTEREST_GROUP&teacher=&topic=&timeOfDay=&dayOfWeek=`,
                type: OnboardingItemType.FIRST_MFG,
                userProfileValue: hasAttendedMFGFromUserProfile,
            },
            {
                id: 3,
                title: "Attend your first talk",
                completed: hasAttendedTalk,
                link: `${routes.memberSessions()}?onlyMyEvents=&sessionType=COMMUNITY_TALK&teacher=&topic=&timeOfDay=&dayOfWeek=`,
                type: OnboardingItemType.FIRST_TALK,
                userProfileValue: hasAttendedTalkFromUserProfile,
            },
            {
                id: 4,
                title: "Attend your first meditation",
                completed: hasAttendedMeditation,
                link: `${routes.memberSessions()}?onlyMyEvents=&sessionType=COMMUNITY_SIT&teacher=&topic=&timeOfDay=&dayOfWeek=`,
                type: OnboardingItemType.FIRST_MEDITATION,
                userProfileValue: hasAttendedMeditationFromUserProfile,
            },
            {
                id: 5,
                title: "Complete the mindful relating course",
                completed: hasCompletedMindfulRelatingCourse,
                link: mindfulRelatingCourse?.myCourseMembership
                    ? mindfulRelatingCourse?.url
                    : routes.courseEmbeddedLandingPage(mindfulRelatingCourse?.id),
                context: "course",
                course: mindfulRelatingCourse,
                type: OnboardingItemType.MINDFUL_RELATING_COURSE,
            },
        ],
        [
            hasAttendedWelcomeSession,
            hasAttendedMFG,
            hasAttendedTalk,
            hasAttendedMeditation,
            hasCompletedMindfulRelatingCourse,
            mindfulRelatingCourse,
        ],
    );

    onboardingData.sort((a, b) => {
        // If both items are either completed or not completed, sort by id
        if (a.completed === b.completed) {
            return a.id - b.id;
        }
        // If a is completed and b is not, a should come after b
        if (a.completed && !b.completed) {
            return 1;
        }
        // If b is completed and a is not, b should come after a
        return -1;
    });

    // Create JSX elements based on onboarding data
    const onboardingItems = onboardingData.map((item, index) => {
        const isLastItem = index === onboardingData.length - 1;
        const commonProps = {
            key: item.id,
            completed: item.completed,
            title: item.title,
            link: item.link,
        };

        const itemComponent =
            item.context === "course" ? (
                <MindfulRelatingOnboardingItem
                    {...commonProps}
                    course={item.course}
                    onOptOut={refetchCourse}
                />
            ) : (
                <OnboardingItem {...commonProps} />
            );

        return (
            <React.Fragment key={item.id}>
                {itemComponent}
                {!isLastItem && <Divider sx={{ m: 0 }} />}
            </React.Fragment>
        );
    });

    const completedCountFromUserProfile = completedItemsFromUserProfile?.length || 0;
    const completedCountFromClient = onboardingData.filter((item) => item.completed).length;
    const completedCount = Math.max(completedCountFromUserProfile, completedCountFromClient);

    const totalItems = ALL_ONBOARDING_ITEMS.length;

    useEffect(() => {
        onCompletionUpdate(completedCount);
    }, [completedCountFromClient]);

    useEffect(() => {
        onboardingData.forEach((item) => {
            if (item.completed && !item.userProfileValue) {
                completeOnboardingItem(item.type);
                analyticsTrack("onboarding.item.completed", { item: item.type, userId: user.id });
            }
        });
    }, [onboardingData]);
    return (
        <div>
            <Popper
                open={popupOpen}
                anchorEl={showFullScreenPopup ? null : anchorEl}
                placement={showFullScreenPopup ? undefined : "top-start"}
                className={classes.popup}
                data-testid="onboardingPopup"
                popperOptions={{
                    modifiers: [
                        {
                            name: "offset",
                            options: {
                                offset: [0, 20],
                            },
                        },
                    ],
                    strategy: "fixed",
                }}
                style={{ top: showFullScreenPopup ? NAVBAR_HEIGHT_WITHOUT_SHADOW : undefined }}
            >
                {isLoading ? (
                    <CircularProgressContainer />
                ) : (
                    <Paper className={classes.popup}>
                        <IconButton
                            className={classes.closeButton}
                            onClick={() => setPopupOpen(false)}
                        >
                            <span className={classNames("material-symbols-rounded")}>close</span>
                        </IconButton>
                        <div className={classes.popupHeader}>
                            {!!showFullScreenPopup && (
                                <IconButton
                                    onClick={() => setPopupOpen(false)}
                                    className={classes.arrowLeft}
                                >
                                    <span className={classNames("material-symbols-rounded")}>
                                        chevron_left
                                    </span>
                                </IconButton>
                            )}
                            <div>
                                <Typography variant="h3" sx={{ mb: 0 }}>
                                    Your first steps
                                </Typography>
                                <Typography variant="body1" sx={{ mb: 0 }}>
                                    Complete these steps to set yourself up for success.
                                </Typography>
                                <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                                    <LinearProgress
                                        value={
                                            totalItems > 0 ? (completedCount / totalItems) * 100 : 0
                                        }
                                        sx={{
                                            color: theme.palette.primaryLeaves,
                                            backgroundColor: alpha(
                                                theme.palette.primaryBanyan,
                                                0.2,
                                            ),
                                            flexGrow: 1,
                                            borderRadius: `${theme.borderRadius.small}px`,
                                            height: 7,
                                        }}
                                        variant="determinate"
                                    />
                                    <Typography variant="caption">
                                        {completedCount}/{totalItems}
                                    </Typography>
                                </Box>
                            </div>
                        </div>

                        <div className={classes.popupBody}>{onboardingItems}</div>
                    </Paper>
                )}
            </Popper>
        </div>
    );
};
