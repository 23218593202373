import { Teacher } from "@app/shared/types";
import { createSlice } from "@reduxjs/toolkit";
import { GRAPHQL_QUERY_TEACHERS } from "app/queries";
import { apolloClient } from "../../app/apollo";
import {
    createErrorLoggingAsyncThunk,
    defaultFetchedState,
    fetchedObjectReducers,
    FetchedObjectState,
} from "../../app/fetchers";
import { RootState } from "../../app/store";

export const fetchTeachers = createErrorLoggingAsyncThunk(
    "community/fetchTeachers",
    async (params: {}, thunkAPI): Promise<Teacher[]> => {
        const result: any = await apolloClient.query({
            query: GRAPHQL_QUERY_TEACHERS,
        });

        return result.data.teachers.filter((teacher: Teacher) => teacher.published);
    },
);

export const selectTeachers = (state: RootState): Teacher[] | undefined =>
    state.community.teachers.result;

export const selectTeachersIsLoading = (state: RootState): boolean =>
    state.community.teachers.loading;

export const selectTeachersHaveLoaded = (state: RootState): boolean =>
    state.community.teachers.loaded;

const communitySlice = createSlice({
    name: "community",
    initialState: {
        teachers: defaultFetchedState() as FetchedObjectState<Teacher[]>,
    },
    reducers: {},
    extraReducers: {
        ...fetchedObjectReducers(fetchTeachers, (state) => state.teachers),
    },
});

export default communitySlice.reducer;
