import { Snackbar, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { Button, CircularProgress } from "@mui/material";
import { theme } from "app/theme";

import { useMutation } from "@apollo/client";
import { INTENTION_SUMMARY_CHAR_LIMIT } from "@app/shared/constants";
import { analyticsTrack } from "app/analytics/track";
import { GRAPHQL_MUTATION_UPDATE_INTENTION_SUMMARY } from "app/queries";
import { CSTextField } from "components/CSTextField";
import { useState } from "react";

const useStyles = makeStyles((theme) => ({
    intentionSummary: {
        minWidth: 300,
        maxWidth: 550,
        marginBottom: 0,

        "& .MuiInputBase-root": {
            backgroundColor: theme.palette.neutralGold,

            "&.Mui-disabled": {
                backgroundColor: theme.palette.neutralGold,
            },
        },
        "& .MuiInputBase-input": {
            color: theme.palette.grey900,

            "&.Mui-disabled": {
                color: theme.palette.grey800,
                "-webkit-text-fill-color": theme.palette.grey800,
                opacity: 0.7,
            },
        },
        "& .MuiInputLabel-root": {
            color: theme.palette.grey800,
            fontWeight: theme.typography.fontWeightBold,

            "&.Mui-disabled": {
                color: theme.palette.grey800,
                fontWeight: theme.typography.fontWeightRegular,
            },
        },

        [theme.breakpoints.up("md")]: {
            minWidth: 442,
        },
    },
    intentionSummaryContainer: {
        display: "flex",
        flexWrap: "wrap",
        alignItems: "center",
        gap: theme.spacing(2),
        width: "100%",
    },
    intentionInputContainer: {},
    editIntentionButtonsContainer: {
        display: "flex",
        gap: theme.spacing(1),
    },
    snackBar: {
        marginTop: theme.spacing(12),
    },
    spinner: {
        marginRight: theme.spacing(1),
    },
}));

const isIntentionSummaryLengthValid = (intentionSummary: string = "") => {
    if (intentionSummary.length > INTENTION_SUMMARY_CHAR_LIMIT) {
        return false;
    }
    return true;
};

export const IntentionSummary = (props: {
    currentIntentionSummary: string;
    onUpdateIntentionSummary: (newSummary: string) => void;
}) => {
    const classes = useStyles();
    const { currentIntentionSummary: originalIntentionSummary, onUpdateIntentionSummary } = props;

    const [updateIntentionSummaryMutation, { loading: isSavingIntentionSummaryUpdate }] =
        useMutation(GRAPHQL_MUTATION_UPDATE_INTENTION_SUMMARY);

    const [localIntentionSummary, setLocalIntentionSummary] = useState<string>(
        originalIntentionSummary || "",
    );
    const [isEditingIntentionSummary, setIsEditingIntentionSummary] = useState(false);
    const [showIntentionSummaryCharacterLimit, setShowIntentionSummaryCharacterLimit] =
        useState(false);
    const [snackBarOpen, setSnackBarOpen] = useState<boolean>(false);

    const handleIntentionSummaryInputChange = (summaryInputValue: string) => {
        const isSummaryValid = isIntentionSummaryLengthValid(summaryInputValue);

        if (isSummaryValid) {
            setLocalIntentionSummary(summaryInputValue);
            setShowIntentionSummaryCharacterLimit(false);
        } else {
            setShowIntentionSummaryCharacterLimit(true);
        }
    };

    const cancelIntentionSummaryEdit = () => {
        setShowIntentionSummaryCharacterLimit(false);
        setLocalIntentionSummary(originalIntentionSummary);
        setIsEditingIntentionSummary(false);
    };

    const updateIntentionSummary = async () => {
        const isSummaryValid = isIntentionSummaryLengthValid(localIntentionSummary);

        if (isSummaryValid) {
            const { data } = await updateIntentionSummaryMutation({
                variables: { intentionSummary: localIntentionSummary },
            });
            if (data?.updateIntentionSummary) {
                analyticsTrack("Intention Summary Updated", {
                    originalIntentionSummary,
                    newIntentionSummary: localIntentionSummary,
                });
                setIsEditingIntentionSummary(false);
                setShowIntentionSummaryCharacterLimit(false);
                setSnackBarOpen(true);

                // Update the parent component's intention state
                onUpdateIntentionSummary(localIntentionSummary);
            }
        } else {
            setShowIntentionSummaryCharacterLimit(true);
        }
    };

    return (
        <>
            <Snackbar
                open={snackBarOpen}
                autoHideDuration={6000}
                onClose={() => setSnackBarOpen(false)}
                message="Intention summary updated"
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
            />
            <div className={classes.intentionSummaryContainer}>
                <div className={classes.intentionInputContainer}>
                    <CSTextField
                        value={localIntentionSummary}
                        label="Summary of your intention"
                        disabled={!isEditingIntentionSummary}
                        onChange={(value: string) => {
                            handleIntentionSummaryInputChange(value);
                        }}
                        className={classes.intentionSummary}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                    {showIntentionSummaryCharacterLimit && (
                        <Typography variant="caption" sx={{ color: theme.palette.error500 }}>
                            Limit of {INTENTION_SUMMARY_CHAR_LIMIT} characters
                        </Typography>
                    )}
                </div>
                <div className={classes.editIntentionButtonsContainer}>
                    {!isEditingIntentionSummary ? (
                        <Button
                            size="small"
                            variant="secondary"
                            onClick={() => {
                                setIsEditingIntentionSummary(true);
                                analyticsTrack("Edit Intention Summary Button Clicked", {
                                    originalIntentionSummary,
                                });
                            }}
                            sx={{
                                minWidth: "auto",
                            }}
                        >
                            Edit
                        </Button>
                    ) : (
                        <>
                            <Button
                                size="small"
                                variant="secondary"
                                onClick={() => {
                                    cancelIntentionSummaryEdit();
                                }}
                                disabled={isSavingIntentionSummaryUpdate}
                                sx={{
                                    minWidth: "auto",
                                }}
                            >
                                Cancel
                            </Button>
                            <Button
                                size="small"
                                variant="primary"
                                sx={{
                                    minWidth: "auto",
                                }}
                                disabled={isSavingIntentionSummaryUpdate}
                                onClick={updateIntentionSummary}
                            >
                                {isSavingIntentionSummaryUpdate && (
                                    <CircularProgress size={20} className={classes.spinner} />
                                )}
                                Save
                            </Button>
                        </>
                    )}
                </div>
            </div>
        </>
    );
};

export default IntentionSummary;
