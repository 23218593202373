import { analyticsTrack } from "app/analytics/track";
import { localImageSrc } from "app/images";
import { MembershipChargeExplanationGlobalModal } from "features/landingPage/modals/MembershipChargeExplanationGlobalModal";
import { VideoModal } from "features/landingPage/modals/VideoModal";
import { ModalID } from "modals";
import { useEffect } from "react";
import { useLocation } from "react-router";

// For new modals that you add, add a modal ID to ./modals.ts
// Adding a hash of #modal_<modalID> to the current location will display that modal
// Eg: If I go to http://cloudsangha.co/intro/page1/#modal_whats_included, I'll see the home page with the "what's included" modal displayed
// Keep in mind that any redirects may remove the hash and hide the modal

export const modalRouteMap: Record<ModalID, React.ReactNode> = {
    [ModalID.membership_charge_explanation]: <MembershipChargeExplanationGlobalModal />,
    [ModalID.intro_video]: (
        <VideoModal
            thumbnailUrl={localImageSrc("landing/introvidthumb.jpg")}
            videoUrl="https://storage.googleapis.com/cloud-sangha-images/funnel-experiment-videos/Intro%20Video.mp4"
            videoId="landingPageFunnel.introVideo"
            captionsUrl="/subtitles/Intro%20Video.vtt"
            autoPlay
        />
    ),
    [ModalID.immersion_video]: (
        <VideoModal
            thumbnailUrl={localImageSrc("landing/immersionvidthumb.jpg")}
            videoUrl="https://storage.googleapis.com/cloud-sangha-images/funnel-experiment-videos/Immersion%20Video.mp4"
            videoId="landingPageFunnel.immersion"
            captionsUrl="/subtitles/Immersion%20Video.vtt"
            autoPlay
        />
    ),
    [ModalID.community_sessions_sample]: (
        <VideoModal
            thumbnailUrl={localImageSrc("landing/thumbnailCommunitySession.jpg")}
            videoUrl="https://storage.googleapis.com/cloud-sangha-images/landing-dec22-videos/sample_meditation_session.mp4"
            videoId="landingPage.communitySessionSample"
            // captionsUrl="/subtitles/Immersion%20Video.vtt"
            autoPlay
        />
    ),
    [ModalID.testimonial_danya]: (
        <VideoModal
            thumbnailUrl={localImageSrc("landing/danyaTestimonialThumbnail.jpg")}
            videoUrl="https://storage.googleapis.com/cloud-sangha-images/landing-sep22-videos/danyaTestimonial.mp4"
            videoId="landingPage.testimonial.danya"
            // captionsUrl="/subtitles/Immersion%20Video.vtt"
            autoPlay
        />
    ),
    [ModalID.testimonial_karen]: (
        <VideoModal
            thumbnailUrl={localImageSrc("landing/karenTestimonialThumbnail.jpg")}
            videoUrl="https://storage.googleapis.com/cloud-sangha-images/landing-sep22-videos/karenTestimonial.mp4"
            videoId="landingPage.testimonial.karen"
            // captionsUrl="/subtitles/Immersion%20Video.vtt"
            autoPlay
        />
    ),
    [ModalID.testimonial_silvia]: (
        <VideoModal
            thumbnailUrl={localImageSrc("landing/silviaTestimonialThumbnail.jpg")}
            videoUrl="https://storage.googleapis.com/cloud-sangha-images/landing-sep22-videos/silviaTestimonial.mp4"
            videoId="landingPage.testimonial.silvia"
            // captionsUrl="/subtitles/Immersion%20Video.vtt"
            autoPlay
        />
    ),
};

const useCurrentModalId = (): ModalID | null => {
    const reactRouterLocation = useLocation();

    const PREFIX = "#modal_";
    if (reactRouterLocation.hash.indexOf(PREFIX) === 0) {
        const modalId = reactRouterLocation.hash.slice(PREFIX.length) as ModalID;
        if (modalRouteMap[modalId]) {
            return modalId;
        } else {
            analyticsTrack("modal.missing", {
                modalId,
                currentPath: reactRouterLocation.pathname,
            });
            return null;
        }
    } else {
        return null;
    }
};

export const ModalRoutes = () => {
    const reactRouterLocation = useLocation();
    const modalId = useCurrentModalId();

    useEffect(() => {
        if (modalId) {
            analyticsTrack("modal.viewed", {
                modalId,
                currentPath: reactRouterLocation.pathname,
            });
        }
    }, [modalId]);

    if (modalId) {
        return <>{modalRouteMap[modalId]}</>;
    }

    return null;
};
